
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade && ! showUnits && ! showAddDept && ! showMembers">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>


        <div
            class="col-lg-12 text-right"
            v-if="! isLoadingBlade && ! showUnits && ! showAddDept && ! showMembers"
        >
            <button
                class="btn btn-lg btn-info"
                @click="showNewDepartment(true, 'Add')"
                :disabled="! isSuper"
            >
                <i class="fa fa-plus"></i> Add New Department
            </button>
            <br><br>
        </div>

        <div
            class="container ct-example-row"
            v-if="! isLoadingBlade && ! showUnits && ! showAddDept && ! showMembers"
        >
            <div class="row">
                <div
                    class="col-sm-4 text-center borderLine"
                    v-for="department in departments"
                    :key="department.id"
                >
                    <br>
                    <h4 class="text-capitalize height-50">
                        {{ department.name }}
                    </h4><br>
                    <base-button
                        size="sm" type="primary"
                        @click="displayUnits(department.id)"
                        :disabled="(privileges && privileges.view == 'no') || ! privileges"
                    >
                        <i class="fa fa-eye"></i>
                        View Units
                    </base-button>
                    <base-button
                        size="sm" type="dark"
                        @click="displayMembers(true, department)"
                        :disabled="(privileges && privileges.view == 'no') || ! privileges"
                    >
                        <i class="fa fa-user"></i>
                        All Members
                    </base-button>
                    <base-button
                        size="sm" type="info"
                        @click="showNewDepartment(true, 'Update', department)"
                        :disabled="! isSuper"
                    >
                        <i class="fa fa-pen"></i>
                        Edit
                    </base-button>
                    <br><br>
                    <base-button
                        size="sm" type="danger"
                        @click="deleteDepartment(department.id)"
                        :disabled="department.units && department.units.length > 0"
                        v-if="isSuper"
                    >
                        <i class="fa fa-trash"></i>
                        Delete
                    </base-button>
                    <br><br>
                </div>
            </div>
        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus && ! showUnits && ! showMembers"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <!-- import Members template here -->
        <Units
            v-bind:units="units"
            v-bind:privileges="privileges"
            v-bind:changeTitle="changeTitle"
            v-bind:displayUnits="displayUnits"
            v-bind:updateUnits="updateUnits"
            v-bind:department="department"
            v-bind:loadDepartments="loadDepartments"
            v-on:closeUnitPage="closeUnitPage"
            v-if="showUnits && ! showAddDept && ! showMembers"
        />

        <!-- import Add Department template here -->
        <AddDepartment
            v-bind:department="department"
            v-bind:privileges="privileges"
            v-bind:loadDepartments="loadDepartments"
            v-bind:showNewDepartment="showNewDepartment"
            v-on:showNewDepartment="showNewDepartment"
            v-if="showAddDept && ! showMembers"
        />

        <!-- import Department Members template here -->
        <DepartmentMembers
            v-bind:department="department"
            v-bind:privileges="privileges"
            v-bind:displayMembers="displayMembers"
            v-bind:changeTitle="changeTitle"
            v-on:displayMembers="displayMembers"
            v-if="showMembers"
        />

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import Units from './Units';
  import AddDepartment from './AddDepartment';
  import DepartmentMembers from './DepartmentMembers';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'Department',
    props: ['privileges'],
    components: {
        Units,
        AddDepartment,
        DepartmentMembers
    },
    data() {
      return {
        departments: [],
        units: [],
        isLoadingBlade: true,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        unit: null,
        showUnits: false,
        department: null,
        title: 'Departments',
        showAddDept: false,
        showMembers: false
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
        isSuper() {
            return (this.user && this.user.unit && this.user.unit.admin_department_id == 1);
        },
        isHod() {
            return this.user && this.user.is_hod == 'yes';// if user is HOD
        }
    },
    methods: {
        ...mapActions(['getAdminDepartments', 'deleteAdminDepartment', 'fetchUserProfile']),
        checkUserIsHod(menu) {
            let isHod = this.user && this.user.is_hod == 'yes';// if user is HOD
            let UserMenu = menu.menu_id == 4;// get User Mgt Menu
            let status = (isHod && UserMenu) ? 'yes' : 'no';
            return  { 'view' : status, 'add' : status, 'update' : status, 'delete' : status };
        },
        loadDepartments() {// load departments
        this.isLoadingBlade = true;
            this.fetchUserProfile().then((res) => {// get records
                this.getUserDepartments(res);
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        getUserDepartments(user) {
            this.getAdminDepartments().then((res) => {// get records
                this.isLoadingBlade = false;
                this.setData(res, user);
                this.emptyRecordsMsg();
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setData(data, user) {
            let dept_id = user && user.unit ? user.unit.admin_department_id : null;
            let isSuper = (dept_id == 1);
            let isHod = (user && user.is_hod == 'yes');
            if(isSuper) {
                this.departments = data;
            } else if(isHod) {
                for(let department of data) {
                    if(department.id == dept_id) {
                        this.departments.push(department);
                    }
                }
            }
        },
        emptyRecordsMsg() {
            return this.departments && this.departments.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Departments added yet / You don\'t neccessary rights!';
        },
        displayUnits(id) {// show units
            this.units = [];
            this.department = null;
            for(let department of this.departments) {
                if(department.id == id) {
                    this.units = department.units;
                    this.title = department.name+'\'s Department Units';
                    this.department = department;
                }
            }
            this.showUnits = true;
        },
        closeUnitPage() {// hide units
            this.units = null;
            this.title = 'departments'
            this.showUnits = false;
        },
        changeTitle(value) {// change title
            this.title = value;
        },
        showNewDepartment(value, type, dept) {// display new dept form
            let title = value ? type+' ' : 'Departments';
            let formatTitle = dept ? dept.name+'\'s Department' : 'Department';
            title +=  (formatTitle && type) ? formatTitle : '';
            this.title = title;
            this.department = dept;
            return this.showAddDept = value;
        },
        deleteDepartment(id) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall(id);// make api call
                }
            });
        },
        makeDeleteApiCall(id) {
            let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
            this.preloader(); // show loading
            this.deleteAdminDepartment(data).then((res) => {// delete department
                if(res.status) {
                    this.loadDepartments();// refresh page
                    swal.fire("Record Deleted", res.msg, "success");
                } else {
                    this.loadDepartments();// refresh page
                    swal.fire("Error Occurred!", res.msg, "warning");
                }
            }).
            catch(err => {
                this.loadDepartments();// refresh page
                swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
            });
        },
        updateUnits(units) {// update units
            return this.units = units;
        },
        displayMembers(value, dept) {// show depratment members
            this.department = dept
            this.showMembers = value;
            this.title = value ? dept.name+'\'s Department Members' : 'Departments';
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadDepartments(); // load departments
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
