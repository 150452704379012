
<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <base-input alternative=""
                    label="Country"
                    required
                >
                    <autocomplete
                        input-class="form-control form-control-alternative"
                        placeholder="Type Country"
                        showNoResults
                        :source="locations.countries"
                        :initialDisplay="(hasCountry ? hasCountry.name : '')"
                        resultsValue="name"
                        @selected="changeCountry"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School Name / Institution (Post-Graduate College)"
                    :disabled="schools && schools.length == 0"
                    required
                >
                    <autocomplete
                        v-if="schools && schools.length > 0"
                        ref="schoolAutocomplete"
                        input-class="form-control form-control-alternative"
                        placeholder="Type to search Institution"
                        showNoResults
                        :disableInput="schools && schools.length == 0"
                        :source="schools"
                        :initialDisplay="record.school_name"
                        @selected="changeInstitution"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-12" v-if="showOtherInstitution">
                <base-input alternative=""
                    label="Other School / Institution Name"
                    placeholder="Enter Other School / Institution Name"
                    input-classes="form-control-alternative"
                    v-model="record.other_school_name"
                    required
                />
            </div>

            <div class="col-lg-12 text-capitalize"
                v-if="(qualifications && qualifications.length > 0) ||
                        record.additional_qualification_config_id"
            >
                <base-input alternative=""
                    label="Qualification"
                    required
                >
                    <select
                        class="form-control form-control-alternative text-capitalize"
                        v-model="record.additional_qualification_config_id"
                        @change="getDegrees(null, true)"
                    >
                        <option value="null" disabled>--- Select One ---</option>
                        <option
                            v-for="qual in qualifications"
                            :key="qual.id"
                            :value="qual.id"

                        >
                            {{ qual.course_title.toLowerCase() +' [' + (qual.category ? qual.category.name : '') + ']' }}
                        </option>
                    </select>
                </base-input>
            </div>


            <div
                class="col-lg-12"
                v-if="(degrees && degrees.length > 0) || record.degree"
            >
                <base-input alternative=""
                    v-if="degrees[0] != ''"
                    label="Degree"
                    required
                    >
                    <div class="container">
                        <div class="row">
                            <base-radio
                                v-for="(degree, index) in degrees"
                                :key="index"
                                v-show="degree != ''"
                                :name="degree.trim()"
                                class="mb-3"
                                v-model="record.degree"
                            >
                                {{ degree }} &nbsp;&nbsp;&nbsp;
                            </base-radio>
                        </div>
                    </div>

                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input
                    label="Date of Additional Qualification Degree (Date you obtained the Qualification)"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.qualify_date"
                    >
                    </flat-picker>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Licencing Body"
                    placeholder="Enter Licencing Body"
                    input-classes="form-control-alternative text-capitalize"
                    v-model="record.licencing_body"
                    required
                />
            </div>


            <div class="col-lg-6">
                <base-input alternative=""
                    label="AQ Number"
                    placeholder="Enter AQ Number"
                    input-classes="form-control-alternative text-capitalize"
                    v-model="record.aq_number"
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Status"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.status"
                    >
                        <option value="null" disabled>--- Select One ---</option>
                        <option value="submitted">Submitted</option>
                        <option value="approved">Approved</option>
                    </select>
                </base-input>
            </div>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <hr class="my-4" />

        <div class="col-lg-12">
            <base-button
                type="success" size="lg"
                @click="updateAQ()"
                :disabled="(privileges && privileges.update == 'no') || ! privileges"
            >
                <i class="fa fa-save"></i>
                Save Changes
            </base-button>

            <base-button
                type="danger" size="lg"
                class="right"
                @click="$emit('closeEditPage')"
            >
                <i class="fa fa-times"></i>
                Cancel
            </base-button>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'AddNewQualification',
        props: ['newRecord','privileges', 'loadAddQualifications', 'closeEditPage', 'user', 'qualifications'],
        components: {
            flatPicker,
            Autocomplete
        },
        data() {
            return {
                record: {
                    country: {},
                    country_id: null,
                    school_name: null,
                    additional_qualification_category_id: null,
                    additional_qualification_config_id: null,
                    degree: null,
                    qualify_date: null,
                    licencing_body: null,
                    other_school_name: null,
                    aq_number: null,
                    status: 'submitted'
                },
                schools: [],
                degrees: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showProfile: false,
                showOtherInstitution: false,
                config: {
                    allowInput: true,
                    maxDate: "today",
                    altInput: true,// eslint-disable-next-line
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})],
                    theme: "dark" // defaults to "light"
                }
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['userPhotoUrl', 'blankImageUrl', 'locations']),
            years() {
                let startYear = moment().format('YYYY') - 119;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            hasCountry() {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.record.country_id) : null;
                return country ? country[0] : {};
            }
        },
        methods: {
            ...mapActions(['updateAdditionalQualification', 'getLocations', 'getExactInstitutions']),
            changeInstitution(e) {
                let data = e.selectedObject;
                this.record.other_school_name = null;
                this.showOtherInstitution = false;
                if(data.id == 0) {
                    this.showOtherInstitution = true;
                } else {
                    this.record.school_name = data.name;
                }
            },
            changeCountry(e) {// get exact institutions
                this.schools = [];
                this.record.country_id = null;
                this.record.school_name = null;
                this.record.other_school_name = null;
                let id =  e.selectedObject.id;
                this.record.country_id = id;
                this.preloader();
                this.getExactInstitutions(id).then((res) => {
                    swal.close();
                    this.schools = res.schools ? res.schools : [];
                    let other = { id : 0, name: 'Others (Specify)'};
                    this.schools.push(other);
                    this.$refs.schoolAutocomplete.clear();
                });
            },
            updateAQ() {
                let check = this.validatedInputs();
                if(check) {// update record
                    let data = this.record;
                    data.school_name = data.school_name ? data.school_name : data.other_school_name;
                    data.id = this.newRecord ? this.newRecord.id : null;
                    data.user_data_id = this.user.id;
                    delete data.other_school_name;// remove other school name
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.updateAdditionalQualification(data).then((res) => {// update AQ
                        this.loadAddQualifications();// load add qual records
                        if(res.status) {
                            this.closeEditPage();// hide edit page
                            swal.fire("Record Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadAddQualifications();// load add qual records
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let addRecord = { country_id: null, school_name: null, additional_qualification_config_id: null,
                        qualify_date: null, licencing_body: null, aq_number: null };
                addRecord.aq_number  = data.aq_number == null ? this.showInuptError('AQ Number') : data.aq_number;
                addRecord.licencing_body  = data.licencing_body == null ? this.showInuptError('Licencing Body') : data.licencing_body;
                addRecord.qualify_date  = data.qualify_date == null ? this.showInuptError('Qualification Date') : data.qualify_date;

                addRecord.additional_qualification_config_id  =
                    data.additional_qualification_config_id == null ?
                        this.showInuptError('Qualification') : data.additional_qualification_config_id;
                // validate institution
                if(data.school_name != null) {
                    addRecord.school_name = data.school_name;
                } else if(data.other_school_name != null) {
                    addRecord.school_name = data.other_school_name;
                } else {
                    this.showInuptError('School\'s Name');
                }
                addRecord.country_id  = (data.country_id <= 0) ? this.showInuptError('Country') : data.country_id;
                return this.isEmpty(addRecord) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            setData() {
                if(this.newRecord) {
                    let data = this.newRecord;
                    this.updateData(data);// update data
                    this.preloader();
                    this.getExactInstitutions(data.country_id).then((res) => {
                        swal.close();
                        this.schools = res.schools ? res.schools : [];
                        let other = { id : 0, name: 'Others (Specify)'};
                        this.schools.push(other);

                    });
                }
            },
            updateData(data) {// update data
                this.record.country = data.country;
                this.record.school_name = data.school_name;
                this.showOtherInstitution = false;
                this.record.additional_qualification_category_id = data.qualification.additional_qualification_category_id;
                this.record.additional_qualification_config_id = data.additional_qualification_config_id;
                this.record.degree = data.degree;
                this.record.qualify_date = data.qualify_date;
                this.record.licencing_body = data.licencing_body;
                this.record.aq_number = data.aq_number;
                this.record.country_id = data.country_id;
                this.record.status = data.status;
                this.getDegrees(data.additional_qualification_config_id);
            },
            getDegrees(data, status = false) {// get Qualification's degrees
                let id;
                if(status) {
                    this.record.additional_qualification_category_id = null;
                    id = this.record.additional_qualification_config_id;
                    let qual = this.qualifications.filter(item => item.id == id)[0];
                    if(qual) {// update category id
                        this.record.additional_qualification_category_id = qual.additional_qualification_category_id;
                    }
                } else {
                    id = data;
                }
                this.degrees = [];
                for(let qualification of this.qualifications) {
                    if(qualification.id == id) {
                        this.degrees = qualification.qualification.split(',');
                    }
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.getLocations();
            this.setData(); // set AQ Data
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>