
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if="! isLoadingBlade">
            <hr>
            <div class="row">
                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Inductee's Surname"
                        input-classes="form-control-alternative"
                        placeholder="Enter Surname"
                        v-model="record.surname"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Inductee's First Name"
                        input-classes="form-control-alternative"
                        placeholder="Enter First Name"
                        v-model="record.first_name"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Inductee's Other Names"
                        input-classes="form-control-alternative"
                        placeholder="Enter Other Names"
                        v-model="record.other_names"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Inductee's Folio Number"
                        input-classes="form-control-alternative"
                        placeholder="Enter Folio Number"
                        v-model="record.folio_no"
                        required
                    />
                </div>

            </div>
        </div>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <hr>
        <div class="pl-lg-4">
            <base-button
                type="success"
                @click="updateInductee()"
                :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
            >
                <i class="fa fa-save" @if='! isloading'></i>
                {{isloading ? 'Please Wait' : 'Update Changes'}}                
            </base-button>

            <base-button
                type="danger"
                class="right"
                @click="goBack()"
                :disabled="isloading"
            >
                <i class="fa fa-times"></i>
                Cancel                        
            </base-button>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'EditTicketBatchInductee',
        props: ['privileges', 'data', 'goBack'],
        components: {
            flatPicker
        },
        data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: true,
            record: {
                surname: null,
                first_name : null,
                other_names: null,
                folio_no: null
            },
            isloading: false
        }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
            years() {
                let startYear = moment().format('YYYY') - 2;// start from 1900
                let currentYear = parseInt(moment().format('YYYY')) + 2;
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['updateBatchInductee']),
            setData() {
                this.emptyFields();
                let inductee = this.data;
                if(inductee && inductee.id) {
                    this.record = {
                        surname: inductee.surname,
                        first_name : inductee.first_name,
                        other_names: inductee.other_names,
                        folio_no: inductee.folio_no,
                        id: inductee.id
                    };
                }
                this.isLoadingBlade = false;
            },
            updateInductee() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// add to sanction list
                    this.record.admin_id = this.user.id;// add admin id
                    this.updateBatchInductee(this.record).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            this.emptyFields();
                            this.goBack();
                            swal.fire("Inductee Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    surname: null,
                    first_name : null,
                    other_names: null,
                    folio_no: null
                };
            },
            validatedData() {
                let data = this.record;
                data.folio_no != null ? data.folio_no : this.showInuptError('Folio Number');
                return data.folio_no ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
</style>
<style scoped>
    .right {
        float: right;
    }
</style>
