
<template>
    <div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showErrorStatus">
            
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h3 class="text-uppercase">
                        Upload Intending Inductees
                    </h3>
                    <hr>
                </div>

                <div class="col-lg-6 text-center borderLine">
                    <br>
                    <h4 class="height-50 text-uppercase">
                        Download Inductees' Sample File
                    </h4><br><br>
                    <form :action="getRootUrl+'/print-inductees-sample'" method="POST">
                        <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                        <input
                            type="hidden" name="admin_id"
                            :value="user.id"
                        />

                        <base-button
                            size="sm"
                            type="info"
                            nativeType="submit"
                            :disabled="(privileges && privileges.view == 'no') || ! privileges"
                        >
                            <i class="fa fa-print"></i>
                            Download
                        </base-button>
                    </form>
                </div>

                <div class="col-lg-6 text-center borderLine">
                    <br>
                    <input
                        type="file"
                        id="file" style="display: none"
                        @change="onFileSelected"
                    >
                    <h4 class="height-50 text-uppercase">
                        Upload Inductees' File
                    </h4><br>
                    <em class="text-danger">{{ document.name }}</em><br>
                    <base-button
                        size="sm" type="primary"
                        @click="selectFile()"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading || showButton"
                    >
                        <i class="fa fa-hand-pointer"></i>
                        {{ document.selected ? 'Change File' : 'Select File' }}
                    </base-button>

                    <base-button
                        size="sm" type="success"
                        v-if="document.selected && document.file"
                        @click="uploadFile()"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading || showButton"
                    >
                        <i class="fa fa-times"></i> Upload File
                    </base-button>

                    <base-button
                        size="sm" type="danger"
                        v-if="document.selected"
                        @click="removeFile()"
                        :disabled="showButton"
                    >
                        <i class="fa fa-times"></i> Remove File
                    </base-button>
                    <br><br>

                </div>
            </div>

            <div class="row">
            
                <div class="col-lg-12">
                    <hr>
                    <base-button
                        type="danger"
                        @click="$emit('goBack')"
                        :disabled="isloading"
                    >
                        <i class="fa fa-arrow-left"></i>
                        Previous                        
                    </base-button>

                    <base-button
                        type="success"
                        class="right"
                        @click="completeProcess()"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading || ! showButton"
                    >
                        <i class="fa fa-save"></i>
                        Complete Process
                    </base-button>

                    
                </div>
            </div>
        </div>

        <div v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType" dismissible>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>
        </div>
    </div>
</template>
<script>
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'TicketBatchUpload',
        props: ['privileges', 'batch', 'displayAddPage'],
        data() {
            return {
                isLoadingBlade: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selected: null,
                document: {
                    name: null,
                    selected: false,
                    file: null,
                    ext: null
                },
                showButton: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user', 'getRootUrl', 'getAuthorizationToken'])
        },
        methods: {
            ...mapActions(['uploadTicketingFile']),
            selectFile() {// eslint-disable-next-line
                this.document = { name: null, selected: false, file: null, ext: null };
                $('#file').val('');
                this.selectedFileName = null;// eslint-disable-next-line
                $('#file').click();
            },
            onFileSelected(event) {
                let status =  this.validateFile(event.target.files[0]);
                if(status) {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(event.target.files[0]);
                    fileReader.onload = (event) => {// convert file to binary
                        this.document.file = event.target.result;
                    }
                    return status;
                }  
            },
            validateFile(file) {// check for valid file
                this.document = { name: null, selected: false, file: null, ext: null };
                if(file) {// update seleted file name
                    var fileExt = file.name.split('.').pop().toLowerCase();
                    let fileSize = file.size;
                    if(((fileExt == 'xlsx') || (fileExt == 'xls')) && fileSize <= 500000) {// for valid file and size
                        this.document.name = file.name;
                        this.document.selected = true;
                        this.document.ext = fileExt;
                        return true;
                    } else if((fileExt != 'xlsx') || (fileExt != 'Xlsx')) {// invalid file
                        swal.fire("Invalid File!", "Only Execl files are accepted", "warning");
                        return false;
                    } else if(fileSize > 500000) {// big file
                        swal.fire("File is too large!", "File should be less than 500KB", "warning");
                        return false;
                    }
                }                
            },
            removeFile() {// eslint-disable-next-line
                $('#file').val('');
                this.document = { name: null, selected: false, file: null, ext: null };
            },
            completeProcess() {
                swal.fire("Batch Completed", "Attachment Batch has been created successfully", "success");
                return this.displayAddPage(false);
            },
            uploadFile() {// upload file
                this.isloading = false;
                if(this.document.selected) {
                    this.preloader(); // show loading
                    this.isloading = true;
                    this.document.id = this.batch.id;
                    this.document.admin_id = this.user.id;
                    this.uploadTicketingFile(this.document).then((res) => {// upload function
                        this.isloading = false;
                        if(res.status) {
                            this.showButton = true; 
                            swal.fire("Inductees Uploaded", res.msg, "success");
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        swal.fire(err.message+"!", "An error occurred while uploading, try again", "warning");
                    });
                } else {
                    swal.fire("Error Occurred!", "Kindly reload the page and try again..", "warning");
                }                
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
<style scoped>
    .right {
        float: right;
    }
</style>
