
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showSeatStatus && ! showPassStatus">
            <div class="row">
                <div class="col-lg-12 text-right">
                    <button
                        class="btn btn-lg btn-danger"
                        v-if="showApplications"
                        @click="loadAplications(null)"
                    >
                        <i class="fa fa-reply"></i>
                        Back To Batches
                    </button>
                    <br><br>
                </div>
            </div>
            <div class="row" v-if="! showApplications">
                <div class="col-lg-2">
                    <base-input alternative="">
                        <select
                            class="form-control form-control-alternative"
                            v-model="page"
                            @change="getPageItems()"
                        >
                            <option
                                v-for="number in pageNumbers"
                                :key="number"
                                :value="number"
                            >
                                {{ number }}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-6"></div>
                <div class="col-lg-4 text-right">
                    <base-input alternative="">
                        <select
                            class="form-control form-control-alternative"
                            v-model="searchKey"
                            @change="searchRecords()"
                        >
                            <option :value="null" disabled selected>
                                --- Search By Year ---
                            </option>
                            <option
                                v-for="year in years"
                                :key="year"
                                :value="year"
                            >
                                {{ year }}
                            </option>
                        </select>
                    </base-input>
                </div>
            </div>

            <AssessmentApplicationData
                :records="records"
                :hasPagination="hasPagination"
                :pagination="pagination"
                :paginate="paginate"
                :privileges="privileges"
                :searchKey="searchKey"
                v-on:loadAplications="loadAplications"
                v-on:getSeatStatus="getSeatStatus"
                v-on:getPassStatus="getPassStatus"
                v-if="! showApplications && ! isLoadingBlade"
            />

            <template v-if="showApplications">
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Pending Applications">
                            <span slot="title">
                                <i class="ni ni-books"></i>
                                Pending Applications
                            </span>
                            <!-- import Pending Applications template here -->
                            <PendingApplications
                                :batch="batch"
                                :changeTitle="changeTitle"
                                :privileges="privileges"
                                :loadTitle="loadTitle"
                                :status="'submitted'"
                                v-if="batch && batch.id"
                            />
                        </tab-pane>

                        <tab-pane title="Rejected Applications">
                            <span slot="title">
                                <i class="ni ni-badge"></i>
                                Rejected Applications
                            </span>
                            <!-- import Rejected Applications template here -->
                            <RejectedApplications
                                :batch="batch"
                                :changeTitle="changeTitle"
                                :privileges="privileges"
                                :loadTitle="loadTitle"
                                v-if="batch && batch.id"
                            />
                        </tab-pane>

                        <tab-pane title="Completed Applications">
                            <span slot="title">
                                <i class="ni ni-archive-2"></i>
                                Completed Applications
                            </span>
                            <!-- import Completed Applications template here -->
                            <CompletedApplications
                                :batch="batch"
                                :changeTitle="changeTitle"
                                :privileges="privileges"
                                :loadTitle="loadTitle"
                                v-if="batch && batch.id"
                            />
                        </tab-pane>

                        <tab-pane title="Incomplete Applications">
                            <span slot="title">
                                <i class="ni ni-books"></i>
                                Incomplete Applications
                            </span>
                            <!-- import Incomplete Applications template here -->
                            <PendingApplications
                                :batch="batch"
                                :changeTitle="changeTitle"
                                :privileges="privileges"
                                :loadTitle="loadTitle"
                                :status="'pending'"
                                v-if="batch && batch.id"
                            />
                        </tab-pane>

                    </card>
                </tabs>
            </template>
        </div>

        <AssessmentSeatStatus
            :privileges="privileges"
            :changeTitle="changeTitle"
            :loadTitle="loadTitle"
            :batch="batch"
            v-on:getSeatStatus="getSeatStatus"
            v-if="showSeatStatus"
        />

        <AssessmentPassStatus
            :privileges="privileges"
            :changeTitle="changeTitle"
            :loadTitle="loadTitle"
            :batch="batch"
            v-on:getPassStatus="getPassStatus"
            v-if="showPassStatus"
        />

        <div class="col-sm-12 text-center" v-if='showErrorStatus && ! showSeatStatus && ! showPassStatus'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import PendingApplications from './PendingApplications';
  import RejectedApplications from './RejectedApplications';
  import CompletedApplications from './CompletedApplications';
  import AssessmentApplicationData from './AssessmentApplicationData.vue';
  import AssessmentSeatStatus from './AssessmentSeatStatus/AssessmentSeatStatus.vue';
  import AssessmentPassStatus from './AssessmentPassStatus/AssessmentPassStatus.vue'
  import moment from 'moment';

  export default {
    name: 'AssessmentApplication',
    props: ['batches', 'changeTitle', 'privileges', 'loadTitle'],
    components: {
        PendingApplications,
        RejectedApplications,
        CompletedApplications,
        AssessmentApplicationData,
        AssessmentSeatStatus,
        AssessmentPassStatus
    },
    data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: true,
            batch: null,
            showApplications: false,
            records: [],
            paginatedItems: [],
            pagination: {
                total: null,
                prev_page_url: null,
                next_page_url: null,
                current_page: null,
                last_page: null,
                number: 1,
                from: 0,
                to: null,
                perPage: this.$store.state.url.perPage // get per page count
            },
            page: 1,
            searchKey: null,
            showSeatStatus: false,
            showPassStatus: false
        }
    },
    computed: {
        ...mapGetters(['loadingBladeUrl', 'menus']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        },
        years() {
            let startYear = 2018;// start from 2019
            let currentYear = parseInt(moment().format('YYYY'));
            let years = [];
            for (let i = currentYear; i > startYear; i-- ) {
                years.push(i);
            }
            return years;
        }
    },
    methods: {
        ...mapActions(['getBatches', 'getPaginatedPaymentItems', 'getSearchedBatches']),
        getSeatStatus(data) {
            this.loadTitle();
            this.batch = data;
            this.showSeatStatus = ! this.showSeatStatus;
        },
        getPassStatus(data) {
            this.loadTitle();
            this.batch = data;
            this.showPassStatus = ! this.showPassStatus;
        },
        loadAplications(data) {
            this.loadTitle();
            this.batch = data;
            return this.showApplications = ! this.showApplications;
        },
        loadBatches() {// load details
            this.loadTitle();
            let data = { 'limit' : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getBatches(data).then((res) => {// get users
                this.isLoadingBlade = false;
                this.setPages(res.batches);//set data
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.batches.current_page;
                    this.setPages(res.batches);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        searchRecords() {
            this.showErrorStatus = false;
            let key = this.searchKey;
            if(key) {
                this.isLoadingBlade = true;
                let data = { 'key' : key };
                this.records = [];
                this.getSearchedBatches(data).then((res) => {// get records
                    this.isLoadingBlade = false;
                    this.records = res;
                    if(this.records.length == 0) {
                        this.showErrorStatus = true;
                        this.errorType = 'warning';
                        this.errorMsg = 'No Matched Record!!!';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
                this.emptyRecordsMsg();
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Assessment Batches yet!!!';
        }
    },
    mounted() {
      this.loadBatches();
    }
  };
</script>
<style></style>
