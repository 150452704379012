<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="card-profile-image marginTop40">
                            <img
                                :src="getCpdPix(record)"
                                class="rounded-circle doctor-image"
                                @error="replaceByDefault"
                            >
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Provider's Number (Username)"
                                    input-classes="form-control-alternative"
                                    v-model="record.username"
                                />
                            </div>
                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Provider's Name"
                                    input-classes="form-control-alternative text-capitalize"
                                    v-model="record.name"
                                />
                            </div>
                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Email Address"
                                    input-classes="form-control-alternative"
                                    v-model="record.email"
                                />
                            </div>
                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="Provider's Phone Number"
                                    placeholder="Phone Number"
                                    input-classes="form-control-alternative"
                                    required
                                >
                                <!-- v-model="profile.phone"  --> 
                                    <VuePhoneNumberInput
                                        v-model="tel.phone"
                                        @update="updatePhoneNumber"
                                        :default-country-code="tel.code"
                                        :no-example="true"
                                        :clearable="true"
                                    />
                                </base-input>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Application Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.type"
                            >
                                <option :value="null" disabled selected>--- Select One ---</option>
                                <option value="application">Application</option>
                                <option value="visitation">Visitation</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Provider's Website"
                            input-classes="form-control-alternative"
                            v-model="record.website"
                        />
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Category of Organization"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.category"
                            >
                                <option :value="null" disabled selected>--- Select One ---</option>
                                <option value="government">Government</option>
                                <option value="ngo">NGO</option>
                                <option value="private">Private</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Registration with CAC (Where Applicable)"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.cac"
                            >
                                <option :value="null" disabled selected>--- Select One ---</option>
                                <option value="limited-liability">Limited Liability</option>
                                <option value="plc">Plc</option>
                                <option value="enterprise">Enterprise/Business Name</option>
                            </select>
                        </base-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <base-input alternative=""
                label="Provider's Location">
                <textarea
                    rows="2"
                    class="form-control form-control-alternative"
                    v-model="record.location"></textarea>
            </base-input>
        </div>

        <div class="col-lg-12">
            <base-input alternative=""
                label="Experience/Evidence of previous Performance (if any)">
                <textarea
                    rows="2"
                    class="form-control form-control-alternative"
                    v-model="record.experience"></textarea>
            </base-input>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <hr class="my-4" />

        <div class="col-lg-12">
            <base-button
                type="success" size="lg"
                @click="updateProfile()"
                :disabled="(privileges && privileges.update == 'no') || ! privileges"
            >
                <i class="fa fa-save"></i>
                Save Changes
            </base-button>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'BasicProfile',
        props: ['record','privileges'],
        components: {
            VuePhoneNumberInput
        },
        data() {
            return {
                tel: {
                    phone: null,
                    code: 'NG',
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showProfile: false
            }
        },
        computed: {
            ...mapGetters(['cpdPhotoUrl', 'blankImageUrl'])
        },
        methods: {
            ...mapActions(['updateCpdProfile']),
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            updateProfile() {// update profile
                this.preloader(); // show loading
                let data = this.record;
                // delete data.username;
                this.updateCpdProfile({ profile_data : data }).then((res) => {// get records
                    if(res.status) {
                        swal.fire('Profile updated!', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            getCpdPix(data) {// get user photo url
                return data && data.photo ? this.cpdPhotoUrl+data.id+'/photo/'+data.photo : this.blankImageUrl;
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadPhoneNumber();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
    .doctor-image {
        height: 200px !important;
        width: 250px !important;
    }
</style>