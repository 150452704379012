
<template>
    <div>
        <div class="col-lg-12">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Pending Applications">
                            <span slot="title">
                                <i class="ni ni-books"></i>
                                Pending Applications
                            </span>
                            <!-- import Pending Applications template here -->
                            <PendingApplications 
                                :appType="'application'"
                                :status="'submitted'"
                                :type="'Pending'"
                            />
                        </tab-pane>

                        <tab-pane title="Rejected Applications">
                            <span slot="title">
                                <i class="ni ni-badge"></i>
                                Rejected Applications
                            </span>
                            <!-- import Rejected Applications template here -->
                            <PendingApplications 
                                :appType="'application'"
                                :status="'rejected'"
                                :type="'Rejected'"
                            />
                        </tab-pane>

                        <tab-pane title="Completed Applications">
                            <span slot="title">
                                <i class="ni ni-archive-2"></i>
                                Approved Applications
                            </span>
                            <!-- import Approved Applications template here -->
                            <PendingApplications 
                                :appType="'application'"
                                :status="'approved'"
                                :type="'Approved'"
                            />
                        </tab-pane>

                    </card>
                </tabs>
            </template>
        </div>
    </div>
</template>
<script>
    import PendingApplications from './PendingApplications/PendingApplications';

    export default {
        name: 'CpdApplications',
        components: {
            PendingApplications
        }
    };
</script>
<style></style>
