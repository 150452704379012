
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-sm-12 text-center" v-if='! isLoadingBlade && showErrorStatus'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="container" v-if="! isLoadingBlade">

            <div class="row">
                <div class="col-lg-12">
                    <h2 class="text-uppercase text-center" v-html="batchTitle"></h2>
                    <hr>
                </div>

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="centres"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="38%">Name</th>
                            <th width="20%" class="text-center">Quota</th>
                            <th width="20%" class="text-center">Applicants No</th>
                            <th width="20%" class="text-center">Available Space</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td class="budget">
                                {{ index+1 }}
                            </td>
                            <td class="text-capitalize">
                                {{ row.name ? row.name : 'N/A' }}
                            </td>
                            <td class="text-center">
                                {{ row.quota }}
                            </td>
                            <td class="text-center">
                                {{ row.applicants_count }}
                            </td>
                            <td class="text-center">
                                {{ row.space }}
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- <div 
                    class="col-lg-4 text-center borderLine"
                    v-for="(centre, index) in centres"
                    :key="index"
                >
                    <h4 class="marginTop">{{ centre.centre.name }}</h4>

                    <GChart
                        type="PieChart"
                        :data="centre.data"
                        :options="options"
                    />   
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import { GChart } from "vue-google-charts";

    export default {
        name: 'AttachmentDashboard',
        props: ['changeTitle', 'privileges', 'loadTitle'],
        components: {
            GChart
        },
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                centres: [],
                batch: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            batchTitle() {
                let batch = this.batch;
                return '<em class="text-danger">'+batch.name+' Batch '+ batch.code+' - '
                    +batch.year+'</em> Attachment Program Quota Distribution';
            }
        },
        methods: {
            ...mapActions(['getAttachmentDashboardInfo']),
            loadAttachmentDashboard() {// load details
                this.loadTitle();
                this.showErrorStatus = false;
                this.getAttachmentDashboardInfo().then((res) => {// get users
                    this.isLoadingBlade = false;
                    this.centres = res.centres;// set centres
                    this.batch = res.batch;
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
        },
        mounted() {
            this.loadAttachmentDashboard();
        }

    }
</script>
<style>
    .borderLine {
        border: 2px solid #ededed;
        background-color: #ffffff !important;
    }
    .marginTop {
        margin-top: 10px;
    }
</style>
