
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="8%">Name</th>
                        <th width="5%">Type</th>
                        <th width="5%">Age</th>
                        <th width="5%">Gender</th>
                        <th width="5%">Nationality</th>
                        <th width="5%">State/LGA<br> Origin</th>
                        <th width="5%">State/LGA<br> Practice</th>
                        <th width="5%">Specialization/<br> Sub Specialization</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td
                            class="text-capitalize"
                            v-if="row.basic_profile"
                        >
                            <span v-html="breakWord(getFullName(row.basic_profile), 10)"></span><br>
                            <badge size="lg"
                                type="primary"
                                class="text-capitalize"
                                v-if="row.basic_profile.folio_number"
                            >
                                {{ row.basic_profile.folio_number  }}
                            </badge>
                        </td>
                        <td>
                            <span
                                v-if="row.basic_profile.get_doctor_type"
                                v-html="breakWord(row.basic_profile.get_doctor_type.name, 8)"
                            >
                            </span>
                            <span v-if="! row.basic_profile.get_doctor_type">N/A</span><br>
                            <badge size="lg"
                                type="success"
                                class="text-capitalize"
                                v-if="row.basic_profile.has_sub_registration_type"
                                v-html="breakWord(getRegType(row.basic_profile.has_sub_registration_type), 8)"
                            >
                            </badge>
                        </td>
                        <td>
                            {{ row.basic_profile.date_of_birth | getAge }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.basic_profile.gender ? row.basic_profile.gender : 'N/A' }}
                        </td>
                        <td>
                            <span
                                v-if="row.basic_profile.has_nationality"
                            v-html="breakWord(row.basic_profile.has_nationality.name, 8)"
                            >
                            </span>
                            <span v-if="! row.basic_profile.has_nationality">N/A</span>
                        </td>
                        <td>
                            <span v-html="breakWord(getUserState(row.basic_profile), 8)"></span><br>
                            <badge size="lg"
                                type="success"
                                class="text-capitalize"
                                v-if="row.basic_profile.has_lga"
                                v-html="breakWord(row.basic_profile.has_lga.name, 8)"
                            >
                            </badge>
                            <badge size="lg"
                                type="warning"
                                class="text-capitalize"
                                v-if="! row.basic_profile.has_lga"
                            >
                                N/A
                            </badge>
                        </td>
                        <td>
                            <span
                                v-if="row.basic_profile.has_practice_state"
                                v-html="breakWord(row.basic_profile.has_practice_state.name, 8)"
                            >
                            </span>
                            <span v-if="! row.basic_profile.has_practice_state">N/A</span>
                            <br>
                            <badge size="lg"
                                type="success"
                                class="text-capitalize"
                                v-if="row.basic_profile.has_practice_lga"
                                v-html="breakWord(row.basic_profile.has_practice_lga.name, 8)"
                            >
                            </badge>
                            <badge size="lg"
                                type="warning"
                                class="text-capitalize"
                                v-if="! row.basic_profile.has_practice_lga"
                            >
                                N/A
                            </badge>
                        </td>
                        <td>
                            <span v-if="row.basic_profile.has_specialization">
                                <span
                                    v-if="row.basic_profile.has_specialization.specialization"
                                    v-html="getSpecialization(row.basic_profile.has_specialization.specialization, 16)"
                                >
                                </span>
                                <span v-if="! row.basic_profile.has_specialization.specialization">N/A</span>
                                <br>
                                <badge size="lg"
                                    type="success"
                                    class="text-capitalize"
                                    v-if="row.basic_profile.has_specialization.sub_specialization"
                                    v-html="getSpecialization(row.basic_profile.has_specialization.sub_specialization, 16)"
                                >
                                </badge>
                                <badge size="lg"
                                    type="warning"
                                    class="text-capitalize"
                                    v-if="! row.basic_profile.has_specialization.sub_specialization"
                                >
                                    N/A
                                </badge>
                            </span>
                            <span v-if="! row.basic_profile.has_specialization">N/A</span>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
  import moment from 'moment';

  export default {
    name: 'DoctorProfileReportData',
    props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges'],
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
        getAge(value) {
            return value ? moment().diff(value, 'years') : 'N/A';
        }
    },
    methods: {
        getFullName(user) {// get full name
            if(user) {
                let name = user.first_name+' ';
                name += user.other_name ? user.other_name+' ' : '';
                name += user.last_name+' ';
                let formatName = name.replace(',', '');
                return formatName.toLowerCase();
            } else {
                return 'N/A';
            }
        },
        getUserState(record) {// get state of origin
            if(record && record.nationality && (parseInt(record.nationality) == 164)) {// check for Nigerian
                return record.has_state ? record.has_state.name : 'N/A';
            } else if(record && record.nationality && (parseInt(record.nationality) != 164)) {
                return record.foreign_state ? record.foreign_state : 'N/A';
            } else {
                return 'N/A';
            }
        },
        getRegType(data) {// get user registration type
            return data && data.registration_type ? data.registration_type.name : 'N/A';
        },
        getSpecialization(data,length) {// get Specialization
            let word = data && data.name ? data.name.substring(0, length) : false;
            let operator = word.length > length ? '...' : '';
            return word ? word+operator : 'N/A';
        },
        breakWord(word, length) {// break word into two parts
            if(word) {
                let textLength = word.length;
                let exploded = word.split(' ');
                if(word && textLength < length) {
                    return word;
                } else if(word) {
                    let formedWord = '';
                    let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                    for(let phrase of exploded) {
                        formedWord += phrase+' ';
                        if(exploded[half] == phrase) {
                            formedWord += ' <br> ';
                        }
                    }
                    return formedWord;
                }
            }
            return 'N/A';
        },
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
