<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Attached Documents</h3>
                <hr class="my-4" />
            </div>


            <div
                class="col-sm-4 text-center borderLine"
                v-show="records && records.length > 0"
                v-for="(document, index) in records"
                :key="index"
            >
                <br>
                <input
                    type="file"
                    id="file" style="display: none"
                >
                <h4 class="text-capitalize height-50">
                    <span>{{ document.name }}</span><br><br>
                    <em class="title-font text-danger">
                        {{ document.description }}
                    </em>
                </h4><br><br><br>
                <base-button
                    tag="a" type="primary"
                    size="sm"
                    :href="document.url"
                    class="active" role="button"
                    aria-pressed="true"
                    target="_blank"
                    v-if="document.hasFile"
                >
                    <i class="fa fa-eye"></i> View File
                </base-button>
                <badge
                    type="danger"
                    v-if="! document.hasFile"
                >
                    No File Uploaded
                </badge>
                <br><br>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Attached Documents !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import Badge from '../../../components/Badge.vue'
    export default {
        components: { Badge },
        name: 'Documents',
        props: [ 'records' ]
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>