
<template>
    <div>
        <div class="row">
            <div class="col-lg-12 text-right">
                <button
                    class="btn btn-lg btn-danger"
                    @click="$emit('getSeatStatus', null)"
                >
                    <i class="fa fa-reply"></i>
                    Back To Batches
                </button>
                <br><br>
            </div>
        </div>

        <template>
            <tabs fill class="flex-column flex-md-row">
                <card shadow>
                    <tab-pane title="Not Seated Applicants">
                        <span slot="title">
                            <i class="ni ni-books"></i>
                            Applicants Didn't Seat
                        </span>
                        <!-- import Applicants template here -->
                        <NotSeatedApplicants
                            :batch="batch"
                            :changeTitle="changeTitle"
                            :privileges="privileges"
                            :loadTitle="loadTitle"
                            :type="'no'"
                            :checked="false"
                            v-if="batch && batch.id"
                        />
                    </tab-pane>

                    <tab-pane title="Seated Applications">
                        <span slot="title">
                            <i class="ni ni-badge"></i>
                            Seated Applicants
                        </span>
                        <!-- import Rejected Applications template here -->
                        <NotSeatedApplicants
                            :batch="batch"
                            :changeTitle="changeTitle"
                            :privileges="privileges"
                            :loadTitle="loadTitle"
                            :type="'yes'"
                            :checked="true"
                            v-if="batch && batch.id"
                        />
                    </tab-pane>

                </card>
            </tabs>
        </template>
    </div>
</template>
<script>
    import NotSeatedApplicants from './NotSeatedApplicants/NotSeatedApplicants.vue';

    export default {
        name: 'AssessmentSeatStatus',
        props: ['batch', 'changeTitle', 'privileges', 'loadTitle'],
        components: {
            NotSeatedApplicants
        }
    };
</script>
<style></style>
