
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0 && ! searchKey">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Batch Name <br>/ Code</th>
                        <th width="10%">Quota</th>
                        <th width="10%">Commencement <br> Date</th>
                        <th width="10%">Expiry <br> Date</th>
                        <th width="10%">Date Created <br> / Status</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.title }}<br>
                            <badge
                                size="sm" type="success"
                                class="font-weight-bolder"
                            >
                                {{ row.description }}
                            </badge>&nbsp;
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.year }}
                            </badge>
                        </td>
                        <td>
                            {{ row.quota }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.commencement_date | formatDate }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.expiry_date | formatDate }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.created_at | formatDate }} <br>
                            <badge
                                :type="((getExactStatus(row) == 'active') ? 'success' : 'danger')"
                                class="font-weight-bolder" size="sm"
                            >
                                {{ getExactStatus(row) }}
                            </badge>
                        </td>
                        <td>
                            <button
                                class="btn btn-sm btn-info"
                                @click="$emit('editBatch', row)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </button>

                            <button
                                :class="'btn btn-sm btn-'+ (row.status == 'active' ? 'warning' : 'success')"
                                @click="changeStatus(row.id)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-history"></i>
                                {{ (row.status == 'active' ? 'Deactivate' : 'Activate') }}
                            </button>

                            <button
                                :class="'btn btn-sm btn-'+ (row.status == 'suspended' ? 'danger' : 'primary')"
                                @click="changeStatus(row.id, 'suspend')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-history"></i>
                                {{ (row.status == 'suspended' ? 'Resume' : 'Suspend') }}
                            </button>

                            <button
                                class="btn btn-sm btn-danger"
                                @click="removeBatch(row.id)"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges
                                            || row.applicant_count > 0"
                            >
                                <i class="fa fa-ban"></i>
                                Remove
                            </button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';

    export default {
        name: 'AssessmentBatchData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'searchKey', 'loadBatches'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            ...mapActions(['deleteAssessmentBatch', 'changeBatchStatus']),
            getExactStatus(data) {// get exact status of batch 
                let startDate   = moment(data.commencement_date);// get commencement date minus one day
                let endDate     = moment(data.expiry_date).add(1, 'days');// get expiry date plus one day
                let hasValidDate = (moment().isBetween(startDate, endDate));
                let hasActiveStatus = (data.status == 'active') ? true : false;
                if(hasValidDate && hasActiveStatus) {
                    return 'active';
                } else if(data.status == 'suspended') {
                    return 'Suspended';
                } else if(! hasActiveStatus) {
                    return 'Deactivated';
                } else {
                    return 'closed';
                }
            },
            changeStatus(id, option = null) {
                let data = { id : id, admin_id : this.$store.state.user.userId, option : option };
                this.preloader(); // show loading
                this.changeBatchStatus(data).then((res) => {// delete country
                    if(res.status) {
                        this.loadBatches();// refresh page
                        swal.fire("Status Changed", res.msg, "success");
                    } else {
                        this.loadBatches();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadBatches();// refresh page
                    swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                });
            },
            removeBatch(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRemoveApiCall(id);// make api call
                    }
                });
            },
            makeRemoveApiCall(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
                this.preloader(); // show loading
                this.deleteAssessmentBatch(data).then((res) => {// delete country
                    if(res.status) {
                        this.loadBatches();// refresh page
                        swal.fire("Batch Deleted", res.msg, "success");
                    } else {
                        this.loadBatches();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadBatches();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
