
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showPaymentBlade">
            <div class="row">
                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Email Address"
                        placeholder="Enter Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        type="email"
                        @keyup.enter="confirmMwanPayment()"
                        required
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Paystack Reference Number"
                        placeholder="Enter Paystack Reference Number"
                        input-classes="form-control-alternative"
                        v-model="record.reference"
                        type="text"
                        @keyup.enter="confirmMwanPayment()"
                        required
                    />
                </div>

                <!-- wePiuNOG6v -->

                <div class="col-lg-12">
                    <base-button
                        type="primary" size="lg"
                        @click="confirmMwanPayment()"
                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                    >
                        <i class="fa fa-history"></i>
                        Confirm Payment
                    </base-button>
                </div>
            </div>
            
        </div>

        <div class="col-sm-12 text-center" v-if="showErrorStatus">
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'MwanPaymentVerify',
    props: ['privileges'],
    data() {
      return {
        record: {
            email: null,
            reference: null
        },
        showPaymentBlade: false,
        isLoadingBlade: false,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: false
      }
    },
    computed: {
        ...mapGetters(['loadingBladeUrl'])
    },
    methods: {
        ...mapActions(['verifyMwanPayment']),
        confirmMwanPayment() {// get user details
            if(this.record.email && this.record.reference) {
                this.preloader(); // show loading
                this.verifyMwanPayment(this.record).then((res) => {// get records
                    if(res.status) {
                        swal.fire('Payment Successful', res.msg, 'success');
                        this.record = { email: null, reference: null };
                    } else {
                        swal.fire('Payment Failed', res.msg, 'error');
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                swal.fire('Invalid Input!', 'Enter a valid Email Address / Reference', 'warning');
            }            
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
    .right {
        float: right;
    }
</style>
