
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="10%">Reporter's Name <br> / Email Address</th>
                        <th width="10%">Suspect's Name <br> / Category</th>
                        <th width="10%">Pratice State <br> / Lga</th>
                        <th width="10%">Status</th>
                        <th width="10%">Date Reported</th>
                        <th width="5%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                    <td class="budget">
                        {{ pagination.from+index}}
                    </td>
                    <td class="text-capitalize">
                        <span v-html="breakWord(row.name)"></span> <br>
                        <badge size="lg" type="primary">
                            {{ row.email ? row.email : 'N/A' }}
                        </badge>
                    </td>
                    <td>
                        <span v-html="breakWord(row.suspect_name)"></span> <br>
                        <badge size="lg" type="primary" class="text-uppercase">
                            {{ row.suspect_category ? row.suspect_category : 'N/A' }}
                        </badge>
                    </td>
                    <td>
                        {{ row.has_practice_state && row.has_practice_state.name ? row.has_practice_state.name: 'N/A' }} <br>
                        <badge size="lg" type="primary" class="text-uppercase">
                            {{ row.has_practice_lga && row.has_practice_lga.name ? row.has_practice_lga.name: 'N/A' }}
                        </badge>
                    </td>
                    <td>
                        <badge size="lg"
                            :type="((row.status == 'pending') ? 'warning' : 'success')"
                            class="text-uppercase"
                        >
                            {{ row.status  }}
                        </badge>
                    </td>
                    <td>
                        {{ row.date_of_incident | getDateFormat }}
                    </td>

                    </template>

                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'QuackReportsData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges'],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            breakWord(word, length = 40) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
