
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">{{ this.$route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && ! showPaymentBlade">
                            <div class="container">
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="RRR Number"
                                        placeholder="Enter RRR Number"
                                        input-classes="form-control-alternative"
                                        v-model="payment_ref"
                                        @keyup.enter="getRRRDetails()"
                                        @change="getRRRDetails()"
                                        required
                                    />
                                </div>

                                <div class="col-lg-12">
                                    <base-button
                                        type="primary" size="lg"
                                        @click="getRRRDetails()"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-history"></i>
                                        Load Payment
                                    </base-button>
                                </div>
                            </div>
                        </div>

                        <div
                            class="col-sm-12 text-center"
                            v-if="showErrorStatus"
                        >
                          <base-alert :type="errorType">
                              <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                              <span class="alert-inner--text">
                                  {{errorMsg}}
                              </span>
                          </base-alert>
                        </div>

                        <div class="table-responsive" v-if="! isLoadingBlade && showPaymentBlade">

                            <div class="col-12">
                                <h3 class="mb-0">RRR Payment Details</h3>
                            </div>

                            <hr class="my-4">

                            <table class="table table-bordered" v-if="record">
                                <tbody>
                                    <td>
                                        <h4>User Full Name</h4>
                                    </td>
                                    <td>
                                        <h4 class="text-capitalize font-weight-light">
                                            {{ record.application && record.application.user_data ?
                                               fullName(record.application.user_data) : 'N/A' }}
                                        </h4>
                                    </td>
                                </tbody>

                                <tbody>
                                    <td>
                                        <h4>Application Type</h4>
                                    </td>
                                    <td>
                                        <h4 class="font-weight-light">
                                            {{ record.application && record.application.application_type ?
                                                record.application.application_type.name : 'N/A' }}
                                        </h4>
                                    </td>
                                </tbody>

                                <tbody>
                                    <td>
                                        <h4>Transaction Amount</h4>
                                    </td>
                                    <td>
                                        <h4 class="font-weight-light">
                                            {{ record.amount | formatPrice }}
                                        </h4>
                                    </td>
                                </tbody>

                                <tbody>
                                    <td>
                                        <h4>Transaction Number</h4>
                                    </td>
                                    <td>
                                        <h4 class="font-weight-light">
                                            {{ record.trans_no }}
                                        </h4>
                                    </td>
                                </tbody>

                                <tbody>
                                    <td>
                                        <h4>Transaction Date</h4>
                                    </td>
                                    <td>
                                        <h4 class="font-weight-light">
                                            {{ record.date_paid | getDateTimeFormat }}
                                        </h4>
                                    </td>
                                </tbody>

                                <tbody>
                                    <td>
                                        <h4>RRR</h4>
                                    </td>
                                    <td>
                                        <h4 class="font-weight-light">
                                            {{ record.payment_ref }}
                                        </h4>
                                    </td>
                                </tbody>

                            </table>

                            <hr class="my-4">

                            <div class="col-lg-12">
                                <base-button
                                    type="primary" size="lg"
                                    @click="removeRRR()"
                                    :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                >
                                    <i class="fa fa-trash"></i>
                                    Remove Payment
                                </base-button>

                                <base-button
                                    type="danger" size="lg"
                                    class="right"
                                    @click="hidePaymentBlade()"
                                >
                                    <i class="fa fa-times"></i>
                                    Cancel
                                </base-button>
                            </div>

                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'RemovePayment',
    data() {
      return {
        payment_ref: null,
        record: null,
        showPaymentBlade: false,
        isLoadingBlade: false,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,

      }
    },
    filters: {
        getDateTimeFormat(value) {
            if(value) {
                return moment(value).format('Do MMM, YYYY [at] h:mm:ss a');
            } else {
                return 'N/A';
            }
        },
        formatPrice(value) {
            if(value) {
                let val = (value/1).toFixed(2).replace(',', '.');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
            } else {
                return 'N/A';
            }
        }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == this.$route.path) {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        }
    },
    methods: {
        ...mapActions(['loadRRRDetails', 'removeRRRPayment']),
        getRRRDetails() {// get user details
            this.isLoadingBlade = true;
            this.loadRRRDetails(this.payment_ref).then((res) => {// get records
                this.isLoadingBlade = false;
                if(res.status) {
                    this.record = res.record;
                    this.showPaymentBlade = res.status;
                } else {
                    let msg = "RRR was not found kindly check and try again";
                    swal.fire('Not Found!', msg, 'error');
                }
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        removeRRR() {// enable doctor to edit profile
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeRemoveRRRapiCall();// make api call
                }
            });
        },
        makeRemoveRRRapiCall() {// remove RRR
            this.preloader(); // show loading
            this.removeRRRPayment(this.payment_ref).then((res) => {// remove RRR
                if(res.status) {
                    swal.fire('RRR Removed!', res.msg, 'success');
                    this.hidePaymentBlade();
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
            }).
            catch(() => {
                this.isLoadingBlade = false;
                let message = "Server Failure!, Kindly reload the page...";
                swal.fire('Not Found!', message, 'error');
            });
        },
        hidePaymentBlade() {
            this.showPaymentBlade = false;
            this.record = null;
            this.payment_ref = null;
        },
        fullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.last_name+' ';
            return name;
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
    .right {
        float: right;
    }
</style>
