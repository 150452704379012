
<template>
    <div>
        <div v-if='! showErrorStatus && ! isLoadingBlade'>

            <div v-if="! approvalPage">
                <!-- This is profile details template here -->
                <ProfileDetails
                    :application="applicant" 
                    v-if="applicant"
                />

                <!-- This is Documents template here --> 
                <Documents
                    :records="applicant.documents"
                    v-if="applicant && applicant.documents"
                />

                <!-- This is payment Details template here -->
                <PaymentDetails
                    :transaction="applicant.transaction"
                    v-if="applicant && applicant.transaction && ! showEditPage"
                />


                <div v-if="(applicant.status == 'submitted') && ! showEditPage">
                    <base-button
                        type="danger" size="lg"
                        @click="showApprovalPage('reject', true)"
                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                    >
                        <i class="fa fa-ban"></i> Reject Application
                    </base-button>

                    <base-button
                        type="success" size="lg"
                        class="right"
                        @click="showApprovalPage('approve', true)"
                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                    >
                        <i class="fa fa-check"></i> Approve Application
                    </base-button>
                </div>
            </div>

            <div v-if="approvalPage">
                <!-- Import Approve Application template here -->
                <ApproveApplication
                    :applicant="applicant"
                    :privileges="privileges"
                    :loadRemediationApplication="loadRemediationApplication"
                    v-on:showApprovalPage="showApprovalPage"
                    v-if="pageType == 'approve'"
                />

                <!-- Import Reject Application template here -->
                <RejectApplication
                    :applicant="applicant"
                    :privileges="privileges"
                    :loadRemediationApplication="loadRemediationApplication"
                    v-on:showApprovalPage="showApprovalPage"
                    v-if="pageType == 'reject'"
                />
            </div>

        </div>


        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-sm-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import ProfileDetails from './ProfileDetails.vue';
  import Documents from './Documents.vue'
  import RejectApplication from './RejectApplication.vue'
  import ApproveApplication from './ApproveApplication.vue'
  import PaymentDetails from './PaymentDetails.vue'

  export default {
    name: 'RemediationApplicationDetails',
    props: ['privileges', 'changeTitle', 'loadTitle', 'id'],
    components: {
        ProfileDetails,
        Documents,
        RejectApplication,
        ApproveApplication,
        PaymentDetails
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        applicant: null,
        approvalPage: false,
        pageType: null,
        showEditPage: false,
        showEditPageText: null
      }
    },
    computed: {
        ...mapGetters(['loadingBladeUrl'])
    },
    methods: {
        ...mapActions(['getRemediationApplication']),
        loadRemediationApplication() {// load payments
            if(this.id) {
                this.approvalPage = false;
                this.isLoadingBlade = true;
                this.showErrorStatus = false;
                this.getRemediationApplication({id : this.id}).then((res) => {
                    this.isLoadingBlade = false;
                    this.applicant = res.applicant;
                    let title = 'Remediation Programme Applications';
                    this.changeTitle(title, true);
                }).
                catch(() => {
                    this.applicant = null;
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            }
        },
        editPage(status, text) {
            this.showEditPageText = null;
            this.showEditPage = status;
            this.showEditPageText = text;
        },
        showApprovalPage(type = null, status = false) {// show approval/reject page
            this.approvalPage = status;
            this.pageType = status ? type : null;
        },
        breakWord(word, length = 40) {// break word into two parts
            if(word) {
                let textLength = word.length;
                let exploded = word.split(' ');
                if(word && textLength < length) {
                    return word;
                } else if(word) {
                    let formedWord = '';
                    let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                    for(let phrase of exploded) {
                        formedWord += phrase+' ';
                        if(exploded[half] == phrase) {
                            formedWord += ' <br> ';
                        }
                    }
                    return formedWord;
                }
            }
            return 'N/A';
        }
    },
    created() {
        this.loadRemediationApplication(); // load application
    }
  };
</script>
<style scoped>
    .right {
        float: right;
    }
</style>
