
<template>
    <div>

        <div class="pl-lg-4">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Basic Profile">
                            <span slot="title">
                                <i class="ni ni-circle-08"></i>
                                Basic Profile
                            </span>
                            <!-- import User Basic Profile Details template here -->
                            <BasicProfile
                                :record="record"
                                :privileges="privileges"
                            />
                        </tab-pane>

                    </card>
                </tabs>
            </template>
        </div>

        <hr class="my-4" />

        <div class="pl-lg-4">
            <div class="col-lg-12">
                <base-button
                    :type="getColor(record.profile_lock)" size="lg"
                    @click="enableEdit('lock')"
                    :disabled="privileges && privileges.update == 'no'"
                >
                    <i :class="'fa fa-'+getIcon(record.profile_lock )"></i>
                    {{record && (record.profile_lock == 'yes') ?
                        'Enable' : 'Disable'}} Edit
                </base-button>

                <base-button
                    :type="getColor(record.status)" size="lg"
                    @click="enableEdit('status')"
                    :disabled="privileges && privileges.update == 'no'"
                >
                    <i :class="'fa fa-'+getIcon(record.status )"></i>
                    {{record && (record.status == 'enable') ?
                        'Deactivate' : 'Activate'}} Account
                </base-button>

                <base-button
                    type="danger" size="lg"
                    @click="deleteAccount()"
                    :disabled="(privileges && privileges.delete == 'no') || ! isHodOrSuper"
                >
                    <i class="fa fa-trash"></i>
                    Delete Account
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('hideProfile')"
                    :disabled="privileges && privileges.view == 'no'"
                >
                    <i class="fa fa-times"></i>
                    Close Profile
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import BasicProfile from './BasicProfile';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'Profile',
    props: ['record','privileges', 'hideProfile'],
    components: {
      BasicProfile
    },
    data() {
      return {
        key: null,
        isLoading: false,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showProfile: false
      }
    },
    computed: {
        ...mapGetters(['user']),
        isHodOrSuper() {// check if user is HOD or Super Admin
            let isHod = this.user && this.user.is_hod == 'yes';// if user is HOD
            let isSuper = this.user && this.user.unit && this.user.unit.department
                        && this.user.unit.department.code == 'super-admin';
            return isHod || isSuper;
        }
    },
    methods: {
        ...mapActions(['enableHospitalEdit', 'deleteHospitalAcount']),
        enableEdit(type) {// enable doctor to edit profile
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeEditApiCall(type);// make api call
                }
            });
        },
        makeEditApiCall(type) {// enable edit api call
            this.preloader(); // show loading
            let data = { id : this.record.id, type : type };
            this.enableHospitalEdit(data).then((res) => {
                if(res.status) {
                    this.record.profile_lock = res.profile_lock;
                    this.record.status = res.status;
                    swal.fire('Profile '+ this.capitalize(res.type), res.msg, 'success');
                } else {
                    let msg = "An error occurred while proccessing, kindly try again...";
                    swal.fire('Error Occurred!', msg, 'error');
                }
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        deleteAccount() {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete It!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall();// make api call
                }
            });
        },
        makeDeleteApiCall() {// delete api call
            this.preloader(); // show loading
            this.deleteHospitalAcount(this.record.id).then((res) => {
                if(res.status) {
                    this.hideProfile(); // close profile
                    swal.fire('Account Deleted!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        getColor(value) {
            return (value == 'yes') || (value == 'disable') ? 'primary' : 'danger';
        },
        getIcon(value) {
            return (value == 'no') || (value == 'enable') ? 'ban' : 'check';
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
.right {
    float: right;
}
</style>
