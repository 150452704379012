<template>
    <div>
        <div class="row" v-if="records && records.length > 0">
            <div class="col-lg-12">
                <div class="col-lg-8">
                    <small class="font-weight-bold">
                        Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                        {{ pagination.total }} entries
                    </small>
                </div>
                <div class="card shadow">

                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="records"
                        >
                            <template slot="columns">
                                <th width="5%">S/N</th>
                                <th width="25%">School Name</th>
                                <th width="15%">Email Address</th>
                                <th width="15%">Selected School</th>
                                <th width="10%">Phone Number</th>
                                <th width="10%">Status</th>
                                <th width="10%">Date Created</th>
                                <th width="10%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td class="text-capitalize">
                                <span v-html="breakWord(row.name)"></span>
                                <br>
                                <badge size="lg"
                                    type="primary"
                                    class="text-capitalize"
                                    v-if="row.username"
                                >
                                    {{ row.username  }}
                                </badge>
                            </td>
                            <td>{{ row.email ? row.email : 'N/A' }}</td>
                            <td>
                                <span v-if="row.school && row.school.school_name" v-html="breakWord(row.school.school_name)"></span>
                            </td>
                            <td>{{ row.phone ? row.phone : 'N/A' }}</td>
                            <td>
                                <badge size="lg"
                                    :type="getColor(row.status)"
                                    class="text-capitalize"
                                >
                                    {{ row.status }}
                                </badge>
                            </td>
                            <td>
                                {{ row.created_at | getDateFormat }}
                            </td>
                            <td>
                                <base-button
                                    :type="getColor(row.status, true)" size="sm"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges 
                                                || (row.status == 'new')"
                                    @click="enableEdit(row.id, 'status', (row.status != 'new'))"
                                >
                                    <i :class="'fa fa-' +getIcon(row.status)"></i>
                                    {{ (row.status == 'enable') ? 'Deactivate' : 'Activate' }}
                                </base-button>
                            </td>

                            </template>

                        </base-table>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"

                        >
                        </base-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';

    export default {
        name: 'SchoolsData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "paginatedItems", "loadTrainingSchools"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            ...mapActions(['enableSchoolEdit']),
            enableEdit(id, type, check) {// enable doctor to edit profile
                if(check) {// if not new account, proceed
                    swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Proceed!'
                    }).then((result) => {
                        if (result.value) {
                            this.makeEditApiCall(id, type);// make api call
                        }
                    });
                }
            },
            makeEditApiCall(id, type) {// enable edit api call
                this.preloader(); // show loading
                let data = { id : id, type : type };
                this.enableSchoolEdit(data).then((res) => {
                    this.loadTrainingSchools();
                    if(res.status) {
                        swal.fire('Profile '+ this.capitalize(res.type), res.msg, 'success');
                    } else {
                        let msg = "An error occurred while proccessing, kindly try again...";
                        swal.fire('Error Occurred!', msg, 'error');
                    }
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            capitalize(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            getColor(value, status = false) {
                if(status) {
                    return (value == "enable") ? 'warning' : 'success';
                } else {
                    return (value == "enable") ? 'success' : 'warning';
                }
            },
            getIcon(value) {
                return (value == "enable") ? 'ban' : 'check';
            },
            breakWord(word, length = 20) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>