
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">
                                        Old AQ Application for 
                                        <em class="text-danger">
                                            {{ application.user_data ? 
                                                    getFullName(application.user_data) : 'N/A' }}
                                        </em>
                                    </h3>
                                </div>
                                <div class="col-4 text-right">
                                    <a href="/old-additional-pgq/old-aq" 
                                        class="btn btn-sm btn-danger">
                                        <i class="fa fa-reply"></i> Back
                                    </a>
                                </div>
                            </div>
                        </div> 

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>
                        
                        <div v-if="! isLoadingBlade">
                          
                            <!-- Import AQ template here for only AQ application -->
                            <AddQualification 
                                v-bind:record="application"
                                v-if="application"
                            />

                            <!-- Import personal details template here -->
                            <PersonalDetails 
                                v-bind:user="application.user_data"
                                v-if="application && application.user_data"
                            />

                            <div v-if="application.status == 'submitted'">
                                <base-button 
                                        type="danger" size="lg"
                                        @click="updateApplication('rejected')"
                                        :disabled="privileges && privileges.update == 'no'"
                                    >
                                        <i class="fa fa-ban"></i> Reject 
                                    </base-button>

                                    <base-button 
                                        type="success" size="lg"
                                        class="right"
                                        @click="updateApplication('approved')"
                                        :disabled="privileges && privileges.update == 'no'"
                                    >
                                        <i class="fa fa-check"></i> Approve 
                                    </base-button>
                            </div>
                          
                        </div>

                        
                        <div class="col-sm-12 text-center">
                          <base-alert :type="errorType" v-if='showErrorStatus'>
                              <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                              <span class="alert-inner--text">
                                  {{errorMsg}}
                              </span>
                          </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import PersonalDetails from '../ApplicationDetail/PersonalDetails';
  import AddQualification from '../ApplicationDetail/Others/AddQualification';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'OldAQDetails',
    components: {
        PersonalDetails,
        AddQualification
    },
    data() {
      return {
        id: this.$route.params.id,
        application: {},
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true
      }
    },
    computed: {
      ...mapGetters(['loadingBladeUrl', 'menus']),
      privileges() {
        let privileges = null;
        if(this.menus && this.menus.length > 0) {
          for(let menu of this.menus) {
              if(menu.has_child && menu.has_child.length > 0) {
                  for(let child of menu.has_child) {
                      if('/'+child.link+'/'+this.id == this.$route.path) {
                          privileges = child.privileges;
                      } 
                  }
              }
          }
        }
        return privileges;
      }
    },
    methods: {
        ...mapActions(['getOldAQApplication', 'approveOldAQApplication']),
        loadApplication() {// load Application
            this.getOldAQApplication({'id' : this.id}).then((res) => {// get records
                this.application = res;
                this.isLoadingBlade = false;
            }).
            catch(() => {
                this.isLoadingBlade = false;
                this.showErrorStatus = true;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        getFullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.last_name;
            return name;
        },
        updateApplication(type) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    if(type == 'approved') {
                        this.makeApproveApiCall(type);// make approve api call
                    } else {
                        this.makeRejectApiCall(type);// make approve api call
                    }                    
                }
            });
        },
        makeApproveApiCall(type) {
            let data = { 'id' : this.application.id, 'type' : type, 'reject_reason' : null };
            this.preloader();// show loading
            this.approveOldAQApplication(data).then((res) => {// get records
                if(res.status) {
                    swal.fire('Application '+type+'!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication();// load Application 
            }).
            catch(() => {
                let msg = "Server Failure!, Kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        makeRejectApiCall(type) {
            swal.fire({
                title: 'Enter Reject Reason',
                input: 'textarea',
                inputPlaceholder: 'Type your reason here...',
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                preConfirm: (reason) => {   
                    let data = { 'id' : this.application.id, 'type' : type, 
                        'reject_reason' : reason };
                    if(reason) {
                        return this.approveOldAQApplication(data).then((res) => {//update
                            if (!res.status) {
                            throw new Error(res.msg)
                            }
                            return res;
                        }).
                        catch((error) => {
                            swal.showValidationMessage(
                            `Request failed: ${error}`
                            )
                        });
                    } else {
                        return swal.showValidationMessage('Reason message is required');  
                    }
                },
                allowOutsideClick: () => !swal.isLoading()
                }).then((res) => {
                if (res.value) {  
                    let type = res.value.status ? 'success' : 'error';
                    let title = res.value.status ? 'Application Rejected!' : 'Error Occurred!';
                    swal.fire(title, res.value.msg, type);
                }
                this.loadApplication();// load Application
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
      this.loadApplication();// load Application 
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
</style>
