<template>
    <div class="row align-items-center">
        <div class="col-lg-12">
            <h2 class="text-uppercase text-center">
                Complaint from
                <em class="text-danger">{{ complanit.name }}</em>
            </h2>
            <hr>
        </div>

        <div class="col-lg-12">

            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <td width="20%">
                            <h4>Complainant's Full Name:</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ complanit.name ? complanit.name : 'N/A' }}
                            </h4>
                        </td>
                        <td width="20%">
                            <h4>Complainant's Email Address:</h4>
                        </td>
                        <td width="30%">
                            <h4 class="font-weight-light">
                                {{ complanit.email ? complanit.email : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td width="20%">
                            <h4>Complainant's Phone Number</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ complanit.phone ? complanit.phone : 'N/A'  }}
                            </h4>
                        </td>
                        <td width="20%">
                            <h4>Status</h4>
                        </td>
                        <td width="30%">
                            <badge size="lg"
                                :type="((complanit.status == 'pending') ? 'warning' : 'success')"
                                class="text-uppercase"
                            >
                                {{ complanit.status  }}
                            </badge>
                        </td>
                    </tbody>
                    <tbody>
                        <td width="20%">
                            <h4>File Uploaded</h4>
                        </td>
                        <td width="30%" colspan="3">
                            <a
                                class="btn-sm btn-info"
                                :href="complanit.url"
                                target="_blank"
                            >
                                <i class="fa fa-eye"></i>
                                View File
                            </a>
                        </td>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-12">
                <hr>
                <h3 class="text-uppercase">
                    Complain Details:
                </h3>
                <span v-html="complanit.message"></span>
                <hr>
            </div>

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="changeStatus(complanit.id)"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                >
                    <i class="fa fa-history"></i>
                    {{isloading ? 'Please Wait...' : 'Change Status'}}
                </base-button>

                <base-button
                    type="warning" size="lg"
                    class="right marginLeft5"
                    @click="viewDetails(null, false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="deleteComplaint(complanit.id)"
                    :disabled="! isHodOrSuper"
                >
                    <i class="fa fa-trash"></i>
                    Delete Complaint
                </base-button>
            </div>

        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ViewComplaint',
        props: ["complanit", "privileges", "loadComplaints", "viewDetails"],
        data() {
            return {
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['user']),
            isHodOrSuper() {// check if user is HOD or Super Admin 
                let isHod = this.user && this.user.is_hod == 'yes';// if user is HOD
                let isSuper = this.user && this.user.unit && this.user.unit.department
                            && this.user.unit.department.code == 'super-admin';
                return isHod || isSuper;
            }
        },
        methods: {
            ...mapActions(['changeComplaintStatus', 'deleteComplaintData']),
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            changeStatus(id) {// change complaint's status
                this.preloader();// show loading
                this.changeComplaintStatus(id).then((res) => {
                    if(res.status) {
                        let status = (this.complanit.status == 'pending') ? 'resolved' : 'pending';
                        this.complanit.status = status;
                        this.loadComplaints();
                        swal.fire('Status Changed!', res.msg, 'success');
                        setTimeout(function() { window.location.reload(); }, 1000);// reload page

                    } else {
                        swal.fire('Error Occurred', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    swal.fire('Error Occurred', 'An error occurred while updating, try again', 'warning');
                });
            },
            deleteComplaint(id) {// enable doctor to edit profile
                if(this.isHodOrSuper) {
                    swal.fire({
                        title: 'Are you sure?',
                        text: "You won't to continue with this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Proceed!'
                    }).then((result) => {
                        if (result.value) {
                            this.deleteComplaintApi(id);// make api call
                        }
                    });
                } else {
                    swal.fire('Permission Denied', 'You don\'t the necessary permission', 'warning');
                }           
            },
            deleteComplaintApi(id) {// change complaint's status
                this.preloader();// show loading
                this.deleteComplaintData(id).then((res) => {
                    if(res.status) {
                        this.loadComplaints();
                        swal.fire('Complaint Deleted!', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    swal.fire('Error Occurred', 'An error occurred while deleting, try again', 'warning');
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginLeft5 {
        margin-left: 5px;
    }
</style>