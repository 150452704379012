<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <h3 class="text-uppercase"  v-if="! isLoadingBlade">Edit Employment Data</h3>

        <hr class="my-4" />

        <div class="row align-items-center" v-if="! isLoadingBlade">

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Employer's Name"
                    placeholder="Enter Employer's Name"
                    input-classes="form-control-alternative"
                    v-model="record.employer_name"
                    required
                />
            </div>
            <div class="col-lg-6">
                <base-input alternative=""
                    label="Employer's Address"
                    required
                >
                    <textarea
                        rows="2"
                        class="form-control form-control-alternative"
                        placeholder="Enter Employer's Address"
                        v-model="record.employer_address"></textarea>
                </base-input>
            </div>

            <div class="col-lg-5">
                <br>
                <h4>
                    Is this your current Appointment?
                </h4>
            </div>
            <div class="col-lg-2">
                <br>
                <base-switch
                    v-if="record.current_status == 'no'"
                    :value="false"
                    @click="changeCurrentStatus()"
                ></base-switch>
                <base-switch
                    v-if="record.current_status == 'yes'"
                    :value="true"
                    @click="changeCurrentStatus()"
                ></base-switch>
            </div>
            <div class="col-lg-12"></div>
            <div class="col-lg-6">
                <br>
                <base-input
                    label="Employment Start Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.start_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-6" v-if="showEndDate">
                <br>
                <base-input
                    label="Employment End Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.end_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-12">
                <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>
            </div>
            <div class="col-lg-12"></div>
            <div class="col-lg-12">
                <base-button
                    type="success"
                    class="right"
                    @click="saveAppRecords()"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Update Record'}}
                </base-button>

                <div>
                    <base-button
                        type="danger"
                        @click="editEmployRecord(false)"
                    >
                        <i class="fa fa-times"></i>
                        Cancel Edit
                    </base-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            flatPicker
        },
        name: 'EditEmploymentDetails',
        props: ['editEmployRecord', 'applicant', 'loadAssessmentApplicant', 'res'],
        data() {
            return {
                record: {
                    employer_name: null,
                    employer_address: null,
                    current_status: 'no',
                    start_date: null,
                    end_date: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    maxDate: new Date().fp_incr(1),
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            showEndDate() {
                return this.record.current_status == 'no' ? true : false;
            },
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData']),
            setData() {// set paginated function
                if(this.res) {
                    let data = this.res;
                    this.record = {
                        employer_name: data.employer_name,
                        employer_address: data.employer_address,
                        current_status: data.current_status,
                        start_date: data.start_date,
                        end_date: data.end_date,
                        id: data.id
                    }
                    this.isLoadingBlade = false;
                }
            },
            changeCurrentStatus() {// change Current Status switch
                const status = this.record.current_status == "yes" ? "no" : "yes";
                this.record.current_status = status;
            },
            saveAppRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save appointment
                    this.preloader();// show loading
                    data.id = this.res.id;
                    let record = { 'employment_data' : data , 'id' : this.applicant.id };
                    this.saveAssessmentApplicantData(record).then((res) => {
                        this.loadAssessmentApplicant();
                        this.isloading = false;
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            this.editEmployRecord(false);
                            swal.fire("Record Added!", "Appointment record was added successfully", "success");
                            this.emptyRecords();
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                    this.emptyRecords();
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { employer_name: null, employer_address: null, start_date: null };
                appRecord.employer_name = data.employer_name == null ? this.showInuptError('Employer\'s Name') : data.employer_name;
                appRecord.employer_address  = data.employer_address == null ? this.showInuptError('Employer\'s Address') : data.employer_address;
                appRecord.start_date  = data.start_date == null ? this.showInuptError('Start Date') : data.start_date;
                var status = this.isEmpty(appRecord);
                appRecord.current_status = data.current_status;
                appRecord.end_date  = data.end_date == null && data.current_status == 'no' ? this.showInuptError('End Date') : data.end_date;
                return (status && data.current_status == 'yes') || (status && data.current_status == 'no'
                    && data.end_date != null) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                this.record.employer_name = null;
                this.record.employer_address = null;
                this.record.current_status = 'no';
                this.record.start_date = null;
                this.record.end_date = null;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();// load edu records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>