import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=c25f9a78&scoped=true&"
import script from "./Documents.vue?vue&type=script&lang=js&"
export * from "./Documents.vue?vue&type=script&lang=js&"
import style0 from "./Documents.vue?vue&type=style&index=0&id=c25f9a78&scoped=true&lang=css&"
import style1 from "./Documents.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c25f9a78",
  null
  
)

export default component.exports