<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <i class="ni ni-tv-2"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <!-- import Registered CPD Provider template here -->
                            <Provider
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'cpd-provider'"
                            />

                            <!-- import CPD Applications template here -->
                            <CpdApplications
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'cpd-applications'"
                            />

                            <!-- import CPD Visitations template here -->
                            <CpdVisitations
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'cpd-visitations'"
                            />

                            <!-- import CPD Visitations template here -->
                            <CpdReAccreditation
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'cpd-reaccreditation'"
                            />

                            <!-- import Accredited CPDs template here -->
                            <AccreditedCpds
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'accredited-cpds'"
                            />

                            <!-- import Registered CPD Providers template here -->
                            <Providers
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'cpd-providers'"
                            />

                            <!-- import CPD applications Reporting template here -->
                            <Reporting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'cpd-applications-reporting'"
                            />

                            <!-- import CPD providers Reporting template here -->
                            <ProvidersReporting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'cpd-providers-reporting'"
                            />

                            <!-- import Accredited CPD providers Reporting template here -->
                            <AccreditedReporting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'accredited-cpd-providers-reporting'"
                            />

                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Provider from './Provider/Provider';
  import Providers from './Providers/Providers';
  import CpdApplications from './CpdApplications/CpdApplications.vue';
  import CpdVisitations from './CpdVisitations/CpdVisitations.vue';
  import AccreditedCpds from './AccreditedCpds/AccreditedCpds.vue';
  import Reporting from './Reporting/Reporting.vue';
  import ProvidersReporting from './ProvidersReporting/ProvidersReporting.vue';
  import AccreditedReporting from './AccreditedReporting/AccreditedReporting.vue';
  import CpdReAccreditation from './CpdReAccreditation/CpdReAccreditation.vue'

  export default {
    name: 'CpdProviders',
    components: {
        Provider,
        CpdApplications,
        CpdVisitations,
        AccreditedCpds,
        Providers,
        Reporting,
        ProvidersReporting,
        AccreditedReporting,
        CpdReAccreditation
    },
    data() {
      return {
        code: this.$route.params.code,
        title: null,
        isLoadingBlade: false
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == this.$route.path) {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        }
    },
    methods: {
        loadTitle() {// load title
            return this.title = this.replaceAll(this.code, '-', ' ');
        },
        replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, 'g'), replace);
        },
        changeTitle(value, status) {// change title
            return status ? this.title = value : this.loadTitle();
        }
    },
    created() {
        this.loadTitle(); // load title
    }
  };
</script>
<style scoped>
</style>
