
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-capitalize">{{ $route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <template>
                            <tabs fill class="flex-column flex-md-row">
                                <card shadow>
                                    <tab-pane title="Pending Complaints">
                                        <span slot="title">
                                            <i class="ni ni-books"></i>
                                            Pending Complaints
                                        </span>
                                        <!-- import Pending Complaints template here -->
                                        <PendingComplaints />
                                    </tab-pane>

                                    <tab-pane title="Resolved Complaints">
                                        <span slot="title">
                                            <i class="ni ni-badge"></i>
                                            Resolved Complaints
                                        </span>
                                        <!-- import Pending Complaints template here -->
                                        <ResolvedComplaints />
                                    </tab-pane>

                                </card>
                            </tabs>

                        </template>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import PendingComplaints from './PendingComplaints';
  import ResolvedComplaints from './ResolvedComplaints';

  export default {
    name: 'Complaints',
    components: {
      PendingComplaints,
      ResolvedComplaints,
    },
  };
</script>
<style></style>
