
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if="! isLoadingBlade">
            <div class="row">
                <div class="col-lg-2">
                    <base-input alternative="">
                        <select
                            class="form-control form-control-alternative"
                            v-model="page"
                            @change="getPageItems()"
                        >
                            <option
                                v-for="number in pageNumbers"
                                :key="number"
                                :value="number"
                            >
                                {{ number }}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-4"></div>
                <div class="col-lg-6">
                    <base-input
                        placeholder="Search by Name / Email and hit enter"
                        class="input-group-alternative"
                        alternative=""
                        addon-right-icon="fas fa-search"
                        @change="searchRecords"
                        v-model="searchKey"
                        >
                    </base-input>
                </div>
            </div>
        </div>

        <!-- import records details template here -->
        <PendingApplicationData
            :records="records"
            :title="title"
            :pagination="pagination"
            :hasPagination="hasPagination"
            :paginate="paginate"
            :privileges="privileges"
            :paginatedItems="paginatedItems"
            v-if="! isLoadingBlade"
        />

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import PendingApplicationData from './PendingApplicationData';

    export default {
        name: 'PendingApplications',
        components: {
            PendingApplicationData
        },
        props: ['status', 'appTitle'],
        data() {
            return {
                code: this.$route.params.code,
                records: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.url.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                searchKey: '',
                page: 1,
                title: 'Apply Date'
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'menus']),
            hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            privileges() {
            let privileges = null;
            if(this.menus && this.menus.length > 0) {
                for(let menu of this.menus) {
                    if(menu.has_child && menu.has_child.length > 0) {
                        for(let child of menu.has_child) {
                            if('/'+child.link == this.$route.path) {
                                privileges = child.privileges;
                            }
                        }
                    }
                }
            }
            return privileges;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            }
        },
        methods: {
            ...mapActions(['getIndexingApplications', 'getPaginatedExactApplications']),
            loadAplications() {
                let data = {
                    status : this.status,
                    limit : this.pagination.perPage
                }
                this.getIndexingApplications(data).then((res) => {// get records
                    this.setPages(res.applications);
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedExactApplications(url).then((res) => {// get records
                        this.pagination.number = res.applications.current_page;
                        this.setPages(res.applications);
                    }).
                    catch(() => {
                        this.records = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No '+ this.appTitle +' Applications Yet!!!';
            },
            searchRecords() {
                this.showErrorStatus = false;
                let key = this.searchKey.toLowerCase();
                if(key != '' ) {
                    this.isLoadingBlade = true;
                    let data = { school_type: this.schoolType, type : this.type , status : 'submitted', key : key };
                    this.records = [];
                    this.getIndexingApplications(data).then((res) => {// get records
                        this.isLoadingBlade = false;
                        this.records = res.applications;
                        if(this.records.length == 0) {
                            this.showErrorStatus = true;
                            this.errorType = 'warning';
                            this.errorMsg = 'No Matched Record!!!';
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                    this.emptyRecordsMsg();
                }
            },
            getPageItems() {
                let url = this.pagination.next_page_url ?
                            this.pagination.next_page_url : this.pagination.prev_page_url;
                let exploded = url.split('&');
                let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
                this.isLoadingBlade = true
                this.getPaginatedExactApplications(newUrl).then((res) => {// get records
                    this.pagination.number = res.applications.current_page;
                    this.setPages(res.applications);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        mounted() {
            this.loadAplications();// load applications
        }
    };
</script>
<style></style>
