
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>

                                <div class="col-4 text-right" v-if="(code != 'batches')">
                                    <a
                                        href="javascript:void(0)"
                                        @click="$router.go(-1)"
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Go Back
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="! isLoadingBlade">
                            <!-- import Assessment Batches template here -->
                            <RemediationBatch
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'batches'"
                            />
                            
                            <RemediationApplications
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                :id="splitCode.id"
                                v-if="splitCode.code && splitCode.code == 'applications'"
                            />

                            <RemediationApplicationDetails
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                :id="splitCode.id"
                                v-if="splitCode.code && splitCode.code == 'details'"
                            />

                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-sm-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import RemediationBatch from './RemediationBatch/RemediationBatch';
  import RemediationApplications from './RemediationApplications/RemediationApplications.vue';
  import RemediationApplicationDetails from './RemediationApplicationDetails/RemediationApplicationDetails.vue'

  export default {
    name: 'Remediation',
    components: {
        RemediationBatch,
        RemediationApplications,
        RemediationApplicationDetails
    },
    data() {
      return {
        code: this.$route.params.code,
        title: null,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: false,
        batches: []
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == '/remediation/batches') {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        },
        splitCode() {
            return {
                id : this.getNumbersFromText(this.code),
                code : this.code.replace(/[0-9]/g, '')
            };
        }
    },
    methods: {
        getNumbersFromText(str) { 
            var num = str.replace(/[^0-9]/g, ''); 
            return parseInt(num,10); 
        },
        loadTitle() {// load title
            return this.title = this.replaceAll(this.code, '-', ' ');
        },
        replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, 'g'), replace);
        },
        changeTitle(value, status) {// change title
            return status ? this.title = value : this.loadTitle();
        }
    }
  };
</script>
<style scoped>
</style>
