<template>
    <div>
        <div class="container" v-if="! isLoadingBlade && ! showAddPage">
            <div class="row">
                <div class="col-lg-2">
                    <base-input alternative="">
                        <select
                            class="form-control form-control-alternative"
                            v-model="page"
                            @change="getPageItems()"
                        >
                            <option
                                v-for="number in pageNumbers"
                                :key="number"
                                :value="number"
                            >
                                {{ number }}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-10 text-right">
                    <base-button
                        :type="(showAddPage ? 'danger' : 'primary')" size="lg"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges"
                        @click="addNotification()"
                    >
                        <i :class="'fa fa-' + (showAddPage ? 'reply' : 'plus')"></i>
                        {{ showAddPage ? 'Go back' : 'Send Notification' }}
                    </base-button>
                </div>
            </div>
        </div>

        <!-- import notification records details template here -->
        <NotificationsData
            v-bind:records="records"
            v-bind:pagination="pagination"
            v-bind:hasPagination="hasPagination"
            v-bind:paginate="paginate"
            :loadNotifications="loadNotifications"
            v-on:addNotification="addNotification"
            v-on:removeNotification="removeNotification"
            v-if="! isLoadingBlade && ! showAddPage"
        />

        <!-- import add notification template here -->
        <AddNotification
            :data="record"
            :privileges="privileges"
            :loadNotifications="loadNotifications"
            :addNotification="addNotification"
            v-on:addNotification="addNotification"
            :saveNotification="saveNotification"
            v-if="! isLoadingBlade && showAddPage"
        />

        <div class="text-center" v-if='showErrorStatus && ! showAddPage'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import NotificationsData from '../NotificationsData.vue';
    import AddNotification from '../AddNotification';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            NotificationsData,
            AddNotification
        },
        name: 'CpdNotifications',
        data() {
            return {
                records: [],
                paginatedItems: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.url.perPage // get per page count
                },
                searchKey: '',
                page: 1,
                showAddPage: false,
                record: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'menus']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            },
            privileges() {
                let privileges = null;
                if(this.menus && this.menus.length > 0) {
                    for(let menu of this.menus) {
                        if(menu.has_child && menu.has_child.length > 0) {
                            for(let child of menu.has_child) {
                                if('/'+child.link == this.$route.path) {
                                    privileges = child.privileges;
                                }
                            }
                        }
                    }
                }
                return privileges;
            }
        },
        methods: {
            ...mapActions(['getNotifications', 'deleteNotification', 'createNotification']),
            addNotification(data = null) {
                this.record = null;
                this.record = data;
                this.showAddPage = ! this.showAddPage;
            },
            loadNotifications() {
                this.showErrorStatus = false;
                this.getNotifications({ limit : this.pagination.perPage, option : 'cpd' }).then((res) => {
                    this.setPages(res.notifications);
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedSanctions(url).then((res) => {// get users
                        this.pagination.number = res.sanctions.current_page;
                        this.setPages(res.sanctions);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Notifications yet!!!';
            },
            getPageItems() {
                let url = this.pagination.next_page_url ?
                            this.pagination.next_page_url : this.pagination.prev_page_url;
                let exploded = url.split('&');
                let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
                this.isLoadingBlade = true
                this.getPaginatedSanctions(newUrl).then((res) => {// get records
                    this.pagination.number = res.sanctions.current_page;
                    this.setPages(res.sanctions);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            saveNotification(data) {
                data.admin_id = this.$store.state.user.userId;// add admin user id
                data.option = 'cpd';
                this.preloader(); // show loading
                this.createNotification(data).then((res) => {// create cpd
                    if(res.status) {
                        this.addNotification();// hide edit page
                        this.loadNotifications();// refresh page
                        swal.fire("Record Updated!", res.msg, "success");
                    } else {
                        this.loadNotifications();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadNotifications();// refresh page
                    swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                });
            },
            removeNotification(id) {// get full name
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRemoveApiCall(id);// make api call
                    }
                });
            },
            makeRemoveApiCall(id) {
                let data = { id : id , option : 'cpd'};
                this.preloader(); // show loading
                this.deleteNotification(data).then((res) => {// delete
                    if(res.status) {
                        swal.fire("Notification Deleted", res.msg, "success");
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                    this.loadNotifications();// refresh page
                }).
                catch(err => {
                    this.loadNotifications();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadNotifications();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 190px;
    }
</style>
