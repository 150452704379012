
<template>
    <div>
        <!-- Assessment batch payments data template here -->
        <BatchPaymentsData
            :records="payments"
            :batch="batch"
            :privileges="privileges"
            :loadAssessmentPayments="loadAssessmentPayments"
            :showBreakdown="true"
            v-on:viewBreakdowns="viewBreakdowns"
            v-if="payments && payments.length > 0 && ! isloading && ! showErrorStatus && ! showBreakdowns && ! showAddPage"
        />

        <!-- Assessment batch payment breakdowns data template here -->
        <BatchPaymentBreakdownData
            :breakdowns="breakdowns"
            :payment="payment"
            :privileges="privileges"
            :viewBreakdowns="viewBreakdowns"
            v-on:editPaymentBreakdown="editPaymentBreakdown"
            v-if="! isloading && ! showErrorStatus && showBreakdowns && ! showAddPage"
        />

        <div
            class="pl-lg-4 text-center"
            v-if="! isloading && ! showErrorStatus && showBreakdowns"
        >
            <hr>
            <base-button
                :type="(showAddPage ? 'danger' : 'primary')"
                @click="displayAddNewBreakdown()"
                :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
            >
                <i :class="'fa fa-'+(showAddPage ? 'times' : 'plus')"></i>
                {{showAddPage ? 'Cancel Add Breakdown' : 'Add New Breakdown'}}
            </base-button>
        </div>

        <!-- Assessment add batch payment breakdown data template here -->
        <AddBatchPaymentBreakdown
            :privileges="privileges"
            :loadAssessmentPayments="loadAssessmentPayments"
            :payment="payment"
            :breakdown="breakdown"
            :viewBreakdowns="viewBreakdowns"
            v-if="! isloading && ! showErrorStatus && showBreakdowns && showAddPage"
        />

        <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isloading'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <hr>
        <div class="pl-lg-4">
            <base-button
                type="danger"
                :disabled="isloading"
                v-if="! showBreakdowns"
                @click="displayBreakdown(false)"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </base-button>

            <base-button
                type="success"
                class="right"
                :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                @click="completeBatch()"
                v-if="! showBreakdowns && completePayment && enableComplete"
            >
                <i class="fa fa-save"></i>
                Complete Process
            </base-button>
        </div>

        <div class="pl-lg-4">
            <base-button
                type="danger"
                :disabled="isloading"
                v-if="showBreakdowns"
                @click="backToBreakdown()"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </base-button>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import BatchPaymentsData from '../BatchPaymentsData';
    import BatchPaymentBreakdownData from './BatchPaymentBreakdownData';
    import AddBatchPaymentBreakdown from './AddBatchPaymentBreakdown';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'BatchPaymentBreakdown',
        props: ['privileges', 'payments', 'batch', 'loadAssessmentPayments', 'editBatchPayment', 'displayBreakdown',
            'changeTitle', 'allAssessmentTypes', 'displayAddPage'],
        components: {
            BatchPaymentsData,
            BatchPaymentBreakdownData,
            AddBatchPaymentBreakdown
        },
        data() {
            return {
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                breakdowns: [],
                payment: null,
                showBreakdowns: false,
                showAddPage: false,
                breakdown: null
            }
        },
        computed: {
            completePayment() {
                return this.allAssessmentTypes.length == this.payments.length;
            },
            enableComplete() {
                for(let payment of this.payments) {
                    if(! payment.amount) {
                        return false;
                    }
                }
                return true;
            }
        },
        methods: {
            ...mapActions(['getBatchPaymentBreakdowns', 'completeAssessmentBatch']),
            completeBatch() {// complete batch process
                swal.fire({
                    title: 'Are you sure?',
                    text: "Batch will be active from date of commencement!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeCompleteApiCall(this.batch.id);// make api call
                    }
                });
            },
            makeCompleteApiCall(id) {
                this.preloader(); // show loading
                this.completeAssessmentBatch(id).then((res) => {
                    this.displayAddPage(false);// refresh page
                    if(res.status) {
                        swal.fire("Batch Completed", res.msg, "success");
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    // this.viewBreakdowns(this.payment, true);// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            editPaymentBreakdown(record) {
                this.breakdown = null;
                this.breakdown = record;
                this.showAddPage = true;
            },
            backToBreakdown() {
                this.showErrorStatus = false;
                this.showBreakdowns = false;
                this.breakdowns = [];
                this.payment = null;
                this.showAddPage = false;
                this.breakdown = null;
                this.loadAssessmentPayments();
            },
            viewBreakdowns(payment, status = false) {
                this.isloading = true;
                this.showErrorStatus = false;
                this.showBreakdowns = false;
                this.payment = null;
                this.breakdowns = [];
                this.preloader();
                this.getBatchPaymentBreakdowns(payment.id).then((res) => {
                    this.setData(payment, res);
                    ! status ? swal.close() : false;
                }).
                catch(() => {
                    this.breakdowns = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                    swal.close();
                });
            },
            setData(payment, res) {
                this.breakdown = null;
                this.isloading = false;
                this.breakdowns = res.breakdowns;
                this.payment = res.payment;
                this.showBreakdowns = true;
                this.showAddPage = false;
                let title = 'Add Breakdowns to <em class="text-danger">' + payment.title;
                title += (payment.assessment_type ? ' (' + payment.assessment_type.title + ')' : '');
                title += '</em> Assessment Payment Batch';
                this.changeTitle(title, true);
            },
            displayAddNewBreakdown() {
                return this.showAddPage = ! this.showAddPage;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            // this.loadAssessmentPayments();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
    .right {
        float: right !important;
    }
</style>
