<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">Candidate</th>
                            <th width="10%">Category</th>
                            <th width="10%">Apply Date</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.user_data ? getFullName(row.user_data) : 'N/A' }}
                            <br>
                            <badge size="lg"
                                type="dark"
                                class="text-capitalize"
                            >
                                {{ row.user_data ? row.user_data.folio_number : 'N/A'  }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.category ? row.category.name : 'N/A' }}
                        </td>
                        <td>
                            {{ row.created_at | getDateFormat }}
                        </td>
                        <td>
                            <base-button
                                type="success" size="sm"
                                @click="redirectUrl(row.id)"
                                :disabled="privileges && privileges.view == 'no'"
                            >
                                <i class="fa fa-eye"></i> Details
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'OldAddQualificationsData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges",
            "paginatedItems", "code"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getYear(date) {
                return date ? moment(date).format('YYYY') : 'N/A';
            },
            getFullName(value) {
                let name = value.first_name+' ';
                name += value.other_name ? value.other_name+' ' : '';
                name += value.last_name;
                return name.toLowerCase();
            },
            redirectUrl(id) {// redirect to view page
                return window.location.href = '/old-additional-pgq/details/'+id;
            }
        },
    }
</script>