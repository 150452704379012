
<template>
    <div>
        <div class="container">
            <div class="row">

                <div class="col-lg-2">
                    <h3 class="mb-0 text-uppercase">Basic Details</h3>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>

                <div class="col-lg-8" v-if="application">
                    
                    <h3 v-if="(application.cpd && (application.status == 'approved'))">
                        CPD Provider's No - &nbsp;&nbsp;&nbsp;
                        {{ application.cpd.username ? application.cpd.username : 'N/A' }}
                    </h3>

                    <h3>
                        Application Date - &nbsp;&nbsp;&nbsp;
                        {{ application.application_date | formatDate }}
                    </h3>

                    <h3>
                        Payment Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm"
                            :type="getPaymentStatusColor(application.payment_status)"
                        >
                            {{ application.payment_status }}
                        </badge>
                    </h3>

                    <h3 v-if="application.cpd">
                        Account Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm"
                            :type="getAccountStatusColor(application.cpd.status)"
                        >
                            {{ application.cpd.status }}
                        </badge>
                    </h3>

                    <h3>
                        Application Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm"
                            :type="getStatusColor(application.status)"
                        >
                            {{ application.status }}
                        </badge>
                    </h3>

                    <h3 v-if="application && application.application_type">
                        Application Type - &nbsp;&nbsp;&nbsp;
                        {{ application.application_type.name ? application.application_type.name : 'N/A' }}
                    </h3>

                    <h3 v-if="((application.status == 'approved') || (application.status == 'rejected'))" class="text-capitalize">
                        {{ application.status }} Date - &nbsp;&nbsp;&nbsp;
                        {{ application.approved_date | formatDate }}
                    </h3>

                    <h3 v-if="(application.status == 'approved')">
                        Expiry Date - &nbsp;&nbsp;&nbsp;
                        {{ application.expiry_date | formatDate }}
                    </h3>

                    <h3 v-if="(application.status == 'rejected')" class="text-capitalize">
                        Reject Reason - &nbsp;&nbsp;&nbsp;
                        <em class="small">{{ application.reject_reason }}</em>
                    </h3>

                    <h3 v-if="application.notify_date" class="text-capitalize">
                        Fixed Visitation Date - &nbsp;&nbsp;&nbsp;
                        {{ application.notify_date | formatDate }}
                    </h3>

                    <h3 v-if="application.notify_date && application.notify_comment" class="text-capitalize">
                        Visitation Additional Comment - &nbsp;&nbsp;&nbsp;
                        <em class="small">{{ application.notify_comment }}</em> 
                    </h3>
                    <br>

                    <base-button
                        size="lg"
                        type="primary"
                        @click="$emit('displayVisitationDatePage', true)"
                        v-if="(application.status == 'submitted')"
                    >
                        <i class="fa fa-bullhorn"></i>
                        {{ application.notify_date ? 'Change' : 'Notify' }} Visitation Date
                    </base-button>
                    
                    <br>

                    <hr>

                    <div 
                        class="row" 
                        v-if="application && (application.status == 'approved') && application.letter && application.letter.length > 0"
                    >
                        <div 
                            class="col-sm-3"
                            v-for="letter in application.letter"
                            :key="letter.id"                            
                        >
                            <form
                                :action="getRootUrl+'/print-cpd-certificate'"
                                method="POST"
                                target="_blank"
                            >
                                <input type="hidden" name="id" :value="letter.cpd_application_id" />
                                <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                                <input type="hidden" name="user_id" :value="user.id" />
                                <input type="hidden" name="option" value="admin" />
                                <input type="hidden" name="date" :value="letter.date" />
                                <input type="hidden" name="expiry_date" :value="letter.expiry_date" />
                                <input type="hidden" name="visit_date" :value="letter.visit_date" />
                                <input type="hidden" name="ref_no" :value="letter.ref_no" />
                                <input type="hidden" name="certificate" :value="letter.certificate" />

                                
                                <base-button
                                    :type="((letter.certificate == 'yes') ? 'primary' : 'info')"
                                    class="btn btn-lg"
                                    nativeType="submit"
                                >
                                    <i class="fa fa-print"></i>
                                    Print {{ (letter.certificate == 'yes') ? 'Letter' : 'License' }}
                                </base-button>
                            </form>
                        </div>
                    </div>                    
                    <br>

                </div>

                <div class="col-lg-4">
                    <div class="card-profile-image marginTop40">
                        <img
                            :src="getCpdPix(application.cpd)"
                            class="rounded-circle"
                            @error="replaceByDefault"
                        >
                    </div>
                    <br><br><br><br><br>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    // import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'BasicDetails',
        props: ['application', 'privileges', 'loadApplication'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['cpdPhotoUrl', 'blankImageUrl', 'getAuthorizationToken', 'getRootUrl', 'user'])
        },
        methods: {
            getStatusColor(value) {
                if(value == 'approved') {
                    return 'success';
                } else if(value == 'pending') {
                    return 'warning';
                } else {
                    return 'danger'
                }
            },
            getPaymentStatusColor(value) {
                return (value == 'paid') ? 'success' : 'danger';
            },
            getAccountStatusColor(value) {
                return (value == 'enable') ? 'success' : 'danger';
            },
            getCpdPix(cpd) {// get user photo url
                return cpd && cpd.photo ? this.cpdPhotoUrl+cpd.id+'/photo/'+cpd.photo : this.blankImageUrl;
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            }        
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
    .right {
        float: right;
    }
    .marginLeft5 {
        margin-left: 5px;
    }
</style>
