<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <button
                    class="right btn btn-lg btn-danger"
                    @click="getPostPage(false)"
                >
                    <i class="fa fa-arrow-left"></i> Back to List
                </button>
            </div>
            
        </div>
        <hr>

        <div class="row" v-if="! showPostingData && ! showCentreInfo">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 text-center">
                <base-input alternative=""
                    label="Select Attachment Program Centre"
                >
                    <select
                        class="form-control form-control-alternative text-center"
                        v-model="centre"
                    >
                        <option :value="null" disabled>--- Select One ---</option>
                        <option 
                            v-for="centre in centres"
                            :key="centre.id"
                            :value="centre"
                        >
                            {{ centre.name }}
                        </option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-12">
                <button
                    class="btn btn-lg btn-info btn-block"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                    @click="getCentreInfo(true)"
                >
                    <i class="fa fa-check"></i>
                    Get Centre Info
                </button>
            </div>
        </div>

        <SelectedCentreInfo
            :centre="centre"
            :disableButton="disableButton"
            v-if="! showPostingData && showCentreInfo && centre"
        />

        <hr>

        <PostingPageData
            :applicants="applicants"
            v-if="applicants && applicants.length > 0 && (showPostingData || showCentreInfo)"
        />

        <div class="row" v-if="! showPostingData && showCentreInfo">            
            <div class="col-lg-12">
                <hr>
                <base-button
                    type="success" size="lg"
                    @click="postNow()"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges || disableButton"
                >
                    <i class="fa fa-check"></i> Post Now
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="getCentreInfo(false)"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                >
                    <i class="fa fa-times"></i> Cancel
                </base-button>
            </div>
        </div>

    </div>
</template>

<script>
    import moment from 'moment';
    import PostingPageData from './PostingPageData.vue';
    import SelectedCentreInfo from '../SinglePostPage/SelectedCentreInfo.vue';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'PostingPage',
        props: ["applicants", "centres", "privileges", "loadAplications", "preloader", "getPostPage"],
        components: {
            PostingPageData,
            SelectedCentreInfo
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['user']),
            disableButton() {
                return this.centre && ((this.centre.available_space == 0) 
                    || (this.centre.available_space < this.applicants.length))
            }
        },
        data() {
            return {
                centre: null,
                showPostingData: false,
                showCentreInfo: false
            }
        },
        methods: {
            ...mapActions(['postAttachmentApplicants']),
            getCentreInfo(status) {// get centre info
                if(this.centre) {
                    if(status) {
                        this.showCentreInfo = status
                    } else {
                        this.centre = null;
                        this.showCentreInfo = status
                    }
                } else {
                    let msg = 'Kindly select one centre, and try again';
                    swal.fire('Invalid Selection', msg, 'warning');
                }
            },
            postNow() {// post function
                let space = this.centre && this.centre.available_space ? this.centre.available_space : 0;
                let check = space >= this.applicants.length;
                if(check) {// if space is enough                
                    swal.fire({
                        title: 'Are you sure?',
                        text: "You would be able to revert this, on individual applicant!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Proceed!'
                    }).then((result) => {
                        if (result.value) {
                            this.makePostApiCall();// make api call
                        }
                    });
                } else {
                    let msg = 'No available sapce for the selected Applicants, check and try again';
                    swal.fire('No Available Space', msg, 'warning');
                }
            },
            makePostApiCall() {// post applicants api
                let data = { centre_id : this.centre.id, applicants : this.applicants, admin_id : this.user.id };
                this.preloader();// show loading
                this.postAttachmentApplicants(data).then((res) => {// post applicants
                    if(res.status) {
                        swal.fire('Applicants Posted!', res.msg, 'success');
                        
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                    this.loadAplications(); // reload data
                    this.getPostPage();// go back
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            }
        }
    }
</script>
<style scoped>
    .right {
        float: right !important;
    }
</style>