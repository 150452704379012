<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Hospital's Name</th>
                            <th>Application Type</th>
                            <th>{{ title }}</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.training_hospital && row.training_hospital.name ? row.training_hospital.name.toLowerCase() : 'N/A' }}
                            <br>
                            <badge size="lg"
                                type="dark"
                                class="text-capitalize"
                                v-if="row.training_hospital && row.training_hospital.hospital_type"
                            >
                                {{ row.training_hospital.hospital_type  }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ getExactType(row) }}
                        </td>
                        <td>
                            {{ getExactDate(row) | getDateFormat }}
                        </td>
                        <td>
                            <router-link
                                :to="'/training-hospitals/applications/'+row.id"
                                :class="'btn btn-sm btn-success '+
                                    (((privileges && privileges.view == 'no') || ! privileges) ? 'disabled' : '')"
                            >
                                <i class="fa fa-eye"></i> Details
                            </router-link>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'PendingApplicationData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "title", "paginatedItems"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getExactType(data) {
                if(data) {
                    let text = data.request_type ? data.request_type+' for ' : '';
                    text += data.training_type ? data.training_type+' training' : '';
                    return text;
                } else {
                    return 'N/A';
                }
            },
            getExactDate(data) {// get exact date for the application
                if(data && (data.status == 'pending')) {
                    return data.application_date;
                } else {
                    return data.approved_date ? data.approved_date : data.application_date;
                }
            }
        },
    }
</script>