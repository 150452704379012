
<template>
    <div>
        <div v-if="! showErrorStatus">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="font-weight-bold">
                        {{ state.name }}'s Local Government Area
                    </h2>
                </div>

                <div class="col-lg-6 text-right">
                    <button
                        class="btn btn-lg btn-info"
                        @click="editLga()"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges"
                    >
                        <i class="fa fa-plus"></i> Add Lga
                    </button>

                    <button
                        class="btn btn-lg btn-danger"
                        @click="$emit('displayLgas', false)"
                    >
                        <i class="fa fa-reply"></i> Back
                    </button>
                </div>
            </div>
            <hr>

            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing 1 - {{ lgas.length }} of {{ lgas.length }} entries
                </small>
            </div>


            <div
                class="card shadow"
                v-if="lgas && lgas.length > 0"
            >
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="lgas"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="20%">Lga</th>
                            <th width="10%">State</th>
                            <th width="10%">Date Modified</th>
                            <th width="10%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td class="budget">
                                {{ index+1 }}
                            </td>
                            <td class="text-capitalize font-weight-bold">
                                {{ row.name }}
                            </td>
                            <td class="text-capitalize">
                                <badge
                                    size="sm" type="primary"
                                    class="font-weight-bolder"
                                >
                                    {{ state.name  }}
                                </badge>
                            </td>
                            <td>
                                {{ row.updated_at | formatDate }}
                            </td>
                            <td>
                                <div class="text-right">
                                    <base-button
                                        type="primary" size="sm"
                                        @click="editLga(row)"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-pen"></i>
                                        Edit
                                    </base-button>

                                    <base-button
                                        type="danger" size="sm"
                                        @click="deleteLga(row.id)"
                                        :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                        v-if="row.delete_status"
                                    >
                                        <i class="fa fa-trash"></i>
                                        Delete
                                    </base-button>
                                </div>
                            </td>
                        </template>
                    </base-table>
                </div>

            </div>
        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'Lgas',
    props: ['state', 'loadStates', 'privileges', 'displayLgas'],
    data() {
      return {
        lgas: [],
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    methods: {
        ...mapActions(['updateLga', 'deleteUserLga']),
        setData() {// set paginated function
            this.showErrorStatus = false;
            this.lgas = [];
            this.lgas = this.state ? this.state.lgas : [];
            this.emptyRecordsMsg();//show empty msg
        },
        emptyRecordsMsg() {
            return this.lgas && this.lgas.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Lgas added yet!!!';
        },
        editLga(lga) {
            let name = lga && lga.name ? lga.name : '';
            swal.fire({
                title: (lga ? 'Update ('+lga.name+')' : 'Add New LGA'),
                html:
                    '<br>'+
                    '<label><b>LGA Name</b></label>' +
                    '<input id="swal-input1" class="swal2-input" value="'+ name+'">',
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: (lga ? 'Update it!' : 'Add Now!'),
                cancelButtonText: 'No, cancel!',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let data =  {
                        'id' : lga ? lga.id : null,
                        'state_id' : this.state.id,
                        'name' : document.getElementById('swal-input1').value,
                        'admin_id' : this.$store.state.user.userId // add admin user id
                    };
                    if(data && data.name) {//update
                        return this.updateLga(data).then((response) => {
                            if (!response.status) {
                            throw new Error(response.msg)
                            }
                            return response
                        }).
                        catch((error) => {
                            swal.showValidationMessage(
                            `Request failed: ${error}`
                            )
                        });
                    } else {
                       swal.showValidationMessage(
                            `Request failed: Fill all necessary fields`
                        )
                    }
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then((res) => {
                if (res.value) {
                    this.lgas = [];
                    let type = res.value.status ? 'success' : 'error';
                    let title = res.value.status ? 'Lga '+res.value.type+'!' : 'Error Occurred!';
                    swal.fire(title, res.value.msg, type);
                    this.displayLgas(false);
                    this.loadStates();// refresh items
                }
            });
        },
        deleteLga(id) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall(id);// make api call
                }
            });
        },
        makeDeleteApiCall(id) {
            let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
            this.preloader(); // show loading
            this.deleteUserLga(data).then((res) => {// delete state
                if(res.status) {
                    this.displayLgas(false);
                    this.loadStates();// refresh page
                    swal.fire("Record Deleted", res.msg, "success");
                } else {
                    this.loadStates();// refresh page
                    swal.fire("Error Occurred!", res.msg, "warning");
                }
            }).
            catch(err => {
                this.loadStates();// refresh page
                swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    created() {
        this.setData();
    }
  };
</script>
<style scoped>
    .right {
        float: 'right';
    }
    .full-width {
        width: 100%;
    }
</style>
