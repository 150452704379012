
<template>
    <div>
        <div v-if="! showLgas">
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-10 text-right">
                        <button
                            class="btn btn-lg btn-info"
                            @click="editState()"
                            :disabled="(privileges && privileges.add == 'no') || ! privileges"
                        >
                            <i class="fa fa-plus"></i> Add State
                        </button>
                        <br><br>
                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>

            <div
                class="card shadow"
                v-if="records && records.length > 0"
            >
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="20%">State</th>
                            <th width="10%">Region</th>
                            <th width="10%">Lgas</th>
                            <th width="10%">Postage Fee</th>
                            <th width="10%">Date Modified</th>
                            <th width="10%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td class="text-capitalize font-weight-bold">
                                {{ row.name }}
                            </td>
                            <td class="text-capitalize">
                                {{ row.region_id ? getExactRegion(row.region_id) : 'N/A' }}
                            </td>
                            <td class="text-capitalize">
                                <badge
                                    size="sm" type="primary"
                                    class="font-weight-bolder"
                                >
                                    {{ row.lgas ? row.lgas.length : 'M/A' }}
                                </badge>
                            </td>
                            <td>
                                {{ row.postage_fee ? '&#x20a6; '+formatPrice(row.postage_fee) : 'N/A' }}
                            </td>
                            <td>
                                {{ row.updated_at | formatDate }}
                            </td>
                            <td>
                                <div class="text-right">
                                    <base-button
                                        type="info" size="sm"
                                        @click="displayLgas(true, row)"
                                        :disabled="(privileges && privileges.view == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-eye"></i>
                                        View Lgas
                                    </base-button>

                                    <base-button
                                        type="primary" size="sm"
                                        @click="editState(row)"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-pen"></i>
                                        Edit
                                    </base-button>

                                    <base-button
                                        type="danger" size="sm"
                                        @click="deleteState(row.id)"
                                        :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                        v-if="row.delete_status"
                                    >
                                        <i class="fa fa-trash"></i>
                                        Delete
                                    </base-button>
                                </div>
                            </td>
                        </template>
                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                        @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>
        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus && ! showLgas"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <!-- import Lgas template here -->
        <Lgas
            v-bind:state="state"
            v-bind:loadStates="loadStates"
            v-bind:privileges="privileges"
            v-bind:displayLgas="displayLgas"
            v-on:displayLgas="displayLgas"
            v-if="showLgas"
        />

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import Lgas from './Lgas';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'States',
    props: ['privileges'],
    components: {
        Lgas
    },
    data() {
      return {
        state: null,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        page: 1,
        showLgas: false
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        ...mapGetters(['loadingBladeUrl', 'locations']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        }
    },
    methods: {
        ...mapActions(['getStates', 'getPaginatedSpecializations', 'updateState', 'deleteUserState', 'getLocations']),
        displayLgas(status, data) {
            this.showLgas = status;
            this.state = data;
        },
        formatPrice(value) {
            if(value) {
                let val = (value/1).toFixed(2).replace(',', '.');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 'N/A';
            }
        },
        loadStates() {// load details
            this.page = 1;// return page to default
            let data = { 'limit' : this.pagination.perPage };
            this.getStates(data).then((res) => {// get Countries
                this.isLoadingBlade = false;
                this.setPages(res);//set data
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedSpecializations(url).then((res) => {// get users
                    this.pagination.number = res.current_page;
                    this.setPages(res);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Country added yet!!!';
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedSpecializations(newUrl).then((res) => {// get records
                this.pagination.number = res.current_page;
                this.setPages(res);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        editState(state) {
            let name = state && state.name ? state.name : '';
            let postage_fee = state && state.postage_fee ? state.postage_fee : '';
            let region = state && state.region_id ? state.region_id : '';
            let options = '';
            for(let reg of this.locations.regions) {// format region select options
                options += '<option '+ (reg.id == state.region_id ? "selected" : "") 
                                +' value="' + reg.id +'">' + reg.name +'</option>'
            }
            swal.fire({
                title: (state ? 'Update ('+state.name+')' : 'Add New State'),
                html:
                    '<br>'+
                    '<label><b>State Name</b></label>' +
                    '<input id="swal-input1" class="swal2-input" value="'+ name+'">'+
                    '<br>'+
                    '<label><b>Postage Fee</b></label><br>' +
                    '<input id="swal-input2" class="swal2-input" value="'+ postage_fee+'" type="number">' +
                    '<label><b>Region</b></label><br>' +
                    '<select id="swal-input3" class="swal2-input" value="'+ region +'">' + options + '</select>',
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: (state ? 'Update it!' : 'Add Now!'),
                cancelButtonText: 'No, cancel!',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let data =  {
                        'id' : state ? state.id : null,
                        'name' : document.getElementById('swal-input1').value,
                        'postage_fee' : document.getElementById('swal-input2').value,
                        'region_id' : document.getElementById('swal-input3').value,
                        'admin_id' : this.$store.state.user.userId // add admin user id
                    };
                    if(data && data.name) {//update
                        return this.updateState(data).then((response) => {
                            if (!response.status) {
                            throw new Error(response.msg)
                            }
                            return response
                        }).
                        catch((error) => {
                            swal.showValidationMessage(
                            `Request failed: ${error}`
                            )
                        });
                    } else {
                       swal.showValidationMessage(
                            `Request failed: Fill all necessary fields`
                        )
                    }
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then((res) => {
                if (res.value) {
                    let type = res.value.status ? 'success' : 'error';
                    let title = res.value.status ? 'State '+res.value.type+'!' : 'Error Occurred!';
                    swal.fire(title, res.value.msg, type);
                    this.loadStates();// refresh items
                }
            });
        },
        deleteState(id) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall(id);// make api call
                }
            });
        },
        makeDeleteApiCall(id) {
            let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
            this.preloader(); // show loading
            this.deleteUserState(data).then((res) => {// delete state
                if(res.status) {
                    this.loadStates();// refresh page
                    swal.fire("Record Deleted", res.msg, "success");
                } else {
                    this.loadStates();// refresh page
                    swal.fire("Error Occurred!", res.msg, "warning");
                }
            }).
            catch(err => {
                this.loadStates();// refresh page
                swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
            });
        },
        getExactRegion(id) {// get exact region
            let region = this.locations.regions.filter(e => e.id == id);
            return region && region.length > 0 ? region[0].name : 'N/A';
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadStates();
        this.getLocations();
    }
  };
</script>
<style scoped>
    .right {
        float: 'right';
    }
    .swal2-input[type=number] {
        width: 100%;
    }
</style>
