
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">                                
                            <select 
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option 
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-6 text-right">
                        <base-input 
                            placeholder="Search by Name/Folio Number and hit enter"
                            class="input-group-alternative"
                            alternative=""
                            addon-right-icon="fas fa-search"
                            @change="searchRecords"
                            v-model="searchKey"
                            >
                        </base-input>
                    </div>
                </div>  
            </div>
            
            <!-- import Add Exit Register Data template here -->
            <AddExitRegisterData
                v-bind:records="records"
                v-bind:hasPagination="hasPagination"
                v-bind:pagination="pagination"
                v-bind:paginate="paginate"
                v-bind:privileges="privileges"
                v-bind:loadUsers="loadUsers"
            />

            <div 
                class="col-sm-12 text-center" 
                v-if="showErrorStatus"
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>
        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'; 
  import moment from 'moment';
  import AddExitRegisterData from './AddExitRegisterData';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'AddExitRegister',
    props: ['privileges'],
    components: {
        AddExitRegisterData
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null, 
            perPage: this.$store.state.url.perPage // get per page count 
        },
        page: 1,
        searchKey: ''
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;           
        }
    },
    methods: {
        ...mapActions(['getUsers', 'getPaginatedPaymentItems']),
        loadUsers() {// load details 
            let data = { 'limit' : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getUsers(data).then((res) => {// get users
                this.isLoadingBlade = false;
                this.setPages(res);//set data
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ? 
                this.pagination.next_page_url : this.pagination.prev_page_url;            
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.current_page;
                    this.setPages(res);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }           
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true; 
            this.errorType = 'warning';
            this.errorMsg = 'No Users added yet!!!';
        },
        getPageItems() {
            let url = this.pagination.next_page_url ? 
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                this.pagination.number = res.current_page;
                this.setPages(res);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        searchRecords() {
            this.showErrorStatus = false;
            let key = this.searchKey.toLowerCase();
            if(key != '' ) {
                this.isLoadingBlade = true;
                let data = { 'limit' : this.pagination.perPage, 'key' : key };
                this.records = [];
                this.getUsers(data).then((res) => {// get records
                    this.isLoadingBlade = false;
                    this.setPages(res);//set data
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.loadUsers();
            }         
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadUsers();
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
