<template>
    <div class="row align-items-center">
        <div class="col-lg-12">
            <h2 class="text-uppercase text-center">
                Quack Report from
                <em class="text-danger">{{ report.name }}</em>
            </h2>
            <hr>
        </div>

        <div class="col-lg-12">

            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <td width="20%">
                            <h4>Reporter's Full Name</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.name ? report.name : 'N/A' }}
                            </h4>
                        </td>
                        <td width="20%">
                            <h4>Reporter's Email Address</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.email ? report.email : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td width="20%">
                            <h4>Reporter's Phone Number</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.phone ? report.phone : 'N/A'  }}
                            </h4>
                        </td>
                        <td width="20%">
                            <h4>Suspect's Name</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.suspect_name ? report.suspect_name : 'N/A'  }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td width="20%">
                            <h4>Suspect's Gender</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.suspect_gender ? report.suspect_gender : 'N/A'  }}
                            </h4>
                        </td>
                        <td width="20%">
                            <h4>Suspect's Category</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.suspect_category ? report.suspect_category : 'N/A'  }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td width="20%">
                            <h4>Suspect's Address</h4>
                        </td>
                        <td width="60%" colspan="4">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.suspect_address ? report.suspect_address : 'N/A'  }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td width="20%">
                            <h4>Suspect's State of Practice</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.has_practice_state && report.has_practice_state.name
                                    ? report.has_practice_state.name : 'N/A'  }}
                            </h4>
                        </td>
                        <td width="20%">
                            <h4>Suspect's LGA</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.has_practice_lga && report.has_practice_lga.name
                                    ? report.has_practice_lga.name : 'N/A'  }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td width="20%">
                            <h4>Date of Incident</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                {{ report.date_of_incident ? getDateFormat(report.date_of_incident) : 'N/A'  }}
                            </h4>
                        </td>
                        <td width="20%">
                            <h4>Status</h4>
                        </td>
                        <td width="30%">
                            <h4 class="text-capitalize font-weight-light">
                                <badge size="lg"
                                    :type="((report.status == 'pending') ? 'warning' : 'success')"
                                    class="text-uppercase"
                                >
                                    {{ report.status  }}
                                </badge>
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-12">
                <hr>
                <h3 class="text-uppercase">
                    Complain Details:
                </h3>
                <span v-html="report.message"></span>
                <hr>
            </div>

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="changeStatus(report.id)"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                >
                    <i class="fa fa-history"></i>
                    {{isloading ? 'Please Wait...' : 'Change Status'}}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="viewDetails(null, false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>

        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ViewQuackReport',
        props: ["report", "privileges", "loadReports", "viewDetails"],
        data() {
            return {
                isloading: false
            }
        },
        methods: {
            ...mapActions(['changeReportStatus']),
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            changeStatus(id) {// change complaint's status
                this.isloading = true;
                this.preloader();// show loading
                this.changeReportStatus(id).then((res) => {
                    if(res.status) {
                        let status = (this.report.status == 'pending') ? 'resolved' : 'pending';
                        this.report.status = status;
                        this.loadReports();
                        swal.fire('Status Changed!', res.msg, 'success');
                        setTimeout(function() { window.location.reload(); }, 1000);// reload page
                    } else {
                        swal.fire('Error Occurred', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    swal.fire('Error Occurred', 'An error occurred while updating, try again', 'warning');
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>
<style scoped>
    .right {
        float: right;
    }
</style>