
<template>
    <div>
        <div v-if="! displayBreakdown">
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-10"></div>
                </div>
            </div>

            <div class="col-lg-8" v-if="! isLoadingBlade">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>

            <div
                class="card shadow"
                v-if="records && records.length > 0 && ! isLoadingBlade"
            >
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="20%">Payment Items</th>
                            <th width="10%">Amount</th>
                            <th width="10%">Surchage</th>
                            <th width="15%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td>
                                {{ row.application_type ? row.application_type.name : 'N/A' }}
                            </td>
                            <td v-html="(row.amount > 0 ? getExactCurrency(row) +formatPrice(row.amount) : 'N/A')"></td>
                            <td v-html="(row.surcharge > 0 ? getExactCurrency(row) +formatPrice(row.surcharge) : 'N/A')"></td>
                            <td class="text-capitalize">
                                <base-button
                                    type="info" size="sm"
                                    @click="showBreakdowns(true, row)"
                                    :disabled="(privileges && privileges.view == 'no') || ! privileges"
                                >
                                    <i class="fa fa-eye"></i>
                                    Breakdowns
                                </base-button>

                                <base-button
                                    type="primary" size="sm"
                                    @click="editPaymentSurcharge(row, 'surcharge')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-pen"></i>
                                    Edit Surcharge
                                </base-button>

                                <base-button
                                    type="dark" size="sm"
                                    @click="editPaymentCurrency(row, 'currency')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-pen"></i>
                                    Edit Currency
                                </base-button>
                            </td>
                        </template>
                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                        @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>
        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus && ! displayBreakdown && ! isLoadingBlade"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <!-- import Payment Breakdowns template here -->
        <PaymentBreakdowns
            v-bind:payment="payment"
            v-bind:privileges="privileges"
            v-bind:changeTitle="changeTitle"
            v-bind:loadPaymentItems="loadPaymentItems"
            v-on:showBreakdowns="showBreakdowns"
            v-if="displayBreakdown && ! isLoadingBlade"
        />
    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import PaymentBreakdowns from './PaymentBreakdowns';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'PaymentSettings',
    props: ['privileges'],
    components: {
        PaymentBreakdowns
    },
    data() {
      return {
        payment: [],
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        title: 'Payment Settings',
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        searchKey: '',
        page: 1,
        displayBreakdown: false
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        }
    },
    methods: {
        ...mapActions(['getPaymentItems', 'getPaginatedPaymentItems', 'updatePaymentSurcharge']),
        getExactCurrency(record) {// get exact payment currency
            const currency = record.currency;
            return (currency == 'NGN') ? '&#x20a6; ' : '&dollar; ';
        },
        formatPrice(value) {
            if(value) {
                let val = (value/1).toFixed(2).replace(',', '.');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 'N/A';
            }
        },
        loadPaymentItems() {// load details
            let data = { 'limit' : this.pagination.perPage };
            this.getPaymentItems(data).then((res) => {// get users
                this.isLoadingBlade = false;
                this.setPages(res);//set data
            }).
            catch(() => {
                this.isLoadingBlade = false;
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.current_page;
                    this.setPages(res);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Users added yet!!!';
        },
        changeTitle(value) {// change title
            this.title = value;
        },
        searchRecords() {
            this.showErrorStatus = false;
            let key = this.searchKey.toUpperCase();
            if(key != '' ) {
                this.isLoadingBlade = true;
                this.users = [];
                this.searchAdminUser(key).then((res) => {// get records
                    this.isLoadingBlade = false;
                    this.users = res;
                    if(this.users.length == 0) {
                        this.showErrorStatus = true;
                        this.errorType = 'warning';
                        this.errorMsg = 'No Matched Record!!!';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.users = this.paginatedItems;
                this.emptyRecordsMsg();
            }
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                this.pagination.number = res.current_page;
                this.setPages(res);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        showBreakdowns(status, data) {
            this.title = status && data.application_type ?
                    data.application_type.name+'\'s Payment Breakdowns' : 'Payment Settings';
            this.displayBreakdown = status;
            this.payment = data;
        },
        editPaymentCurrency(payment, type) {// update payment item currency type function
            let name = payment && payment.application_type ? payment.application_type.name : 'N/A';
            swal.fire({
                title: 'Edit ('+name+') Currency Type',
                html:
                    '<br>'+
                    '<select id="swal-input1" class="swal2-input" value="'+ payment.currency+'">'+
                    '<option value="NGN"'+ ((payment.currency == 'NGN') ? 'selected' : '') +'>NGN (Nigerian Naira)</option>'+
                    '<option value="USD"'+ ((payment.currency == 'USD') ? 'selected' : '') +'>US Dollar</option></select>',
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Update it!',
                cancelButtonText: 'No, cancel!',
                showLoaderOnConfirm: true,
            preConfirm: (surcharge) => {
                let data = { 
                    type : type,
                    currency : document.getElementById('swal-input1').value,
                    id : payment.id,
                    admin_id : this.$store.state.user.userId
                };
                return this.updatePaymentSurcharge(data).then((response) => {//update
                    if (!response.status) {
                    throw new Error(response.msg)
                    }
                    return response
                }).
                catch((error) => {
                    swal.showValidationMessage(
                    `Request failed: ${error}`
                    )
                });
            },
            allowOutsideClick: () => !swal.isLoading()
            }).then((res) => {
                if (res.value) {
                    let type = res.value.status ? 'success' : 'error';
                    let title = res.value.status ? 'Currency Updated!' : 'Error Occurred!';
                    swal.fire(title, res.value.msg, type);
                    this.loadPaymentItems();// refresh page
                }
            });
        },
        editPaymentSurcharge(payment, type) {// update payment item surcharge amount function
            let name = payment && payment.application_type ? payment.application_type.name : 'N/A';
            swal.fire({
                title: 'Edit ('+name+') Surcharge Amount',
                input: 'number',
                inputValue: payment.surcharge,
                showCancelButton: true,
                confirmButtonText: 'Update',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showLoaderOnConfirm: true,
            preConfirm: (surcharge) => {
                let data = { 
                    type : type,
                    surcharge : surcharge, 
                    id : payment.id,
                    admin_id : this.$store.state.user.userId
                };
                return this.updatePaymentSurcharge(data).then((response) => {//update
                    if (!response.status) {
                    throw new Error(response.msg)
                    }
                    return response
                }).
                catch((error) => {
                    swal.showValidationMessage(
                    `Request failed: ${error}`
                    )
                });
            },
            allowOutsideClick: () => !swal.isLoading()
            }).then((res) => {
                if (res.value) {
                    let type = res.value.status ? 'success' : 'error';
                    let title = res.value.status ? 'Surcharge Updated!' : 'Error Occurred!';
                    swal.fire(title, res.value.msg, type);
                    this.loadPaymentItems();// refresh page
                }
            });
        }
        
    },
    mounted() {
        this.loadPaymentItems();
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
