
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th>Payer's Name</th>
                        <th>RRR / Amount</th>
                        <th>Type</th>
                        <th>Remark(s) / <br>Status</th>
                        <th>Date / Time <br>Paid</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            <span v-html="getName(row)"></span> <br>
                            <badge 
                                :type="((row.assessment_applicant.seat_status == 'yes') ? 'success' : 'danger')"
                                v-if="row.assessment_applicant && row.assessment_applicant.seat_status"
                            >
                                {{ (row.assessment_applicant.seat_status == 'yes') ? 'Seated' : 'Absent' }}
                            </badge> &nbsp;
                            <badge 
                                :type="((row.assessment_applicant.pass_status == 'pass') ? 'success' : 'danger')"
                                v-if="row.assessment_applicant && row.assessment_applicant.pass_status"
                            >
                                {{ row.assessment_applicant.pass_status }}
                            </badge>
                        </td>
                        <td>
                            {{ row.payment_ref }}<br>
                            <badge type="primary">
                                &#x20a6; {{ row.amount | formatPrice }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ getType(row) }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.assessment_applicant ? row.assessment_applicant.status : 'Pending' }}
                        </td>
                        <td>
                            {{ getExactDate(row) | formatDate }}<br>
                            <badge type="success">
                                {{ getExactDate(row) | formatTime }}
                            </badge>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>

            <div class="col-lg-12" v-if="totalAmount">
                <hr>
                <div class="container">
                    <h3 class="text-uppercase">
                        total amount paid:
                        <span class="right">
                            &#x20a6; {{ totalAmount | formatPrice }}
                        </span>
                    </h3>
                </div>
                <hr>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import Badge from '../../../components/Badge.vue';

    export default {
    components: { Badge },
        name: 'ReportingData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'totalAmount'],
        filters: {
            formatDate(date) {
                return date ? moment(date).format('Do MMM, YYYY') : 'N/A';
            },
            formatTime(date) {
                return date ? moment(date).format('hh:mm') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        methods: {
            getName(record) {// get full name
                let data = record && record.assessment_applicant && record.assessment_applicant.personal_data ?
                            record.assessment_applicant.personal_data : 'N/A';
                let name = data ? data.first_name +' ' : '';
                name += data && data.other_name ? data.other_name +' ' : '';
                name += data ? data.surname : '';
                return name ? this.breakWord(name) : 'N/A';
            },
            getExactDate(record) {
                return record.date_paid ? record.date_paid : record.date_generated;
            },
            getType(record) {// get purpose
                let type;
                let data = record && record.assessment_applicant && record.assessment_applicant.additional_data ?
                                        record.assessment_applicant.additional_data : false;
                if(data && data.practice_type) {
                    type = (data && data.practice_type == 'm') ? 'Medical' : 'Dental';
                } else {
                    type = false;
                }
                return type ? ('Assessment - ' + type) : 'N/A';
            },
            breakWord(word, length = 40) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
