
<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-10">
                                    <h3 class="mb-0 text-uppercase" v-if="application && application.training_school">
                                        <em class="text-danger">{{ application.type }}</em> School
                                        Indexing Application from
                                        <em class="text-danger">
                                            {{ application.training_school.name }}
                                        </em>
                                    </h3>
                                </div>
                                <div class="col-2 text-right">
                                    <a href="javascript:void(0)" @click="$router.go(-1)" class="btn btn-sm btn-danger">
                                      <i class="fa fa-reply"></i> Back
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && ! approvalPage && application">

                            <!-- Import basic details template here -->
                            <BasicDetails
                                :application="application"
                                :loadApplication="loadApplication"
                                :privileges="privileges"
                            />

                            <div class="row">
                                <div class="col-9">
                                    <h3 class="mb-0 text-uppercase left">Indexing Students' Records</h3>
                                </div>
                                <div 
                                    class="col-3 text-right"
                                    v-if="application && (application.status == 'submitted')"
                                >
                                    <base-button
                                        class="right"
                                        type="primary"
                                        size="lg"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                        @click="updateSerialNo()"
                                    >
                                        <i class="fa fa-save"></i>
                                        Update Serial No
                                    </base-button>
                                </div>
                                
                            </div>

                            <hr class="my-4" />


                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-9">
                                        <div>
                                            <small class="font-weight-bold">
                                                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                                                {{ pagination.total }} entries
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="page"
                                            @change="getPageItems()"
                                        >
                                            <option
                                                v-for="number in pageNumbers"
                                                :key="number"
                                                :value="number"
                                            >
                                                {{ 'Page ' + number }}
                                            </option>
                                        </select>
                                    </div>
                                    
                                </div>
                                <br>
                            </div>

                            <!-- Import student records details template here -->
                            <StudentRecords
                                :records="records"
                                :loadApplication="loadApplication"
                                :privileges="privileges"
                                :pagination="pagination"
                                :paginate="paginate"
                                :hasPagination="hasPagination"
                                v-on:getPageItems="getPageItems"
                            />

                            <div v-if="application && ! application.serial_status" class="text-center">
                                <h3 class="text-danger">
                                    Enter all Serial Numbers, so you can approve the list above
                                </h3>
                            </div>

                            <div v-if="(application.status == 'submitted')">
                                <base-button
                                    type="danger" size="lg"
                                    @click="showApprovalPage('reject')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-ban"></i> Reject
                                </base-button>

                                <base-button
                                    type="success" size="lg"
                                    class="right"
                                    @click="showApprovalPage('approve')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges ||
                                                (! application.serial_status)"
                                >
                                    <i class="fa fa-check"></i> Approve
                                </base-button>
                            </div>

                        </div>

                        <div v-if="approvalPage">

                          <!-- Import Approve Application template here -->
                          <ApproveApplication
                            :application="application"
                            :privileges="privileges"
                            :loadApplication="loadApplication"
                            :hideApprovePage="hideApprovePage"
                            v-on:hideApprovePage="hideApprovePage"
                            v-if="pageType == 'approve'"
                          />

                          <!-- Import Reject Application template here -->
                          <RejectApplication
                            :application="application"
                            :privileges="privileges"
                            :loadApplication="loadApplication"
                            v-on:hideApprovePage="hideApprovePage"
                            v-if="pageType == 'reject'"
                          />

                        </div>

                        <div class="col-sm-12 text-center">
                          <base-alert :type="errorType" v-if='showErrorStatus'>
                              <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                              <span class="alert-inner--text">
                                  {{errorMsg}}
                              </span>
                          </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import BasicDetails from './BasicDetails';
    import StudentRecords from './StudentRecords.vue';
    import RejectApplication from './RejectApplication';
    import ApproveApplication from './ApproveApplication';
    import moment from 'moment';
    import swal from 'sweetalert2';

    export default {
        name: 'ApplicationDetail',
        components: {
            BasicDetails,
            StudentRecords,
            RejectApplication,
            ApproveApplication,
        },
        data() {
            return {
                id: this.$route.params.id,
                application: {},
                records: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.url.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                approvalPage: false,
                pageType: null,
                page: 1
            }
        },
        filters: {
            getDateFormat(value) {
                if(value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'menus']),
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            },
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            privileges() {
                let privileges = null;
                if(this.menus && this.menus.length > 0) {
                for(let menu of this.menus) {
                    if(menu.has_child && menu.has_child.length > 0) {
                        for(let child of menu.has_child) {
                            if('/'+child.link == '/indexing/indexing-applications') {
                                privileges = child.privileges;
                            }
                        }
                    }
                }
                }
                return privileges;
            },
            isAccreditation() {
                return this.application && this.application.type == 'accreditation';
            }
        },
        methods: {
            ...mapActions(['getIndexingApplication', 'getPaginatedExactApplications', 'updateIndexingSerialNo']),
            loadApplication() {// load Application
                let data = { id : this.id, limit : this.pagination.perPage };
                this.getIndexingApplication(data).then((res) => {// get records
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.setPages(res);
                    } else {
                        this.showErrorStatus = true;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(record) {// set paginated function
                this.application = record.application;
                let res = record.students;
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Indexing Applications Yet!!!';
            },
            getPageItems() {
                let url = this.pagination.next_page_url ?
                            this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {
                    let exploded = url.split('&');
                    let newUrl = exploded[0]+'&'+exploded[1]+'&limit='+this.pagination.perPage+'&page='+this.page;
                    this.isLoadingBlade = true
                    this.getPaginatedExactApplications(newUrl).then((res) => {// get records
                        this.pagination.number = res.students.current_page;
                        this.page = res.students.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.records = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                }
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedExactApplications(url).then((res) => {// get records
                        this.pagination.number = res.students.current_page;
                        this.page = res.students.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.records = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            showApprovalPage(type) {// show approval/reject page
                this.approvalPage = true;
                this.pageType = type;
            },
            hideApprovePage() {// hide approve page
                this.approvalPage = false;
                this.pageType = null;
            },
            updateSerialNo() {
                let prefix = this.application && this.application.prefix ? this.application.prefix : null;
                swal.fire({
                    title: 'Update Serial Numbers',
                    html:
                        '<br>'+
                        '<label><b>Enter Serial Number Prefix <br> <small>(Without the Number)</b></small></label>' +
                        '<input id="swal-input1" class="swal2-input" value="'+ prefix +'" ' +
                        'placeholder="Enter Serial Number Prefix"><br>',
                    focusConfirm: false,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Update Now!',
                    cancelButtonText: 'No, cancel!',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        let data =  {// get all data
                            id : this.application && this.application.id ? this.application.id : null,
                            prefix : document.getElementById('swal-input1').value,
                            admin_id : this.$store.state.user.userId // add admin user id
                        };
                        if(data && data.id && (data.prefix != '')) {//update
                            return this.updateIndexingSerialNo(data).then((response) => {
                                if (!response.status) {
                                throw new Error(response.msg)
                                }
                                return response
                            }).
                            catch((error) => {
                                swal.showValidationMessage(
                                `Request failed: ${error}`
                                )
                            });
                        } else {
                        swal.showValidationMessage(
                                `Request failed: Fill all necessary fields`
                            )
                        }
                    },
                    allowOutsideClick: () => !swal.isLoading()
                }).then((res) => {
                    if (res.value) {
                        let type = res.value.status ? 'success' : 'error';
                        let title = res.value.status ? 'Serial Numbers Updated!' : 'Error Occurred!';
                        swal.fire(title, res.value.msg, type);
                        this.loadApplication();// refresh items
                    }
                });
            },
        },
        mounted() {
            this.loadApplication();// load Application
        }
    };
</script>
<style scoped>
.right {
  float: right;
}
</style>
