
<template>
    <div>

        <div class="table-responsive">

            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>CPD Provider's Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.cpd ? application.cpd.name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Email Address:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ application.cpd ? application.cpd.email : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Phone Number:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ application.cpd ? application.cpd.phone : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Does This Provider Needs Certificate:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input>
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="certificate"
                                            :disabled="showBackButton"
                                        >
                                            <option  value="null" disabled>---Select One---</option>
                                            <option  value="no">No</option>
                                            <option  value="yes">Yes</option>
                                        </select>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>CPD Provider's Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        input-classes="form-control-alternative"
                                        placeholder="Enter CPD Provider's Number"
                                        v-model="username"
                                        type="text"
                                        :readonly="showBackButton"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Modify License Year:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input>
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="license_year"
                                            :disabled="showBackButton"
                                        >
                                            <option  value="null" disabled>
                                                ---Select One---
                                            </option>
                                            <option
                                                v-for="(year, index) in years"
                                                :key="index"
                                                :value="year"
                                            >
                                                {{ year }}
                                            </option>
                                        </select>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="(isVisitation || isReaccreditation) && showLetterDetails">
                    <td width="30%">
                        <h4>Ref Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        input-classes="form-control-alternative"
                                        placeholder="Enter Ref Number"
                                        v-model="ref_no"
                                        type="text"
                                        :readonly="showBackButton"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="(isVisitation || isReaccreditation) && showLetterDetails">
                    <td width="30%">
                        <h4>Visitation Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: false}"
                                            class="form-control datepicker"
                                            placeholder="Select Date..."
                                            v-model="visit_date"
                                            :disabled="showBackButton"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="isVisitation || isReaccreditation">
                    <td width="30%">
                        <h4>Expiry Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: false}"
                                            class="form-control datepicker"
                                            placeholder="Select Date..."
                                            v-model="expiry_date"
                                            :disabled="showBackButton"
                                            @input="updateExpiryDate"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="isCompleted && ! showBackButton && (isVisitation  || isReaccreditation)">
                    <td width="30%">
                        <h4>Preview Letter:</h4>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-3" v-if="showLetterDetails">
                                <form
                                    :action="getRootUrl+'/print-cpd-certificate'"
                                    method="POST"
                                    target="_blank"                                    
                                >
                                    <input type="hidden" name="id" :value="application.id" />
                                    <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                                    <input type="hidden" name="user_id" :value="user.id" />
                                    <input type="hidden" name="option" value="admin" />
                                    <input type="hidden" name="date" :value="dateNow" />
                                    <input type="hidden" name="expiry_date" :value="expiry_date" />
                                    <input type="hidden" name="visit_date" :value="visit_date" />
                                    <input type="hidden" name="ref_no" :value="ref_no" />
                                    <input type="hidden" name="certificate" value="yes" />

                                    <base-button
                                        type="primary"
                                        class="btn btn-lg"
                                        nativeType="submit"
                                    >
                                        <i class="fa fa-print"></i>
                                        Print Letter (Preview)
                                    </base-button>
                                </form>
                            </div>                        

                            <div class="col-sm-3">
                                <form
                                    :action="getRootUrl+'/print-cpd-certificate'"
                                    method="POST"
                                    target="_blank"
                                >
                                    <input type="hidden" name="id" :value="application.id" />
                                    <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                                    <input type="hidden" name="user_id" :value="user.id" />
                                    <input type="hidden" name="option" value="admin" />
                                    <input type="hidden" name="date" :value="dateNow" />
                                    <input type="hidden" name="expiry_date" :value="expiry_date" />
                                    <input type="hidden" name="certificate" value="no" />

                                    <base-button
                                        type="info"
                                        class="btn btn-lg"
                                        nativeType="submit"
                                    >
                                        <i class="fa fa-print"></i>
                                        Print License (Preview)
                                    </base-button>
                                </form>
                            </div> 
                        </div>                       
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="success"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges || ! isCompleted"
                            @click="approveApplication(application.id)"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-check"></i>
                            {{ approveText }}
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Approval
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import moment from 'moment';

  export default {
    name: 'ApproveApplication',
    props: ['application', 'privileges', 'loadApplication'],
    components: {
        flatPicker
    },
    data() {
      return {
        id: this.$route.params.id,
        username: this.application.cpd.username,
        showBackButton: false,
        ref_no: null,
        visit_date: null,
        expiry_date: null,
        license_year: moment().format("YYYY"),
        certificate: 'no'
      }
    },
    computed: {
        ...mapGetters(['user', 'getRootUrl', 'getAuthorizationToken']),
        isVisitation() {// is a visitation application
            return this.application && (this.application.type == 'visitation');
        },
        isReaccreditation() {// is a visitation application
            return this.application && (this.application.type == 're-accreditation');
        },
        approveText() {
            return (this.isVisitation || this.isReaccreditation) ? 
               (this.isReaccreditation ? 'Re-' : '') + 'Accredit Provider' : 'Approve Application';
        },
        isCompleted() {// check if has completed values
            // this.ref_no && this.visit_date && 
            let license = (this.expiry_date && (! this.isReaccreditation || this.license_year)) || (! this.isVisitation && ! this.isReaccreditation);
            let certificate = ((this.ref_no && this.visit_date) || ! this.showLetterDetails);
            return license && certificate;
        },
        dateNow() {
            return moment().format("YYYY-MM-DD");
        },
        years() {
            let startYear = 2022;// start from 2022
            let currentYear = parseInt(moment().format('YYYY')) + 2;
            let years = [];
            for (let i = startYear; i <= currentYear; i++ ) {
                years.push(i);
            }
            return years;
        },
        showLetterDetails() {// show letter details
            return this.certificate == 'yes';
        }
    },
    methods: {
        ...mapActions(['approveCpdApplication']),
        updateExpiryDate() {// add four years automatically
            let date = moment().add(1, 'year');
            return this.expiry_date = date.format('YYYY-MM-DD');
        },
        approveApplication(id) {// reject application
            if(this.username) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeApproveApiCall(id);// make api call
                    }
                });
            } else {
                swal.fire('Invalid Number!', 'Kindly enter CPD Provider\'s number', 'warning');
            }            
        },
        makeApproveApiCall(id) {// reject application api
            let data = { id : id, reject_reason : null, status : 'approved', admin_id : this.user.id, ref_no : this.ref_no, 
                        visit_date : this.visit_date, username: this.username, expiry_date: this.expiry_date, license_year: this.license_year, 
                        date: this.dateNow, certificate : this.certificate };
            this.preloader();// show loading
            this.approveCpdApplication(data).then((res) => {// reject application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
