import { render, staticRenderFns } from "./SchoolReporting.vue?vue&type=template&id=738ba9b0&"
import script from "./SchoolReporting.vue?vue&type=script&lang=js&"
export * from "./SchoolReporting.vue?vue&type=script&lang=js&"
import style0 from "./SchoolReporting.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports