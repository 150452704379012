<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Hospital's Name"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="record.name"
                        />
                    </div>
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Email Address"
                            input-classes="form-control-alternative"
                            v-model="record.email"
                        />
                    </div>
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Hospital's Phone Number"
                            placeholder="Phone Number"
                            input-classes="form-control-alternative"
                            required
                        >
                        <!-- v-model="profile.phone"  -->
                            <VuePhoneNumberInput
                                v-model="tel.phone"
                                @update="updatePhoneNumber"
                                :default-country-code="tel.code"
                                :no-example="true"
                                :clearable="true"
                            />
                        </base-input>
                    </div>
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Hospital Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.type"
                            >
                                <option :value="null" disabled selected>--- Select One ---</option>
                                <option value="medical">Medical Training Hospital</option>
                                <option value="dental">Dental Training Hospital</option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Registration Status"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.status"
                            >
                                <option :value="null" disabled selected>--- Select One ---</option>
                                <option value="new">New Account</option>
                                <option value="enable">Old Account</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Establishment Year"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.establishment"
                            >
                                <option :value="null" disabled selected>--- Select One ---</option>
                                <option
                                    v-show="years && years.length > 0"
                                    v-for="year in years"
                                    :key="year"
                                    :value="year"
                                >
                                    {{ year }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <base-input alternative=""
                label="Hospital's Location">
                <textarea
                    rows="2"
                    class="form-control form-control-alternative"
                    v-model="record.location"></textarea>
            </base-input>
        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <hr class="my-4" />

        <div class="col-lg-12">
            <base-button
                type="success" size="lg"
                @click="updateProfile()"
                :disabled="privileges && privileges.view == 'no'"
            >
                <i class="fa fa-save"></i>
                Save Changes
            </base-button>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment'

    export default {
        name: 'BasicProfile',
        props: ['record','privileges'],
        components: {
            VuePhoneNumberInput
        },
        data() {
            return {
                tel: {
                    phone: null,
                    code: 'NG',
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showProfile: false
            }
        },
        computed: {
            years() {
                let startYear = moment().format('YYYY') - 119;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
        },
        methods: {
            ...mapActions(['updateHospitalProfile']),
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            updateProfile() {// update profile
                this.preloader(); // show loading
                let data = this.record;
                this.updateHospitalProfile({ profile_data : data }).then((res) => {// get records
                    if(res.status) {
                        swal.fire('Profile updated!', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadPhoneNumber();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
    .doctor-image {
        height: 200px !important;
        width: 250px !important;
    }
</style>