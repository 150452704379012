
<template>
    <div>
        <div class="row">
            <div class="col-xl-12 order-xl-1">
                <template>
                    <tabs fill class="flex-column flex-md-row">
                        <card shadow>
                            <tab-pane title="Medical Hospitals">
                                <span slot="title">
                                    <i class="ni ni-books"></i>
                                    Medical Hospitals
                                </span>
                                <!-- import Medical Hospitals template here -->
                                <MedicalHospitals
                                    :privileges="privileges"
                                />
                            </tab-pane>

                            <tab-pane title="Dental Hospitals">
                                <span slot="title">
                                    <i class="ni ni-badge"></i>
                                    Dental Hospitals
                                </span>
                                <!-- import Dental Hospitals template here -->
                                <DentalHospitals
                                    :privileges="privileges"
                                />
                            </tab-pane>
                        </card>
                    </tabs>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
    import MedicalHospitals from './MedicalHospitals';
    import DentalHospitals from './DentalHospitals';

    export default {
        name: 'Applications',
        props: ['changeTitle', 'privileges'],
        components: {
            MedicalHospitals,
            DentalHospitals
        }
    };
</script>
<style></style>
