
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-if="applicant">
                                        <em class="text-danger">
                                            {{ this.applicant.personal_data.fullname }}
                                        </em>
                                        's Attachment Application Details
                                    </h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/attachment/attachment-applications"
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Go Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if='! showErrorStatus && ! isLoadingBlade'>

                            <div v-if="! approvalPage">
                                <!-- This is personal Details template here -->
                                <PersonalDetails
                                    :applicant="applicant"
                                    :rrr="applicant.rrr"
                                    :photo="applicant.photo"
                                    :breakWord="breakWord"
                                    v-if="applicant && applicant.personal_data"
                                />

                                <!-- This is educational Details template here -->
                                <EduDetails
                                    :applicant="applicant"
                                    :records="applicant.edu_data"
                                    :breakWord="breakWord"
                                    v-if="applicant && applicant.edu_data"
                                />

                                <!-- This is Prof Qualification Details template here -->
                                <ProfQualDetails
                                    :applicant="applicant"
                                    :records="applicant.qualification_data"
                                    :privileges="privileges"
                                    :breakWord="breakWord"
                                    v-if="applicant && applicant.qualification_data"
                                />

                                <!-- This is Employment Details template here -->
                                <EmploymentDetails
                                    :applicant="applicant"
                                    :records="applicant.employment_data"
                                    :privileges="privileges"
                                    :breakWord="breakWord"
                                    v-if="applicant && applicant.personal_data && applicant.personal_data.employ_status == 'yes'
                                            && applicant.employment_data"
                                />

                                <!-- This is Referee details template here -->
                                <RefereeDetails
                                    :applicant="applicant"
                                    :records="applicant.referee_data"
                                    :privileges="privileges"
                                    :breakWord="breakWord"
                                    v-if="applicant && applicant.referee_data"
                                />

                                <!-- This is documents template here -->
                                <Documents
                                    :applicant="applicant"
                                    :records="applicant.files"
                                    :privileges="privileges"
                                    v-if="applicant && applicant.files"
                                />

                                <!-- This is Centre details template here -->
                                <CentreDetails
                                    :applicant="applicant"
                                    :records="applicant.centres"
                                    :privileges="privileges"
                                    v-if="applicant && applicant.centres"
                                />

                                <!-- This is payment Details template here -->
                                <PaymentDetails
                                    :transaction="applicant.transaction"
                                    v-if="applicant && applicant.transaction"
                                />


                                <div v-if="(applicant.status == 'submitted')">
                                    <base-button
                                        type="danger" size="lg"
                                        @click="showApprovalPage('reject', true)"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-ban"></i> Reject Application
                                    </base-button>

                                    <base-button
                                        type="success" size="lg"
                                        class="right"
                                        @click="addToPosting()"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-check"></i> Add to Posting List
                                    </base-button>
                                </div>
                            </div>

                            <div v-if="approvalPage">
                                <!-- Import Reject Application template here -->
                                <RejectApplication
                                    :applicant="applicant"
                                    :privileges="privileges"
                                    :loadAttachmentApplicant="loadAttachmentApplicant"
                                    v-on:showApprovalPage="showApprovalPage"
                                    v-if="pageType == 'reject'"
                                />
                            </div>

                        </div>


                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-sm-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import PersonalDetails from './AttachmentPreview/PersonalDetails.vue';
  import EduDetails from './AttachmentPreview/EduDetails.vue';
  import ProfQualDetails from './AttachmentPreview/ProfQualDetails';
  import EmploymentDetails from './AttachmentPreview/EmploymentDetails';
  import RefereeDetails from './AttachmentPreview/RefereeDetails';
  import RejectApplication from './AttachmentPreview/RejectApplication';
  import CentreDetails from './AttachmentPreview/CentreDetails';
  import Documents from './AttachmentPreview/Documents';
  import PaymentDetails from './AttachmentPreview/PaymentDetails.vue';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'AttachmentApplicationDetails',
    components: {
        PersonalDetails,
        EduDetails,
        ProfQualDetails,
        EmploymentDetails,
        RefereeDetails,
        Documents,
        PaymentDetails,
        RejectApplication,
        CentreDetails
    },
    data() {
      return {
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        applicant: null,
        approvalPage: false,
        pageType: null,
        hospitals: []
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == '/attachment/attachment-applications') {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        }
    },
    methods: {
        ...mapActions(['getAttachmentApplicant', 'addToPostingList']),
        loadAttachmentApplicant() {// load payments
            if(this.id) {
                this.approvalPage = false;
                this.isLoadingBlade = true;
                this.showErrorStatus = false;
                this.getAttachmentApplicant(this.id).then((res) => {
                    this.isLoadingBlade = false;
                    this.applicant = res.applicant;
                    this.hospitals = res.hospitals;
                    this.loadTitle(); // load title
                }).
                catch(() => {
                    this.applicant = null;
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            }
        },
        addToPosting() {// add applicant to posting list
            if(this.id) {
                this.showErrorStatus = false;
                this.preloader();// show loading
                let data = { id : this.id, admin_id : this.user.id };
                this.addToPostingList(data).then((res) => {
                    if(res.status) {
                        swal.fire('Added To Posting List!', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                    this.loadAttachmentApplicant(); // reload data
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "An error has occurred while processing, kindly reload page";
                    this.errorType = 'danger';
                });
            } else {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "An error has occurred while processing, kindly reload page";
                this.errorType = 'danger';
            }
        },
        showApprovalPage(type = null, status = false) {// show approval/reject page
            this.approvalPage = status;
            this.pageType = status ? type : null;
        },
        loadTitle() {// load title
            return this.title = '';
        },
        replaceString(value) {
            return value ? value.replace('-', ' ') : 'Attachment Program';
        },
        changeTitle(value, status) {// change title
            return status ? this.title = value : this.loadTitle();
        },
        breakWord(word, length = 40) {// break word into two parts
            if(word) {
                let textLength = word.length;
                let exploded = word.split(' ');
                if(word && textLength < length) {
                    return word;
                } else if(word) {
                    let formedWord = '';
                    let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                    for(let phrase of exploded) {
                        formedWord += phrase+' ';
                        if(exploded[half] == phrase) {
                            formedWord += ' <br> ';
                        }
                    }
                    return formedWord;
                } else {
                    return 'N/A';
                }
            } else {
                return 'N/A';
            }
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    created() {
        this.loadAttachmentApplicant(); // load applicant
    }
  };
</script>
<style scoped>
    .right {
        float: right;
    }
</style>
