
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-10 text-right">
                        <div v-if="! hasExportPages">
                            <form :action="getRootUrl+'/export-quack-reports'" method="POST">
                                <input
                                    v-for="(value, item) in filters"
                                    v-if="(item != 'limit')"
                                    :key="item"
                                    type="hidden"
                                    :name="item"
                                    :value="value"
                                />

                                <input
                                    type="hidden"
                                    name="admin_id"
                                    :value="user.id"
                                />

                                <button
                                    :class="'btn btn-lg btn-'+ (showFilters ? 'danger' : 'primary')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    @click="displayFilters()"
                                >
                                    <i :class="'fa fa-'+(showFilters ? 'times' : 'sort')"></i>
                                    {{ showFilters ? 'Remove Filters' : 'Filters Report'}}
                                </button>

                                <button
                                    class="btn btn-lg btn-success"
                                    nativeType="submit"
                                    v-if="records && records.length > 0"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-file-export"></i>
                                    Export Report
                                </button>
                            </form>
                        </div>

                        <div v-if="hasExportPages">
                            <button
                                :class="'btn btn-lg btn-'+ (showFilters ? 'danger' : 'primary')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @click="displayFilters()"
                            >
                                <i :class="'fa fa-'+(showFilters ? 'times' : 'sort')"></i>
                                {{ showFilters ? 'Remove Filters' : 'Filters Report'}}
                            </button>
                            <button
                                :class="'btn btn-lg btn-'+ (showExportPages ? 'danger' : 'success')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @click="displayExportPages()"
                            >
                                <i :class="'fa fa-'+(showExportPages ? 'times' : 'sort')"></i>
                                {{ showExportPages ? 'Remove Export Pages' : 'Show Export Pages'}}
                            </button>
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="hasExportPages && showExportPages">
                        <hr>
                        <form :action="getRootUrl+'/export-quack-reports'" method="POST">
                            <input
                                v-if="(item != 'limit')"
                                v-for="(value, item) in filters"
                                :key="item"
                                type="hidden"
                                :name="item"
                                :value="value"
                            />

                            <input
                                type="hidden"
                                name="admin_id"
                                :value="user.id"
                            />

                            <input
                                type="hidden"
                                name="limit"
                                :value="exportPerSheet"
                            />

                            <div class="col-lg-12 text-center">
                                <h3 class="text-danger">
                                    Note: Maximum of {{ exportPerSheet }} record(s) per export.
                                </h3>
                                <base-input label="Select Range of Records" required>
                                    <select
                                        class="form-control form-control-alternative"
                                        name="export_page"
                                        @change="enableExportPageButton()"
                                        required
                                    >
                                        <option  value="null" disabled selected>
                                            ---Select One---
                                        </option>
                                        <option
                                            v-for="page in exportPages"
                                            :key="page"
                                            :value="page"
                                        >
                                            Page {{ page }}
                                        </option>
                                    </select>
                                </base-input>
                            </div>

                            <button
                                class="btn btn-lg btn-success btn-block"
                                style="margin-top: 10px;"
                                nativeType="submit"
                                v-if="records && records.length > 0"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges || disableExportPageButton"
                            >
                                <i class="fa fa-file-export"></i>
                                Export Page
                            </button>
                        </form>
                        <hr>
                    </div>
                </div>
            </div>

            <div class="container" v-if="showFilters && ! isLoadingBlade">
                <hr>
                <h3 class="mb-0 text-uppercase text-center" v-html="filteredTitle"></h3>
                <hr v-if="! isFiltered">
                <div class="row" v-if="! isFiltered">

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="State of Practice"
                        >
                            <autocomplete
                                input-class="form-control form-control-alternative"
                                placeholder="Type and Search for State of Practice"
                                showNoResults
                                :source="locations.states"
                                v-model="filters.suspect_state"
                                @selected="getOriginLgas"
                                @clear="clearData"
                            >
                            </autocomplete>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="lgas && lgas.length > 0">
                        <base-input alternative=""
                            label="Lga of Practice"
                        >
                            <autocomplete
                                input-class="form-control form-control-alternative"
                                placeholder="Type and Search for Lga of Practice"
                                showNoResults
                                :source="lgas"
                                v-model="filters.suspect_lga"
                            >
                            </autocomplete>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Quack Category"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.suspect_category"
                            >
                                <option :value="null" disabled>--- Select One ---</option>
                                <option value="personnel">Quack Medical Practitioner</option>
                                <option value="school">Quack School</option>
                                <option value="hospital">Quack Hospital</option>
                                <option value="cpd-provider">Quack CPD Provider</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Gender"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.gender"
                            >
                                <option :value="null" disabled>--- Select One ---</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Status"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.status"
                            >
                                <option :value="null" disabled>--- Select One ---</option>
                                <option value="pending">Pending</option>
                                <option value="resolved">Resolved</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            label="Reported Month"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                v-model="filters.month"
                                :config="config"
                                class="form-control datepicker"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Reported Year"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.year"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="year in years"
                                    :value="year"
                                    :key="year"
                                >
                                    {{year}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-12">
                        <button
                            class="btn btn-lg btn-info btn-block"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            @click="applyFilters()"
                        >
                            <i class="fa fa-check"></i>
                            Apply Filters
                        </button>
                        <button
                            class="btn btn-lg btn-danger btn-block"
                            @click="displayFilters()"
                        >
                            <i class="fa fa-times"></i>
                            Remove Filters
                        </button>
                    </div>
                </div>
                <hr>
            </div>

            <!-- import Quack Reports Data template here -->
            <QuackReportsData
                :records="records"
                :hasPagination="hasPagination"
                :pagination="pagination"
                :paginate="paginate"
                :privileges="privileges"
                v-if="! isLoadingBlade"
            />

        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import QuackReportsData from './QuackReportsData';
  import swal from 'sweetalert2';// import sweet alert
  import Autocomplete from 'vuejs-auto-complete';

  export default {
    name: 'QuackReports',
    props: ['privileges'],
    components: {
        QuackReportsData,
        flatPicker,
        Autocomplete
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        filters: {
            suspect_state: null,
            suspect_lga: null,
            suspect_category: null,
            gender: null,
            status: null,
            month: null,
            year: null,
            limit: this.$store.state.url.perPage // get per page count
        },
        page: 1,
        showFilters: false,
        isFiltered: false,
        showExportPages: false,
        disableExportPageButton: true,
        filteredTitle: 'Filtered Quack Reports Report',
        lgas: [],
        config: {
            allowInput: true,
            altInput: true,// eslint-disable-next-line
            maxDate: new Date().fp_incr(1),
            plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
        }
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        ...mapGetters(['loadingBladeUrl', 'getRootUrl', 'user', 'exportPerSheet', 'locations']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        },
        hasExportPages() {
            let divider = this.$store.state.url.exportPerSheet;
            return this.pagination.total > divider;
        },
        exportPages() {
            let divider = this.$store.state.url.exportPerSheet;
            let pages = Math.ceil(this.pagination.total / divider);
            return pages;
        },
        years() {
            let startYear = moment().format('YYYY') - 119;// start from 1900
            let currentYear = parseInt(moment().format('YYYY'));
            let years = [];
            for (let i = currentYear; i > startYear; i-- ) {
                years.push(i);
            }
            return years;
        },
    },
    methods: {
        ...mapActions(['quackReportsReporting', 'getPaginatedPaymentItems', 'getLocations']),
        applyFilters() {// apply filters function
            let status = this.validFilterInputs();
            this.showErrorStatus = false;
            if(status) {
                this.preloader();// show loading
                let data = this.filters;
                data.filter = true;
                this.quackReportsReporting(data).then((res) => {// get users
                    this.isLoadingBlade = false;
                    this.setPages(res);//set data
                    this.isFiltered = true;
                    this.getFilteredTitle();// set filtered title
                    swal.close();
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                let msg = 'Kindly select one of the filter actions, and try again';
                swal.fire('Invalid Selection', msg, 'warning');
            }
        },
        getFilteredTitle() {// set filtered payment title
            let data = this.filters;
            if(data.suspect_state) {// add state of practice
                let state = this.locations.states.filter(item => item.id == data.suspect_state);
                this.filteredTitle += `, State of Practice -> <em class="text-danger">${state[0].name}</em>`;
            }
            if(data.suspect_lga) {// add lga of practice
                let lga = this.locations.lgas.filter(item => item.id == data.suspect_lga);
                this.filteredTitle += `, Lga of Practice -> <em class="text-danger">${lga[0].name}</em>`;
            }
            this.filteredTitle += data.suspect_category ?  `, Quack Category -> <em class="text-danger">${data.suspect_category}</em>` : '';
            this.filteredTitle += data.gender ?  `, Gender -> <em class="text-danger">${data.gender}</em>` : '';
            this.filteredTitle += data.status ?  `, Status -> <em class="text-danger">${data.status}</em>` : '';
            this.filteredTitle += data.month ?  `, Reported Month -> <em class="text-danger">${
                                    this.formatRangeDate(data.month)}</em>` : '';
            this.filteredTitle += data.year ?  `, Reported Year -> <em class="text-danger">${data.year}</em>` : '';
            this.filteredTitle += ' Only';
        },
        formatRangeDate(value) {// format range date
            return value ? moment(value).format('MMM, YYYY') : 'N/A';
        },
        validFilterInputs() {
            let data = this.filters;
            return data.suspect_state || data.suspect_lga || data.suspect_category || data.gender || data.status 
                    || data.month || data.year;
        },
        displayFilters() {// diplay filters template
            this.emptyFilters();// empty filters
            this.loadComplaints();// reload payments
            this.showExportPages = false;
            return this.showFilters = ! this.showFilters;
        },
        emptyFilters() {// empty filters
            this.filters = {
                suspect_state: null,
                suspect_lga: null,
                suspect_category: null,
                gender: null,
                status: null,
                month: null,
                year: null,
                limit: this.$store.state.url.perPage // get per page count
            };
            this.isFiltered = false;
            this.filteredTitle = 'Filtered Quack Reports Report';
        },
        loadComplaints() {// load details
            let data = { 'limit' : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.quackReportsReporting(data).then((res) => {// get users
                this.isLoadingBlade = false;
                this.setPages(res);//set data
                this.getLocations();
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(data) {// set paginated function
            let res = data.quacks;
            this.lgas = [];
            // this.doctorTypes = data.doctor_types;
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.quacks.current_page;
                    this.setPages(res);
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Quack Reports recorded yet!!!';
        },
        displayExportPages() {
            this.showFilters = false;
            return this.showExportPages = ! this.showExportPages;
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&'+exploded[3]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                this.pagination.number = res.quacks.current_page;
                this.setPages(res);
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        enableExportPageButton() {
            return this.disableExportPageButton = false;
        },
        getOriginLgas(e) {// get exact lgas
            let id = e.value;
            this.filters.lga_id = null;
            this.lgas = [];
            if(this.locations && this.locations.lgas && this.locations.lgas.length > 0) {
                for(let lga of this.locations.lgas) {
                    if(lga.state_id == id) {
                        this.lgas.push(lga);
                    }
                }
            }
        },
        clearData() {
            this.filters.lga_id = null;
            this.lgas = [];
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadComplaints();
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
