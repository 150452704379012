<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-12">
                <small class="font-weight-bold right">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>

                <base-button
                    v-if="showChangeButton"
                    type="success" size="sm"
                    @click="changeStatus()"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i> Apply Changes
                </base-button><br>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">
                                <input
                                    class="mb-3"
                                    id="all-checked"
                                    type="checkbox"
                                    :checked="checked"
                                    @change="updateRecords(null, true)"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                />
                                <!-- @change="changeStatus(null, true)" -->
                            </th>
                            <th width="2%">S/N</th>
                            <th width="10%">Applicant's Name</th>
                            <th width="10%">Exam Number</th>
                            <th width="10%">Application Status</th>
                            <th width="10%">Application Date</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td>
                                <input
                                    class="mb-3"
                                    type="checkbox"
                                    :id="'other-check'+row.id"
                                    :checked="(row.seat_status == 'yes')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    @change="updateRecords(row)"
                                />
                            </td>
                            <td>
                                {{ pagination.from+index}}
                            </td>
                            <td class="text-capitalize">
                                {{ row.fullname }}
                            </td>
                            <td>{{ row.exam_number ? 'MDCN/' + row.exam_number : 'N/A' }}</td>
                            <td>
                                <badge
                                    :type="((row.status == 'approved') ? 'success' : 'danger')"
                                >
                                    {{ row.status }}
                                </badge>
                            </td>
                            <td>
                                {{ row.reg_date | getDateFormat }}
                            </td>
                        </template>

                    </base-table>
                </div>

                <div class="col-lg-4" v-if="showChangeButton">
                    <base-button
                        type="success" size="sm"
                        @click="changeStatus()"
                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                    >
                        <i class="fa fa-save"></i> Apply Changes
                    </base-button><br>
                </div>


                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Badge from '../../../../../components/Badge.vue';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: { Badge },
        name: 'ApplicantsData',
        props: ["records", "pagination", "batch", "loadAplications", "privileges", "checked", "hasPagination", "paginate"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        data() {
            return {
                applicants: [],
                showChangeButton: false
            }
        },
        computed: {
            ...mapGetters(['user'])
        },
        methods: {
            ...mapActions(['changeSeatStatus']),
            updateRecords(applicant, status = false) {
                this.showChangeButton = false;
                if(status) {
                    this.applicants = [];// eslint-disable-next-line
                    if($('#all-checked').is(":checked")) {
                        for(let record of this.records) { record.seat_status = 'yes'; }
                        this.applicants = this.records;
                    } else {
                        for(let record of this.records) { record.seat_status = 'no'; }
                        this.applicants = this.records;
                    }
                    this.showChangeButton = true;
                } else {// eslint-disable-next-line
                    let checkStatus = $('#other-check'+applicant.id).is(":checked")
                    if(checkStatus) {
                        applicant.seat_status = 'yes';
                        this.applicants.push(applicant);
                    } else {
                        applicant.seat_status = 'no';
                        if(this.applicants.length > 0) {
                            this.applicants = this.applicants.filter(item => item.id !== applicant.id);
                        } else {
                            this.applicants.push(applicant);
                        }
                    }
                    this.showChangeButton = true;
                }
            },
            changeStatus() {// approve application
                swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to proceed!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeChangeStatusApi();// make api call
                    }
                });
            },
            makeChangeStatusApi() {
                let data = { records: this.applicants, id: this.batch.id, admin_id: this.user.id, type: 'seat' };
                this.preloader(); // show loading
                this.changeSeatStatus(data).then((res) => {// delete country
                    if(res.status) {
                        this.loadAplications();// refresh page
                        swal.fire("Status Changed", res.msg, "success");
                        setTimeout(function() { window.location.reload(); }, 2000);
                    } else {
                        this.loadAplications();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadAplications();// refresh page
                    swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>
<style scoped>
    .right {
        float: right;
    }
</style>