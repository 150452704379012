<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <!-- <div class="col-lg-12 text-center font-weight-800" v-if="record.is_sanctioned">
                        <base-alert type="danger">
                            <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                            <span class="alert-inner--text">
                            Doctor has been sanctioned
                            </span>
                        </base-alert>
                        <br>
                    </div> -->
                    <div class="col-lg-12 text-center font-weight-800" v-if="record.is_deceased">
                        <base-alert type="danger">
                            <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                            <span class="alert-inner--text">
                                Doctor is Deceased
                            </span>
                        </base-alert>
                        <br>
                    </div>
                    
                    <div class="col-lg-4">
                        <div class="card-profile-image marginTop40">
                            <img
                                :src="getUserPix(record)"
                                class="rounded-circle doctor-image"
                                @error="replaceByDefault"
                            >
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="40%">
                                        Surname (Last Name)
                                    </td>
                                    <td class="text-uppercase">
                                        {{ record.last_name }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="40%">
                                        First Name
                                    </td>
                                    <td class="text-uppercase">
                                        {{ record.first_name }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="40%">
                                        Other Names
                                    </td>
                                    <td class="text-uppercase">
                                        {{ record.other_name ? record.other_name : 'N/A' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="40%">
                                        Folio Number 
                                    </td>
                                    <td class="text-uppercase">
                                        {{ record.folio_number ? record.folio_number : 'N/A' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="40%">
                                        Full Registration Number
                                    </td>
                                    <td>
                                        {{ record.registration_no ? record.registration_no : 'N/A' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="40%">
                                        Practice Type
                                    </td>
                                    <td class="text-uppercase" colspan="2">
                                        {{ practiceType ? practiceType : 'N/A' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="40%">
                                        Registration Type
                                    </td>
                                    <td class="text-uppercase" v-if="record.registration_no">
                                        {{ registrationType ? registrationType : 'N/A' }}
                                    </td>
                                    <td class="text-uppercase" v-if="! record.registration_no">
                                        PROVISIONAL REGISTRATION
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="40%">
                                        Registration Status
                                    </td>
                                    <td class="text-uppercase">
                                        {{ record.is_sanctioned ? 'Sanctioned' : 'Not Sanctioned' }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-lg-12">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td class="text-uppercase text-center" colspan="5">
                                        <h2>schools attended</h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-uppercase font-weight-bolder" width="10%">S/N</td>
                                    <td class="text-uppercase font-weight-bolder" width="30%">SCHOOL NAME</td>
                                    <td class="text-uppercase font-weight-bolder" width="20%">
                                        SCHOOL type
                                    </td>
                                    <td class="text-uppercase font-weight-bolder" width="20%">
                                        QUALIFICATION
                                    </td>
                                    <td class="text-uppercase font-weight-bolder" width="2  0%">
                                        START &amp; END DATE
                                    </td>
                                </tr>
                                <tr 
                                    v-show="schools && schools.length > 0"
                                    v-for="(school, index) in schools"
                                    :key="school.id"
                                >
                                    <td class="text-uppercase font-weight-bolder">
                                        {{ index+1 }}
                                    </td>
                                    <td class="text-capitalize">
                                        {{ getSchoolName(school) }}
                                    </td>
                                    <td class="text-capitalize">
                                        {{ school.school_type ? replaceAll(school.school_type, '-', ' ') : 'N/A' }}
                                    </td>
                                    <td class="text-uppercase">
                                        {{ school.qualifications_obtained ? school.qualifications_obtained : 'N/A' }}
                                    </td>
                                    <td class="text-capitalize">
                                        {{ school.start_date | formatDate }} - 
                                        {{ school.end_date | formatDate }}
                                    </td>
                                </tr>
                                <tr v-if="schools && schools.length == 0">
                                    <td colspan="5" class="text-center">
                                        <base-alert type="danger">
                                            <span class="alert-inner--icon"><i class="ni ni-fat-remove"></i></span>
                                            <span class="alert-inner--text">
                                                No School(s) record uploaded yet!!!
                                            </span>
                                        </base-alert>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-4" />
        <div class="col-lg-12">
            <base-button
                type="danger" size="lg"
                @click="$emit('hideProfile')"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </base-button>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'Profile',
        props: ['record','privileges'],
        filters: {
            formatDate(date) {
                return date ? moment(date).format('MMM, Y') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['userPhotoUrl', 'blankImageUrl']),
            practiceType() {// get pratice type
                if(this.record) {
                    let types = this.record.doctor_types ? this.record.doctor_types : false;
                    let filtered = types ? types.filter(e => e.id == this.record.doctor_type_id) : false;
                    let record = filtered.length > 0 ? filtered[0] : false;
                    return record ? record.name : false;
                } else {
                    return false;
                }
            },
            registrationType() {// get registration type
                let type = this.record && this.record.reg_type ? this.record.reg_type : false;
                return type && type.name ? type.name : false;
            },
            schools() {// get user educational records
                return this.record && this.record.has_educational_record ? this.record.has_educational_record : [];
            }
        },
        methods: {
            getUserPix(data) {// get user photo url
                return data ? this.userPhotoUrl+data.user_id+'/photo/'+data.photo : null;
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            },
            getSchoolName(school) {// get school name
                if(school && school.school_name) {
                    let name = '';
                    name += school.school_name;
                    name += school.has_country && school.has_country.name ? ', ' + school.has_country.name : '';
                    return name;
                } else {
                    return 'N/A';
                }                
            }
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
    .doctor-image {
        height: 200px !important;
        width: 250px !important;
    }
</style>