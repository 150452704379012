<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row" v-if="! isLoadingBlade && ! showOtherDashboard">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Doctor Signups"
                                type="gradient-red"
                                :sub-title="dashbardInfo.signups > 0 ? formatValue(dashbardInfo.signups) : 'NILL'"
                                icon="ni ni-active-40"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Approved Signups"
                                type="gradient-orange"
                                :sub-title="dashbardInfo.approved_signups > 0 ? 
                                  formatValue(dashbardInfo.approved_signups) : 'NILL'"
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Approved Licenses"
                                type="gradient-green"
                                :sub-title="dashbardInfo.approved_licenses > 0 ? 
                                  formatValue(dashbardInfo.approved_licenses) : 'NILL'"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Active Agents"
                                type="gradient-info"
                                :sub-title="dashbardInfo.active_agents > 0 ? 
                                  formatValue(dashbardInfo.active_agents) : 'NILL'"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>
                </div>
            </div>

            <div class="row" v-if="! isLoadingBlade && showOtherDashboard">
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Agent Department"
                                type="gradient-red"
                                :sub-title="(user.unit && user.unit.department ? user.unit.department.name : 'N/A')"
                                icon="ni ni-active-40"
                                class="mb-4 mb-xl-0 text-uppercase"
                    >
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Agent Unit"
                                type="gradient-orange"
                                :sub-title="(user.unit ? user.unit.name : 'N/A')"
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0 text-uppercase"
                    >
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Gender"
                                type="gradient-green"
                                :sub-title="(user.gender ? user.gender : 'N/A')"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0 text-uppercase"
                    >
                    </stats-card>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div 
                  class="col-xl-12 order-xl-2 mb-5 mb-xl-0"
                  v-if="! isLoadingBlade"
                >
                    <div class="card card-profile shadow">
                        <div class="card-body pt-0 pt-md-4">
                            <div class="text-center">
                                <h3 class="text-capitalize">
                                    {{ user.name }}
                                </h3>
                                <div class="h5 mt-4">
                                    <h3>
                                        Email Address - 
                                        <badge size="sm" 
                                          type="success"
                                          class="text-lowercase"
                                        >
                                          {{ user.email }}
                                        </badge>
                                    </h3>
                                </div>
                                <div class="h5 mt-4">
                                    <h3>
                                        Gender - 
                                        <badge size="sm" 
                                          type="success"
                                          class="text-capitalize"
                                        >
                                          {{ user.gender }}
                                        </badge>
                                    </h3>
                                </div>
                                <div class="h5 mt-4">
                                    <h3 class="text-capitalize">
                                        Unit -  
                                        <badge size="sm" 
                                          type="success"
                                        >
                                          {{ user.unit ? user.unit.name : 'N/A' }}
                                        </badge>
                                    </h3>
                                </div>
                                <div class="h5 mt-4">
                                    <h3 class="text-capitalize">
                                        Department - 
                                        <badge size="sm" 
                                          type="success"
                                        >
                                          {{ user.unit && user.unit.department ? 
                                            user.unit.department.name : 'N/A' }}
                                        </badge>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" v-if="isLoadingBlade">
                    <div class="d-flex justify-content-center">
                        <img :src="loadingBladeUrl" />
                    </div>
                </div>

                <div class="col-sm-12 text-center">
                  <base-alert :type="errorType" v-if='showErrorStatus'>
                      <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                      <span class="alert-inner--text">
                          {{errorMsg}}
                      </span>
                  </base-alert>
                </div>

            </div>
        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Dashboard',
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true
      }
    },
    computed: {
      ...mapGetters(['user', 'dashbardInfo', 'loadingBladeUrl']),
      role() {
        return this.user ? this.user.role : false;
      },
      showOtherDashboard() {// show dashboard
        return this.role && this.role.role ? (this.role.role == 'other') : false;
      }
    },
    methods: {
      ...mapActions(['fetchUserProfile', 'getAdminDashboardInfo']),
      loadAdminInfo() {
        this.fetchUserProfile();// get user profile
        this.getAdminDashboardInfo().then(() => {// get details
            this.isLoadingBlade = false;
        }).
        catch(() => {
            this.isLoadingBlade = false;
            this.showErrorStatus = true;
            this.errorMsg = "Server Failure!, Kindly reload the page...";
            this.errorType = 'danger';
        });
      },
      formatValue(value) {// format values
        if(value) {
          return String(value).replace(/(.)(?=(\d{3})+$)/g,'$1,');
        } else {
          return 'NILL';
        }
      }
    },
    mounted() {
      this.loadAdminInfo();// get dashboard info
    }
  };
</script>
<style></style>
