<template>
    <div>

        <div class="col-lg-12" v-if="isLoadingBlade && ! showMemberPrivileges">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <UserData 
            v-bind:users="users"
            v-bind:hasPagination="hasPagination"
            v-bind:pagination="pagination"
            v-bind:paginate="paginate"
            v-bind:pageNumbers="pageNumbers"
            v-bind:getPageItems="getPageItems"
            v-bind:searchRecords="searchRecords"
            v-bind:privileges="privileges"
            v-bind:loadUsers="loadUsers"
            v-bind:changeTitle="changeTitle"
            v-on:viewMemberPrivileges="viewMemberPrivileges"
            v-if="! showMemberPrivileges && ! isLoadingBlade"
        />

        <div class="col-sm-12 text-center" v-if="! showMemberPrivileges">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <MemberPrivileges 
            :member="member"
            :privileges="privileges"
            v-on:closeMemberPrivileges="closeMemberPrivileges"
            v-if="showMemberPrivileges"
        />
        
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import MemberPrivileges from '../Unit/MemberPrivileges';
    import UserData from './UserData';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AdminUser',
        props: ['privileges', 'changeTitle'],
        components: {
            MemberPrivileges,
            UserData
        },
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                member: null,
                showMemberPrivileges: false,
                users: [],
                paginatedItems: [],
                id: localStorage.getItem('user_id'),
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.url.perPage // get per page count 
                },
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;           
            }
        },
        methods: {
            ...mapActions(['getAdminUsers', 'getPaginatedAdminUsers', 'enableAdminUser', 'searchAdminUser']),
            loadUsers() {// load details 
                let data = { limit : this.pagination.perPage, id : this.id };
                this.getAdminUsers(data).then((res) => {// get users
                    this.isLoadingBlade = false;
                    this.setPages(res);//set data
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            setPages(res) {// set paginated function
                this.users = [];
                this.paginatedItems = res.data;
                this.users = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ? 
                    this.pagination.next_page_url : this.pagination.prev_page_url;            
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedAdminUsers(url).then((res) => {// get users
                        this.pagination.number = res.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.users = this.paginatedItems;
                }           
            },
            emptyRecordsMsg() {
                return this.users && this.users.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true; 
                this.errorType = 'warning';
                this.errorMsg = 'No Users added yet!!!';
            },
            viewMemberPrivileges(data) {
                this.member = data;
                this.showMemberPrivileges = true;
                let title = data.name+'\'s Privileges';
                this.changeTitle(title);// change title
            },
            closeMemberPrivileges() {
                this.member = null;
                this.showMemberPrivileges = false;
                this.changeTitle('User Accounts');// change title
            },
            searchRecords(key) {
                this.showErrorStatus = false;
                if(key != '' ) {
                    this.isLoadingBlade = true;
                    this.users = [];
                    this.searchAdminUser(key).then((res) => {// get records
                        this.isLoadingBlade = false;
                        this.users = res;
                        if(this.users.length == 0) {
                            this.showErrorStatus = true; 
                            this.errorType = 'warning';
                            this.errorMsg = 'No Matched Record!!!';
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.users = this.paginatedItems;
                    this.emptyRecordsMsg();
                }          
            },
            getPageItems(page) {
                let url = this.pagination.next_page_url ? 
                            this.pagination.next_page_url : this.pagination.prev_page_url;
                let exploded = url.split('&');
                let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+page;
                this.isLoadingBlade = true
                this.getPaginatedAdminUsers(newUrl).then((res) => {// get records
                    this.pagination.number = res.current_page;
                    this.setPages(res);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadUsers();// load users
        },
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>