
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ replaceString(code) }}</h3>
                                </div>
                            </div>
                        </div>

                        <!-- import Unit template here -->
                        <VerifyDoctor
                            :privileges="privileges"
                            v-if="code && code == 'verify-doctor'"
                        />

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import VerifyDoctor from './VerifyDoctor/VerifyDoctor.vue';

    export default {
        name: 'Verification',
        components: {
            VerifyDoctor,
        },
        data() {
        return {
            code: this.$route.params.code,
        }
        },
        computed: {
            ...mapGetters(['menus']),
            privileges() {
            let privileges = null;
            if(this.menus && this.menus.length > 0) {
                for(let menu of this.menus) {
                    if(menu.has_child && menu.has_child.length > 0) {
                        for(let child of menu.has_child) {
                            if('/'+child.link == this.$route.path) {
                                privileges = child.privileges;
                            }
                        }
                    }
                }
            }
            return privileges;
            }
        },
        methods: {
            replaceString(value) {
                let text = value.replace('-', ' ');
                return value ? text.replace('-', ' ') : 'Verification';
            }
        }
    };
</script>
<style scoped>
</style>
