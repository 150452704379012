<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <h3 class="text-uppercase">Edit Personal Data</h3>
            <hr>
            <div class="row">
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="RRR"
                        input-classes="form-control-alternative"
                        v-model="rrr"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Surname"
                        placeholder="Enter Surname"
                        input-classes="form-control-alternative"
                        v-model="record.surname"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="Enter First Name"
                        input-classes="form-control-alternative"
                        v-model="record.first_name"
                        required
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Other Name"
                        placeholder="Enter Other Name"
                        input-classes="form-control-alternative"
                        v-model="record.other_name"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Previous Name"
                        placeholder="Enter Previous Name"
                        input-classes="form-control-alternative"
                        v-model="record.previous_name"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Gender"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.gender"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Email Address"
                        placeholder="Enter Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        type="email"
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Marital Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.marital_status"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow">Widow</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input
                        label="Date of Birth"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true, maxDate: new Date().fp_incr(1)}"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.date_of_birth"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Place of Birth"
                        placeholder="Enter Place of Birth"
                        input-classes="form-control-alternative"
                        v-model="record.place_of_birth"
                    />
                </div>

                <!-- <div class="col-lg-3">
                    <base-input alternative=""
                        label="Nationality"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.nationality"
                        >
                            <option :value="null" disabled>---Select One---</option>
                            <option
                                v-for="country in locations.countries"
                                :key="country.id"
                                :value="country.id"
                            >
                                {{country.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.nationality && showForeignState">
                    <base-input alternative=""
                        label="State/Provice of Origin"
                        placeholder="Enter State/Provice of Origin"
                        input-classes="form-control-alternative"
                        v-model="record.foreign_state"
                        required
                    />
                </div>

                <div class="col-lg-3" v-if="! showForeignState && locations && locations.states">
                    <base-input alternative=""
                        label="State of Origin"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.state_origin"
                            @change="getStateLgas()"
                        >
                            <option :value="null" disabled>---Select One---</option>
                            <option
                                v-for="state in locations.states"
                                :value="state.id"
                                :key="state.id"
                            >
                                {{state.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.state_origin && ! showForeignState">
                    <base-input alternative=""
                        label="LGA of Origin"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.lga_origin"
                            v-if="lgas"
                        >
                            <option :value="null" disabled>---Select One---</option>
                            <option
                                v-for="lga in lgas"
                                :value="lga.id"
                                :key="lga.id"
                            >
                                {{lga.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.nationality">
                    <base-input alternative=""
                        label="Home Town"
                        placeholder="Enter Home Town"
                        input-classes="form-control-alternative"
                        v-model="record.home_town"
                    />
                </div> -->

                <!-- <div class="col-lg-3" v-if="record.nationality && ! showForeignState">
                    <base-input
                        label="Date of Return to Nigeria"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.return_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.nationality && showForeignState">
                    <base-input
                        label="Date of Entry into Nigeria"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            placeholder="Select Date.."
                            class="form-control datepicker"
                            v-model="record.entry_date"
                        >
                        </flat-picker>
                    </base-input>
                </div> -->

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Permanent Home Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Permanent Home Address"
                            v-model="record.permanent_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Residential Address (In Nigeria)"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Residential Address"
                            v-model="record.residential_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Full Business Address"
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Full Business Address"
                            v-model="record.business_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Contact Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Contact Address"
                            v-model="record.contact_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Medical School (With Start and End Dates)"
                        required
                    >
                        <textarea
                            rows="3"
                            class="form-control form-control-alternative"
                            placeholder="Enter Medical School Details"
                            v-model="record.medical_school"
                        ></textarea>
                    </base-input>
                </div>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <base-button
                type="danger"
                @click="editPersonalData()"
            >
                <i class="fa fa-times"></i>
                Cancel Edit
            </base-button>

            <base-button
                type="success"
                class="right"
                @click="savePersonalData()"
            >
                <i class="fa fa-save"></i>
                {{ isloading ? 'Please Wait...' : 'Update Changes'}}
            </base-button>
        </div>

    </div>
</template>

<script>
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'EditPersonalDetails',
        props: ['locations', 'applicant', 'editPage', 'loadAssessmentApplicant', 'editPersonalData'],
        components: {
            flatPicker,
            VuePhoneNumberInput
        },
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                rrr: null,
                record: {
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    gender: null,
                    phone: null,
                    marital_status: null,
                    date_of_birth: null,
                    place_of_birth: null,
                    nationality: null,
                    state_origin: null,
                    lga_origin: null,
                    foreign_state: null,
                    home_town: null,
                    permanent_address: null,
                    residential_address: null,
                    business_address: null,
                    contact_address: null,
                    return_date: null,
                    entry_date: null,
                    email: null,
                    medical_school: null,
                    phone_code: null
                },
                lgas: [],
                tel: {
                    phone: null,
                    code: 'NG',
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            showForeignState() {
                return this.record.nationality == 164 ? false : true;
            },
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData']),
            setPersonalData() {// set applicant data
                this.showInputErrorStatus = false;
                this.emptyFields();
                this.isLoadingBlade = false;
                if(this.applicant) {
                    this.rrr = this.applicant.rrr;
                    if(this.applicant.personal_data) {
                        this.record = this.applicant.personal_data
                        this.loadPhoneNumber();// update phone no
                    } else {
                        this.emptyFields();
                    }
                    this.record = this.applicant.personal_data ? this.applicant.personal_data : this.emptyFields();

                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            savePersonalData() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let data = { 'personal_data' : this.record , 'id' : this.applicant.id };
                this.saveAssessmentApplicantData(data).then((res) => {
                    this.loadAssessmentApplicant();
                    this.isloading = false;
                    if(res.status) {
                        this.editPersonalData();
                        swal.fire("Profile Updated!", res.msg, "success");
                    } else {
                        let msg = "Payment details could not be found, reload page";
                        swal.fire("Error Ocurred!", msg, "warning");
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let msg = "Server Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                });
            },
            emptyFields() {
                return this.record = {
                    surname: null,
                    first_name: null,
                    other_name: null,
                    previous_name: null,
                    gender: null,
                    phone: null,
                    marital_status: null,
                    date_of_birth: null,
                    place_of_birth: null,
                    nationality: null,
                    state_origin: null,
                    lga_origin: null,
                    foreign_state: null,
                    home_town: null,
                    permanent_address: null,
                    residential_address: null,
                    business_address: null,
                    contact_address: null,
                    return_date: null,
                    entry_date: null,
                    email: null,
                    medical_school: null,
                    phone_code: null
                };
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            getStateLgas(id) {// get state's lgas
                let lgas = [];
                let stateId = id ? id : this.record.state_origin;
                for(let lga of this.locations.lgas) {
                    if (lga.state_id == stateId) {
                        lgas.push(lga);
                    }
                }
                return this.lgas = lgas;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setPersonalData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>