
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <hr>
                <h3 class="text-center text-uppercase" v-if="! breakdown">
                    Add Breakdown To Payment
                </h3>
                <h3 class="text-center text-uppercase" v-if="breakdown">
                    Edit {{ breakdown.title }} Payment Breakdown Details
                </h3>
                <hr>
                <div class="row">
                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Title"
                            input-classes="form-control-alternative"
                            placeholder="Enter Title"
                            v-model="record.title"
                            required
                        />
                    </div>

                    <div class="col-lg-6">
                        <base-input alternative=""
                            label="Amount"
                            input-classes="form-control-alternative"
                            placeholder="Enter Amount"
                            v-model="record.amount"
                            type="number"
                            required
                        />
                    </div>
                </div>
            </div>

            <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>

            <hr>
            <div class="pl-lg-4">
                <base-button
                    type="success"
                    @click="savePaymentBreakdown()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                >
                    <i class="fa fa-save" @if='! isloading'></i>
                    {{isloading ? 'Please Wait' : (breakdown ? 'Update' : 'Save')+' Breakdown'}}
                </base-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddBatchPaymentBreakdown',
        props: ['privileges', 'payment', 'loadAssessmentPayments', 'viewBreakdowns', 'breakdown'],
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                record: {
                    title: null,
                    amount: null
                },
                isloading: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['addPaymentBreakdown']),
            setData() {
                this.emptyFields();
                let breakdown = this.breakdown;
                if(breakdown && breakdown.id) {
                    this.record = {
                        id: breakdown.id,
                        title: breakdown.title,
                        amount: breakdown.amount
                    };
                }
                this.isLoadingBlade = false;
            },
            savePaymentBreakdown() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// add to sanction list
                    this.record.assessment_batch_payment_id = this.payment.id;// add admin id
                    this.addPaymentBreakdown(this.record).then((res) => {
                        // console.log(res)
                        this.loadAssessmentPayments();
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.viewBreakdowns(this.payment, true);
                            swal.fire("Breakdown Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadAssessmentPayments();// refresh page
                        swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    title: null,
                    amount: null
                };
            },
            validatedData() {
                let data = this.record;
                data.amount != null ? data.amount : this.showInuptError('Amount');
                data.title != null ? data.title : this.showInuptError('Title');
                let status = data.title && data.amount;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
</style>
