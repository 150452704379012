
<template>
        <div class="table-responsive">
                    
            <table class="table table-bordered">

                <tbody>
                    <td width="30%">
                        <h4>Registration Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-1 paddingLeft0">
                                    <h4 class="text-uppercase font-weight-light paddingTop13">
                                        {{ application.user_data.reg_prefix }} -
                                    </h4>
                                </div>
                                <div class="col-sm-5 paddingRight0">
                                    <base-input 
                                        placeholder="Enter Registration Number"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="application.user_data.registration_no"
                                        @keyup="displaySaveButton()"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span 
                            class="text-danger"
                            v-if="application.user_data.registration_no == '' || 
                                application.user_data.registration_no == null"
                        >
                            Kindly enter a valid Registration Number, 
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>                  

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>File Attachment:</h4>
                    </td>
                    <td>
                        <div class="col-sm-6">
                            <div v-if="sendEmailButton && !  loadPercent.show">
                                <img :src="pdfUrl" class="image" />
                                &nbsp;&nbsp;&nbsp;
                                <base-button 
                                    size="sm" type="info" 
                                    class="mr-4"
                                    @click="$refs.photo.click()"
                                >
                                    <i class="fas fa-pen"></i> 
                                    Click to change File
                                </base-button>
                            </div>

                            <input 
                                v-if="! loadPercent.show"
                                type="file"
                                ref="photo" 
                                id="ecis_file"
                                :class="sendEmailButton ? 'hide-file' : ''"
                                @change="onFileSelected"
                            >
                            <base-progress 
                                v-if="loadPercent.show"
                                :type="loadPercent.type" 
                                :height="10" 
                                :value="loadPercent.value"
                                :striped="true"
                                :animated="loadPercent.animated"
                            >
                            </base-progress>
                        </div>
                        <span 
                            class="text-danger"
                            v-if="application.user_data.registration_no == '' || 
                                application.user_data.registration_no == null"
                        >
                            <br>
                            Kindly attach a file, so send email button can be available for you.
                        </span>
                    </td>
                </tbody>                  

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody v-if="showSaveButton || showBackButton || sendEmailButton">
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button 
                            size="lg"
                            type="primary" 
                            :disabled="privileges && privileges.update == 'no'"
                            @click="updateProfile()"
                            v-if="showSaveButton && ! showBackButton && ! sendEmailButton"
                        >
                            <i class="fa fa-save"></i> 
                            Save Changes
                        </base-button> 

                        <base-button 
                            size="lg"
                            type="primary" 
                            :disabled="privileges && privileges.update == 'no'"
                            @click="sendEcisMail(application)"
                            v-if="sendEmailButton && ! showSaveButton && ! showBackButton"
                        >
                            <i class="fa fa-envelope"></i> 
                            {{ sendMailText }}
                        </base-button> 

                        <base-button 
                            size="lg"
                            type="success" 
                            :disabled="privileges && privileges.update == 'no'"
                            @click="approveUserApplication(application.id)"
                            v-if="! showSaveButton && ! showBackButton && sendEmailButton"
                        >
                            <i class="fa fa-check"></i> 
                            Approve Application
                        </base-button>

                        <base-button 
                            size="lg"
                            type="danger" 
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton && ! showSaveButton && sendEmailButton"
                        >
                            <i class="fa fa-times"></i> 
                            Cancel Approval
                        </base-button>

                        <base-button 
                            size="lg"
                            type="danger" 
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton && sendEmailButton"
                        >
                            <i class="fa fa-reply"></i> 
                            Back To Application
                        </base-button>
                    </td> 
                </tbody>

            </table>

        </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'EcisApproval',
    props: ['application', 'privileges', 'loadApplication'],
    data() {
      return {
        code: this.$route.params.code,
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false,
        showSaveButton: false,
        loadPercent: {
            type: 'warning',
            value: 10,
            animated: true,
            show: false
        },
        attachment: {
            id: null,
            ext: null,
            file: null,
            application_id: null
        },
        savedEvent: null,
        sendMailText: 'Send Mail'
      }
    },
    computed: {
        ...mapGetters(['user', 'printCertificateUrl', 'pdfUrl']),
        sendEmailButton() {
            let data = { 'registration_no' : this.application.user_data.registration_no };
            let hasfile = this.application.ecis_file && this.application.ecis_file.hasFile;
            return this.isEmpty(data) && hasfile;
        }
    },
    methods: {
      ...mapActions(['approveApplication', 'updateUserData', 'uploadEcisFile', 'sendUserEcisMail']),
        onFileSelected(event) {
            this.savedEvent = null;
            this.makeUploadFieldsEmpty();
            this.savedEvent = event;
            let status =  this.validateFile(event.target.files[0]);
            return status ? this.uploadPhoto() : false;
        },
        uploadPhoto() {                
            let event = this.savedEvent;// get store file event
            var fileReader = new FileReader();                
            fileReader.readAsDataURL(event.target.files[0]);
            fileReader.onload = (event) => {// convert file to binary
                this.attachment.file = event.target.result;
                this.makeUploadApiCall();// upload photo
            } 
        },
        makeUploadApiCall() {
            this.attachment.id = this.application.user_data_id;// get user id
            this.attachment.application_id = this.application.id;// get application id
            this.loadPercent.value = 10;
            this.loadPercent.show = true;
            this.uploadEcisFile(this.attachment).then((res) => {
                this.uploadPercent(this.$store.state.application.uploadProgress);// show load progress
                var self = this;
                self.makeUploadFieldsEmpty();
                self.savedEvent = null;
                if(res.status) { 
                    setTimeout(function() { 
                        self.loadPercent.show = false; 
                    }, 3000);
                } else {
                    swal.fire("Error Occurred!", "An error occurred while uploading, try again", "warning");
                    setTimeout(function() { 
                        self.loadPercent.show = false; 
                    }, 3000);
                }
            }).
            catch(err => {
                var self = this;
                self.makeUploadFieldsEmpty();
                self.savedEvent = null;
                swal.fire(err.message+"!", "Reduce file size and try again...", "warning");
                self.loadPercent.show = false;
            });
            this.loadApplication();// refresh page
        },
        uploadPercent(percent) {// update upload progress percent
            this.loadPercent.value = percent;
            this.loadPercent.type = percent > 60 ? 'success' : 'warning';
        },
        makeUploadFieldsEmpty() {
            this.attachment.id = null;
            this.attachment.ext = null;
            this.attachment.file = null;
            this.attachment.application_id = null;
        },
        validateFile(file) {
            if(!file) { this.savedEvent = null; }// update seleted file
            const validFileTypes = ['pdf', 'PDF'];// valid image format array
            var fileExt = file.type.split('/').pop();// get selected file ext
            this.attachment.ext = fileExt;
            var validExt = validFileTypes.includes(fileExt);
            let fileSize = file.size;// get selected file size
            if(validExt && fileSize <= 1000000) {// for valid file and size
                return true;
            } else if(! validExt) {// invalid file
                this.savedEvent = null;
                swal.fire("Invalid File!", "Only PDF files are accepted", "warning");// eslint-disable-next-line
                $('#ecis_file').val('');
                return false;
            } else if(fileSize > 1000000) {// big file
                this.savedEvent = null;
                swal.file("File is too large!", "File should be less than 1MB", "warning");// eslint-disable-next-line
                $('#ecis_file').val('');
                return false;
            }                
        },
        getFullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.last_name;
            return name;
        },
        getCertificateName(type) {
            return type.title ? type.title : 'N/A';
        },
        updateProfile() {// update user data
            let data = {
                'id' : this.application.user_data_id,
                'registration_no' : this.application.user_data.registration_no,
            };
            let check = this.validatedInputs(data); // validate
            if(check) {
                this.preloader();// show loading
                this.updateUserData(data).then((res) => {// get records
                    if(res.status) {
                        this.showSaveButton = false
                        swal.fire('Profile Updated!', res.msg, 'success');
                        this.loadApplication(); // reload page
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                }).
                catch(() => {
                    let msg = 'Server Failure!, Kindly reload the page...';
                    swal.fire('Error Occurred!', msg, 'error');
                });
            }
        },
        validatedInputs(data) {
            let record = { registration_no: null };
            record.registration_no = (data.registration_no == null || data.registration_no == '') ? 
                this.showInuptError('Registration Number') : data.registration_no;
            return this.isEmpty(record) ? true : false;
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        approveUserApplication(id) {// approve application
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeApproveApiCall(id);// make api call
                }
            });
        },
        makeApproveApiCall(id) {// approve application
            let data = { 'id' : id, 'valid_date' : null, 'status' : 'approved', 
                        'admin_id' : this.user.id, 'license_year' : null };
            this.preloader();// show loading
            this.approveApplication(data).then((res) => {// approve application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        sendEcisMail(application) {// send mail
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeSendMailApiCall(application);// make api call
                }
            });
        },
        makeSendMailApiCall(application) {// send mail
            let data = { 'id' : application.user_data_id, 'file' : application.ecis_file.path };
            this.preloader();// show loading
            this.sendUserEcisMail(data).then((res) => {// send mail
                if(res.status) {
                    this.sendMailText = 'Re-send Mail';
                    swal.fire('Mail Sent!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        displaySaveButton() {
            this.showSaveButton = true;
        },
        isEmpty(obj) {// check for empty field
            return ! Object.keys(obj).some(k => !obj[k]);
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }   
  };
</script>
<style scoped>
    .right {
        float: right;
    }
    .image {
        width: 50px;
        height: 50px;
    }
    .hide-file {
        display: none;
    }
</style>
