
<template>
    <div>
        <div class="row align-items-center">


            <div class="col-lg-12 text-uppercase">
                <h4 class="mb-0">Update Insurance Indemnity Record Below</h4>
                <hr class="my-4" />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Beneficiary Name"
                    placeholder="Enter Beneficiary Name"
                    input-classes="form-control-alternative"
                    v-model="record.beneficiary_name"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Beneficiary Relationship"
                    placeholder="Enter Beneficiary Relationship"
                    input-classes="form-control-alternative"
                    v-model="record.beneficiary_relationship"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Beneficiary Phone"
                    placeholder="Enter Beneficiary Phone"
                    input-classes="form-control-alternative"
                    v-model="record.beneficiary_phone"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Policy Number"
                    placeholder="Enter Policy Number"
                    input-classes="form-control-alternative"
                    v-model="record.policy_no"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Payment Status"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.trans_status"
                    >
                        <option
                            :value="null"
                            disabled
                        >
                            --- Select One ---
                        </option>
                        <option value="PAID">PAID</option>
                        <option value="PENDING">PENDING</option>
                        <option value="FAILED">FAILED</option>
                    </select>
                </base-input>
            </div>

            <div class="col-lg-4">
                <base-input
                    label="Expiry Date(Date indemnity would expire)"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: true}"
                        class="form-control datepicker"
                        v-model="record.expiry_date"
                    >
                    </flat-picker>
                </base-input>
            </div>

        </div>

        <div
            class="col-sm-12 text-center"
            v-if='showInputErrorStatus'
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <hr class="my-4" />

        <div class="col-lg-12">
            <base-button
                type="success" size="lg"
                @click="saveInsuranceRecords()"
                :disabled="(privileges && privileges.add == 'no') || ! privileges"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : 'Add Record'}}
            </base-button>

            <base-button
                type="danger" size="lg"
                class="right"
                @click="$emit('closeEditPage')"

            >
                <i class="fa fa-times"></i>
                Cancel
            </base-button>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'UpdateInsurance',
        props: ['newRecord','privileges', 'loadInsuranceRecord', 'closeEditPage', 'userId'],
        components: {
            flatPicker
        },
        data() {
            return {
                record: {
                    beneficiary_name: null,
                    beneficiary_relationship: null,
                    beneficiary_phone: null,
                    policy_no: null,
                    trans_no: null,
                    trans_date: null,
                    expiry_date: null,
                    trans_status: null,
                },
                showAddRecord: false,
                showInputErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            ...mapActions(['updateInsuranceRecord']),
            emptyEduRecords() {
                this.record.beneficiary_name = null;
                this.record.beneficiary_relationship = null;
                this.record.beneficiary_phone = null;
                this.record.policy_no = null;
                this.record.trans_no = null;
                this.record.trans_date = null;
                this.record.expiry_date = null;
                this.record.trans_status = null;
            },
            saveInsuranceRecords() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save insurance record
                    let data = this.record;
                    data.id = this.newRecord ? this.newRecord.id : null;
                    data.trans_no = data.trans_no ? data.trans_no : this.genRandomString(12);// check trans no
                    data.trans_date = data.trans_date ? data.trans_date : this.getCurrentDate();// check trans date
                    data.user_data_id = this.userId;// add user data id
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.updateInsuranceRecord(data).then((res) => {// update school
                        this.loadInsuranceRecord();// reload page
                        if(res.status) {
                            this.closeEditPage();// hide edit page
                            swal.fire("Record Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadInsuranceRecord();// reload page
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            getCurrentDate() {// get current date time
                let date = new Date().toJSON().slice(0,10).replace(/-/g,'-');
                return moment(date).format('YYYY-MM-DD');
            },
            genRandomString(length) {
                var text = '';
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
                for (var i=0; i < length; i++) {
                    text += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                return text;
            },
            validatedInputs() {// validate inputs
                let data = this.record;
                let eduRecord = { beneficiary_name: null, beneficiary_relationship: null,
                    beneficiary_phone: null, policy_no: null, expiry_date: null, trans_status: null };
                eduRecord.expiry_date = data.expiry_date == null ?
                    this.showInuptError('Expiry Date') : data.expiry_date;
                eduRecord.trans_status = data.trans_status == null ?
                    this.showInuptError('Payment Status') : data.trans_status;
                eduRecord.policy_no = data.policy_no == null ?
                    this.showInuptError('Policy Number') : data.policy_no;
                eduRecord.beneficiary_phone = data.beneficiary_phone == null ?
                    this.showInuptError('Beneficiary Phone') : data.beneficiary_phone;
                eduRecord.beneficiary_relationship = data.beneficiary_relationship == null ?
                    this.showInuptError('Beneficiary Relationship') : data.beneficiary_relationship;
                eduRecord.beneficiary_name = data.beneficiary_name == null ?
                    this.showInuptError('Beneficiary Name') : data.beneficiary_name;
                return this.isEmpty(eduRecord) ? eduRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            setData() {// set data for update
                if(this.newRecord) {
                    this.record.beneficiary_name = this.newRecord.beneficiary_name;
                    this.record.beneficiary_relationship = this.newRecord.beneficiary_relationship;
                    this.record.beneficiary_phone = this.newRecord.beneficiary_phone;
                    this.record.policy_no = this.newRecord.policy_no;
                    this.record.trans_no = this.newRecord.trans_no;
                    this.record.trans_date = this.newRecord.trans_date;
                    this.record.expiry_date = this.newRecord.expiry_date;
                    this.record.trans_status = this.newRecord.trans_status;
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData(); // set Edu Data
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>