
<template>
        <div class="table-responsive" v-if="application">

            <table class="table table-bordered">

                <tbody>
                    <td width="30%">
                        <h4>Folio Number (In Full):</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        placeholder="Enter Full Folio Number"
                                        class="input-group-alternative"
                                        alternative=""
                                        v-model="folio"
                                        @keyup="displaySaveButton()"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="folio == '' || folio == null"
                        >
                            Kindly enter a valid Folio Number,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>


                <tbody>
                    <td width="30%">
                        <h4>Registration Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-1 paddingLeft0">
                                    <h4 class="text-uppercase font-weight-light paddingTop13">
                                        {{ application.user_data.reg_prefix }} -
                                    </h4>
                                </div>
                                <div class="col-sm-5 paddingRight0">
                                    <base-input
                                        placeholder="Enter Registration Number"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="application.user_data.registration_no"
                                        @keyup="displaySaveButton()"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="application.user_data.registration_no == '' ||
                                application.user_data.registration_no == null"
                        >
                            Kindly enter a valid Registration Number,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>

                <tbody v-if="aqId">
                    <td width="30%">
                        <h4>Do You want to process AQ Certificate:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input>
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="aq_status"
                                        >
                                            <option  value="null" disabled>---Select One---</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="aqId && isAqCertificate">
                    <td width="30%">
                        <h4>AQ Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-1 paddingLeft0">
                                    <h4 class="text-uppercase font-weight-light paddingTop13">
                                        AQ -
                                    </h4>
                                </div>
                                <div class="col-sm-5 paddingRight0">
                                    <base-input
                                        placeholder="Enter AQ Number"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="aq_number"
                                        @keyup="displaySaveButton()"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="aq_number == '' || aq_number == null"
                        >
                            Kindly enter a valid AQ Number,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Modify {{ licenseYear.second ? 'First' : ''}} License Year:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input>
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="licenseYear.first"
                                            @change="displaySaveButton()"
                                        >
                                            <option  value="null" disabled>
                                                ---Select One---
                                            </option>
                                            <option
                                                v-for="(year, index) in years"
                                                :key="index"
                                                :value="year"
                                            >
                                                {{ year }}
                                            </option>
                                        </select>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="licenseYear.first == '' || licenseYear.first == null"
                        >
                            Kindly enter the first license year,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>

                <tbody v-if="licenseYear.second">
                    <td width="30%">
                        <h4>Modify Second License Year:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input>
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="licenseYear.second"
                                            @change="displaySaveButton()"
                                        >
                                            <option  value="null" disabled>
                                                ---Select One---
                                            </option>
                                            <option
                                                v-for="(year, index) in years"
                                                :key="index"
                                                :value="year"
                                            >
                                                {{ year }}
                                            </option>
                                        </select>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="licenseYear.second == '' || licenseYear.second == null"
                        >
                            Kindly enter the second license year,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>

                <tbody v-if="application && application.application_date">
                    <td width="30%">
                        <h4>Application Start Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: true}"
                                            class="form-control datepicker"
                                            v-model="application.application_date"
                                            @input="displaySaveButton()"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Application Expiry Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: true}"
                                            class="form-control datepicker"
                                            v-model="expiry_date"
                                            @input="displaySaveButton()"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Employer's Address:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        placeholder="Enter Employer's Address"
                                        class="input-group-alternative"
                                        alternative=""
                                        v-model="employer_address"
                                        @keyup="displaySaveButton()"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="employer_address == '' || employer_address == null"
                        >
                            Kindly enter a valid Employer's Address,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>

                <tbody v-if="showApproveButton">
                    <td width="30%">
                        <h4>Certificates Generated:</h4>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-3 marginTop5">
                                <form :action="printCertificateUrl" method="POST" target="_blank">
                                    <input type="hidden" name="id" :value="application.id" />
                                    <input
                                        type="hidden" name="cert_id"
                                        :value="application.certificate.id"
                                    />
                                    <input
                                        type="hidden" name="admin_id"
                                        :value="user.id"
                                    />

                                    <base-button
                                        size="sm"
                                        type="info"
                                        nativeType="submit"
                                        :disabled="privileges && privileges.view == 'no'"
                                    >
                                        <i class="fa fa-print"></i>
                                        {{ getCertificateName(application.certificate) }}
                                    </base-button>
                                </form>
                            </div>

                            <div
                                class="col-sm-3 marginTop5"
                                v-show="licenses && licenses.length > 0"
                                v-for="annual in licenses"
                                :key="annual.id"
                            >
                                <form :action="printCertificateUrl" method="POST" target="_blank">
                                    <input
                                        type="hidden" name="id"
                                        :value="annual.id"
                                    />
                                    <input
                                        type="hidden" name="cert_id"
                                        :value="application.license_certificate.certificate.id"
                                    />
                                    <input
                                        type="hidden" name="admin_id"
                                        :value="user.id"
                                    />

                                    <base-button
                                        size="sm"
                                        type="primary"
                                        nativeType="submit"
                                        :disabled="privileges && privileges.view == 'no'"
                                    >
                                        <i class="fa fa-print"></i>
                                        {{ getCertificateName(application.license_certificate.certificate) }}
                                        {{ annual.license_year ? '(' + annual.license_year + ')' : '' }}
                                    </base-button>
                                </form>
                            </div>

                            <div class="col-sm-3 marginTop5"
                                v-if="application.license_certificate 
                                        && application.license_certificate.aq_certificate
                                        && application.license_certificate.aq_certificate.aq 
                                        && isAqCertificate"
                            >
                                <form
                                    :action="printCertificateUrl"
                                    method="POST" target="_blank"
                                >
                                    <input
                                        type="hidden" name="id"
                                        :value="application.license_certificate.aq_certificate.aq.id"
                                    />

                                    <input
                                        type="hidden" name="cert_id"
                                        :value="application.license_certificate.aq_certificate.certificate.id"
                                    />

                                    <input
                                        type="hidden" name="admin_id"
                                        :value="user.id"
                                    />

                                    <base-button
                                        size="sm"
                                        type="dark"
                                        nativeType="submit"
                                        :disabled="privileges && privileges.view == 'no'"
                                    >
                                        <i class="fa fa-print"></i>
                                        {{ getCertificateName(application.license_certificate.aq_certificate.certificate) }}
                                    </base-button>
                                </form>
                            </div>

                        </div>
                    </td>
                </tbody>

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody v-if="showSaveButton || showApproveButton || showBackButton">
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="primary"
                            :disabled="privileges && privileges.update == 'no'"
                            @click="updateProfile()"
                            v-if="showSaveButton && ! showBackButton"
                        >
                            <i class="fa fa-save"></i>
                            Save Changes
                        </base-button>

                        <base-button
                            size="lg"
                            type="success"
                            :disabled="privileges && privileges.update == 'no'"
                            @click="approveUserApplication(application.id)"
                            v-if="! showSaveButton && ! showBackButton && showApproveButton"
                        >
                            <i class="fa fa-check"></i>
                            Approve Application
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton && ! showSaveButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Approval
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import swal from 'sweetalert2';// import sweet alert
  import moment from 'moment';

  export default {
    name: 'TempRegApproval',
    props: ['application', 'privileges', 'loadApplication', 'expiryDate'],
    components: {
        flatPicker
    },
    data() {
      return {
        code: this.$route.params.code,
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false,
        showSaveButton: true,
        folio: this.application.user_data.folio_number,
        expiry_date: null,
        aq_number : null,
        licenseYear: {
            first: null,
            second: null
        },
        aq_status: null,
        employer_address: null
      }
    },
    computed: {
        ...mapGetters(['user', 'printCertificateUrl']),
        aqNumber() {
            let longAq = this.application.license_certificate && this.application.license_certificate.aq_certificate
                && this.application.license_certificate.aq_certificate.aq
                && this.application.license_certificate.aq_certificate.aq.has_additional_qualification?
                this.application.license_certificate.aq_certificate.aq.has_additional_qualification.aq_number : null;
            let shortAq = this.application.has_additional_qualification ? 
                            this.application.has_additional_qualification.aq_number : null;
            return (this.code == 'limited-reg') ? shortAq : longAq;
        },
        aqId() {
            let longAq = this.application.license_certificate && this.application.license_certificate.aq_certificate
                && this.application.license_certificate.aq_certificate.aq
                && this.application.license_certificate.aq_certificate.aq.has_additional_qualification ?
                this.application.license_certificate.aq_certificate.aq.has_additional_qualification.id : null;
            
            let shortAq = this.application.has_additional_qualification ? 
                            this.application.has_additional_qualification.id : null;
            return (this.code == 'limited-reg') ? shortAq : longAq;
        },
        showApproveButton() {
            return this.showSaveButton ? false : true;
        },
        years() {
            let startYear = moment().format('YYYY') -32;// start from 1900
            let currentYear = parseInt(moment().format('YYYY')) + 20;
            let years = [];
            for (let i = currentYear; i > startYear; i-- ) {
                years.push(i);
            }
            return years;
        },
        licenses() {
            return this.application.license_certificate && this.application.license_certificate.annual ?
                        this.application.license_certificate.annual : [];
        },
        userProfile() {
            return this.application.user_data;
        },
        isTempAssessment() {
            return this.userProfile && (this.userProfile.is_temp_assessment == 'yes')
        },
        isTempRenewal() {
            return this.userProfile && (this.userProfile.is_temp_renewal == 'yes')
        },
        isRenewalApply() {
            return ((this.code == 'limited-reg-long-renewal') || (this.code == 'limited-reg-renewal'))
        },
        isShortStay() {// is short stay 
            return (this.code == 'limited-reg');
        },
        isAqCertificate() {// check if to proceed AQ certificate
            return (this.aq_status == 'yes');
        },
        processAqCertificate() {// check if application has AQ and AQ number is inputed
            return (this.aqId && this.aq_number ) || ! this.aqId;
        }
    },
    methods: {
      ...mapActions(['approveApplication', 'updateUserData']),
        loadAqDetails() {
            this.aq_number = this.aqNumber;
            this.expiry_date = this.expiryDate;
            this.employer_address = this.application && this.application.user_data 
                                    && this.application.user_data.has_additional_record 
                                    && this.application.user_data.has_additional_record.employer_address
                                    ? this.application.user_data.has_additional_record.employer_address : null;
            this.setData();
        },
        setData() {
            if(this.licenses && this.licenses.length > 0) {
                this.licenseYear.first = this.licenses[0] ? this.licenses[0].license_year : null;
                this.licenseYear.second = this.licenses[1] ? this.licenses[1].license_year : null;
            }
        },
        getFullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.last_name;
            return name;
        },
        getCertificateName(type) {
            return type.title ? type.title : 'N/A';
        },
        updateProfile() {// update user data
            let data = {
                id : this.application.user_data_id,
                app_id : this.application.id,
                registration_no : this.application.user_data.registration_no,
                folio_number : this.folio,
                valid_date : this.expiry_date,
                aq_number : this.aq_number,
                aq_id : this.aqId,
                licenses : this.licenses,
                employer_address : this.employer_address,
                application_date : this.application.application_date
            };
            if(data.licenses && data.licenses.length > 0) {// set modify first license year
                data.licenses[0].license_year = this.licenseYear.first;
            }
            if(data.licenses && data.licenses.length > 1) {// set modify second license year
                data.licenses[1].license_year = this.licenseYear.second;
            }
            let check = this.validatedInputs(data); // validate
            if(check) {
                this.preloader();// show loading
                this.updateUserData(data).then((res) => {// get records
                    if(res.status) {
                        this.showSaveButton = false
                        swal.fire('Profile Updated!', res.msg, 'success');
                        this.loadApplication(); // reload page
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                }).
                catch(() => {
                    let msg = 'Server Failure!, Kindly reload the page...';
                    swal.fire('Error Occurred!', msg, 'error');
                });
            }
        },
        validatedInputs(data) {
            let record = { registration_no: null, folio: null, valid_date: null, employer_address: null };
            record.valid_date = (this.expiry_date == null || this.expiry_date == '') ?
                this.showInuptError('Expiry Date') : this.expiry_date;
            if(! this.isTempAssessment && ! this.isRenewalApply && this.isAqCertificate) {
                if(this.aq_number == null || this.aq_number == '') {
                    this.showInuptError('AQ Number');
                    record.aq_number = null;
                }
            }            
            record.registration_no = (data.registration_no == null || data.registration_no == '') ?
                this.showInuptError('Registration Number') : data.registration_no;
            record.folio = (this.folio == null || this.folio == '') ?
                this.showInuptError('Folio Number') : this.folio;
            record.employer_address = (this.employer_address == null || this.employer_address == '') ?
                this.showInuptError("Employer\'s Address") : this.employer_address;
            // if(this.aq_status == null && ! this.isRenewalApply && ! this.isShortStay) {// check for AQ certificate Process status
            //     this.showInuptError('AQ Certificate Process Status');
            //     this.aq_number = null
            // } former condition to check if to process AQ Certificate // the new condition below check if there is an existing AQ id
            if(! this.processAqCertificate && ! this.isRenewalApply && ! this.isShortStay) {// check for AQ certificate Process status
                this.showInuptError('AQ Certificate Process Status');
                this.aq_number = null
            }
            return this.isEmpty(record) && (this.processAqCertificate || this.isRenewalApply  || this.isShortStay) ? true : false;
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        approveUserApplication(id) {// approve application
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeApproveApiCall(id);// make api call
                }
            });
        },
        makeApproveApiCall(id) {// approve application
            let data = { id : id, status : 'approved', admin_id : this.user.id, aq_status : this.aq_status };
            this.preloader();// show loading
            this.approveApplication(data).then((res) => {// approve application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        displaySaveButton() {
            this.showSaveButton = true;
        },
        isEmpty(obj) {// check for empty field
            return ! Object.keys(obj).some(k => !obj[k]);
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    created() {
        this.loadAqDetails();
    }
  };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop5 {
        margin-top: 5px;
    }
</style>
