
<template>
    <div>
        <div v-if="! isLoadingBlade">
            <h3 class="text-uppercase text-center">
                {{ isEdit }} Advisory {{ type }} School
            </h3>
            <hr>
            <div class="row align-items-center">

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="School's Name"
                        required
                    >
                        <autocomplete
                            ref="schoolAutocomplete"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to search School"
                            showNoResults
                            :disableInput="allSchools && allSchools.length == 0"
                            :source="allSchools"
                            resultsValue="name"
                            :results-display="formattedDisplay"
                            :initialDisplay="record.name"
                            @selected="updateSchoolName"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Approved Quota"
                        placeholder="Enter Approved Quota"
                        input-classes="form-control-alternative"
                        v-model="record.quota"
                        type="number"
                        required
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Institution Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.type"
                            disabled
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="medical">Medical School</option>
                            <option value="dental">Dental School</option>
                        </select>
                    </base-input>
                </div>

                <div
                    class="col-sm-12 text-center"
                    v-if='showErrorStatus'
                >
                    <base-alert :type="errorType">
                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                        <span class="alert-inner--text">
                            {{errorMsg}}
                        </span>
                    </base-alert>
                </div>

                <hr class="my-4" />

                <div class="col-lg-12">
                    <base-button
                        type="success" size="lg"
                        @click="updateSchool()"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges"
                    >
                        <i class="fa fa-save"></i>
                        {{isloading ? 'Please Wait...' : isEdit + ' Record'}}
                    </base-button>

                    <base-button
                        type="danger" size="lg"
                        class="right"
                        @click="$emit('addSchool')"
                    >
                        <i class="fa fa-times"></i>
                        Cancel
                    </base-button>
                </div>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'AddAdvisorySchool',
        props: ['privileges', 'data', 'loadAdvisorySchools', 'type', 'allSchools'],
        components: {
            Autocomplete
        },
        data() {
            return {
                record: {
                    training_school_id: null,
                    name: null,
                    quota: null,
                    type: this.type
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            isEdit() {
                return this.record && this.record.id ? 'Update' : 'Add';
            },
            disableType() {
                return this.record && this.record.id ? true : false;
            }
        },
        methods: {
            ...mapActions(['modifyDeleteAdvisorySchool']),
            updateSchoolName(e) {// update school name
                this.record.training_school_id = null;
                this.record.name = null;
                this.record.training_school_id = e.value;
                this.record.name = e.display;
            },
            setData() {// set data for editing
                this.emptyRecords();
                let data = this.data;
                if(data) {
                    this.record =   {
                        training_school_id: data.training_school_id,
                        name: data.name,
                        quota: data.quota,
                        type: data.type,
                        id: data.id
                    }
                }
                var self = this;
                setTimeout(function() { self.isLoadingBlade = false; }, 100);
            },
            emptyRecords() {
                return this.record = {
                    training_school_id: null,
                    name: null,
                    quota: null,
                    type: this.type
                };
            },
            updateSchool() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save department
                    let data = this.record;
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    data.option = 'update';
                    this.preloader(); // show loading
                    this.modifyDeleteAdvisorySchool(data).then((res) => {// update unit
                        this.loadAdvisorySchools();// refresh page
                        if(res.status) {
                            let type = (this.isEdit == 'Update') ? 'Updated' : 'Added';
                            swal.fire("Record "+type, res.msg, "success");
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadAdvisorySchools();// refresh page
                        swal.fire(err.message+"!", "An error occurred while processing, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let school = { training_school_id: null, quota: null, type: null };
                school.type = data.type ? data.type : this.showInuptError('Institution Type');
                school.quota = data.quota ? data.quota : this.showInuptError('Approved Quota');
                school.training_school_id = data.training_school_id ? data.training_school_id 
                                                : this.showInuptError('School\'s Name');
                return this.isEmpty(school) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            formattedDisplay (result) {
                return result.school_name;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {// set records
            this.setData();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>