
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th>CPD Provider's Name</th>
                        <th>RRR / Amount</th>
                        <th>Type / <br>License Year</th>
                        <th>Remark(s) / <br>Status</th>
                        <th>Date / Time <br>Paid</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            <span v-html="getName(row)"></span><br>
                            <badge type="primary">
                                {{ getUsername(row) }}
                            </badge>
                        </td>
                        <td>
                            {{ row.payment_ref }}<br>
                            <badge type="primary">
                                &#x20a6; {{ row.amount | formatPrice }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ getType(row) }} <br>
                            <badge type="info">
                                {{ row.license_year ? row.license_year : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.cpd_application ? row.cpd_application.status : 'Pending' }}
                        </td>
                        <td>
                            {{ getExactDate(row) | formatDate }}<br>
                            <badge type="success">
                                {{ getExactDate(row) | formatTime }}
                            </badge>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>

            <div class="col-lg-12" v-if="totalAmount">
                <hr>
                <div class="container">
                    <h3 class="text-uppercase">
                        total amount paid:
                        <span class="right">
                            &#x20a6; {{ totalAmount | formatPrice }}
                        </span>
                    </h3>

                    <h3 class="text-uppercase">
                        Total Number of CPD Providers Accredited:
                        <span class="right">
                            {{ accreditedCount }}
                        </span>
                    </h3>

                    <h3 class="text-uppercase">
                        Total Number of CPD Providers Awaiting Accreditation:
                        <span class="right">
                            {{ waitingCount }}
                        </span>
                    </h3>

                    <h3 class="text-uppercase">
                        Total Number of CPD Providers Applications:
                        <span class="right">
                            {{ totalCount }}
                        </span>
                    </h3>
                </div>
                <hr>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import Badge from '../../../components/Badge.vue';

    export default {
    components: { Badge },
        name: 'ReportingData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'totalAmount', 'accreditedCount',
            'waitingCount', 'totalCount'],
        filters: {
            formatDate(date) {
                return date ? moment(date).format('Do MMM, YYYY') : 'N/A';
            },
            formatTime(date) {
                return date ? moment(date).format('hh:mm') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return 'N/A';
                }
            }
        },
        methods: {
            getName(record) {// get full name
                return record && record.cpd_application && record.cpd_application.cpd ?
                    this.breakWord(record.cpd_application.cpd.name) : 'N/A';
            },
            getUsername(record) {// get CPD no
                return record && record.cpd_application && record.cpd_application.cpd && 
                    record.cpd_application.cpd.username ? record.cpd_application.cpd.username : 'N/A';
            },
            getExactDate(record) {
                return record.date_paid ? record.date_paid : record.date_generated;
            },
            getType(record) {// get purpose
                return record.cpd_application && record.cpd_application.type ?
                        record.cpd_application.type +' Fee' : 'N/A';
            },
            breakWord(word, length = 40) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
