
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="20%">Causer's Name</th>
                        <th width="20%">Description</th>
                        <th width="10%">IP Address</th>
                        <th width="10%">Activity Date</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.causer ? row.causer.name : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.description ? reduceString(row.description) : 'N/A' }}
                        </td>
                        <td>
                            {{ row.properties && row.properties.ip ? row.properties.ip : 'N/A' }}
                        </td>
                        <td>
                            {{ row.created_at | formatDate }}
                        </td>
                        <td>
                            <base-button
                                type="info" size="sm"
                                @click="viewRecord(row)"
                                :disabled="privileges && privileges.view == 'no' || ! privileges"
                            >
                                <i class="fa fa-eye"></i>
                                View
                            </base-button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>

        <div class="col-md-12">
            <modal :show.sync="showModal" v-if="record">
                <div id="printMe">
                    <h3 slot="header" class="modal-title" id="modal-title-default">
                        Action performed by
                        <em class="text-danger">
                            {{ record.causer ? record.causer.name : 'N/A' }}
                        </em>
                    </h3>

                    <div class="table-responsive">

                        <table class="table">
                            <tbody>
                                <td width='20%'>
                                    <h4>Causer's <br>Name</h4>
                                </td>
                                <td width='80%'>
                                    <h4 class="text-capitalize font-weight-light">
                                        {{ record.causer ? record.causer.name : 'N/A' }}
                                    </h4>
                                </td>
                            </tbody>
                            <tbody>
                                <td>
                                    <h4>Causer's <br>Unit</h4>
                                </td>
                                <td>
                                    <h4 class="text-capitalize font-weight-light">
                                        {{ record.causer && record.causer.unit ? record.causer.unit.name : 'N/A' }}
                                    </h4>
                                </td>
                            </tbody>
                            <tbody>
                                <td>
                                    <h4>Causer's <br>Department</h4>
                                </td>
                                <td>
                                    <h4 class="text-capitalize font-weight-light">
                                        {{ record.causer && record.causer.unit && record.causer.unit.department
                                            ? record.causer.unit.department.name : 'N/A' }}
                                    </h4>
                                </td>
                            </tbody>

                            <tbody v-if="record.user_name">
                                <td width='20%'>
                                    <h4>Suject's <br>Name</h4>
                                </td>
                                <td width='80%'>
                                    <h4 class="text-capitalize font-weight-light">
                                        {{ record.user_name.toLowerCase() }}
                                    </h4>
                                </td>
                            </tbody>

                            <tbody v-if="record.folio_number">
                                <td width='20%'>
                                    <h4>Suject's <br>Folio Number</h4>
                                </td>
                                <td width='80%'>
                                    <h4 class="text-capitalize font-weight-light">
                                        {{ record.folio_number }}
                                    </h4>
                                </td>
                            </tbody>

                            <tbody>
                                <td>
                                    <h4>Action <br>Performed</h4>
                                </td>
                                <td v-if="record.description">
                                    <h4
                                        class="text-capitalize font-weight-light"
                                        v-html="breakWord(record.description)"
                                        >
                                    </h4>
                                </td>
                            </tbody>

                            <tbody>
                                <td>
                                    <h4>IP Address</h4>
                                </td>
                                <td>
                                    <h4 class="text-capitalize font-weight-light">
                                        {{ record.properties && record.properties.ip ? record.properties.ip : 'N/A' }}
                                    </h4>
                                </td>
                            </tbody>

                            <tbody>
                                <td>
                                    <h4>Date <br>Performed</h4>
                                </td>
                                <td>
                                    <h4 class="font-weight-light">
                                        {{ record.created_at | formatDateTime }}
                                    </h4>
                                </td>
                            </tbody>
                        </table>
                    </div>
                </div>

                <template slot="footer">
                    <base-button type="success" @click="print">
                        <i class="fa fa-print"></i> Print
                    </base-button>
                    <base-button type="link" class="ml-auto" @click="showModal = false">
                        Close
                    </base-button>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'AuditTrailsData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges'],
        data() {
            return {
                showModal: false,
                record: null,
                output: null
            }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            formatDateTime(value) {
                return value ? moment(value).format('Do MMM, YYYY [at] H:mm a') : 'N/A';
            }
        },
        methods: {
            reduceString(string) {
                if(string) {
                    let exploded = string.split(' ');
                    let formedWord = '';
                    for(let word of exploded) {
                        if(formedWord.length < 40) {
                            formedWord += word+' ';
                        }
                    }
                    return (formedWord.toLowerCase() + ' ...');
                } 
                // return 'N/A';
            },
            viewRecord(data) {// display record function
                this.showModal = false;
                if(data) {
                    this.showModal = true;
                    this.record = data;
                }
            },
            breakWord(word) {// break word into two parts
                let exploded = word.split(' ');
                let formedWord = '';
                let half = Math.ceil((exploded.length - 1)/2);
                for(let phrase of exploded) {
                    formedWord += phrase+' ';
                    if(exploded[half] == phrase) {
                        formedWord += ' <br> ';
                    }
                }
                return formedWord.toLowerCase();
            },
            async print () {
                await this.$htmlToPaper('printMe');
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .modal-content {
        width: 150%;
    }
</style>
