
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Quack's Name</th>
                        <th width="10%">Quack's Category</th>
                        <th width="10%">Gender</th>
                        <th width="10%">State / LGA <br>of Practice</th>
                        <th width="10%">Apprehended <br> Date</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.fullname }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.category ? replaceAll(row.category, '-', ' ') : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.gender ? row.gender : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.has_practice_state ? row.has_practice_state.name : 'N/A' }} <br>
                            <badge size="lg" type="primary" class="text-uppercase">
                                {{ row.has_practice_lga ? row.has_practice_lga.name : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.date | getDateFormat }}
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination && ! searchKey"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'QuacksData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges'],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
