<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">Doctor's Name</th>
                            <th width="10%">Doctor's Location</th>
                            <th width="10%">Doctor's Gender</th>
                            <th width="10%">Status</th>
                            <th width="10%">Date Reported</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.name }}
                        </td>
                        <td>
                            {{ row.location }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.gender }}
                        </td>
                        <td>
                            <badge size="lg"
                                :type="((row.status == 'pending') ? 'warning' : 'success')"
                                class="text-uppercase"
                            >
                                {{ row.status  }}
                            </badge>
                        </td>
                        <td>
                            {{ row.created_at | getDateFormat }}
                        </td>
                        <td>
                            <base-button
                                type="success" size="sm"
                                @click="viewDetails(row.id)"
                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                            >
                                <i class="fa fa-eye"></i> Details
                            </base-button>

                            <base-button
                                type="danger" size="sm"
                                @click="removeRecord(row.id)"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                            >
                                <i class="fa fa-trash"></i> Delete
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

                <!-- View details modal -->
                <div class="col-md-4" v-if="report">
                    <modal :show="showModal">
                        <h3 slot="header" class="modal-title text-capitalize" id="modal-title-default">
                            Exit Register Report for
                            <em class="text-danger">{{ report.name }}</em>
                        </h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <h4 class="text-capitalize">
                                    Dcotor's Name:
                                    <em class="font-weight-light">{{ report.name }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12">
                                <h4>
                                    Dcotor's Location:
                                    <em class="font-weight-light">{{ report.location }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12">
                                <h4>
                                    Date of Death:
                                    <em class="font-weight-light">{{ report.date_of_death }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12">
                                <h4 class="text-capitalize">
                                    Dcotor's Gender:
                                    <em class="font-weight-light">{{ report.gender }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12" v-if="report.state_practice && report.has_practice_state">
                                <h4 class="text-capitalize">
                                    State of Practice:
                                    <em class="font-weight-light">{{ report.has_practice_state.name }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12" v-if="report.reporter_name">
                                <h4>
                                    Reporter's Name:
                                    <em class="font-weight-light">{{ report.reporter_name }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12" v-if="report.reporter_phone">
                                <h4>
                                    Reporter's Phone Number:
                                    <em class="font-weight-light">{{ report.reporter_phone }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12" v-if="report.reporter_email">
                                <h4>
                                    Reporter's Email:
                                    <em class="font-weight-light">{{ report.reporter_email }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12" v-if="report.relation_name">
                                <h4>
                                    Relation's Name:
                                    <em class="font-weight-light">{{ report.relation_name }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12" v-if="report.relation_phone">
                                <h4>
                                    Relation's Phone Number:
                                    <em class="font-weight-light">{{ report.relation_phone }}</em>
                                </h4>
                            </div>

                            <div class="col-lg-12">
                                <h4>
                                    Status:
                                    <badge size="lg"
                                        :type="((report.status == 'pending') ? 'warning' : 'success')"
                                        class="text-uppercase"
                                    >
                                        {{ report.status  }}
                                    </badge>
                                </h4>
                            </div>
                        </div>

                        <template slot="footer">
                            <base-button
                                :type="((report.status == 'pending') ? 'success' : 'danger')"
                                @click="changeStatus(report.id)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-history"></i>
                                Change Status
                            </base-button>
                            <base-button type="link" class="ml-auto" @click="showModal = false">Close
                            </base-button>
                        </template>
                    </modal>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapActions } from 'vuex';
    import swal from 'sweetalert2';

    export default {
        name: 'PendingReportsData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "loadReports"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        data() {
            return {
                showModal: false,
                report: null
            }
        },
        methods: {
            ...mapActions(['changeExitRegisterReportStatus', 'deleteExitRegisterReport']),
            viewDetails(id) {// show details modal
                for(let record of this.records) {
                    if(record.id == id) {
                        this.showModal = true;
                        this.report = record;
                    }
                }
            },
            changeStatus(id) {// change complaint's status
                this.changeExitRegisterReportStatus(id).then((res) => {
                    if(res.status) {
                        let status = (this.report.status == 'pending') ? 'resolved' : 'pending';
                        this.report.status = status;
                        this.loadReports();
                    } else {
                        swal.fire('Error Occurred', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    swal.fire('Error Occurred', 'An error occurred while updating, try again', 'warning');
                });
            },
            removeRecord(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRemoveApiCall(id);// make api call
                    }
                });
            },
            makeRemoveApiCall(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
                this.preloader(); // show loading
                this.deleteExitRegisterReport(data).then((res) => {// delete report
                    this.loadReports();// refresh page
                    if(res.status) {
                        swal.fire("Report Deleted", res.msg, "success");
                    } else {
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadReports();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>