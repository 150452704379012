<template>
    <div>
        <div class="row">
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th>Full Name <br> (surname last)</th> 
                        <th>Email Address /<br> Gender</th>
                        <th>Matriculation number <br> / Year of admission</th>
                        <th>Serial Number</th>
                        <th>&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ index+1}}
                        </td>
                        <td class="text-capitalize">
                            {{ getFullName(row) }}
                        </td>
                        <td>
                            {{ row.email ? row.email : 'N/A'  }} <br>
                            <badge type="primary">
                                {{ row.gender ? row.gender : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.matric_no ? row.matric_no : 'N/A' }} <br>
                            <badge type="info">
                                {{ row.year ? row.year : 'N/A'  }}
                            </badge>
                        </td>
                        <td>
                            <base-input alternative=""
                                placeholder="Enter Serial Number"
                                input-classes="form-control-alternative text-capitalize"
                                v-model="row.serial_no"
                                :readonly="! row.serial_no"
                            />
                        </td>
                        <td>
                            <base-button
                                :type="(row.serial_no ? 'primary' : 'success')"
                                size="sm"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges || ! row.serial_no"
                                @click="updateStudent(row)"
                            >
                                <i class="fa fa-save"></i>
                                Update Now
                            </base-button>
                        </td>
                    </template>

                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No students Records !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import Badge from '../../../components/Badge.vue';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';

    export default {
        components: { Badge },
        name: 'StudentRecords',
        props: [ 'records', 'privileges', 'loadApplication', 'pagination', 'paginate', 'hasPagination' ],
        methods: {
            ...mapActions(['updateIndexingStudent']),
            getFullName(record) {
                if(record) {
                    let name = record.first_name;
                    name += record.other_name ? ' ' + record.other_name : '';
                    name += ' ' + record.surname;
                    return name;
                } else {
                    return 'N/A';
                }
            },
            updateStudent(record) {// update
                if(record && record.serial_no) {
                    this.isloading = true;
                    this.preloader();// show loading
                    this.updateIndexingStudent(record).then((res) => {
                        this.loadApplication();
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Record Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                        this.loadApplication();
                    });
                } else {
                    let msg = 'Enter a valid Serial Number ';
                    msg += record ? 'for ' +this.getFullName(record).toUpperCase() : ''
                    swal.fire("Invalid Input!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>