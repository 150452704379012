
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0 && ! searchKey">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Batch Name <br>/ Code</th>
                        <th width="10%">Amount / <br> Active Status</th>
                        <th width="10%">Commencement <br> Date</th>
                        <th width="10%">Expiry <br> Date</th>
                        <th width="10%">Date Created <br> / Status</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.name }}<br>
                            <badge
                                size="sm" type="success"
                                class="font-weight-bolder"
                            >
                                {{ row.code }}
                            </badge>&nbsp;
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.year }}
                            </badge>
                        </td>
                        <td>
                            {{ row.amount | formatPrice }}<br>
                            <badge
                                :type="((row.status == 'active') ? 'success' : 'danger')"
                                class="font-weight-bolder" size="sm"
                            >
                                {{ row.status }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.commencement_date | formatDate }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.expiry_date | formatDate }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.created_at | formatDate }} <br>
                            <badge
                                :type="((getExactStatus(row) == 'open') ? 'success' : 'danger')"
                                class="font-weight-bolder" size="sm"
                            >
                                {{ getExactStatus(row) }}
                            </badge>
                        </td>
                        <td>
                            <button
                                class="btn btn-sm btn-success"
                                @click="$emit('loadAplications', row)"
                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                            >
                                <i class="fa fa-eye"></i>
                                {{ (type == 'posting') ? 'View Postings' : 'Get Applications'}}
                            </button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'AttachmentApplicationData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'searchKey', 'type'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }                
            }
        },
        methods: {
            getExactStatus(data) {// get exact status of batch 
                let startDate   = moment(data.commencement_date);// get commencement date minus one day
                let endDate     = moment(data.expiry_date).add(1, 'days');// get expiry date plus one day
                return (moment().isBetween(startDate, endDate)) ? 'open' : 'closed'; 
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
