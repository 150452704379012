<template>
    <div>
        <div v-if="! showAddForm">
            <div
                class="col-lg-12 text-right"
            >
                <button
                    class="btn btn-lg btn-info"
                    @click="showAddUser(true, 'Add')"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-plus"></i> Add New User
                </button>
                <br><br>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageNumbers()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-6">
                        <base-input
                            placeholder="Search by Email Address/Name and hit enter"
                            class="input-group-alternative"
                            alternative=""
                            addon-right-icon="fas fa-search"
                            @change="getSearchRecords"
                            v-model="searchKey"
                            >
                        </base-input>
                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>

            <div
                class="card shadow"
                v-if="users && users.length > 0"
            >
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="users"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="25%">Name</th>
                            <th width="10%">Email Address</th>
                            <th width="10%">Status</th>
                            <th width="10%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td>
                            {{ row.name }}
                        </td>
                        <td>
                            {{ row.email }}
                        </td>
                        <td class="text-capitalize">
                            <badge size="sm" :type="getColor(row.status)">
                                {{ row.status }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            <base-button
                                type="primary" size="sm"

                                @click="showAddUser(true, 'Update', row)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-user-edit"></i>
                                Edit Profile
                            </base-button>
                            <base-button
                                type="info" size="sm"

                                @click="$emit('viewMemberPrivileges', row)"
                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Privileges
                            </base-button>

                            <base-button
                                :type="getButtonColor(row.status)" size="sm"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @click="enableUser(row.id, row.status)"
                            >
                                <i :class="'fa fa-'+getIcon(row.status)"></i>
                                {{ row.status == 'enable' ? 'Disable' : 'Enable' }}
                            </base-button>
                            <base-button
                                type="dark" size="sm"

                                @click="resetAdminUserPassword(row)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-key"></i>
                                Reset Password
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                        @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>


            <!-- the modal for password reset  -->
            <div class="col-md-4" :show="showPasswordModal">
                <modal :show="showPasswordModal"
                        gradient="danger"
                        modal-classes="modal-danger modal-dialog-centered">
                    <h4
                        slot="header" class="modal-title"
                        id="modal-title-notification"
                    >
                        New Password
                    </h4>

                    <div class="py-3 text-center">
                        <i class="ni ni-bell-55 ni-3x"></i>
                        <h2 class="text-white">
                            Password: {{ password }}
                        </h2>
                    </div>

                    <template slot="footer">
                        <base-button type="link"
                                    text-color="white"
                                    class="ml-auto"
                                    @click="showPasswordModal = false">
                            Close
                        </base-button>
                    </template>
                </modal>
            </div>

        </div>

        <!-- import Add User template here -->
        <AddUser
            v-bind:user="user"
            v-bind:privileges="privileges"
            v-bind:loadUsers="loadUsers"
            v-bind:showAddUser="showAddUser"
            v-on:showAddUser="showAddUser"
            v-if="showAddForm"
        />

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import AddUser from './AddUser';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'UserData',
        props: ['privileges', 'pagination', 'users', 'hasPagination', 'paginate', 'loadUsers', 'changeTitle',
            'pageNumbers', 'getPageItems', 'searchRecords'],
        components: {
            AddUser
        },
        data() {
            return {
                user: null,
                showAddForm: false,
                searchKey: '',
                page: 1,
                showPasswordModal: false,
                password: null
            }
        },
        methods: {
            ...mapActions(['enableAdminUser', 'resetAdminPassword']),
            getSearchRecords() {
                let key = this.searchKey.toUpperCase();
                return this.searchRecords(key);
            },
            getPageNumbers() {
                return this.getPageItems(this.page);
            },
            getColor(value) {
                return value == 'enable' ? 'success' : 'danger';
            },
            getButtonColor(value) {
                return value == 'enable' ? 'danger' : 'success';
            },
            getIcon(value) {
                return value == 'enable' ? 'ban' : 'check';
            },
            enableUser(id, type) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeEnableApiCall(id, type);// make api call
                    }
                });
            },
            makeEnableApiCall(id, type) {
                let status = type == 'enable' ? 'disable' : 'enable';
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId, 'type' : status };
                this.preloader(); // show loading
                this.enableAdminUser(data).then((res) => {// delete department
                    if(res.status) {
                        this.loadUsers();// refresh page
                        swal.fire(res.type, res.msg, "success");
                    } else {
                        this.loadUsers();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadUsers();// refresh page
                    swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                });
            },
            showAddUser(value, type, user) {// display new dept form
                let title = value ? type+' ' : 'User Accounts';
                let formatTitle = user ? user.name+'\'s Account' : 'New User Account';
                title +=  (formatTitle && type) ? formatTitle : '';
                this.changeTitle(title);
                this.user = user;
                return this.showAddForm = value;
            },
            resetAdminUserPassword(admin) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't to continue with this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeResetPasswordApiCall(admin);// make api call
                    }
                });
            },
            makeResetPasswordApiCall(admin) {// enable edit api call
                if(admin && admin.email) {
                    this.preloader(); // show loading
                    this.password = null;
                    this.showPasswordModal = false;
                    let data = { email: admin.email, admin_id: this.$store.state.user.userId, skip: true };
                    this.resetAdminPassword(data).then((res) => {
                        if(res.status) {
                            swal.close();
                            this.password = res.password;
                            this.showPasswordModal = true;
                        } else {
                            let msg = "An error occurred while resetting password, kindly try again...";
                            swal.fire('Error Occurred!', msg, 'error');
                        }
                    }).
                    catch(() => {
                        let msg = "Server Failure, kindly reload the page...";
                        swal.fire('Server Failure!', msg, 'error');
                    });
                } else {
                    swal.fire('Server Failure!', "Server Failure, kindly reload the page...", 'error');
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>