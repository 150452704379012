
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade && ! showAddPage">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-12 text-right">
                        <button
                            class="btn btn-lg btn-primary"
                            @click="displayAddPage(true)"
                            v-if="! showAddPage"
                            :disabled="(privileges && privileges.add == 'no') || ! privileges"
                        >
                            <i class="fa fa-plus"></i>
                            Add New Type
                        </button>

                        <button
                            class="btn btn-lg btn-danger"
                            @click="displayAddPage(false)"
                            v-if="showbackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Go back
                        </button>
                        <br><br>
                    </div>
                </div>

                <div class="row" v-if="! showAddPage">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-6"></div>
                    <div class="col-lg-4 text-right">
                        <!-- <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="searchKey"
                                @change="searchRecords()"
                            >
                                <option :value="null" disabled selected>
                                    --- Search By Year ---
                                </option>
                                <option
                                    v-for="year in years"
                                    :key="year"
                                    :value="year"
                                >
                                    {{ year }}
                                </option>
                            </select>
                        </base-input> -->
                    </div>
                </div>
            </div>

            <!-- import Assessment Type Data template here -->
            <AssessmentTypeData
                :records="records"
                :hasPagination="hasPagination"
                :pagination="pagination"
                :paginate="paginate"
                :privileges="privileges"
                :searchKey="searchKey"
                :loadAssessmentTypes="loadAssessmentTypes"
                v-on:editBatch="editBatch"
                v-if="! showAddPage && ! isLoadingBlade"
            />

            <div
                class="col-sm-12 text-center"
                v-if="showErrorStatus && ! showAddPage && ! isLoadingBlade"
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>
        </div>

        <!-- import Add Assessment Type template here -->
        <AddAssessmentType
            :privileges="privileges"
            :data="record"
            :changeTitle="changeTitle"
            :displayAddPage="displayAddPage"
            :loadAssessmentTypes="loadAssessmentTypes"
            v-if="showAddPage && ! isLoadingBlade"
        />
    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import AssessmentTypeData from './AssessmentTypeData';
  import AddAssessmentType from './AddAssessmentType';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'AssessmentType',
    props: ['privileges', 'changeTitle', 'loadTitle'],
    components: {
        AssessmentTypeData,
        AddAssessmentType
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        page: 1,
        searchKey: null,
        showAddPage: false,
        showbackButton: false,
        record: null
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        },
        years() {
            let startYear = moment().format('YYYY') - 3;// start from 2019
            let currentYear = parseInt(moment().format('YYYY'));
            let years = [];
            for (let i = currentYear; i > startYear; i-- ) {
                years.push(i);
            }
            return years;
        },
    },
    methods: {
        ...mapActions(['getAssessmentTypes', 'getPaginatedPaymentItems', 'getSearchedBatches']),
        displayAddPage(value) {
            this.record = null;
            let title = 'Add New Assessment Type';
            this.changeTitle(title, value);
            this.showbackButton = value;
            this.loadAssessmentTypes();
            return this.showAddPage = value;
        },
        editBatch(data) {
            this.record = data;
            let title = 'Edit <em class="text-danger">' + data.title + '</em> Batch Details';
            this.changeTitle(title, true);
            this.showbackButton = true;
            return this.showAddPage = true;
        },
        loadAssessmentTypes() {// load details
            this.loadTitle();
            let data = { 'limit' : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getAssessmentTypes(data).then((res) => {// get users
                this.isLoadingBlade = false;
                this.setPages(res.types);//set data
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.types.current_page;
                    this.setPages(res.types);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Assessment Types yet!!!';
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                this.pagination.number = res.types.current_page;
                this.setPages(res.types);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        searchRecords() {
            this.showErrorStatus = false;
            let key = this.searchKey;
            if(key) {
                this.isLoadingBlade = true;
                let data = { 'key' : key };
                this.records = [];
                this.getSearchedBatches(data).then((res) => {// get records
                    this.isLoadingBlade = false;
                    this.records = res;
                    if(this.records.length == 0) {
                        this.showErrorStatus = true;
                        this.errorType = 'warning';
                        this.errorMsg = 'No Matched Record!!!';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
                this.emptyRecordsMsg();
            }
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadAssessmentTypes();
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
