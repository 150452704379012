
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade">
            <div class="row">
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Batch Name"
                        input-classes="form-control-alternative"
                        placeholder="Enter Batch Name"
                        v-model="record.name"
                        required
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Batch Code"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.code"
                        >
                            <option :value="null" disabled>
                                --- Select One ---
                            </option>
                            <option value="a">A</option>
                            <option value="b">B</option>
                            <option value="c">C</option>
                            <option value="d">D</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Programme Year"
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.year"
                        >
                            <option :value="null" disabled>
                                --- Select One ---
                            </option>
                            <option
                                v-for="year in years"
                                :key="year"
                                :value="year"
                            >
                                {{year}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input
                        label="Commencement Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.commencement_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input
                        label="Expiry Date"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="record.expiry_date"
                        >
                        </flat-picker>
                    </base-input>
                </div>

            </div>
        </div>

        <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <hr>
        <div class="pl-lg-4">
            <base-button
                type="success"
                class="right"
                @click="addBatch()"
                :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
            >
                {{isloading ? 'Please Wait' : (data ? 'Update' : 'Save')+' &amp; Continue'}}
                <i class="fa fa-arrow-right" @if='! isloading'></i>
            </base-button>

            <base-button
                type="danger"
                @click="displayAddPage(false)"
                :disabled="isloading"
            >
                <i class="fa fa-times"></i>
                Cancel                        
            </base-button>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    // import RemediationBatchUpload from './RemediationBatchUpload.vue';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddRemediationBatch',
        props: ['privileges', 'data', 'displayAddPage', 'changeTitle', 'loadRemediationBatches'],
        components: {
            flatPicker
        },
        data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: true,
            record: {
                name: null,
                code : null,
                commencement_date: null,
                expiry_date: null,
                year: null,
                invitee_quota: null
            },
            batch: null,
            isloading: false
        }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
            years() {
                let startYear = moment().format('YYYY') - 2;// start from 1900
                let currentYear = parseInt(moment().format('YYYY')) + 2;
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['addRemediationBatch']),
            setData() {
                this.emptyFields();
                let batch = this.data;
                if(batch && batch.id) {
                    this.record = {
                        id: batch.id,
                        name: batch.name,
                        code : batch.code,
                        commencement_date: batch.commencement_date,
                        expiry_date: batch.expiry_date,
                        year: batch.year,
                        status: batch.status
                    };
                }
                this.isLoadingBlade = false;
            },
            addBatch() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// add to sanction list
                    this.record.admin_id = this.user.id;// add admin id
                    this.record.status = 'enable';// add status
                    this.batch = null;
                    this.addRemediationBatch(this.record).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.batch = res.batch ? res.batch : null;
                            this.loadRemediationBatches();
                            this.displayAddPage(false);
                            swal.fire("Batch Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    name: null,
                    code : null,
                    commencement_date: null,
                    expiry_date: null,
                    year: null,
                    invitee_quota: null
                };
            },
            validatedData() {
                let data = this.record;
                data.expiry_date != null ? data.expiry_date : this.showInuptError('Expiry Date');
                data.commencement_date != null ? data.commencement_date : this.showInuptError('Commencement Date');
                data.year != null ? data.year : this.showInuptError('Induction Year');
                data.code != null ? data.code : this.showInuptError('Batch Code');
                data.name != null ? data.name : this.showInuptError('Batch Name');
                let status = data.name && data.code && data.year && data.commencement_date && data.expiry_date;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
</style>
<style scoped>
    .right {
        float: right;
    }
</style>
