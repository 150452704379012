
<template>
    <div>
        <div class="col-lg-12">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Medical Schools">
                            <span slot="title">
                                <i class="ni ni-books"></i>
                                Medical Schools
                            </span>
                            <!-- import Medical Schools template here -->
                            <MedicalSchools 
                                :type="'medical'"
                            />
                        </tab-pane>

                        <tab-pane title="Dental Schools">
                            <span slot="title">
                                <i class="ni ni-badge"></i>
                                Dental Schools
                            </span>
                            <!-- import Dental Schools template here -->
                            <MedicalSchools 
                                :type="'dental'"
                            />
                        </tab-pane>

                    </card>
                </tabs>
            </template>
        </div>
    </div>
</template>
<script>
    import MedicalSchools from './MedicalSchools/MedicalSchools';

    export default {
        name: 'Schools',
        components: {
            MedicalSchools
        }
    };
</script>
<style></style>
