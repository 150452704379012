
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0 && ! searchKey">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Doctor's Name</th>
                        <th width="10%">Practice Type</th>
                        <th width="10%">Gender</th>
                        <th width="10%">State of <br>Practice</th>
                        <th width="10%">LGA of <br>Practice</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ getFullName(row) }}<br>
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.user_data && row.user_data.folio_number ? row.user_data.folio_number : 'M/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ getPracticeTypeName(row) }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.user_data ? row.user_data.gender : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.user_data && row.user_data.has_practice_state
                                    ? row.user_data.has_practice_state.name : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.user_data && row.user_data.has_practice_lga
                                    ? row.user_data.has_practice_lga.name : 'N/A' }}
                        </td>
                        <td>
                            <button
                                class="btn btn-sm btn-info"
                                @click="$emit('editRecord', row)"
                                v-if="! row.user_data_id"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </button>

                            <button
                                class="btn btn-sm btn-danger"
                                @click="$emit('removeExitRegister', row.id)"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                            >
                                <i class="fa fa-ban"></i>
                                Remove
                            </button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>

  export default {
    name: 'ExitRegisterData',
    props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'searchKey', 'getFullName'],
    methods: {
        getPracticeTypeName(data) {// get practice type name
            let type = data && data.user_data && data.user_data.get_doctor_type
                        ? data.user_data.get_doctor_type : false;
            return (type && type.name) ? type.name : 'N/A';
        }
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
