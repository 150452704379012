
<template>
    <div>
        <div class="col-lg-12">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Active Advised Schools">
                            <span slot="title" class="text-capitalize">
                                <i class="ni ni-books"></i>
                                Active Advised {{ type }} Schools
                            </span>
                            <!-- import Active Advisory Schools template here -->
                            <ActiveAdvisorySchools
                                :type="type"
                            />
                        </tab-pane>

                        <tab-pane title="Inactive Advised Schools">
                            <span slot="title" class="text-capitalize">
                                <i class="ni ni-badge"></i>
                                Inactive Advised {{ type }} Schools
                            </span>
                            <!-- import InActive Advisory Schools template here -->
                            <InActiveAdvisorySchools
                                :type="type"
                            />
                        </tab-pane>

                    </card>
                </tabs>
            </template>
        </div>
    </div>
</template>
<script>
    import ActiveAdvisorySchools from './ActiveAdvisorySchools';
    import InActiveAdvisorySchools from './InActiveAdvisorySchools';

    export default {
        name: 'AdvisorySchools',
        props: ['type'],
        components: {
            ActiveAdvisorySchools,
            InActiveAdvisorySchools
        }
    };
</script>
<style></style>
