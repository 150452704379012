
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div v-if="! showPaymentPage">
                <div class="container" v-if="! isLoadingBlade">
                    <hr>
                    <div class="row">
                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Batch Name"
                                input-classes="form-control-alternative"
                                placeholder="Enter Title"
                                v-model="record.title"
                                required
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Batch Code (either A or B)"
                                input-classes="form-control-alternative"
                                placeholder="Enter Batch Code"
                                v-model="record.description"
                                required
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Quota (Max. No of Applicants)"
                                input-classes="form-control-alternative"
                                placeholder="Enter Quota"
                                type="number"
                                v-model="record.quota"
                                required
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Exam Year"
                            >
                                <select
                                    class="form-control form-control-alternative"
                                    v-model="record.year"
                                >
                                    <option
                                        :value="null"
                                        disabled
                                    >
                                        --- Select One ---
                                    </option>
                                    <option
                                        v-for="year in years"
                                        :key="year"
                                        :value="year"
                                    >
                                        {{year}}
                                    </option>
                                </select>
                            </base-input>
                        </div>

                        <div class="col-lg-4">
                            <base-input
                                label="Commencement Date"
                                addon-left-icon="ni ni-calendar-grid-58"
                                required
                            >
                                <flat-picker
                                    slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: true}"
                                    class="form-control datepicker"
                                    v-model="record.commencement_date"
                                >
                                </flat-picker>
                            </base-input>
                        </div>

                        <div class="col-lg-4">
                            <base-input
                                label="Expiry Date"
                                addon-left-icon="ni ni-calendar-grid-58"
                                required
                            >
                                <flat-picker
                                    slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: true}"
                                    class="form-control datepicker"
                                    v-model="record.expiry_date"
                                >
                                </flat-picker>
                            </base-input>
                        </div>

                        <div class="col-lg-4">
                            <base-input
                                label="Previous Batch Examination Date"
                                addon-left-icon="ni ni-calendar-grid-58"
                                required
                            >
                                <flat-picker
                                    slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: true}"
                                    class="form-control datepicker"
                                    v-model="record.previous_payment_date"
                                >
                                </flat-picker>
                            </base-input>
                        </div>

                    </div>
                </div>

                <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>

                <hr>
                <div class="pl-lg-4">
                    <base-button
                        type="success"
                        @click="addBatch()"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                    >
                        <i class="fa fa-save" @if='! isloading'></i>
                        {{isloading ? 'Please Wait' : (data ? 'Update' : 'Save')+' &amp; Continue &#8594;'}}
                    </base-button>
                </div>
            </div>

            <AddAssessmentPayment
                :batch="batch"
                :privileges="privileges"
                :changeTitle="changeTitle"
                :displayAddPage="displayAddPage"
                v-if="showPaymentPage"
            />

        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import AddAssessmentPayment from './AddAssessmentPayment';
    import moment from 'moment';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddAssessmentBatch',
        props: ['privileges', 'data', 'displayAddPage', 'changeTitle'],
        components: {
            flatPicker,
            AddAssessmentPayment
        },
        data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: false,
            record: {
                title: null,
                description: null,
                quota: null,
                year: null,
                commencement_date: null,
                previous_payment_date: null,
                expiry_date: null
            },
            showPaymentPage: false,
            batch: null,
            isloading: false
        }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
            years() {
                let startYear = moment().format('YYYY') - 2;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['addAssessmentBatch']),
            setData() {
                this.emptyFields();
                let batch = this.data;
                if(batch && batch.id) {
                    this.record = {
                        id: batch.id,
                        title: batch.title,
                        description: batch.description,
                        quota: batch.quota,
                        year: batch.year,
                        commencement_date: batch.commencement_date,
                        previous_payment_date: batch.previous_payment_date,
                        expiry_date: batch.expiry_date
                    };
                }
            },
            addBatch() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// add to sanction list
                    this.record.admin_id = this.user.id;// add admin id
                    this.addAssessmentBatch(this.record).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.batch = res.batch;
                            this.showPaymentPage = true;
                            // this.emptyFields();
                            swal.fire("Batch Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        // this.loadSanctions();// refresh page
                        swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    title: null,
                    description: null,
                    quota: null,
                    year: null,
                    commencement_date: null,
                    previous_payment_date: null,
                    expiry_date: null
                };
            },
            validatedData() {
                let data = this.record;
                data.expiry_date != null ? data.expiry_date : this.showInuptError('Expiry Date');
                data.commencement_date != null ? data.commencement_date : this.showInuptError('Commencement Date');
                data.quota != null ? data.quota : this.showInuptError('Quota');
                data.description != null ? data.description : this.showInuptError('Batch Code');
                data.title != null ? data.title : this.showInuptError('Title');
                let status = data.title && data.quota && data.commencement_date && data.expiry_date && data.description;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
</style>
