
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div v-if="! showBreakdownPage">
                <!-- Assessment batch payments data template here -->
                <BatchPaymentsData
                    :records="payments"
                    :batch="batch"
                    :privileges="privileges"
                    :loadAssessmentPayments="loadAssessmentPayments"
                    v-on:editBatchPayment="editBatchPayment"
                    v-if="payments && payments.length > 0 && ! showAddNewPayment && ! isLoadingBlade"
                />

                <div v-if="! disableAddPayment || editPaymentPage">
                    <div
                        class="pl-lg-4 text-center"
                        v-if="payments && payments.length > 0 && ! isLoadingBlade && ! editPaymentPage"
                    >
                        <hr>
                        <base-button
                            :type="(showAddNewPayment ? 'danger' : 'primary')"
                            :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                            @click="displayAddNewPayment()"
                        >
                            <i :class="'fa fa-'+(showAddNewPayment ? 'times' : 'plus')"></i>
                            {{showAddNewPayment ? 'Cancel Add Payment' : 'Add New Payment'}}
                        </base-button>
                    </div>

                    <div
                        v-if="showAddNewPayment || payments && payments.length == 0 || editPaymentPage"
                    >
                        <div class="container" v-if="! isLoadingBlade">
                            <hr>
                            <h2 class="text-center" v-if="! editPaymentPage">
                                Add New Payment to
                                <em class="text-danger">{{ batch.title }}</em>
                                Assessment Batch
                            </h2>
                            <h2 class="text-center" v-if="editPaymentPage" v-html="editPaymentTitle"></h2>
                            <hr>
                            <div class="row">
                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Title"
                                        input-classes="form-control-alternative"
                                        placeholder="Enter Title"
                                        v-model="record.title"
                                        required
                                    />
                                </div>

                                <div class="col-lg-6">
                                    <base-input alternative=""
                                        label="Assessment Type"
                                        required
                                    >
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="record.assessment_type_id"
                                            :disabled="editPaymentPage"
                                        >
                                            <option
                                                :value="null"
                                                disabled selected
                                            >
                                                --- Select One ---
                                            </option>
                                            <option
                                                v-for="type in assessmentTypes"
                                                :key="type.id"
                                                :value="type.id"
                                            >
                                                {{ type.title }}
                                            </option>
                                        </select>
                                    </base-input>
                                </div>

                            </div>
                        </div>

                        <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </base-alert>

                        <div class="pl-lg-4">
                            <base-button
                                type="success"
                                @click="addBatchPayment()"
                                :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                            >
                                <i class="fa fa-save" @if='! isloading'></i>
                                {{isloading ? 'Please Wait' : (editPaymentPage ? 'Update' : 'Add') + ' Payment'}}
                            </base-button>

                            <base-button type="danger"
                                @click="cancelEditBatchPayment()"
                                :disabled="isloading"
                                class="right"
                                v-if="editPaymentPage"
                            >
                                <i class="fa fa-times" @if='! isloading'></i>
                                Cancel
                            </base-button>
                        </div>
                    </div>
                </div>

                <hr>
                <div class="pl-lg-4" v-if="disableAddPayment && ! editPaymentPage">
                    <base-button
                        type="success"
                        :disabled="(privileges && privileges.view == 'no') || ! privileges || isloading"
                        @click="displayBreakdown(true)"
                    >
                        Continue
                        <i class="fa fa-arrow-right"></i>
                    </base-button>
                </div>
            </div>

            <BatchPaymentBreakdown
                :payments="payments"
                :loadAssessmentPayments="loadAssessmentPayments"
                :editBatchPayment="editBatchPayment"
                :batch="batch"
                :privileges="privileges"
                :displayBreakdown="displayBreakdown"
                :changeTitle="changeTitle"
                :allAssessmentTypes="allAssessmentTypes"
                :displayAddPage="displayAddPage"
                v-if="showBreakdownPage"
            />

        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import BatchPaymentsData from './BatchPaymentsData';
    import BatchPaymentBreakdown from './BatchPaymentBreakdown/BatchPaymentBreakdown';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddAssessmentPayment',
        props: ['privileges', 'batch', 'changeTitle', 'displayAddPage'],
        components: {
            BatchPaymentsData,
            BatchPaymentBreakdown
        },
        data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: true,
            record: {
                title: null,
                assessment_type_id: null
            },
            payments: [],
            assessmentTypes: [],
            allAssessmentTypes: [],
            showBreakdownPage: false,
            editPaymentPage: false,
            editPaymentTitle: null,
            showAddNewPayment: false,
            isloading: false
        }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
            disableAddPayment() {
                return this.assessmentTypes && this.assessmentTypes.length == 0;
            }
        },
        methods: {
            ...mapActions(['getBatchPayments', 'addAssessmentBatchPayment']),
            displayBreakdown(status) {
                let title = 'Add Payment' + (status ? ' Breakdowns' : '') + ' to <em class="text-danger">';
                title += this.batch.title + '</em> Assessment Batch';
                this.changeTitle(title, true);
                this.showBreakdownPage = status;
            },
            cancelEditBatchPayment() {
                this.editPaymentPage = false;
                this.emptyFields();
            },
            editBatchPayment(data) {
                if(data) {
                    this.editPaymentTitle = 'Edit <em class="text-danger">' + data.title ;
                    this.editPaymentTitle += (data.assessment_type ? ' (<strong>'+data.assessment_type.title +'</strong>)' : '');
                    this.editPaymentTitle += '</em> Payment';
                    this.editPaymentPage = true;
                    this.record = {
                        title: data.title,
                        assessment_type_id: data.assessment_type_id,
                        id : data.id
                    }
                }
            },
            displayAddNewPayment() {
                return this.showAddNewPayment = ! this.showAddNewPayment;
            },
            loadAssessmentPayments() {// load payments
                this.isLoadingBlade = true;
                this.showErrorStatus = false;
                this.getBatchPayments(this.batch.id).then((res) => {
                    this.isLoadingBlade = false;
                    this.setData(res);
                }).
                catch(() => {
                    this.payments = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setData(res) {
                this.emptyFields();
                let title = 'Add Payments to <em class="text-danger">' + this.batch.title + '</em> Assessment Batch';
                this.changeTitle(title, true);
                this.payments = res.payments;
                this.assessmentTypes = res.assessment_types;
                this.allAssessmentTypes = res.assessment_types_all;
            },
            addBatchPayment() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    this.record.assessment_batch_id = this.batch.id;// add admin id
                    this.addAssessmentBatchPayment(this.record).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.showAddNewPayment = false;
                            this.editPaymentPage = false;
                            swal.fire("Batch Payment Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                        this.loadAssessmentPayments();
                    }).
                    catch(err => {
                        this.loadAssessmentPayments();// refresh page
                        this.isloading = false;
                        swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    title: null,
                    assessment_type_id: null
                };
            },
            validatedData() {
                let data = this.record;
                data.assessment_type_id != null ? data.assessment_type_id : this.showInuptError('Assessment Type');
                data.title != null ? data.title : this.showInuptError('Title');
                let status = data.title && data.assessment_type_id;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadAssessmentPayments();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
    .right {
        float: right !important;
    }
</style>
