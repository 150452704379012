<template>
    <div class="pl-lg-4">
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link 
                                        to="/assessment/assessment-applications" 
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Back to Batches
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="container">
                            <div class="row" v-if="batch && (exportRange > 0) && ! isLoadingBlade">
                                <div
                                    class="col-sm-4 text-center borderLine margin20"
                                    v-for="item in exportRange"
                                    :key="item"
                                >
                                    <br>
                                    <h4 class="text-uppercase height-50">
                                        Print Exam Cards 
                                        for  Applicant(s) <br> from <br>
                                        <em class="text-danger">
                                            {{ recordRange(item).from }} - {{ recordRange(item).to }}
                                        </em>
                                    </h4><br>
                                    <form
                                        :action="getRootUrl+'/print-assessment-cards'"
                                        method="POST"
                                        target="_blank"
                                    >
                                        <input type="hidden" name="export_page" :value="item" />
                                        <input type="hidden" name="limit" :value="assessmentPrintRange" />
                                        <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                                        <input type="hidden" name="user_id" :value="user.id" />
                                        <input type="hidden" name="id" :value="id" />

                                        <base-button
                                            type="primary"
                                            class="btn btn-sm"
                                            nativeType="submit"
                                        >
                                            <i class="fa fa-print"></i>
                                            Exam Card
                                        </base-button>
                                    </form>
                                </div>
                            </div>

                            <div class="row" v-if="! batch && ! isLoadingBlade">
                                <div class="col-sm-12 text-center">
                                    <base-alert type="danger">
                                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                        <span class="alert-inner--text text-uppercase">
                                            It is not an Assessment Exam Batch
                                        </span>
                                    </base-alert>
                                </div>
                            </div>

                            <div class="row" v-if="(exportRange == 0) && ! isLoadingBlade">
                                <div class="col-sm-12 text-center">
                                    <base-alert type="danger">
                                        <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                        <span class="alert-inner--text text-uppercase">
                                            No Approved Applicant(s) yet !!!
                                        </span>
                                    </base-alert>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-sm-12 text-center">
                            <base-alert :type="errorType" v-if='showErrorStatus && ! isLoadingBlade'>
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'AssessmentExamCard',
        data() {
            return {
                batch: null,
                isLoadingBlade: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                id: this.$route.params.id
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'assessmentPrintRange', 'getRootUrl', 'user', 'getAuthorizationToken']),
            title() {
                let batch = this.batch ? this.batch : false;
                let title = batch ? "<em class='text-danger'>" + batch.title : '';
                title += batch ? " - " + batch.description : '';
                title += batch ? " (" + batch.year + ") </em>": '';
                title += 'Assessment Exam Cards';
                return title;
            },
            exportRange() {
                let total = this.batch ? this.batch.total : 0;
                let divider = this.assessmentPrintRange;
                return Math.ceil(total / divider);
            }
        },
        methods: {
            ...mapActions(['getBatches']),
            loadBatch() {// load details
                let data = { id : this.$route.params.id };
                this.isLoadingBlade = true;
                this.showErrorStatus = false;
                this.getBatches(data).then((res) => {// get batch
                    this.isLoadingBlade = false;
                    this.batch = res.batch;
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            recordRange(item) {
                let divider = this.assessmentPrintRange;
                return {
                    from : ((divider * item) - divider) + 1,
                    to : (item == this.exportRange) ? this.batch.total : divider * item
                };
            }
        },
        created() {
            this.loadBatch();// load Batch
        },
    }
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 2px solid #ededed;
        /* background-color: #f7fafc; */
    }
    .margin20 {
        margin-bottom: 20px;
        height: 150px;
    }
</style>