<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <i class="ni ni-tv-2"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <!-- import Applications template here -->
                            <Applications
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'applications'"
                            />

                            <!-- import Accredited Hospitals template here -->
                            <AccreditedHospitals
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'accredited-hospitals'"
                            />

                            <!-- import training Hospitals template here -->
                            <ViewHospitals
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'training-hospitals'"
                            />

                            <!-- import training Hospitals template here -->
                            <Reporting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'training-hospitals-applications-reporting'"
                            />

                            <!-- import training Hospitals template here -->
                            <HospitalReporting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'training-hospitals-reporting'"
                            />

                            <!-- import search for training Hospital template here -->
                            <SearchHospital
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'search-for-training-hospital'"
                            />

                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Applications from './Applications';
  import AccreditedHospitals from './AccreditedHospitals/AccreditedHospitals';
  import ViewHospitals from './ViewHospitals/ViewHospitals';
  import Reporting from './Reporting/Reporting';
  import HospitalReporting from './HospitalReporting/HospitalReporting.vue';
  import SearchHospital from './SearchHospital/SearchHospital.vue';

  export default {
    name: 'TrainingHospitals',
    components: {
        Applications,
        AccreditedHospitals,
        ViewHospitals,
        Reporting,
        HospitalReporting,
        SearchHospital
    },
    data() {
      return {
        code: this.$route.params.code,
        title: null,
        isLoadingBlade: false
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == this.$route.path) {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        }
    },
    methods: {
        loadTitle() {// load title
            return this.title = this.replaceAll(this.code, '-', ' ');
        },
        replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, 'g'), replace);
        },
        changeTitle(value, status) {// change title
            return status ? this.title = value : this.loadTitle();
        }
    },
    created() {
        this.loadTitle(); // load title
    }
  };
</script>
<style scoped>
</style>
