<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">CONSULTANTS' / DOCTORS' Details</h3>
                <hr class="my-4" />
            </div>

            <div class="col-lg-12" v-if="records && records.length > 0">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead class="text-uppercase">
                            <td width="5%">
                                <h4>#</h4>
                            </td>
                            <td width="15%">
                                <h4>Surname</h4>
                            </td>
                            <td width="15%">
                                <h4>First Name</h4>
                            </td>
                            <td width="20%">
                                <h4>Other Name /<br>Previous Name</h4>
                            </td>
                            <td width="15%">
                                <h4>Gender</h4>
                            </td>
                            <td width="15%">
                                <h4>Designation / <br> Department</h4>
                            </td>
                            <td width="15%">
                                <h4>Documents</h4>
                            </td>
                        </thead>
                    </table>
                </div>
                <div
                    class="table-responsive"
                    v-for="(record, index) in records"
                    :key="record.id"
                >
                    <table class="table table-bordered">
                        <tbody>
                            <td width="5%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ index+1 }}
                                </h4>
                            </td>
                            <td width="15%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.surname ? record.surname : 'N/A' }}
                                </h4>
                            </td>
                            <td width="15%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.first_name ? record.first_name : 'N/A' }}
                                </h4>
                            </td>
                            <td width="20%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.other_name ? record.other_name : 'N/A' }}<br>
                                    <badge type="success">
                                        {{ record.previous_name ? record.previous_name : 'N/A' }}
                                    </badge>
                                </h4>
                            </td>
                            <td width="15%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.gender ? record.gender : 'N/A' }}
                                </h4>
                            </td>
                            <td width="15%">
                                <h4 class="text-capitalize font-weight-light">
                                    {{ record.designation ? record.designation : 'N/A' }}<br>
                                    <badge type="success">
                                        {{ record.department ? record.department : 'N/A' }}
                                    </badge>
                                </h4>
                            </td>
                            <td width="15%">
                                <a
                                    :href="documentUrl+ record.cpd_id + '/consultant'+ record.id +'/credentials.pdf'"
                                    class="btn btn-sm btn-info"
                                    target="_blank"
                                    v-if="record.credential_file"
                                >
                                    <i class="fa fa-eye"></i> View Credentials
                                </a>
                                <badge
                                    type="info" class="text-danger"
                                    v-if="! record.credential_file"
                                >
                                    No Credentials Uploaded
                                </badge>
                                <br>
                                <a
                                    :href="documentUrl+ record.cpd_id + '/consultant' + record.id +'/license.pdf'"
                                    target="_blank"
                                    class="btn btn-sm btn-primary marginTop5"
                                    v-if="record.license_file"
                                >
                                    <i class="fa fa-eye"></i> View License
                                </a>
                                <badge
                                    type="primary" class="marginTop5 text-danger"
                                    v-if="! record.license_file"
                                >
                                    No License Uploaded
                                </badge>
                            </td>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Consultants Records !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'ConsultantDetails',
        props: [ 'records' ],
        computed: {
            ...mapGetters(['getRootUrl']),
            documentUrl() {// get document url
                return this.getRootUrl+'/storage/cpd/cpd';
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
    .marginTop5 {
        margin-top: 5px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>