
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0 && ! searchKey">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Centre Name <br>/ Code / Phone Number</th>
                        <th width="10%">City / <br> State</th>
                        <th width="10%">Email Address <br>/ Training Type</th>
                        <th width="10%">Date Created <br> / Status</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            <span v-html="breakWord(row.name)"></span><br>
                            <badge
                                size="sm" type="success"
                                class="font-weight-bolder"
                            >
                                {{ row.code ? row.code : 'N/A' }}
                            </badge>&nbsp;
                            <badge
                                size="sm" type="info"
                                class="font-weight-bolder"
                            >
                                {{ row.phone ? row.phone : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.city ? row.city : 'N/A' }}<br>
                            <badge
                                type="success"
                                class="font-weight-bolder" size="sm"
                            >
                                {{ row.has_state ? row.has_state.name : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.email ? row.email : 'N/A' }}<br>
                            <badge
                                type="primary"
                                class="font-weight-bolder" size="sm"
                            >
                                {{ row.training_type + ' Training' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.created_at | formatDate }} <br>
                            <badge
                                :type="((row.status == 'active') ? 'success' : 'danger')"
                                class="font-weight-bolder" size="sm"
                            >
                                {{ row.status }}
                            </badge>
                        </td>
                        <td>
                            <button
                                class="btn btn-sm btn-info"
                                @click="$emit('editCentre', row)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </button>

                            <button
                                :class="'btn btn-sm btn-'+ (row.status == 'active' ? 'warning' : 'success')"
                                @click="changeStatus(row.id)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-history"></i>
                                {{ (row.status == 'active' ? 'Deactivate' : 'Activate') }}
                            </button>
                            <br>
                            <button
                                class="btn btn-sm btn-danger marginTop5"
                                @click="removeBatch(row.id)"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges
                                            || row.applicant_count > 0"
                            >
                                <i class="fa fa-ban"></i>
                                Remove
                            </button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';

    export default {
        name: 'AttachmentBatchData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'searchKey', 'loadCentres'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }                
            }
        },
        methods: {
            ...mapActions(['changeDeleteAttachmentCentre']),
            changeStatus(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId, status: 'change' };
                this.preloader(); // show loading
                this.changeDeleteAttachmentCentre(data).then((res) => {// delete country
                    if(res.status) {
                        this.loadCentres();// refresh page
                        swal.fire("Status Changed", res.msg, "success");
                    } else {
                        this.loadCentres();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadCentres();// refresh page
                    swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                });
            },
            removeBatch(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRemoveApiCall(id);// make api call
                    }
                });
            },
            makeRemoveApiCall(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId, status: 'delete' };
                this.preloader(); // show loading
                this.changeDeleteAttachmentCentre(data).then((res) => {// delete country
                    if(res.status) {
                        this.loadCentres();// refresh page
                        swal.fire("Batch Deleted", res.msg, "success");
                    } else {
                        this.loadCentres();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadCentres();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            breakWord(word, length) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>

<style scoped>
    .marginTop5 {
        margin-top: 5px;
    }
</style>
<style>
    .table td {
        font-size: 15px;
    }
</style>
