
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-4">
                        <base-input label="Quack Category" required>
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.category"
                                required
                            >
                                <option  value="null" disabled>---Select One---</option>
                                <option value="personnel">Quack Medical Personnel</option>
                                <option value="hospital">Quack Hospital</option>
                                <option value="cpd-provider">Quack CPD Provider</option>
                            </select>
                        </base-input>
                    </div>
                    
                    <div class="col-lg-4" v-if="isQuackPersonnel">
                        <base-input alternative=""
                            label="Quack's Surname"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Quack's Surname"
                            v-model="record.last_name"
                            required
                        />
                    </div>

                    <div class="col-lg-4" v-if="isQuackPersonnel">
                        <base-input alternative=""
                            label="Quack's First Name"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Quack's First Name"
                            v-model="record.first_name"
                            required
                        />
                    </div>

                    <div class="col-lg-4" v-if="isQuackPersonnel">
                        <base-input alternative=""
                            label="Quack's Other Names"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Quack's Other Names"
                            v-model="record.other_name"
                        />
                    </div>

                    <div class="col-lg-4" v-if="! isQuackPersonnel">
                        <base-input alternative=""
                            label="Quack's Name"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Quack's Name"
                            v-model="record.first_name"
                            required
                        />
                    </div>

                    <div class="col-lg-4" v-if="isQuackPersonnel">
                        <base-input label="Practice Type" required>
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.doctor_type_id"
                                required
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option value="1">Medical Registration</option>
                                <option value="2">Dental Registration</option>
                                <option value="3">Alternative Registration</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input label="Gender" required>
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.gender"
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                                <option value="others" v-if="! isQuackPersonnel">Hospital / CPD Provider</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="State of Practice"
                            required
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.state_id"
                                @change="getStateLgas()"
                            >
                                <option
                                    :value="null"
                                    disabled
                                >
                                    --- Select One ---
                                </option>
                                <option
                                    v-show="locations.states"
                                    v-for="state in locations.states"
                                    :key="state.id"
                                    :value="state.id"
                                >
                                    {{state.name}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showLga && record.state_id">
                        <base-input alternative=""
                            label="LGA of Practice"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.lga_id"
                            >
                                <option value="null" disabled>--- Select One ---</option>
                                <option
                                    v-show="lgas && lgas.length > 0"
                                    v-for="lga in lgas"
                                    :value="lga.id"
                                    :key="lga.id"
                                >
                                    {{lga.name}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            label="Apprehended Date"
                            required
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                v-model="record.date"
                                :config="config"
                                class="form-control datepicker"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                </div>
            </div>


            <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>

            <div class="pl-lg-4">
                <base-button
                    type="success"
                    @click="addQuack()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                >
                    <i class="fa fa-save" @if='! isloading'></i>
                    {{isloading ? 'Please Wait' : 'Add Quack'}}
                </base-button>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'AddQuacks',
    props: ['privileges', 'data', 'locations', 'displayAddPage'],
    components: {
        flatPicker
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: false,
        record: {
            category: null,
            last_name: null,
            first_name: null,
            other_name: null,
            doctor_type_id: null,
            gender: null,
            state_id: null,
            lga_id: null,
            date: null
        },
        lgas: [],
        isloading: false,
        config: {
            allowInput: true,
            altInput: true,// eslint-disable-next-line
            maxDate: new Date().fp_incr(1),
            plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
        }
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
        showLga() {
            return this.record && this.record.state_id;
        },
        isQuackPersonnel() {// check if Quack is a Medical Presonnel
            return this.record && (this.record.category == 'personnel');
        }
    },
    methods: {
        ...mapActions(['addToQuacksList']),
        setData() {
            this.emptyFields();
            let quack = this.data;
            if(quack && quack.id) {
                this.record = {
                    id: quack.id,
                    last_name: quack.last_name,
                    first_name: quack.first_name,
                    other_name: quack.other_name,
                    doctor_type_id: quack.doctor_type_id,
                    gender: quack.gender,
                    state_id: quack.state_id,
                    lga_id: quack.lga_id,
                    category: quack.category,
                    date: quack.date
                };
                this.getStateLgas(quack.state_id);
            }
        },
        addQuack() {
            this.isloading = true;
            this.preloader();// show loading
            let status = this.validatedData();// valid input data
            if(status) {// add to sanction list
                this.record.admin_id = this.user.id;// add admin id
                this.addToQuacksList(this.record).then((res) => {
                    this.isloading = false;
                    swal.close();
                    if(res.status) {
                        this.record.id ? this.displayAddPage(false) : this.emptyFields();
                        let title = this.record.id ? 'Updated' : 'Added';
                        swal.fire("Quack "+ title +"!", res.msg, "success");
                    } else {
                        swal.fire("Error Ocurred!", res.msg, "warning");
                    }
                });
            }   else {
                this.isloading = false;
                swal.close();
            }
        },
        emptyFields() {
            return this.record = {
                category: null,
                last_name: null,
                first_name: null,
                other_name: null,
                doctor_type_id: null,
                gender: null,
                state_id: null,
                lga_id: null,
                date: null
            };
        },
        validatedData() {
            let data = this.record;
            data.date != null ? data.date : this.showInuptError('Apprehended Date');
            (data.lga_id != null) && (data.state_id != null) 
                                    ? data.lga_id : this.showInuptError('LGA of Practice');
            data.state_id != null ? data.state_id : this.showInuptError('State of Practice');
            data.gender != null ? data.gender : this.showInuptError('Gender');
            ((data.doctor_type_id != null && this.isQuackPersonnel) || ! this.isQuackPersonnel)
                                ? data.doctor_type_id : this.showInuptError('Practice Type');
            data.first_name != null ? data.first_name : 
                        this.showInuptError(this.isQuackPersonnel ? 'First Name' : 'Qucak\'s Name');
            ((data.last_name != null && this.isQuackPersonnel) || ! this.isQuackPersonnel) 
                            ? data.last_name : this.showInuptError('Surname');
            data.category != null ? data.category : this.showInuptError('Quack Category');
            let status = data.category && data.first_name && data.gender && data.state_id && data.lga_id 
                        && data.date && ((data.last_name && data.doctor_type_id) || ! this.isQuackPersonnel);
            return status ? true : false;
        },
        showInuptError(name) {
            this.isloading = false;
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        getStateLgas(id = null) {// get state's lgas
            let lgas = [];
            let stateId = id ? id : this.record.state_id;
            for(let lga of this.locations.lgas) {
                if (lga.state_id == stateId) {
                    lgas.push(lga);
                }
            }
            return this.lgas = lgas;
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.setData();
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100% !important;
    }
</style>
