<template>
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary shadow border-0">
                <div class="card-body px-lg-5 py-lg-5">
                    <form role="form" @submit.prevent="changePassword">
                         <base-alert :type="errorType" dismissible v-if="showErrorBlade">
                            <span class="alert-inner--icon"><i class="ni ni-sound-wave"></i></span>
                            &nbsp;
                            <span class="alert-inner--text" v-text="errorMessage"></span>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </base-alert>
                        <base-input
                            class="input-group-alternative mb-3"
                            placeholder="Enter Valid Email Address"
                            addon-left-icon="ni ni-email-83"
                            :valid="validEmailStatus"
                            @change="changeStatus('email')"
                            v-model="model.email">
                        </base-input>

                        <base-input
                            class="input-group-alternative mb-3"
                            placeholder="Enter Password"
                            type="password"
                            addon-left-icon="ni ni-lock-circle-open"
                            :valid="validPasswordStatus"
                            @change="changeStatus('password')"
                            v-model="model.password">
                        </base-input>

                        <base-input
                            class="input-group-alternative mb-3"
                            placeholder="Confirm Password"
                            type="password"
                            addon-left-icon="ni ni-lock-circle-open"
                            @keyup.enter="changePassword"
                            v-model="model.password_confirmation">
                        </base-input>

                        <div class="text-center">
                            <base-button type="primary" class="my-4" @click="changePassword"
                                v-if="! isLoading">
                                Change Password
                            </base-button>
                            <button class="btn btn-primary" type="button" disabled v-if="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Please Wait...
                            </button>

                            <router-link type="primary" class="my-4" v-if="! isLoading" to="/login">
                                Back to Login
                            </router-link>
                        </div>

                    </form>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <router-link to="/login" class="text-light"><small>Login</small></router-link>
                </div>
                <div class="col-6 text-right">
                    <!-- <router-link to="/register" class="text-light"><small>Create new account</small></router-link> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    name: 'UpdatePassword',
    data() {
        return {
            model: {
                email: this.$route.query.email,
                password: '',
                password_confirmation: '',
                token: this.$route.query.token,
                type: 'admin'
            },
            showErrorBlade: false,
            errorMessage: '',
            errorType: null,
            validEmailStatus: null,
            validPasswordStatus: null,
            isLoading: false
        }
    },
    computed: {
    ...mapGetters(['getupdatePasswordUrl','getAuthorizationToken'])
    },
    methods: {
        changePassword() {
            this.showErrorBlade = false;
            this.isLoading = true;
            const header = {Authorization: `Bearer ${this.getAuthorizationToken}`};
            let data = this.validateForm();//get validated data
            if(data.status) {//reset password
                axios.post(this.getupdatePasswordUrl, this.model, { headers: header })
                .then((res) => {
                    this.isLoading = false;
                    if(res.data.status) {// success
                        data.msg = res.data.msg+', kindly proceed to login';
                        this.showError(data, 'success');
                        setTimeout(function () { return window.location.href='/login'}, 3000)
                    } else if(! res.data.status) {//show error
                        data.msg = res.data.msg;
                        return this.showError(data, 'warning');
                    } else {
                        data.msg = 'Server Failure! Kindly try again';
                        return this.showError(data, 'warning');
                    }
                })
                .catch(() => {//check for failure
                    this.isLoading = false;
                    const data = [];
                    data.msg = 'Server Failure! Kindly try again';
                    return this.showError(data, 'warning');
                })
            } else {// show red alert
                this.isLoading = false;
                return this.showError(data, 'warning');
            }

        },
        redirectToLogin() {
            return this.$router.push({ 'name' : 'Login' });
        },
        validateForm() {
            let email = this.model.email;
            let password = this.model.password;
            let password_confirmation = this.model.password_confirmation;
            let data = [];
            if(email && this.validEmail(email) && password && this.validPassword(password)
                && (password_confirmation == password)) {//check for valid login crendentials
                data.status = true;
            } else {
                data.status = false;
                if (! email) {// check for empty email field
                    data.msg = 'Email field is empty';
                    data.type = 'email';
                } else if(email && ! this.validEmail(email)) {//check for invalid email
                    data.msg = 'Invalid email has been inputed';
                    data.type = 'email';
                } else if(! password) {// check for empty password field
                    data.msg = 'Password field is empty';
                    data.type = 'password';
                } else if(password && ! this.validPassword(password)) {//check for invalid email
                    data.msg = 'Password should not be less than 8 characters';
                    data.type = 'password';
                } else if(! password_confirmation) {// check for empty password field
                    data.msg = 'Confirm Password field is empty';
                    data.type = 'password';
                } else if(password_confirmation != password) {// check for empty password field
                    data.msg = 'Password mismatched, check and try again';
                    data.type = 'password';
                }
            }
            return data;
        },
        validEmail(email) {// eslint-disable-next-line
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validPassword(password) {
            return password.length >= 8 ? true : false;
        },
        showError(data, type) {
            this.showErrorBlade = false;
            this.errorMessage = '';
            // this.validEmailStatus = false;
            this.showErrorBlade = true;
            this.errorType = type;
            this.errorMessage = data.msg;
        },
        changeStatus(type) {
            this.showErrorBlade = false;
            let email = this.model.email;
            let password = this.model.password;
            if(type == 'email') {
                this.validEmailStatus = null;
                this.validEmailStatus = this.validEmail(email) ? true :false;
            } else if(password && type == 'password') {
                this.validPasswordStatus = null;
                this.validPasswordStatus = this.validPassword(password) ? true :false;
                this.validEmailStatus = this.validEmail(email) ? true :false;
            } else {
                this.validEmailStatus = false;
                this.validPasswordStatus = false;
            }
        }
    }
}
</script>

<style scoped>
.red-alert {
    border: 2px solid red;
}
</style>
