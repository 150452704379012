te<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <i class="ni ni-tv-2"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <!-- import Registered Schools template here -->
                            <Schools
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'training-schools'"
                            />

                            <!-- import Registered School template here -->
                            <School
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'training-school'"
                            />

                            <!-- import Medical School Accreditation Applications template here -->
                            <Applications
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                schoolType="medical"
                                type="accreditation"
                                v-if="code && code == 'medical-schools-accreditation-applications'"
                            />

                            <!-- import Dental School Accreditation Applications template here -->
                            <Applications
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                schoolType="dental"
                                type="accreditation"
                                v-if="code && code == 'dental-schools-accreditation-applications'"
                            />

                            <!-- import Medical School Advisory Applications template here -->
                            <Applications
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                schoolType="medical"
                                type="advisory"
                                v-if="code && code == 'medical-schools-advisory-visit-applications'"
                            />

                            <!-- import Dental School Advisory Applications template here -->
                            <Applications
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                schoolType="dental"
                                type="advisory"
                                v-if="code && code == 'dental-schools-advisory-visit-applications'"
                            />

                            <!-- import Accredited Medical Schools template here -->
                            <AccreditedSchools
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                type="medical"
                                v-if="code && code == 'accredited-medical-schools'"
                            />

                            <!-- import Accredited Dental Schools template here -->
                            <AccreditedSchools
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                type="dental"
                                v-if="code && code == 'accredited-dental-schools'"
                            />

                            <!-- import Advisory Medical Schools template here -->
                            <AdvisorySchools
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                type="medical"
                                v-if="code && code == 'advised-medical-schools'"
                            />

                            <!-- import Advisory dental Schools template here -->
                            <AdvisorySchools
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                type="dental"
                                v-if="code && code == 'advised-dental-schools'"
                            />

                            <!-- import Reporting template here -->
                            <SchoolReporting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'training-schools-reporting'"
                            />

                            <!-- import Reporting template here -->
                            <Reporting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                v-if="code && code == 'training-school-applications-reporting'"
                            />

                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import Schools from './Schools/Schools';
    import School from './School/School.vue';
    import Applications from './Applications/Applications.vue';
    import AccreditedSchools from './AccreditedSchools/AccreditedSchools.vue';
    import AdvisorySchools from './AdvisorySchools/AdvisorySchools.vue';
    import Reporting from './Reporting/Reporting.vue';
    import SchoolReporting from './SchoolReporting/SchoolReporting.vue'

    export default {
        name: 'TrainingSchools',
        components: {
            Schools,
            School,
            Applications,
            AccreditedSchools,
            AdvisorySchools,
            Reporting,
            SchoolReporting
        },
        data() {
            return {
                code: this.$route.params.code,
                title: null,
                isLoadingBlade: false
            }
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl']),
            privileges() {
            let privileges = null;
            if(this.menus && this.menus.length > 0) {
                for(let menu of this.menus) {
                    if(menu.has_child && menu.has_child.length > 0) {
                        for(let child of menu.has_child) {
                            if('/'+child.link == this.$route.path) {
                                privileges = child.privileges;
                            }
                        }
                    }
                }
            }
            return privileges;
            }
        },
        methods: {
            loadTitle() {// load title
                return this.title = this.replaceAll(this.code, '-', ' ');
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            },
            changeTitle(value, status) {// change title
                return status ? this.title = value : this.loadTitle();
            }
        },
        created() {
            this.loadTitle(); // load title
        }
    };
</script>
<style scoped>
</style>
