<template>
    <div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <div class="row">
                <div class="col-lg-12">
                    <base-button
                        type="danger" size="lg"
                        @click="$emit('closeMemberPrivileges')"
                        class="right"
                    >
                        <i class="fa fa-reply"></i>
                        Go Back
                    </base-button>
                </div>
            </div>
        </div>

        <hr class="my-4" />

        <div class="card shadow" v-if="! showErrorStatus && ! isLoadingBlade">
            <div class="table-responsive" v-if="records && records.length > 0"> 
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="30%">Menus</th>
                        <th width="10%">View</th>
                        <th width="10%">Add</th>
                        <th width="10%">Update</th>
                        <th width="10%">Delete</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ index+1}}
                        </td>
                        <td>
                            <span v-html="breakWord(row.name)"></span><br>
                            <badge
                                v-if="row.menu"
                                type="primary"
                            >
                                {{ row.menu.name }}
                            </badge>
                        </td>
                        <td>
                            <base-button
                                :type="getColor(row.privilege.view)" size="sm"
                                @click="updatePrivilege(row, index, 'view', row.privilege.view)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i :class="'fa fa-'+getIcon(row.privilege.view)"></i>
                                <span v-if="selected != (index+1)">
                                    {{ (row.privilege.view == 'yes') ? 'Disable' : 'Enable' }}
                                </span>
                                <span v-if="selectedType != 'view' && selected == (index+1)">
                                    {{ (row.privilege.view == 'yes') ? 'Disable' : 'Enable' }}
                                </span>
                                <span v-if="(selected == (index+1)) && (selectedType == 'view') && isLoading">
                                    Wait ...
                                </span>
                            </base-button>
                        </td>
                        <td>
                            <base-button
                                :type="getColor(row.privilege.add)" size="sm"
                                @click="updatePrivilege(row, index, 'add', row.privilege.add)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i :class="'fa fa-'+getIcon(row.privilege.add)"></i>
                                <span v-if="selected != (index+1)">
                                    {{ (row.privilege.add == 'yes') ? 'Disable' : 'Enable' }}
                                </span>
                                <span v-if="selectedType != 'add' && selected == (index+1)">
                                    {{ (row.privilege.add == 'yes') ? 'Disable' : 'Enable' }}
                                </span>
                                <span v-if="(selected == (index+1)) && (selectedType == 'add') && isLoading">
                                    Wait ...
                                </span>
                            </base-button>
                        </td>
                        <td>
                            <base-button
                                :type="getColor(row.privilege.update)" size="sm"
                                @click="updatePrivilege(row, index, 'update', row.privilege.update)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i :class="'fa fa-'+getIcon(row.privilege.update)"></i>
                                <span v-if="selected != (index+1)">
                                    {{ (row.privilege.update == 'yes') ? 'Disable' : 'Enable' }}
                                </span>
                                <span v-if="selectedType != 'update' && selected == (index+1)">
                                    {{ (row.privilege.update == 'yes') ? 'Disable' : 'Enable' }}
                                </span>
                                <span v-if="(selected == (index+1)) && (selectedType == 'update') && isLoading">
                                    Wait ...
                                </span>
                            </base-button>
                        </td><td>
                            <base-button
                                :type="getColor(row.privilege.delete)" size="sm"
                                @click="updatePrivilege(row, index, 'delete', row.privilege.delete)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i :class="'fa fa-'+getIcon(row.privilege.delete)"></i>
                                <span v-if="selected != (index+1)">
                                    {{ (row.privilege.delete == 'yes') ? 'Disable' : 'Enable' }}
                                </span>
                                <span v-if="selectedType != 'delete' && selected == (index+1)">
                                    {{ (row.privilege.delete == 'yes') ? 'Disable' : 'Enable' }}
                                </span>
                                <span v-if="(selected == (index+1)) && (selectedType == 'delete') && isLoading">
                                    Wait ...
                                </span>
                            </base-button>
                        </td>
                    </template>

                </base-table>
            </div>

            <div class="col-sm-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        No Menus associated with your Account, kindly contact Super Admin for resolution
                    </span>
                </base-alert>
            </div>

        </div>

        <div class="col-sm-12 text-center" v-if="showErrorStatus">
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import Badge from '../../../components/Badge.vue';

    export default {
        components: { Badge },
        name: 'MemberPrivileges',
        props: ['member','privileges'],
        data() {
            return {
                records: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                isLoading: false,
                selected: 0,
                selectedType: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user']),
        },
        methods: {
            ...mapActions(['getMemberPrivileges', 'updateMemberPrivilege']),
            loadMemberPrivileges() {// load details
                let data = { member_id: this.member.id, id: this.user.id };
                this.getMemberPrivileges(data).then((res) => {// get members
                    this.isLoadingBlade = false;
                    this.records = res;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            updatePrivilege(record, index, type, value) {// update privilege
                this.selected = 0;
                this.selectedType = null;
                this.selected =  index + 1;
                this.selectedType = type;
                let formatValue = value == 'yes' ? 'no' : 'yes';
                // let child_id = record.child_id ? record.child_id : null;
                let data = { 'id' : record.privilege.id, 'type' : type, 'value' : formatValue, 
                    'admin_id' : this.member.id };
                if(record.menu_id) {// update child menu id
                    data.child_menu_id = record.id;
                    data.menu_id = null;
                } else {// update menu id
                    data.child_menu_id = null;
                    data.menu_id = record.id;
                }
                this.isLoading = true;
                this.updateMemberPrivilege(data).then((res) => {// get records
                    this.selected = 0;
                    this.isLoading = false;
                    if(res.status) {
                        this.selectedType = null;
                        this.loadMemberPrivileges(); // reload page
                    } else {
                        this.selectedType = null;
                        let msg = "An error occurred while updating privilege, kindly try again...";
                        swal.fire('Error Occurred!', msg, 'error');
                    }
                }).
                catch(() => {
                    this.selected = 0;
                    this.isLoading = false;
                    this.selectedType = null;
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            getColor(value) {
                return value == 'no' ? 'success' : 'danger';
            },
            getIcon(value) {
                return value == 'yes' ? 'ban' : 'check';
            },
            breakWord(word, length = 50) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            }
        },
        mounted() {
            this.loadMemberPrivileges();// load unit member privileges
        },
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>