<template>
    <div>
        <template>
            <tabs fill class="flex-column flex-md-row">
                <card shadow>                                    

                    <tab-pane title="Medical Applications">
                        <span slot="title">
                            <i class="ni ni-books"></i>
                            Medical Applications
                        </span>
                        <!-- import Medical Applications template here -->
                        <MedicalApplications
                            :privileges="privileges"
                            :changeTitle="changeTitle"
                            :loadTitle="loadTitle"
                            :id="id"
                            :type="'medical'"
                        />
                    </tab-pane>

                    <tab-pane title="Dental Applications">
                        <span slot="title">
                            <i class="ni ni-badge"></i>
                            Dental Applications
                        </span>
                        <!-- import Medical Applications template here -->
                        <MedicalApplications
                            :privileges="privileges"
                            :changeTitle="changeTitle"
                            :loadTitle="loadTitle"
                            :id="id"
                            :type="'dental'"
                        />
                    </tab-pane>

                </card>
            </tabs>
        </template>
    </div>
</template>
<script>
    import MedicalApplications from './MedicalApplications.vue';

    export default {
        name: 'RemediationApplications',
        props: ['privileges', 'changeTitle', 'loadTitle', 'id'],
        components: {
            MedicalApplications
        },
    };
</script>
<style></style>
