<template>
    <div>
        <div class="row" v-if="! showEditPage">
            <div class="col-lg-8">
                <h3 class="text-uppercase">
                    Application Status:

                    <badge
                        :type="(applicant.status == 'approved') ? 'success' : 'warning'"
                        v-if="applicant"
                    >
                        {{ applicant.status }}
                    </badge>
                </h3>
                <h3 class="text-uppercase">
                    Application Date:
                    <em class="font-weight-light">
                        {{ applicant.reg_date | getDateFormat }}
                    </em>
                </h3>
                <h3 class="text-uppercase">
                    Payment Status:

                    <badge
                        :type="(applicant.payment_status == 'paid') ? 'success' : 'warning'"
                        v-if="applicant"
                    >
                        {{ applicant.payment_status }}
                    </badge>
                </h3>
                <h3 
                    class="text-uppercase" 
                    v-if="applicant && (applicant.status == 'approved') || (applicant.status == 'rejected')"
                >
                    {{ applicant.status }} Date:
                    <em class="font-weight-light">
                        {{ applicant.approved_date | getDateFormat }}
                    </em>
                </h3>
                <h3 class="text-uppercase">
                    Practice Type:
                    <em class="font-weight-light text-capitalize">
                        {{ applicant.practice_type ? (applicant.practice_type + ' Practitioner') : 'N/A' }}
                    </em>
                </h3>
                <h3 class="text-uppercase" v-if="(applicant.status == 'approved')">
                    Examination No:
                    <em class="font-weight-light text-capitalize">
                        {{ applicant.exam_number ? applicant.exam_number : 'N/A' }}
                    </em>
                </h3>

                <hr v-if="applicant && ((applicant.status == 'approved') || (applicant.status == 'rejected'))">

                <h3 class="text-uppercase text-danger" v-if="(applicant.status == 'rejected')">
                    Rejection Reason:
                    <em class="font-weight-light text-capitalize">
                        {{ applicant.reject_reason }}
                    </em>
                </h3>

                <form
                    :action="getRootUrl+'/print-assessment-card'"
                    method="POST"
                    target="_blank"
                    v-if="applicant.status == 'approved'"
                >
                    <input type="hidden" name="id" :value="applicant.id" />
                    <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />

                    <base-button
                        type="success"
                        nativeType="submit"                        
                    >
                        <i class="fa fa-print"></i>
                        Print Exam Card
                    </base-button>
                </form>


            </div>

            <div class="col-lg-4 text-right">
                <img :src="photo" class="photo-class"/>
                <br><br>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
                <h3 class="mb-0 text-uppercase">
                    Personal Details
                    <base-button
                        type="primary" size="sm"
                        class="right"
                        @click="editPersonalData(true)"
                        :disabled="privileges && privileges.update == 'no'"
                        v-if="applicant.status == 'submitted'"
                    >
                        <i class="fa fa-pen"></i>
                        Edit Personal Data
                    </base-button>
                </h3>
                <hr class="my-4" />

            </div>

            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>RRR</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ rrr ? rrr : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Surname</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.surname ? personal.surname : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>First Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.first_name ? personal.first_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Other Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.other_name ? personal.other_name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Previous Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.previous_name ? personal.previous_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Gender</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.gender ? personal.gender : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Marital Status</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.marital_status ? personal.marital_status : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Date of Birth</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.date_of_birth | getDateFormat }}
                            </h4>
                        </td>
                        <td>
                            <h4>Place of Birth</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.place_of_birth ? personal.place_of_birth : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Nationality</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.has_country ? personal.has_country.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td v-if="personal.nationality == 164">
                            <h4>State of Origin</h4>
                        </td>
                        <td v-if="personal.nationality == 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.has_state ? personal.has_state.name : 'N/A' }}
                            </h4>
                        </td>
                        <td v-if="personal.nationality == 164">
                            <h4>LGA of Origin</h4>
                        </td>
                        <td v-if="personal.nationality == 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.has_lga ? personal.has_lga.name : 'N/A' }}
                            </h4>
                        </td>
                        <td v-if="personal.nationality != 164">
                            <h4>State / Province</h4>
                        </td>
                        <td v-if="personal.nationality != 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.foreign_state ? personal.foreign_state : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Home Town</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.home_town ? personal.home_town : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Permanent Home Address</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                <span v-html="breakWord(personal.permanent_address, 150)"></span>
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Residential Address in Nigeria</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                <span v-html="breakWord(personal.residential_address, 150)"></span>
                            </h4>
                        </td>
                    </tbody>
                    <tbody v-if="personal.business_address">
                        <td>
                            <h4>Full Business Address</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                <span v-html="breakWord(personal.business_address, 150)"></span>
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Contact Address</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                <span v-html="breakWord(personal.contact_address, 150)"></span>
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td v-if="personal.nationality == 164">
                            <h4>Date of Return to Nigeria</h4>
                        </td>
                        <td v-if="personal.nationality == 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.return_date | getDateFormat }}
                            </h4>
                        </td>
                        <td v-if="personal.nationality != 164">
                            <h4>Date of Entry into Nigeria</h4>
                        </td>
                        <td v-if="personal.nationality != 164">
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.entry_date | getDateFormat }}
                            </h4>
                        </td>
                        <td>
                            <h4>Phone Number</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ personal.phone ? personal.phone : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Email Address</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ personal.email ? personal.email : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>

        <!-- edit personal data template here -->
        <EditPersonalDetails
            :applicant="applicant"
            :locations="locations"
            :editPersonalData="editPersonalData"
            :loadAssessmentApplicant="loadAssessmentApplicant"
            v-if="showEditPage"
        />

    </div>
</template>

<script>
    import moment from 'moment';
    import EditPersonalDetails from './EditAssessment/EditPersonalDetails';
    import { mapActions, mapGetters } from 'vuex';


    export default {
        name: 'PersonalDetails',
        props: [ 'applicant', 'rrr', 'photo', 'privileges', 'editPage', 'loadAssessmentApplicant', 'breakWord' ],
        components: {
            EditPersonalDetails
        },
        data() {
            return {
                showEditPage: false
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['locations', 'getRootUrl', 'getAuthorizationToken']),
            personal() {
                return this.applicant.personal_data;
            }
        },
        methods: {
            ...mapActions(['getLocations']),
            editPersonalData(status = false) {
                let text = status ? 'personal' : null;
                this.editPage(status, text);
                return this.showEditPage = ! this.showEditPage
            }
        },
        created() {
            this.getLocations();// locations
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>