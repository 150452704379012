<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <h3 class="text-uppercase"  v-if="! isLoadingBlade">Edit Institution Data (Remedial Course)</h3>

        <hr class="my-4" />

        <div  class="row align-items-center" v-if="! isLoadingBlade">

            <div :class="'col-lg-'+ (showOtherName ? 6 : 12)">
                <base-input alternative=""
                    label="Institution Name"
                    required
                >
                    <autocomplete
                        input-class="form-control form-control-alternative"
                        placeholder="Type and Search for Institution Name"
                        showNoResults
                        :source="formatedHospitals"
                        :initialDisplay="record.name"
                        resultsValue="name"
                        @selected="updateExamVenue"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-6" v-if="showOtherName">
                <base-input alternative=""
                    label="Other Institution Name"
                    placeholder="Enter Other Institution Name"
                    input-classes="form-control-alternative"
                    v-model="record.name"
                    required
                />
            </div>
            <div class="col-lg-6">
                <base-input
                    label="Start Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.start_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-6">
                <base-input
                    label="End Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.end_date"
                    >
                    </flat-picker>
                </base-input>
            </div>

            <div class="col-lg-12">
                <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>
            </div>
            <div class="col-lg-12"></div>
            <div class="col-lg-12">
                <base-button
                    type="success"
                    class="right"
                    @click="saveInstitutionRecords()"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Update Record'}}
                </base-button>

                <div>
                    <base-button
                        type="danger"
                        @click="editInstituteRecord(false)"
                    >
                        <i class="fa fa-times"></i>
                        Cancel Edit
                    </base-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        components: {
            flatPicker,
            Autocomplete
        },
        name: 'EditInstitutionDetails',
        props: ['editInstituteRecord', 'applicant', 'loadAssessmentApplicant', 'res', 'hospitals'],
        data() {
            return {
                record: {
                    teaching_hospital_id: null,
                    name: null,
                    start_date: null,
                    end_date: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    maxDate: new Date().fp_incr(1),
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            showOtherName() {
                return this.record.teaching_hospital_id == 0;
            },
            formatedHospitals() {
                let items = this.hospitals;
                items.push({ id: 0, name: 'Others (Specify)'})
                return items;
            }
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData']),
            updateExamVenue(e) {
                let data = e.selectedObject;
                this.record.teaching_hospital_id = null;
                this.record.name = null;
                this.record.teaching_hospital_id = data.id;
                if(data.id > 0) { this.record.name = data.name; }
            },
            setData() {// set paginated function
                if(this.res) {
                    let data = this.res;
                    this.record = {
                        name: data.name,
                        start_date: data.start_date,
                        end_date: data.end_date,
                        teaching_hospital_id: (data.teaching_hospital_id == 0) ? null : data.teaching_hospital_id,
                        id: data.id
                    }
                    this.isLoadingBlade = false;
                }
            },
            saveInstitutionRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                data.id = this.res.id
                this.preloader();// show loading
                let record = { 'institution_data' : data , 'id' : this.applicant.id };
                this.saveAssessmentApplicantData(record).then((res) => {
                    this.loadAssessmentApplicant();
                    this.isloading = false;
                    this.emptyRecords();
                    this.editInstituteRecord(false);
                    if(res.status) {
                        this.showErrorStatus = false;
                        this.showAddRecord = false;
                        swal.fire("Record Updated!", res.msg , "success");
                    } else {
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    }
                }).
                catch(() => {
                    this.editInstituteRecord(false);
                    this.isloading = false;
                    this.emptyRecords();
                    swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                });
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { name: null, start_date: null, end_date: null, teaching_hospital_id: null };
                appRecord.end_date = data.end_date;
                appRecord.start_date  = data.start_date;
                appRecord.name  = data.name;
                appRecord.teaching_hospital_id  = data.teaching_hospital_id;
                return appRecord;
            },
            emptyRecords() {
                return this.record = {
                    name: null,
                    start_date: null,
                    end_date: null,
                    teaching_hospital_id: null
                };
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();// load edu records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>