
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">{{ this.$route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                          <div class="d-flex justify-content-center">
                              <img :src="loadingBladeUrl" />
                          </div>
                        </div>

                        <div class="container">
                          <div class="row">
                              <div class="col-lg-6"></div>
                              <div class="col-lg-6">
                                  <base-input
                                      placeholder="Search by Folio Number / Name / Email and hit enter"
                                      class="input-group-alternative"
                                      alternative=""
                                      addon-right-icon="fas fa-search"
                                      @change="searchRecords"
                                      v-model="searchKey"
                                      v-if="! isLoadingBlade"
                                      >
                                  </base-input>
                              </div>
                          </div>
                      </div>
                        <!-- import edu records details template here -->
                        <NewSignupData
                            v-bind:records="records"
                            v-bind:pagination="pagination"
                            v-bind:hasPagination="hasPagination"
                            v-bind:paginate="paginate"
                            v-bind:privileges="privileges"
                            v-bind:loadPendingSignups="loadPendingSignups"
                            v-bind:paginatedItems="paginatedItems"
                            :getFullName="getFullName"
                            :updateSignup="updateSignup"
                            v-if="! isLoadingBlade"
                        />

                        <div class="col-sm-12 text-center">
                          <base-alert
                            :type="errorType"
                            v-if='showErrorStatus && records && records.length == 0 && ! isLoadingBlade'
                        >
                              <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                              <span class="alert-inner--text">
                                  {{errorMsg}}
                              </span>
                          </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import NewSignupData from './NewSignupData';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'NewSignup',
    components: {
      NewSignupData
    },
    data() {
      return {
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        searchKey: ''
      }
    },
    computed: {
        ...mapGetters(['loadingBladeUrl', 'menus']),
        hasPagination() {
          return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
              if('/'+menu.link == this.$route.path) {
                privileges = menu.privileges;
              }
            }
          }
          return privileges;
        }
    },
    methods: {
      ...mapActions(['getPendingSignups', 'getPaginatedPendingSignups', 'updatePendingSignups']),
      loadPendingSignups() {
        let data = { limit : this.pagination.perPage, type : 'non_temp_doc' };
        this.getPendingSignups(data).then((res) => {// get records
            this.setPages(res);
        }).
        catch(() => {
            this.showErrorStatus = true;
            this.isLoadingBlade = false;
            this.errorMsg = "Server Failure!, Kindly reload the page...";
            this.errorType = 'danger';
        });
      },
      setPages(res) {// set paginated function
          this.records = [];
          this.paginatedItems = res.data;
          this.records = this.paginatedItems;
          this.pagination.from = res.from;
          this.pagination.to = res.to;
          this.pagination.total = res.total;
          this.pagination.current_page = res.current_page;
          this.pagination.prev_page_url = res.prev_page_url;
          this.pagination.next_page_url = res.next_page_url;
          this.isLoadingBlade = false;
          this.emptyRecordsMsg();//show empty msg
      },
      paginate() {// paginate function
          let url = (this.pagination.number > this.pagination.current_page) ?
              this.pagination.next_page_url : this.pagination.prev_page_url;
          if(url) {// make api call
              this.isLoadingBlade = true
              this.getPaginatedPendingSignups(url).then((res) => {// get records
                  this.pagination.number = res.current_page;
                  this.setPages(res);
              }).
              catch(() => {
                  this.records = [];
                  this.showErrorStatus = true;
                  this.isLoadingBlade = false;
                  this.errorMsg = "Server Failure!, Kindly reload the page...";
                  this.errorType = 'danger';
              });
          } else {
              this.records = this.paginatedItems;
          }
      },
      emptyRecordsMsg() {
          return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
      },
      showEmptyMsg() {// show empty msg
          this.showErrorStatus = true;
          this.errorType = 'warning';
          this.errorMsg = 'No Pending Signups Yet!!!';
      },
      searchRecords() {
        this.showErrorStatus = false;
        let key = this.searchKey.toLowerCase();
        if(key != '' ) {
            this.isLoadingBlade = true;
            let data = { 'folio' : key, type : 'non_temp_doc' };
            this.records = [];
            this.getPendingSignups(data).then((res) => {// get records
                this.isLoadingBlade = false;
                this.records = res;
                if(this.records.length == 0) {
                    this.showErrorStatus = true;
                    this.errorType = 'warning';
                    this.errorMsg = 'No Matched Record!!!';
                }
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        } else {
            this.records = this.paginatedItems;
            this.emptyRecordsMsg();
        }
        if(key != '' ) {
            this.records = [];
            for(let item of this.paginatedItems) {
                let folio = item && item.basic_profile ? item.basic_profile.folio_number.toLowerCase() : null;
                let name = item && item.basic_profile ? this.getFullName(item.basic_profile) : null;
                let check = folio.includes(key) || name.includes(key);
                if(check) {// update records
                    this.records.push(item);
                }
            }
            if(this.records.length == 0) {
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Matched Record!!!';
            }
        } else {
            this.records = this.paginatedItems;
        }
      },
      getFullName(value) {
          let name = value.first_name+' ';
          name += value.other_name ? value.other_name+' ' : '';
          name += value.last_name;
          return name.toLowerCase();
      },
      updateSignup(id, type) {// update pending signups
            let data = { 'id' : id, 'type' : type, option : 'other' };
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeUpdateApiCall(data);// make api call 
                }
            });
        },
        makeUpdateApiCall(data) {
            this.preloader();// show loading
            this.updatePendingSignups(data).then((res) => {// get records
                if(res.status) {
                    swal.fire('Signup '+data.type+'!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadPendingSignups(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
      this.loadPendingSignups();// load pending signups
    }
  };
</script>
<style></style>
