
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade && ! showMembers && ! showAddUnit">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div
            class="container ct-example-row"
            v-if="! isLoadingBlade && ! showMembers && ! showAddUnit"
        >
            <div class="row">
                <div
                    class="col-sm-4 text-center borderLine"
                    v-for="unit in units"
                    :key="unit.id"
                >
                    <br>
                    <h4 class="text-capitalize height-50">
                        {{ unit.name }}
                    </h4>
                    <h6 class="text-capitalize">
                        <em>{{ unit.description }}</em>
                    </h6><br>
                    <br v-if="(unit.description == null || unit.description == '')">
                    <base-button
                        size="sm" type="primary"
                        @click="displayUnitMembers(unit)"
                        :disabled="(privileges && privileges.view == 'no') || ! privileges"
                    >
                        <i class="fa fa-eye"></i>
                        View Members
                    </base-button>
                    <base-button
                        size="sm" type="info"
                        @click="showNewUnit(true, 'Update', unit)"
                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                    >
                        <i class="fa fa-pen"></i>
                        Edit
                    </base-button>
                    <base-button
                        size="sm" type="danger"
                        @click="deleteUnit(unit.id)"
                        :disabled="(privileges && privileges.delete == 'no') || ! privileges || 
                            (unit.members && unit.members.length > 0)"
                    >
                        <i class="fa fa-trash"></i>
                        Delete
                    </base-button>
                    <br><br>
                </div>
            </div>
        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus && ! showMembers && ! showAddUnit"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <!-- import Members template here -->
        <UnitMembers
            v-bind:unit="unit"
            v-bind:privileges="privileges"
            v-bind:changeTitle="changeTitle"
            v-on:closeMembersPage="closeMembersPage"
            v-if="showMembers && ! showAddUnit"
        />

        <!-- import Add Department template here -->
        <AddUnit
            v-bind:unit="unit"
            v-bind:privileges="privileges"
            v-bind:showNewUnit="showNewUnit"
            v-bind:department="department"
            v-bind:loadDepartments="loadUnits"
            v-bind:justAddedUnits="loadUnits"
            v-bind:updateUnits="loadUnits"
            v-on:showNewUnit="showNewUnit"
            v-if="showAddUnit"
        />
    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import UnitMembers from './UnitMembers';
  import AddUnit from './AddUnit';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'Unit',
    props: ['privileges'],
    components: {
        UnitMembers,
        AddUnit
    },
    data() {
      return {
        units: [],
        isLoadingBlade: false,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        unit: null,
        showMembers: false,
        title: 'Units',
        showAddUnit: false,
        department: null
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl'])
    },
    methods: {
        ...mapActions(['getAdminUnits', 'deleteAdminUnit', 'fetchUserProfile']),
        checkUserIsHod(menu) {
            let isHod = this.user && this.user.is_hod == 'yes';// if user is HOD
            let UserMenu = menu.menu_id == 4;// get User Mgt Menu
            let status = (isHod && UserMenu) ? 'yes' : 'no';
            return  { 'view' : status, 'add' : status, 'update' : status, 'delete' : status };
        },
        loadUnits() {// load units
            this.isLoadingBlade = true;
            this.fetchUserProfile().then((res) => {// get records
                this.getUserUnits(res);
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        getUserUnits(user) {
            this.getAdminUnits().then((res) => {// get records
                this.setData(res, user);
                this.emptyRecordsMsg();
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setData(data, user) {
            let dept_id = user && user.unit ? user.unit.admin_department_id : null;
            let isSuper = (dept_id == 1);
            let isHod = (user && user.is_hod == 'yes');
            if(isSuper) {
                this.units = data;
            } else if(isHod) {
                for(let unit of data) {
                    if(unit.department.id == dept_id) {
                        this.units.push(unit);
                    }
                }
            }
            this.isLoadingBlade = false;
        },
        emptyRecordsMsg() {
            return this.units && this.units.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Units added yet!!!';
        },
        displayUnitMembers(data) {// show members
            this.unit = data;
            this.showMembers = true;
            this.title = data.name+'\'s Members'
        },
        closeMembersPage() {// hide members
            this.unit = null;
            this.title = 'Units'
            this.showMembers = false;
        },
        changeTitle(value) {// change title
            this.title = value;
        },
        showNewUnit(value, type, data) {// display new dept form
            let deptName = data && data.department ? data.department.name : null;
            let title = value ? type+' ' : 'Units';
            let formatTitle = data ? data.name+'\'s Unit' : 'New Unit to '+deptName+' Department';
            title +=  (formatTitle && type) ? formatTitle : '';
            this.changeTitle(title);
            this.unit = data;
            this.department = data && data.department ? data.department : null;
            return this.showAddUnit = value;
        },
        deleteUnit(id) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall(id);// make api call
                }
            });
        },
        makeDeleteApiCall(id) {
            let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
            this.preloader(); // show loading
            this.deleteAdminUnit(data).then((res) => {// delete department
                if(res.status) {
                    this.loadUnits();// refresh page
                    swal.fire("Record Deleted", res.msg, "success");
                } else {
                    this.loadUnits();// refresh page
                    swal.fire("Error Occurred!", res.msg, "warning");
                }
            }).
            catch(err => {
                this.loadUnits();// refresh page
                swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadUnits(); // load units
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
