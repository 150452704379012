<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <h3 class="text-uppercase"  v-if="! isLoadingBlade">Edit Professional Qualification Data</h3>

        <hr class="my-4" />

        <div
            class="row align-items-center"
            v-if="! isLoadingBlade"
        >

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Qualification Obtained"
                    placeholder="Enter Name of Qualification Obtained"
                    input-classes="form-control-alternative"
                    v-model="record.qualification"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Licensing Body"
                    placeholder="Enter Licensing Body"
                    input-classes="form-control-alternative"
                    v-model="record.licensing_body"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input
                    label="Start Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.start_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-6">
                <base-input
                    label="End Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="record.end_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-12">
                <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>
            </div>
            <div class="col-lg-12"></div>
            <div class="col-lg-12">
                <base-button
                    type="success"
                    class="right"
                    @click="saveQualRecords()"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Update Record'}}
                </base-button>

                <div>
                    <base-button
                        type="danger"
                        @click="editQualRecord(false)"
                    >
                        <i class="fa fa-times"></i>
                        Cancel Edit
                    </base-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            flatPicker
        },
        name: 'EditProfQualDetails',
        props: ['editQualRecord', 'applicant', 'loadAssessmentApplicant', 'res'],
        data() {
            return {
                record: {
                    qualification: null,
                    start_date: null,
                    end_date: null,
                    licensing_body: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    maxDate: new Date().fp_incr(1),
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData']),
            setData() {// set paginated function
                if(this.res) {
                    let data = this.res;
                    this.record = {
                        qualification: data.qualification,
                        start_date: data.start_date,
                        end_date: data.end_date,
                        licensing_body: data.licensing_body,
                        id: data.id
                    }
                    this.isLoadingBlade = false;
                }
            },
            saveQualRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save appointment
                    data.id = this.res.id
                    this.preloader();// show loading
                    let record = { 'qualification_data' : data , 'id' : this.applicant.id };
                    this.saveAssessmentApplicantData(record).then((res) => {
                        this.loadAssessmentApplicant();
                        this.isloading = false;
                        this.emptyRecords();
                        this.editQualRecord(false);
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", "Qualification record was added successfully", "success");
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.editQualRecord(false);
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                    this.emptyRecords();
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { qualification: null, start_date: null, end_date: null, licensing_body: null };
                appRecord.end_date = data.end_date == null ? this.showInuptError('End Date') : data.end_date;
                appRecord.start_date  = data.start_date == null ? this.showInuptError('Start Date') : data.start_date;
                appRecord.licensing_body  = data.licensing_body == null ? this.showInuptError('Licensing Body') : data.licensing_body;
                appRecord.qualification  = data.qualification == null ? this.showInuptError('Qualification Obtained') : data.qualification;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                this.record = {
                    qualification: null,
                    start_date: null,
                    end_date: null,
                    licensing_body: null
                };
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();// load edu records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>