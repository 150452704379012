
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div>
                <div class="container" v-if="! isLoadingBlade">
                    
                    <div class="row">
                        
                        <div class="col-lg-12">
                            <base-input alternative=""
                                label="Centre Name"
                                input-classes="form-control-alternative"
                                placeholder="Enter Centre Name"
                                v-model="record.name"
                                required
                            />
                        </div>

                        <div class="col-lg-12">
                            <base-input alternative=""
                                label="Centre Address">
                                <textarea
                                    rows="2"
                                    class="form-control form-control-alternative"
                                    placeholder="Enter Centre Address"
                                    v-model="record.address"></textarea>
                            </base-input>
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Centre Code (Note: To be used for Unique ID)"
                                input-classes="form-control-alternative"
                                placeholder="Enter Centre Code"
                                v-model="record.code"
                                required
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Centre City"
                                input-classes="form-control-alternative"
                                placeholder="Enter Centre City"
                                v-model="record.city"
                                required
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Centre State"
                                required
                            >
                                <autocomplete
                                    v-if="locations.states && locations.states.length > 0"
                                    input-class="form-control form-control-alternative"
                                    placeholder="Type to Search for Centre State"
                                    showNoResults
                                    :source="locations.states"
                                    v-model="record.state_id"
                                    :initialDisplay="(hasState ? hasState.name : '')"
                                >
                                </autocomplete>
                            </base-input>
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Training Type"
                                required
                            >
                                <select
                                    class="form-control form-control-alternative"
                                    v-model="record.training_type"
                                >
                                    <option value="null" disabled>--- Select One ---</option>
                                    <option value="medical">Medical Training</option>
                                    <option value="dental">Dental Training</option>
                                </select>
                            </base-input>
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Email Address"
                                input-classes="form-control-alternative"
                                placeholder="Enter Email Address"
                                v-model="record.email"
                                type="email"
                                required
                            />
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Password (Note: Initial Password)"
                                input-classes="form-control-alternative"
                                placeholder="Enter Password"
                                v-model="record.password"
                                :disabled="record.id"
                                type="password"
                                required
                            />
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Phone Number"
                                placeholder="Phone Number"
                                input-classes="form-control-alternative"
                                required
                            >
                            <!-- v-model="profile.phone"  -->
                                <VuePhoneNumberInput
                                    v-model="tel.phone"
                                    @update="updatePhoneNumber"
                                    :default-country-code="tel.code"
                                    :clearable="true"
                                />
                            </base-input>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <hr>
                            <h3 class="text-uppercase">Bank Information</h3>
                            <hr>
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Account Name"
                                input-classes="form-control-alternative"
                                placeholder="Enter Account Name"
                                v-model="record.bank_name"
                                required
                            />
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Account Number"
                                input-classes="form-control-alternative"
                                placeholder="Enter Account Number"
                                v-model="record.bank_no"
                                required
                            />
                        </div>

                    </div>
                </div>

                <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>

                <hr>
                <div class="pl-lg-4">
                    <base-button
                        type="success"
                        @click="addCentre()"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                    >
                        <i class="fa fa-save" @if='! isloading'></i>
                        {{isloading ? 'Please Wait' : (data ? 'Update' : 'Save')+' Centre'}}
                    </base-button>

                    <base-button
                        type="primary"
                        @click="resetPassword()"
                        :disabled="(privileges && privileges.update == 'no') || ! privileges || isloading"
                        v-if="record.id"
                    >
                        <i class="fa fa-save" @if='! isloading'></i>
                        {{isloading ? 'Please Wait' : 'Reset Password'}}
                    </base-button>

                    <base-button
                        type="danger"
                        class="right"
                        @click="displayAddPage(false)"
                    >
                        <i class="fa fa-times"></i>
                        Cancel
                    </base-button>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import Autocomplete from 'vuejs-auto-complete';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddAttachmentCentre',
        props: ['privileges', 'data', 'displayAddPage', 'changeTitle', 'hasState'],
        components: {
            Autocomplete,
            VuePhoneNumberInput
        },
        data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: false,
            record: {
                name: null,
                address : null,
                code: null,
                city: null,
                state_id: null,
                email: null,
                password: null,
                training_type: null,
                phone: null,
                phone_code: null,
                bank_name: null,
                bank_no: null
            },
            tel: {
                phone: null,
                code: 'NG',
            },
            isloading: false
        }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl', 'user', 'locations']),
            years() {
                let startYear = moment().format('YYYY') - 2;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['addAttachmentCentre', 'getLocations', 'resetAttachmentCentrePassword']),
            loadPhoneNumber(data) {// update phone no
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            setData() {
                this.emptyFields();
                let centre = this.data;
                if(centre && centre.id) {
                    this.record = {
                        id: centre.id,
                        name: centre.name,
                        address : centre.address,
                        code: centre.code,
                        city: centre.city,
                        state_id: centre.state_id,
                        email: centre.email,
                        training_type: centre.training_type,
                        phone: centre.phone,
                        phone_code: centre.phone_code,
                        bank_name: centre.bank_name,
                        bank_no: centre.bank_no
                    };
                    this.loadPhoneNumber(centre.phone_code);// load phone number
                }                
            },
            addCentre() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// add to sanction list
                    this.record.admin_id = this.user.id;// add admin id
                    this.addAttachmentCentre(this.record).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.emptyFields();
                            this.displayAddPage(false);
                            swal.fire("Centre Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    name: null,
                    address : null,
                    code: null,
                    city: null,
                    state_id: null,
                    email: null,
                    password: null,
                    training_type: null,
                    bank_name: null,
                    bank_no: null
                };
            },
            validatedData() {
                let data = this.record;
                let pass = true;
                if(! this.record.id && ((data.password == null) || (data.password == ''))) {// check for password
                    pass = false;
                    this.showInuptError('Password')
                }
                data.bank_no != null ? data.bank_no : this.showInuptError('Account Number');
                data.bank_name != null ? data.bank_name : this.showInuptError('Account Name');
                data.phone != null ? data.phone : this.showInuptError('Phone Number');
                data.email != null ? data.email : this.showInuptError('Email Address');
                data.training_type != null ? data.training_type : this.showInuptError('Training Type');
                data.state_id != null ? data.state_id : this.showInuptError('Centre State');
                data.city != null ? data.city : this.showInuptError('Centre City');
                data.code != null ? data.code : this.showInuptError('Centre Code');
                data.name != null ? data.name : this.showInuptError('Centre Name');
                let status = data.name && data.code && data.city && data.state_id && data.email && pass 
                                && data.training_type && data.phone && data.bank_name && data.bank_no;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            resetPassword() {// reset centre password
                if(this.record.id) {
                    this.isloading = true;
                    this.preloader();// show loading
                    let data = { id : this.record.id, admin_id : this.user.id };
                    this.resetAttachmentCentrePassword(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Password Reset!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        swal.fire(err.message+"!", "An error occurred while processing, try again", "warning");
                    });
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.getLocations();
            this.setData();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
</style>
<style scoped>
    .right {
        float: right;
    }
</style>