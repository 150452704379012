<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card shadow">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="applicants"
                        >
                            <template slot="columns">
                                <th width="2%">S/N</th>
                                <th width="10%">Applicant's Name</th>
                                <th width="10%">RRR</th>
                                <th width="10%">Training Type</th>
                                <th width="10%">Application Date</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ index+1 }}
                            </td>
                            <td class="text-capitalize">
                                {{ row.fullname }}
                            </td>
                            <td>{{ row.rrr }}</td>
                            <td class="text-capitalize">
                                {{ row.training_type ? (row.training_type + ' Training') : 'N/A' }}
                            </td>
                            <td>
                                {{ row.reg_date | getDateFormat }}
                            </td>
                            </template>
                        </base-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'PostingPageData',
        props: ["applicants"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
    }
</script>
<style scoped>
    .right {
        float: right !important;
    }
</style>