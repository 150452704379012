<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if="! isLoadingBlade">
            <div class="row" v-if="! showPostPage">
                <div class="col-lg-2">
                    <base-input alternative="">
                        <select
                            class="form-control form-control-alternative"
                            v-model="pagination.perPage"
                            @change="getPageItems()"
                        >
                            <option
                                v-for="number in entries"
                                :key="number"
                                :value="number"
                            > 
                                {{ number + ' - Entries'}}
                            </option>
                        </select>
                    </base-input>
                </div>
                <div class="col-lg-2">
                    <select
                        class="form-control form-control-alternative"
                        v-model="page"
                        @change="getPageItems()"
                    >
                        <option
                            v-for="number in pageNumbers"
                            :key="number"
                            :value="number"
                        >
                            {{ 'Page ' + number }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-6">
                    <!-- <base-input
                        placeholder="Search by RRR Number and hit enter"
                        class="input-group-alternative"
                        alternative=""
                        addon-right-icon="fas fa-search"
                        @change="searchRecords"
                        v-model="searchKey"
                        >
                    </base-input> -->

                    <button
                        :class="'right btn btn-lg btn-'+ (showFilters ? 'danger' : 'primary')"
                        :disabled="(privileges && privileges.view == 'no') || ! privileges"
                        @click="displayFilters()"
                    >
                        <i :class="'fa fa-'+(showFilters ? 'times' : 'sort')"></i>
                        {{ showFilters ? 'Remove Filters' : 'Filter List'}}
                    </button>


                </div>
            </div>
        </div>

        <div class="container" v-if="showFilters && ! isLoadingBlade">
                <hr>
                <h3 class="mb-0 text-uppercase text-center" v-html="filteredTitle"></h3>
                <hr v-if="! isFiltered">
                <div class="row" v-if="! isFiltered">
                    <div :class="'col-lg-'+ (isDental || ! showChoice ? 4 : 6)">
                        <base-input alternative=""
                            label="RRR"
                            placeholder="Enter RRR"
                            input-classes="form-control-alternative text-capitalize"
                            v-model="filters.rrr"
                            type="number"
                        />
                    </div> 
                    <div :class="'col-lg-'+ (isDental || ! showChoice ? 4 : 6)">
                        <base-input alternative=""
                            label="Attachment Program Centres"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.centre_id"
                                @change="updateChoice()"
                            >
                                <option :value="null" disabled>--- Select One ---</option>
                                <option 
                                    v-for="centre in centres"
                                    :key="centre.id"
                                    :value="centre.id"
                                >
                                    {{ centre.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div :class="'col-lg-'+ (isDental || ! showChoice ? 4 : 6)">
                        <base-input alternative=""
                            label="Training Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.training_type"
                                @change="updateChoice()"
                            >
                                <option :value="null" disabled>--- Select One ---</option>
                                <option value="medical">Medical Training</option>
                                <option value="dental">Dental Training</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-6" v-if="! isDental && showChoice">
                        <base-input alternative=""
                            label="Applicant's Choice"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.choice"
                            >
                                <option :value="null" disabled>--- Select One ---</option>
                                <option value="first_choice">First Choice</option>
                                <option value="second_choice">Second Choice</option>
                                <option value="third_choice">Third Choice</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-12">
                        <button
                            class="btn btn-lg btn-info btn-block"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            @click="applyFilters()"
                        >
                            <i class="fa fa-check"></i>
                            Apply Filters
                        </button>
                        <button
                            class="btn btn-lg btn-danger btn-block"
                            @click="displayFilters()"
                        >
                            <i class="fa fa-times"></i>
                            Remove Filters
                        </button>
                    </div>
                </div>
                <hr>
            </div>

        <!-- import records details template here -->
        <AwaitingPostingData
            :records="records"
            :pagination="pagination"
            :hasPagination="hasPagination"
            :paginate="paginate"
            :privileges="privileges"
            :paginatedItems="paginatedItems"
            :displayPostPage="displayPostPage"
            :showPostPage="showPostPage"
            :centres="centres"
            :displayBackButton="displayBackButton"
            :loadAplications="loadAplications"
            :preloader="preloader"
            :isPosted="isPosted"
            v-if="! isLoadingBlade && (! showFilters || isFiltered)"
        />

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import AwaitingPostingData from './AwaitingPostingData.vue';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'AwaitingPosting',
    props: ['batch', 'changeTitle', 'privileges', 'loadTitle', 'status', 'displayBackButton'],
    components: {
      AwaitingPostingData
    },
    data() {
      return {
        centres: [],
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: false,
        searchKey: '',
        page: 1,
        filters: {
            rrr: null,
            centre_id: null,
            choice: null,
            training_type: null,
            status: this.status,
            limit: this.$store.state.url.perPage // get per page count
        },
        isFiltered: false,
        showFilters: false,
        showChoice: false,
        showPostPage: false
      }
    },
    computed: {
        ...mapGetters(['loadingBladeUrl', 'menus']),
        hasPagination() {
          return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        },
        entries() {
            return [ 20, 30, 50 ];
        },
        isDental() {// check if dental is selected
            return (this.filters.training_type == 'dental');
        },
        isPosted() {// check for posted applicants template
            return this.status && (this.status == 'posted');
        }
    },
    methods: {
        ...mapActions(['getAttachmentApplications', 'getPaginatedExactApplications', 'filterAttachmentApplications']),
        displayPostPage(status) {// show Posting Page
            return this.showPostPage = status;
        },
        updateChoice() {// update choice
            this.showChoice = true;
            return this.filters.choice = null;
        },
        applyFilters() {// apply filters function
            let status = this.validFilterInputs();
            this.showErrorStatus = false;
            if(status) {
                this.preloader();// show loading
                let data = this.filters;
                this.filterAttachmentApplications(data).then((res) => {// get transactions
                    this.isLoadingBlade = false;
                    this.setPages(res.applicants);//set data
                    this.isFiltered = true;
                    this.getFilteredTitle();// set filtered title
                    swal.close();
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                let msg = 'Kindly select two of the filter actions, and try again';
                swal.fire('Invalid Selection', msg, 'warning');
            }
        },
        replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, 'g'), replace);
        },
        getFilteredTitle() {// set filtered payment title
            let data = this.filters;
            if(data.centre_id && this.centres.length > 0) {// get centre
                let centre = this.centres.filter(e => e.id == data.centre_id)[0];
                this.filteredTitle += `, Attachment Centres -> <em class="text-danger">${centre.name}</em>`;
            }
            this.filteredTitle += data.rrr ?  `, Applicant's RRR ->  <em class="text-danger">${data.rrr}</em>` : '';
            this.filteredTitle += data.training_type ?  `, Training Type -> 
                                    <em class="text-danger">${data.training_type} Training</em>` : '';
            this.filteredTitle += data.choice ?  `, Applicant's Choice -> 
                                <em class="text-danger">${this.replaceAll(data.choice, '_', ' ')}</em>` : '';
            this.filteredTitle += ' Applicants Only';
        },
        validFilterInputs() {
            let data = this.filters;
            return data.rrr || (data.centre_id && data.training_type || (data.type && ! this.isDental));
        },
        loadAplications() {
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            let data = {
                id: this.batch.id,
                status : this.status,
                limit : this.pagination.perPage
            }
            this.getAttachmentApplications(data).then((res) => {// get records
                this.setPages(res.applicants);
                this.centres = res.centres;// load attachment centres
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            let title = this.batch ? '<em class="text-danger">' + this.batch.name : '';
            title += this.batch ? ' Batch ' + this.batch.code : '';
            title += this.batch ? ' (' + this.batch.year + ')</em>' : '';
            title += ' Attachment Program Applications';
            this.changeTitle(title, true)
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedExactApplications(url).then((res) => {// get records
                    this.pagination.number = res.current_page;
                    this.setPages(res);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Applicants!!!';
        },
        searchRecords() {
            this.showErrorStatus = false;
            let key = this.searchKey.toUpperCase();
            if(key != '' ) {
                this.isLoadingBlade = true;
                let data = { id: this.batch.id, status : this.status, key : key };
                this.records = [];
                this.getAttachmentApplications(data).then((res) => {// get records
                    this.isLoadingBlade = false;
                    this.records = res.applicants;
                    if(this.records.length == 0) {
                        this.showErrorStatus = true;
                        this.errorType = 'warning';
                        this.errorMsg = 'No Matched Record!!!';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
                this.emptyRecordsMsg();
            }
        },
        getPageItems() {            
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {
                let exploded = url.split('&');
                let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
                this.isLoadingBlade = true
                this.getPaginatedExactApplications(newUrl).then((res) => {// get records
                    this.pagination.number = res.applicants.current_page;
                    this.setPages(res.applicants);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            }            
        },
        getFullName(value) {
            let name = value.first_name+' ';
            name += value.other_name ? value.other_name+' ' : '';
            name += value.last_name;
            return name.toLowerCase();
        },
        displayFilters() {// diplay filters template
            this.emptyFilters();// empty filters
            this.loadAplications();// reload payments
            return this.showFilters = ! this.showFilters;
        },
        emptyFilters() {// empty filters
            this.filters = {
                rrr: null,
                centre_id: null,
                choice: null,
                training_type: null,
                status: this.status,
                limit: this.pagination.perPage // get per page count
            };
            this.isFiltered = false;
            this.showChoice = false
            this.filteredTitle = 'Filtered Posting List';
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
      this.loadAplications();// load title
    }
  };
</script>
<style scoped>
    .right {
        float: right;
    }
</style>
