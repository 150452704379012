
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="20%">Payee's Name</th>
                        <th width="20%">Application Type</th>
                        <th width="10%">Amount (&#x20a6;)</th>
                        <th width="10%">RRR Number</th>
                        <th width="10%">Date Paid</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ getFullName(row) }}<br>
                            <badge type="primary">
                                {{ getInstitutionType(row) }}
                            </badge>
                        </td>
                        <td v-html="breakWord(getApplicationTypeName(row))" class="text-capitalize"></td>
                        <td>
                            &#x20a6; {{ row.amount | formatPrice }}
                        </td>
                        <td>
                            {{ row.payment_ref ? row.payment_ref : 'N/A' }}
                        </td>
                        <td>
                            {{ getExactDate(row) | formatDate }}
                        </td>
                    </template>
                </base-table>
            </div>

            <div class="col-lg-12" v-if="totalAmount">
                <hr>
                <div class="container">
                    <h3 class="text-uppercase">
                        total amount paid:
                        <span class="right">
                            &#x20a6; {{ totalAmount | formatPrice }}
                        </span>
                    </h3>
                </div>
                <hr>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
  import moment from 'moment';

  export default {
    name: 'MedicalEducationReportData',
    props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'totalAmount'],
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
        formatPrice(value) {
            if(value) {
                let val = (value/1).toFixed(2).replace(',', '.');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 'N/A';
            }
        }
    },
    methods: {
        getFullName(trans) {// get full name
            let record = this.getExactInstitution(trans);// get institution
            return record && record.status && record.institution ? record.institution.name : 'N/A';
        },
        getInstitutionType(row) {// get institution type
            let record = this.getExactInstitution(row);// get institution
            return record && record.status && record.type ? record.type : 'N/A';
        },
        getApplicationTypeName(row) {// get application name
            let record = this.getExactInstitution(row);// get institution
            let app = record && record.status && record.app ? record.app : false;
            if(app && app.request_type) {// for hospital & school
                return record.type + ' ' + app.request_type;
            } else if(app && app.type) {// fro cpd provider
                return record.type + ' ' + app.type;
            } else {
                return 'N/A';
            }
        },
        getExactInstitution(record) {// get payment exact institution
            let res = { institution: null, type: null, status: false, app : null };
            if(record && record.application) {// training hospital
                let hospital = record.application && record.application.training_hospital 
                                        ? record.application.training_hospital : false;
                res = { institution: hospital, type: 'Training Hospital', status: (hospital ? true : false), app : record.application };
            } else if(record && record.cpd_application) {// cpd provider
                let cpd = record.cpd_application && record.cpd_application.cpd ? record.cpd_application.cpd : false;
                res = { institution: cpd, type: 'CPD Provider', status: (cpd ? true : false), app : record.cpd_application };
            } else if(record && record.school_application) {// training school
                let school = record.school_application && record.school_application.training_school 
                                        ? record.school_application.training_school : false;
                res = { institution: school, type: 'Training School', status: (school ? true : false), app : record.school_application };
            } 
            return res;
        },
        getExactDate(record) {
            return record.date_paid ? record.date_paid : record.date_generated;
        }, 
        breakWord(word) {// break word into two parts
            let exploded = word.split(' ');
            let formedWord = '';
            let half = Math.ceil((exploded.length - 1)/2);
            for(let phrase of exploded) {
                formedWord += phrase+' ';
                if(exploded[half] == phrase) {
                    formedWord += ' <br> ';
                }
            }
            return formedWord;
        }
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
