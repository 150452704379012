<template>
    <div class="row">
        <div class="col-lg-12">
            <h2 class="text-uppercase text-center">
                Selected Centre's Information
            </h2>
            <hr>
            <table class="table table-bordered" v-if="centre">
                <tbody>
                    <td>
                        <h4>Centre Name</h4>
                    </td>
                    <td colspan="3">
                        <h4 class="text-capitalize font-weight-light">
                            {{ centre.name ? centre.name : 'N/A' }}
                        </h4>
                    </td>                    
                </tbody>
                <tbody>
                    <td>
                        <h4>Centre Quota</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ centre.centre_quota ? centre.centre_quota : 'NILL' }}
                        </h4>
                    </td>
                    <td>
                        <h4>Applicants Count</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ centre.applicants_count ? centre.applicants_count : 'NILL' }}
                        </h4>
                    </td>
                </tbody>
                <tbody>
                    <td>
                        <h4>Available Space</h4>
                    </td>
                    <td colspan="3">
                        <h4 class="text-capitalize font-weight-light">
                            {{ centre.available_space ? centre.available_space : 'NILL' }}
                        </h4>
                    </td>
                </tbody>
            </table>
        </div>

        <div class="col-lg-12 text-center">
            <br>
            <em class="text-danger" v-if="! disableButton">
                Note: The Applicants that will be posted to this Centre would not be more than the Available space,
                kindly check before posting...
            </em>

            <em class="text-danger" v-if="disableButton">
                Note: You can't post the below Applicants because the Available Space is less than the required number...
            </em>
        </div>
        <hr>
    </div>
</template>

<script>
    export default {
        name: 'SelectedCentreInfo',
        props: ["centre", "disableButton"]
    }
</script>
<style scoped>
    .right {
        float: right !important;
    }
</style>