
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-capitalize">{{ this.$route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <button
                                        class="btn btn-sm btn-danger"
                                    >
                                        <i class="fa fa-reply"></i> Back
                                    </button> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="row align-items-center" v-if="! isLoadingBlade">

                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="User's Name"
                                    placeholder="Enter User's Name"
                                    input-classes="form-control-alternative"
                                    v-model="record.name"
                                    required
                                />
                            </div>

                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="User's Email Address"
                                    placeholder="Enter User's Email Address"
                                    input-classes="form-control-alternative"
                                    v-model="record.email"
                                    :readonly="! isHodOrSuper"
                                />
                            </div>

                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="User's Phone Number"
                                    placeholder="Enter User's Phone Number"
                                    input-classes="form-control-alternative"
                                    v-model="record.phone"
                                    type="number"
                                />
                            </div>

                            <div class="col-lg-6">
                                <base-input alternative=""
                                    label="User's Gender"
                                    required
                                >
                                    <select
                                        class="form-control form-control-alternative"
                                        v-model="record.gender"
                                    >
                                        <option
                                            :value="null"
                                            disabled
                                        >
                                            --- Select One ---
                                        </option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </base-input>
                            </div>

                            <div
                                class="col-sm-12 text-center"
                                v-if='showErrorStatus'
                            >
                                <base-alert :type="errorType">
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{errorMsg}}
                                    </span>
                                </base-alert>
                            </div>

                            <hr class="my-4" />

                            <div class="col-lg-12">
                                <base-button
                                    type="success" size="lg"
                                    @click="updateProfile()"
                                >
                                    <i class="fa fa-save"></i>
                                    {{isloading ? 'Please Wait...' : 'Update Record'}}
                                </base-button>
                            </div>


                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'UserProfile',
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        record: {
            name: null,
            email: null,
            gender: null,
            phone: null,
            id: null,
            admin_id: null,
        },
        isloading: false,
        isLoadingBlade: true
      }
    },
    computed: {
        ...mapGetters(['user', 'loadingBladeUrl']),
        isHodOrSuper() {// check if user is HOD or Super Admin
            let isHod = this.user && this.user.is_hod == 'yes';// if user is HOD
            let isSuper = this.user && this.user.unit && this.user.unit.department
                        && this.user.unit.department.code == 'super-admin';
            return isHod || isSuper;
        }
    },
    methods: {
        ...mapActions(['createAdminUser', 'fetchUserProfile']),
        loadProfile() {
            this.isLoadingBlade = true;
            this.fetchUserProfile().then((res) => {// get records
                this.isLoadingBlade = false;
                this.setData(res);
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setData(user) {// set data for editing'
            if(user) {
                this.record.name = user.name;
                this.record.email = user.email;
                this.record.gender = user.gender;
                this.record.phone = user.phone;
                this.record.id = user.id;
                this.record.admin_id = user.id;
            }
        },
        updateProfile() {
            let data = this.record;
            if(data.id) {// save user
                data.update = true;// for update only
                this.preloader(); // show loading
                this.createAdminUser(data).then((res) => {// create user
                    if(res.status) {
                        this.setData();// refresh page
                        swal.fire("Account "+res.type, res.msg, "success");
                    } else {
                        this.setData();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.setData();// refresh page
                    swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                });
            }
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    created() {
        this.loadProfile();
    }
  };
</script>
<style scoped>
</style>
