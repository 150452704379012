
<template>
    <div>
        <div class="row align-items-center">

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Email Subject"
                    input-classes="form-control-alternative"
                    v-model="record.subject"
                    required
                />
            </div>

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Email Parameters"
                    input-classes="form-control-alternative"
                    v-model="email.params"
                    readonly
                />
            </div>

            <div class="col-lg-12">
                <h3 class="text-danger">
                    Note that the parameters above are not to be removed/modified from the email content section,
                    other characters can be modified
                </h3>
                <br>
            </div>

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Email Message Content">
                    <ckeditor
                        :editor="editor"
                        v-model="record.message"
                        :config="editorConfig"
                    ></ckeditor>
                </base-input>
            </div>

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="updateEmailRecord()"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Update Email'}}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('showEditPage', false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';

    export default {
        name: 'EditEmail',
        props: ['privileges', 'email', 'loadEmailItems', 'showEditPage'],
        components: {
            ckeditor: CKEditor.component
        },
        data() {
            return {
                record: {
                    subject: null,
                    message: null,
                    id: null,
                    admin_id: null
                },
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList' ],
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        methods: {
            ...mapActions(['updateEmail']),
            setData() {// set data for editing
                this.emptyEduRecords();
                let email = this.email;
                if(email) {
                    this.record.subject = email.subject;
                    this.record.message = email.message;
                    this.record.id = email.id;
                }
            },
            emptyEduRecords() {
                this.record.subject = null;
                this.record.message = null;
                this.record.id = null;
            },
            updateEmailRecord() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save department
                    let data = this.record;
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.updateEmail(data).then((res) => {// update email
                        if(res.status) {
                            this.showEditPage(false);// hide edit page
                            this.loadEmailItems();// refresh page
                            swal.fire("Email Updated", res.msg, "success");
                        } else {
                            this.loadEmailItems();// refresh page
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadEmailItems();// refresh page
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let email = { subject: null, message: null };
                email.message = data.message == null || data.message == '' ?
                                    this.showInuptError('Email Message') : data.message;
                email.subject = data.subject == null || data.subject == '' ?
                                    this.showInuptError('Email Subject') : data.subject;
                return this.isEmpty(email) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {// set records
            this.setData();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>