<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">Applicant's Name</th>
                            <th width="10%">RRR / Amount Paid</th>
                            <th width="10%">Practice Type</th>
                            <th width="10%">Application Date</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.fullname }}
                        </td>
                        <td>
                            {{ row.rrr }} <br>
                            <!-- <span v-if="row.transaction">{{ row.transaction.amount | formatPrice }}</span> -->
                            <badge
                                size="lg"
                                :type="hasPaidComplete(row)"
                                v-if="row.transaction"
                            >
                                {{ row.transaction.amount | formatPrice }}
                            </badge>
                        </td>
                        <td>{{ row.practice_type ? (row.practice_type + ' Practitioner') : 'N/A' }}</td>
                        <td>
                            {{ row.reg_date | getDateFormat }}
                        </td>
                        <td>
                            <router-link
                                :to="'/assessment-application/'+row.id"
                                :class="'btn btn-sm btn-success '+
                                    (((privileges && privileges.view == 'no') || ! privileges) ? 'disabled' : '')"
                            >
                                <i class="fa fa-eye"></i> Details
                            </router-link>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Badge from '../../../components/Badge.vue';

    export default {
  components: { Badge },
        name: 'PendingApplicationData',
        props: ["records", "pagination", "hasPagination", "paginate", "paginatedItems", "privileges"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }                
            }
        },
        methods: {
            hasPaidComplete(record) {// check if applicant has paid complete
                if(record && record.payment && record.transaction) {                    
                    let actualPayment = parseInt(record.payment.amount);
                    let amountPaid = parseInt(record.transaction.amount);
                    return amountPaid >= actualPayment ? 'success' : 'warning';
                }
                return 'warning';
            }
        }
    }
</script>