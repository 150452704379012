
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div v-if="! showPaymentPage">
                <div class="container" v-if="! isLoadingBlade">
                    <hr>
                    <div class="row">
                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="RRR"
                                input-classes="form-control-alternative"
                                placeholder="Enter RRR"
                                v-model="record.rrr"
                                type="number"
                                required
                            />
                        </div>

                        <div class="col-lg-6">
                            <base-input alternative=""
                                label="Assessment Batch"
                                required
                            >
                                <autocomplete
                                    v-if="batches && batches.length > 0"
                                    input-class="form-control form-control-alternative"
                                    placeholder="Type to Search Assessment Batch"
                                    showNoResults
                                    :source="batches"
                                    :results-display="formattedDisplay"
                                    v-model="record.assessment_batch_id"
                                >
                                </autocomplete>
                            </base-input>
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Service Charge Percentage (%)"
                                input-classes="form-control-alternative"
                                placeholder="Enter Service Charge Percentage"
                                type="number"
                                v-model="record.percentage"
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Amount Deducted"
                                input-classes="form-control-alternative"
                                placeholder="Enter Amount Deducted"
                                type="number"
                                v-model="record.amount"
                            />
                        </div>

                        <div class="col-lg-4">
                            <base-input alternative=""
                                label="Status" 
                                required
                            >
                                <select
                                    class="form-control form-control-alternative"
                                    v-model="record.status"
                                >
                                    <option :value="null" disabled selected>--- Select One ---</option>
                                    <option value="valid">Valid</option>
                                    <option value="used">Used</option>
                                </select>
                            </base-input>
                        </div>
                    </div>
                </div>

                <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>

                <hr>
                <div class="pl-lg-4">
                    <base-button
                        type="success"
                        @click="addBatch()"
                        :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                    >
                        <i class="fa fa-save" @if='! isloading'></i>
                        {{isloading ? 'Please Wait' : (data ? 'Update' : 'Add')+' Record'}}
                    </base-button>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'AddAssessmentPreviousBatch',
        props: ['privileges', 'data', 'displayAddPage', 'changeTitle', 'loadPreviousEntries', 'batches'],
        components: {
            Autocomplete
        },
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false,
                record: {
                    rrr: null,
                    percentage: null,
                    assessment_batch_id: null,
                    amount: null,
                    status: null
                },
                showPaymentPage: false,
                isloading: false
            }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
            years() {
                let startYear = moment().format('YYYY') - 2;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['addPreviousAssessmentEntry']),
            formattedDisplay (batch) {
                return batch.title + ' - ' + batch.description.toUpperCase() + ' (' + batch.year + ')';
            },
            setData() {
                this.emptyFields();
                let type = this.data;
                if(type && type.id) {
                    this.record = {
                        id: type.id,
                        rrr: type.rrr,
                        percentage: type.percentage,
                        amount: type.amount,
                        assessment_batch_id: type.assessment_batch_id,
                        status: type.status,
                    };
                }
            },
            addBatch() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// add to sanction list
                    this.record.admin_id = this.user.id;// add admin id
                    this.addPreviousAssessmentEntry(this.record).then((res) => {
                        this.loadPreviousEntries();
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.emptyFields();
                            this.displayAddPage(false);
                            swal.fire("Record Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadPreviousEntries();// refresh page
                        swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    rrr: null,
                    percentage: null,
                    assessment_batch_id: null
                };
            },
            validatedData() {
                let data = this.record;
                data.assessment_batch_id != null ? data.assessment_batch_id : this.showInuptError('Batch');
                data.percentage != null ? data.percentage : this.showInuptError('Service Charge Percentage');
                data.rrr != null ? data.rrr : this.showInuptError('RRR');
                let status = data.rrr && data.percentage && data.assessment_batch_id;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
</style>
