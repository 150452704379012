
<template>
    <div>
        <div class="pl-lg-4">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Countries">
                            <span slot="title">
                                <i class="ni ni-world"></i>
                                Countries
                            </span>
                            <!-- import Countries template here --> 
                            <Countries 
                                v-bind:privileges="privileges"
                            /> 
                        </tab-pane>

                        <tab-pane title="States">
                            <span slot="title">
                                <i class="ni ni-world-2"></i>
                                States
                            </span>
                            <!-- import States template here -->
                            <States 
                                v-bind:privileges="privileges"
                            /> 
                        </tab-pane>                   
                    </card>
                </tabs>
            </template>
        </div>
    </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Countries from './Countries'; 
  import States from './States';

  export default {
    name: 'CountriesStatesSettings',
    props: ['privileges'],
    components: {
        Countries,
        States
    },
    computed: {
        ...mapGetters(['menus'])
    }
  };
</script>
<style scoped>
</style>
