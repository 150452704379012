<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">School Profile Details</h3>
                <hr class="my-4" />
            </div>

            <div class="table-responsive">

                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>Name</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.name ? profile.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>RRR</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.transaction ? application.transaction.payment_ref : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Email Address</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.email ? profile.email : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Phone No</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.phone ? profile.phone : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>                        
                        <td>
                            <h4>Year of <br>establishment</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.establishment ? profile.establishment : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Request Type</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.request_type ? application.request_type : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Accreditation Type</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.accredit_status ? replaceAll(profile.accredit_status, '-', ' ') : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Location</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.location ? profile.location : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td v-if="! isAccreditation">
                            <h4>Date of First <br>Accreditation</h4>
                        </td>
                        <td v-if="! isAccreditation">
                            <h4 class="font-weight-light">
                                {{ application.first_accreditation | getDateFormat }}
                            </h4>
                        </td>
                        <td v-if="! isAccreditation">
                            <h4>Date of Last <br>Accreditation</h4>
                        </td>
                        <td v-if="! isAccreditation">
                            <h4 class="font-weight-light">
                                {{ application.last_accreditation | getDateFormat }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Type of Institution</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.school_type ? profile.school_type : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td colspan="2">
                            <h4>Additional Accreditation Notes</h4>
                        </td>
                        <td colspan="4">
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.additional_notes ? application.additional_notes : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td colspan="3">
                            <h4>Training Curriculum and Method of Implementation</h4>
                        </td>
                        <td colspan="3">
                            <base-button
                                tag="a" size="sm"
                                type="success"
                                :href="fileUrl"
                                target="_blank"
                                role="button"
                                aria-pressed="true"
                                v-if="application.has_file"
                            >
                                <i class="fa fa-eye"></i> View File
                            </base-button>
                            <badge type="danger" class="text-uppercase" v-if="! application.has_file">
                                No File Uploaded
                            </badge>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'ProfileDetails',
        props: [ 'application' ],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['schoolPhotoUrl']),
            profile() {
                return this.application ? this.application.training_school : {};
            },
            fileUrl() {
                return this.schoolPhotoUrl+this.profile.id+'/'+this.application.id+'_credentials.pdf';
            },
            isAccreditation() {
                return this.application && (this.application.request_type == 'accreditation');
            }
        },
        methods: {
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>