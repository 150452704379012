
<template>
    <div>

        <div class="container">
            <div class="row">

                <div class="col-lg-12">
                    <h3 class="mb-0 text-uppercase">Payment Details</h3>
                    <hr class="my-4" />
                </div>

                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <th>Transaction Number</th>
                            <th>Amount</th>
                            <th>Transaction Date</th>
                            <th>RRR</th>
                            <th>Payment Status</th>
                        </thead>
                        <tbody>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ transaction.trans_no }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="font-weight-light">
                                    {{ transaction.amount | formatPrice }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="text-lowercase font-weight-light">
                                    {{ getTransactionDate(transaction) | formatDate }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ transaction.payment_ref }}
                                </h4>
                            </td>
                            <td>
                                <h4 class="text-capitalize font-weight-light">
                                    {{ transaction.status }}
                                </h4>
                            </td>
                        </tbody>
                    </table>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>

            </div>
        </div>

    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'PaymentDetails',
        props: ['transaction'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        methods: {
            getTransactionDate(trans) {// get trans date
                return trans.date_paid ? trans.date_paid: trans.date_generated;
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
