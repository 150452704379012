
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-if="applicant">
                                        <em class="text-danger">
                                            {{ this.applicant.personal_data.fullname }}
                                        </em>
                                        's Assessment Application Details
                                    </h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/assessment/assessment-applications"
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-arrow-left"></i>
                                        Go Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if='! showErrorStatus && ! isLoadingBlade'>

                            <div v-if="! approvalPage">
                                <!-- This is personal Details template here -->
                                <PersonalDetails
                                    :applicant="applicant"
                                    :rrr="applicant.rrr"
                                    :photo="applicant.photo"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :breakWord="breakWord"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.personal_data
                                            && (! showEditPage || showEditPageText == 'personal')"
                                />

                                <!-- This is educational Details template here -->
                                <EduDetails
                                    :applicant="applicant"
                                    :records="applicant.edu_data"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :breakWord="breakWord"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.edu_data
                                            && (! showEditPage || showEditPageText == 'edu')"
                                />

                                <!-- This is Prof Qualification Details template here -->
                                <ProfQualDetails
                                    :applicant="applicant"
                                    :records="applicant.qualification_data"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :breakWord="breakWord"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.qualification_data
                                            && (! showEditPage || showEditPageText == 'qual')"
                                />

                                <!-- This is Additional Details template here -->
                                <AdditionalDetails
                                    :applicant="applicant"
                                    :record="applicant.additional_data"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :breakWord="breakWord"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.additional_data
                                            && (! showEditPage || showEditPageText == 'additional')"
                                />

                                <!-- This is Employment Details template here -->
                                <EmploymentDetails
                                    :applicant="applicant"
                                    :records="applicant.employment_data"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :breakWord="breakWord"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.additional_data && applicant.additional_data.employ_status == 'yes'
                                            && applicant.employment_data && (! showEditPage || showEditPageText == 'employ')"
                                />

                                <!-- This is previous examination details template here -->
                                <ExaminationDetails
                                    :applicant="applicant"
                                    :records="applicant.examination_data"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :hospitals="hospitals"
                                    :breakWord="breakWord"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.additional_data && applicant.additional_data.exam_taken == 'yes'
                                            && applicant.examination_data && (! showEditPage || showEditPageText == 'exam')"
                                />

                                <!-- This is Institution (Remedial course) details template here -->
                                <InstitutionDetails
                                    :applicant="applicant"
                                    :records="applicant.institution_data"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :breakWord="breakWord"
                                    :hospitals="hospitals"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.additional_data && applicant.additional_data.remedial_course == 'yes'
                                            && applicant.institution_data && (! showEditPage || showEditPageText == 'institution')"
                                />

                                <!-- This is Referee details template here -->
                                <RefereeDetails
                                    :applicant="applicant"
                                    :records="applicant.referee_data"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :breakWord="breakWord"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.referee_data && (! showEditPage || showEditPageText == 'referee')"
                                />

                                <!-- This is documents template here -->
                                <Documents
                                    :applicant="applicant"
                                    :records="applicant.files"
                                    :privileges="privileges"
                                    :editPage="editPage"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-if="applicant && applicant.files && (! showEditPage || showEditPageText == 'documents')"
                                />

                                <!-- This is payment Details template here -->
                                <PaymentDetails
                                    :transaction="applicant.transaction"
                                    v-if="applicant && applicant.transaction && ! showEditPage"
                                />


                                <div v-if="(applicant.status == 'submitted') && ! showEditPage">
                                    <base-button
                                        type="danger" size="lg"
                                        @click="showApprovalPage('reject', true)"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-ban"></i> Reject Application
                                    </base-button>

                                    <base-button
                                        type="success" size="lg"
                                        class="right"
                                        @click="showApprovalPage('approve', true)"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-check"></i> Approve Application
                                    </base-button>
                                </div>
                            </div>

                            <div v-if="approvalPage">
                                <!-- Import Approve Application template here -->
                                <ApproveApplication
                                    :applicant="applicant"
                                    :privileges="privileges"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-on:showApprovalPage="showApprovalPage"
                                    v-if="pageType == 'approve'"
                                />

                                <!-- Import Reject Application template here -->
                                <RejectApplication
                                    :applicant="applicant"
                                    :privileges="privileges"
                                    :loadAssessmentApplicant="loadAssessmentApplicant"
                                    v-on:showApprovalPage="showApprovalPage"
                                    v-if="pageType == 'reject'"
                                />
                            </div>

                        </div>


                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-sm-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import PersonalDetails from './AssessmentPreview/PersonalDetails';
  import EduDetails from './AssessmentPreview/EduDetails';
  import ProfQualDetails from './AssessmentPreview/ProfQualDetails';
  import EmploymentDetails from './AssessmentPreview/EmploymentDetails';
  import AdditionalDetails from './AssessmentPreview/AdditionalDetails';
  import ExaminationDetails from './AssessmentPreview/ExaminationDetails';
  import InstitutionDetails from './AssessmentPreview/InstitutionDetails';
  import RefereeDetails from './AssessmentPreview/RefereeDetails';
  import RejectApplication from './AssessmentPreview/RejectApplication';
  import ApproveApplication from './AssessmentPreview/ApproveApplication';
  import Documents from './AssessmentPreview/Documents';
  import PaymentDetails from './AssessmentPreview/PaymentDetails.vue';

  export default {
    name: 'AssessmentApplicationDetails',
    components: {
        PersonalDetails,
        EduDetails,
        ProfQualDetails,
        EmploymentDetails,
        AdditionalDetails,
        ExaminationDetails,
        InstitutionDetails,
        RefereeDetails,
        Documents,
        PaymentDetails,
        RejectApplication,
        ApproveApplication
    },
    data() {
      return {
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        applicant: null,
        approvalPage: false,
        pageType: null,
        showEditPage: false,
        showEditPageText: null,
        hospitals: []
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == '/assessment/assessment-applications') {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        }
    },
    methods: {
        ...mapActions(['getAssessmentApplicant']),
        loadAssessmentApplicant() {// load payments
            if(this.id) {
                this.approvalPage = false;
                this.isLoadingBlade = true;
                this.showErrorStatus = false;
                this.getAssessmentApplicant(this.id).then((res) => {
                    this.isLoadingBlade = false;
                    this.applicant = res.applicant;
                    this.hospitals = res.hospitals;
                    this.loadTitle(); // load title
                }).
                catch(() => {
                    this.applicant = null;
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            }
        },
        editPage(status, text) {
            this.showEditPageText = null;
            this.showEditPage = status;
            this.showEditPageText = text;
        },
        showApprovalPage(type = null, status = false) {// show approval/reject page
            this.approvalPage = status;
            this.pageType = status ? type : null;
        },
        loadTitle() {// load title
            return this.title = '';
        },
        replaceString(value) {
            return value ? value.replace('-', ' ') : 'Assessment';
        },
        changeTitle(value, status) {// change title
            return status ? this.title = value : this.loadTitle();
        },
        breakWord(word, length = 40) {// break word into two parts
            if(word) {
                let textLength = word.length;
                let exploded = word.split(' ');
                if(word && textLength < length) {
                    return word;
                } else if(word) {
                    let formedWord = '';
                    let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                    for(let phrase of exploded) {
                        formedWord += phrase+' ';
                        if(exploded[half] == phrase) {
                            formedWord += ' <br> ';
                        }
                    }
                    return formedWord;
                }
            }
            return 'N/A';
        }
    },
    created() {
        this.loadAssessmentApplicant(); // load applicant
    }
  };
</script>
<style scoped>
    .right {
        float: right;
    }
</style>
