
<template>
    <div>
        <div class="container">
            <div class="row">

                <div class="col-lg-2">
                    <h3 class="mb-0 text-uppercase">Basic Details</h3>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>

                <div class="col-lg-8" v-if="application"> 

                    <h3>
                        Application Date - &nbsp;&nbsp;&nbsp;
                        {{ application.application_date | formatDate }}
                    </h3>

                    <h3>
                        Application Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm"
                            :type="getStatusColor(application.status)"
                        >
                            {{ application.status }}
                        </badge>
                    </h3>

                    <h3>
                        Payment Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm"
                            :type="getPaymentStatusColor(application.payment_status)"
                        >
                            {{ application.payment_status }}
                        </badge>
                    </h3>

                    <h3 v-if="application.training_school">
                        Account Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm"
                            :type="getAccountStatusColor(application.training_school.status)"
                        >
                            {{ application.training_school.status }}
                        </badge>
                    </h3>

                    <h3 v-if="application.training_school">
                        Type of School - &nbsp;&nbsp;&nbsp;
                        <span class="text-capitalize">
                            {{ application.training_school.type }} School
                        </span>
                    </h3>

                    <h3 v-if="application.training_school && application.training_school.accredit_status">
                        Accreditation Type - &nbsp;&nbsp;&nbsp;
                        <span class="text-capitalize">
                            {{ replaceAll(application.training_school.accredit_status, '-', ' ') }}
                        </span>
                    </h3>

                    <h3 v-if="(application.status == 'approved') || (application.status == 'rejected')">
                        <span class="text-capitalize">{{ application.status }}</span> Date - &nbsp;&nbsp;&nbsp;
                        {{ application.approved_date | formatDate }}
                    </h3>

                    <h3 v-if="(application.status == 'rejected')">
                        <hr>
                        Reject Reason - &nbsp;&nbsp;&nbsp;
                        <em class="text-danger">{{ application.reject_reason }}</em>
                    </h3>

                    <h3 v-if="(application.visit_status == 'yes')">
                        Verification Visit Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm" type="success">
                            {{ application.visit_status }}
                        </badge>
                    </h3>

                    <h3 v-if="(application.visit_status == 'yes')">
                        <hr>
                        Verification Visit Comment - &nbsp;&nbsp;&nbsp;
                        <em class="text-danger">{{ application.visit_comment }}</em>
                    </h3>

                    <h3 
                        v-if="(application.status == 'approved') && application.letter 
                                && application.letter.accredit_status"
                    >
                        <hr>
                        Accreditation Status - &nbsp;&nbsp;&nbsp;
                        <em class="text-danger text-capitalize">
                            {{ replaceAll(application.letter.accredit_status, '-', ' ') }} 
                        </em>
                    </h3>

                    <h3 v-if="application.notify_date" class="text-capitalize">
                        Fixed Visitation Date - &nbsp;&nbsp;&nbsp;
                        {{ application.notify_date | formatDate }}
                    </h3>

                    <h3 v-if="application.notify_date && application.notify_comment" class="text-capitalize">
                        Visitation Additional Comment - &nbsp;&nbsp;&nbsp;
                        <em class="small">{{ application.notify_comment }}</em> 
                    </h3>
                    <br>

                    <base-button
                        size="lg"
                        type="primary"
                        @click="$emit('displayVisitationDatePage', true)"
                        v-if="(application.status == 'submitted')"
                    >
                        <i class="fa fa-bullhorn"></i>
                        {{ application.notify_date ? 'Change' : 'Notify' }} Visitation Date
                    </base-button>
                    

                    <form
                        :action="getRootUrl+'/print-school-letter'"
                        method="POST"
                        target="_blank"
                        v-if="(application.status == 'approved') && application.letter"
                    >
                        <input type="hidden" name="id" :value="application.id" />
                        <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                        <input type="hidden" name="user_id" :value="user.id" />
                        <input type="hidden" name="option" value="admin" />
                        <input type="hidden" name="date" :value="application.approved_date" />
                        <input type="hidden" name="ref_no" :value="application.letter.ref_no" />
                        <!-- <input type="hidden" name="accredit_status" :value="application.letter.accredit_status" /> -->
                        <input 
                            v-if="isAccreditation" 
                            type="hidden" 
                            name="accredit_status" 
                            :value="application.letter.accredit_status" 
                        />
                        <input 
                            v-if="! isAccreditation" 
                            type="hidden" 
                            name="accredit_status" 
                            :value="application.request_type" 
                        />
                        <input type="hidden" name="plenary" :value="application.letter.plenary" />
                        <input type="hidden" name="plenary_date" :value="application.letter.plenary_date" />
                        <input type="hidden" name="plenary_venue" :value="application.letter.plenary_venue" />
                        <input type="hidden" name="visit_date" :value="application.letter.visit_date" />
                        <input type="hidden" name="quota" :value="application.letter.quota" />

                        <hr>
                        <base-button
                            type="primary"
                            class="btn btn-lg"
                            nativeType="submit"
                        >
                            <i class="fa fa-print"></i>
                            Print Letter
                        </base-button>
                    </form>

                    <br>

                </div>

                <div class="col-lg-4">
                    <div class="card-profile-image marginTop40">
                        <img
                            :src="getSchoolPix(application.training_school)"
                            class="rounded-circle"
                            @error="replaceByDefault"
                        >
                    </div>
                    <br><br><br><br><br>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'BasicDetails',
        props: ['application', 'privileges', 'loadApplication', 'isAccreditation'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['schoolPhotoUrl', 'blankImageUrl', 'getRootUrl', 'getAuthorizationToken', 'user'])
        },
        methods: {
            getStatusColor(value) {
                if(value == 'approved') {
                    return 'success';
                } else if(value == 'pending') {
                    return 'warning';
                } else {
                    return 'danger'
                }
            },
            getPaymentStatusColor(value) {
                return (value == 'paid') ? 'success' : 'danger';
            },
            getAccountStatusColor(value) {
                return (value == 'enable') ? 'success' : 'danger';
            },
            getSchoolPix(school) {// get user photo url
                return school ? this.schoolPhotoUrl+school.id+'/photo/'+school.photo : null;
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
    .right {
        float: right;
    }
    .marginLeft5 {
        margin-left: 5px;
    }
</style>
