<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Hospital's Name</th>
                            <th>Quota</th>
                            <th>Status</th>
                            <th>Expiry Date</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.name ? row.name : 'N/A' }}
                        </td>
                        <td>{{ row.quota ? row.quota : 'N/A' }}</td>
                        <td>
                            <badge size="lg"
                                :type="((row.status == 'active') ? 'success' : 'danger')"
                                class="text-uppercase"
                            >
                                {{ row.status }}
                            </badge>
                        </td>
                        <td>{{ row.expiry_date | getDateFormat }}</td>
                        <td>
                            <base-button
                                type="info" size="sm"
                                @click="$emit('editRecord', row, true)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </base-button>

                            <base-button
                                type="danger" size="sm"
                                @click="removeHospital(row.id)"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                            >
                                <i class="fa fa-trash"></i>
                                Delete
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';

    export default {
        name: 'HospitalsData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "paginatedItems", "loadHospitals"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            ...mapActions(['deleteAccreditedHospital']),
            removeHospital(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRemoveApiCall(id);// make api call
                    }
                });
            },
            makeRemoveApiCall(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
                this.preloader(); // show loading
                this.deleteAccreditedHospital(data).then((res) => {// delete country
                    if(res.status) {
                        this.loadHospitals();// refresh page
                        swal.fire("Hospital Deleted", res.msg, "success");
                    } else {
                        this.loadHospitals();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadHospitals();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>