
<template>
    <div>
        <div class="row">
            <div class="col-xl-12 order-xl-1">
                <template>
                    <tabs fill class="flex-column flex-md-row">
                        <card shadow>
                            <tab-pane title="Pending Applications">
                                <span slot="title">
                                    <i class="ni ni-books"></i>
                                    Pending Applications
                                </span>
                                <!-- import Pending Applications template here -->
                                <PendingApplications 
                                    :visitStatus="'no'"
                                />
                            </tab-pane>

                            <tab-pane title="Verification Visit">
                                <span slot="title">
                                    <i class="ni ni-archive-2"></i>
                                    Verification Visit
                                </span>
                                <!-- import Verification Visit template here -->
                                <PendingApplications 
                                    :visitStatus="'yes'"
                                />
                            </tab-pane>

                            <tab-pane title="Rejected Applications">
                                <span slot="title">
                                    <i class="ni ni-badge"></i>
                                    Rejected Applications
                                </span>
                                <!-- import Rejected Applications template here -->
                                <RejectedApplications 
                                    :status="'rejected'"
                                />
                            </tab-pane>

                            <tab-pane title="Completed Applications">
                                <span slot="title">
                                    <i class="ni ni-archive-2"></i>
                                    Completed Applications
                                </span>
                                <!-- import Completed Applications template here -->
                                <CompletedApplications />
                            </tab-pane>

                            <tab-pane title="Incomplete Applications">
                                <span slot="title">
                                    <i class="ni ni-badge"></i>
                                    Incomplete Applications
                                </span>
                                <!-- import Rejected Applications template here -->
                                <RejectedApplications 
                                    :status="'pending'"
                                />
                            </tab-pane>

                        </card>
                    </tabs>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
    import PendingApplications from './Applications/PendingApplications';
    import RejectedApplications from './Applications/RejectedApplications';
    import CompletedApplications from './Applications/CompletedApplications';

    export default {
        name: 'Applications',
        props: ['changeTitle', 'privileges'],
        components: {
            PendingApplications,
            RejectedApplications,
            CompletedApplications
        },
        methods: {
            makeTitle() {
                let title = 'Training Hospital Applications';
                return this.changeTitle(title, true);
            }
        },
        created() {
            this.makeTitle();
        }
    };
</script>
<style></style>
