
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-capitalize">{{ title }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="container" v-if="! isLoadingBlade">
                            <div class="row">
                                <div class="col-lg-12 text-right">
                                    <button
                                        class="btn btn-lg btn-primary"
                                        @click="displayAddPage(true)"
                                        :disabled="privileges && privileges.add == 'no' || ! privileges"
                                        v-if="! showAddPage"
                                    >
                                        <i class="fa fa-plus"></i>
                                        Add To List
                                    </button>
                                    <button
                                        class="btn btn-lg btn-danger"
                                        @click="displayAddPage(false)"
                                        v-if="showAddPage"
                                    >
                                        <i class="fa fa-reply"></i>
                                        Go back
                                    </button>
                                    <br><br>
                                </div>
                            </div>

                            <div class="row" v-if="! showAddPage && ! showErrorStatus">
                                <div class="col-lg-2">
                                    <base-input alternative="">
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="page"
                                            @change="getPageItems()"
                                        >
                                            <option
                                                v-for="number in pageNumbers"
                                                :key="number"
                                                :value="number"
                                            >
                                                {{ number }}
                                            </option>
                                        </select>
                                    </base-input>
                                </div>
                                <div class="col-lg-4"></div>
                                <div class="col-lg-6 text-right">
                                    <base-input
                                        placeholder="Search by Name and hit enter"
                                        class="input-group-alternative"
                                        alternative=""
                                        addon-right-icon="fas fa-search"
                                        @change="searchRecords"
                                        v-model="searchKey"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>

                        <!-- import Sanctions Data template here -->
                        <QuacksData
                            v-bind:records="records"
                            v-bind:hasPagination="hasPagination"
                            v-bind:pagination="pagination"
                            v-bind:paginate="paginate"
                            v-bind:privileges="privileges"
                            :searchKey="searchKey"
                            v-on:removeQuack="removeQuack"
                            v-on:editQuack="editQuack"
                            v-if="! showAddPage && ! isLoadingBlade"
                        />

                        <div
                            class="col-sm-12 text-center"
                            v-if="showErrorStatus && ! showAddPage && ! isLoadingBlade"
                        >
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                        <!-- import Add Quacks template here -->
                        <AddQuacks
                            :privileges="privileges"
                            :data="record"
                            :locations="locations"
                            :displayAddPage="displayAddPage"
                            v-if="showAddPage && ! isLoadingBlade"
                        />


                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import AddQuacks from './AddQuacks';
    import QuacksData from './QuacksData';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'Quacks',
        components: {
            AddQuacks,
            QuacksData
        },
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                records: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.url.perPage // get per page count
                },
                page: 1,
                title: '',
                showAddPage: false,
                searchKey: null,
                record: null
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'menus', 'locations']),
            privileges() {
            let privileges = null;
            if(this.menus && this.menus.length > 0) {
                for(let menu of this.menus) {
                    if(menu.has_child && menu.has_child.length > 0) {
                        for(let child of menu.has_child) {
                            if('/'+child.link == this.$route.path) {
                                privileges = child.privileges;
                            }
                        }
                    }
                }
            }
            return privileges;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            },
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
        },
        methods: {
            ...mapActions(['getQuacks', 'removeQuackFromList', 'getPaginatedPaymentItems', 'getSearchedQuacks', 'getLocations']),
            loadQuacks() {// load details
                let data = { 'limit' : this.pagination.perPage };
                this.isLoadingBlade = true;
                this.showErrorStatus = false;
                this.getQuacks(data).then((res) => {// get users
                    this.isLoadingBlade = false;
                    this.loadTitle(); // load title
                    this.setPages(res.quacks);//set data
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            editQuack(data) {
                this.record = data;
                let title = 'Edit '+ data.fullname +'\'s Quack Details';
                this.changeTitle(title, true);
                this.showAddPage = true;
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedPaymentItems(url).then((res) => {// get users
                        this.pagination.number = res.quacks.current_page;
                        this.setPages(res.quacks);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            getPageItems() {
                let url = this.pagination.next_page_url ?
                            this.pagination.next_page_url : this.pagination.prev_page_url;
                let exploded = url.split('&');
                let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                    this.pagination.number = res.quacks.current_page;
                    this.setPages(res.quacks);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Quacks recorded yet!!!';
            },
            displayAddPage(value) {
                let title = 'Add A Quack';
                this.changeTitle(title, value);
                if(! value) {
                    this.record = null
                    this.loadQuacks();
                }
                return this.showAddPage = value;
            },
            loadTitle() {// load title
                return this.title = this.$route.name;
            },
            changeTitle(value, status) {// change title
                return status ? this.title = value : this.loadTitle();
            },
            searchRecords() {
                this.showErrorStatus = false;
                let key = this.searchKey.toLowerCase();
                if(key) {
                    this.isLoadingBlade = true;
                    let data = { 'key' : key };
                    this.records = [];
                    this.getSearchedQuacks(data).then((res) => {// get records
                        this.isLoadingBlade = false;
                        this.records = res;
                        if(this.records.length == 0) {
                            this.showErrorStatus = true;
                            this.errorType = 'warning';
                            this.errorMsg = 'No Matched Record!!!';
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                    this.emptyRecordsMsg();
                }
            },
            removeQuack(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRemoveApiCall(id);// make api call
                    }
                });
            },
            makeRemoveApiCall(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
                this.preloader(); // show loading
                this.removeQuackFromList(data).then((res) => {// delete country
                    if(res.status) {
                        this.loadQuacks();// refresh page
                        swal.fire("Quack Removed", res.msg, "success");
                    } else {
                        this.loadQuacks();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadQuacks();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadQuacks();
            this.getLocations();
        }
    };
</script>
<style></style>
