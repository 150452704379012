
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0" v-html="title"></h3>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-12" v-if="isLoadingBlade && ! showAddPage">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="container" v-if="! isLoadingBlade">
                            <div class="row">
                                <div class="col-lg-12 text-right">
                                    <button
                                        class="btn btn-lg btn-primary"
                                        @click="displayAddPage(true)"
                                        v-if="! showAddPage && ! showAddOfflinePage"
                                        :disabled="(privileges && privileges.add == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-plus"></i>
                                        Add Online Doctors To List
                                    </button>

                                    <button
                                        class="btn btn-lg btn-info"
                                        @click="displayAddOfflinePage(true)"
                                        v-if="! showAddPage && ! showAddOfflinePage"
                                        :disabled="(privileges && privileges.add == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-plus"></i>
                                        Add Offline Doctors To List
                                    </button>

                                    <button
                                        class="btn btn-lg btn-danger"
                                        @click="displayAddPage(false)"
                                        v-if="showbackButton"
                                    >
                                        <i class="fa fa-reply"></i>
                                        Go back
                                    </button>
                                    <br><br>
                                </div>
                            </div>

                            <div class="row" v-if="! showAddPage && ! showErrorStatus && ! showAddOfflinePage">
                                <div class="col-lg-2">
                                    <base-input alternative="" v-show="! searchKey">
                                        <select
                                            class="form-control form-control-alternative"
                                            v-model="page"
                                            @change="getPageItems()"
                                        >
                                            <option
                                                v-for="number in pageNumbers"
                                                :key="number"
                                                :value="number"
                                            >
                                                {{ number }}
                                            </option>
                                        </select>
                                    </base-input>
                                </div>
                                <div class="col-lg-4"></div>
                                <div class="col-lg-6 text-right">
                                    <base-input
                                        placeholder="Search by Name/Folio Number and hit enter"
                                        class="input-group-alternative"
                                        alternative=""
                                        addon-right-icon="fas fa-search"
                                        @change="searchRecords"
                                        v-model="searchKey"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>

                        <!-- import Exit Register Data template here -->
                        <ExitRegisterData
                            v-bind:records="records"
                            v-bind:hasPagination="hasPagination"
                            v-bind:pagination="pagination"
                            v-bind:paginate="paginate"
                            v-bind:privileges="privileges"
                            :searchKey="searchKey"
                            :getFullName="getFullName"
                            v-on:removeExitRegister="removeExitRegister"
                            v-on:editRecord="editRecord"
                            v-if="! showAddPage && ! showAddOfflinePage && ! isLoadingBlade"
                        />

                        <div
                            class="col-sm-12 text-center"
                            v-if="showErrorStatus && ! showAddPage && ! showAddOfflinePage && ! isLoadingBlade"
                        >
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    <!-- import Add to Exit Register template here -->
                    <AddExitRegister
                        v-bind:privileges="privileges"
                        v-if="showAddPage && ! showAddOfflinePage && ! isLoadingBlade"
                    />

                    <!-- import Add Offline Exit Register template here -->
                    <AddOfflineExitRegister
                        :privileges="privileges"
                        :data="record"
                        :locations="locations"
                        v-if="! showAddPage && showAddOfflinePage && ! isLoadingBlade"
                    />

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import ExitRegisterData from './ExitRegisterData';
    import AddExitRegister from './AddExitRegister';
    import AddOfflineExitRegister from './AddOfflineExitRegister';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ExitRegister',
        components: {
            ExitRegisterData,
            AddExitRegister,
            AddOfflineExitRegister
        },
        data() {
            return {
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                records: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.url.perPage // get per page count
                },
                page: 1,
                searchKey: null,
                showAddPage: false,
                showAddOfflinePage: false,
                showbackButton: false,
                title: null,
                record: null
            }
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl', 'locations']),
            privileges() {
            let privileges = null;
            if(this.menus && this.menus.length > 0) {
                for(let menu of this.menus) {
                    if(menu.has_child && menu.has_child.length > 0) {
                        for(let child of menu.has_child) {
                            if('/'+child.link == this.$route.path) {
                                privileges = child.privileges;
                            }
                        }
                    }
                }
            }
            return privileges;
            },
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            }
        },
        methods: {
            ...mapActions(['getExitRegister', 'getPaginatedPaymentItems', 'searchExitRegister', 'removeUserExitRegister',
                'getLocations']),
            editRecord(record) {
                this.record = record;
                this.title = 'Edit <em class="text-danger text-capitalize">'+ this.getFullName(record) + '</em>\'s Details';
                this.showbackButton = true;
                this.showAddPage = false;
                return this.showAddOfflinePage = true;
            },
            getFullName(data) {// get full name
                let user = data && data.user_data ? data.user_data : false;
                if(user) {
                    let name = user.first_name+' ';
                    name += user.other_name ? user.other_name+' ' : '';
                    name += user.last_name+' ';
                    return name.toLowerCase();
                } else {
                    return 'N/A';
                }
            },
            loadTitle() {// load title
                return this.title = this.$route.name;
            },
            displayAddPage(value) {
                this.record = null;
                let title = 'Add Online Doctors to Exit Register';
                value ? this.title = title : this.loadExitRegister();
                this.showbackButton = value;
                this.showAddOfflinePage = false;
                return this.showAddPage = value;
            },
            displayAddOfflinePage(value) {
                this.record = null;
                let title = 'Add Offline Doctors to Exit Register';
                value ? this.title = title : this.loadExitRegister();
                this.showbackButton = value;
                this.showAddPage = false;
                return this.showAddOfflinePage = value;
            },
            loadExitRegister() {// load details
                this.loadTitle();// load title
                let data = { 'limit' : this.pagination.perPage };
                this.isLoadingBlade = true;
                this.showErrorStatus = false;
                this.getExitRegister(data).then((res) => {// get users
                    this.isLoadingBlade = false;
                    this.setPages(res);//set data
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedPaymentItems(url).then((res) => {// get users
                        this.pagination.number = res.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Record on Exit Register yet!!!';
            },
            getPageItems() {
                let url = this.pagination.next_page_url ?
                            this.pagination.next_page_url : this.pagination.prev_page_url;
                let exploded = url.split('&');
                let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                    this.pagination.number = res.current_page;
                    this.setPages(res);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            searchRecords() {
                this.showErrorStatus = false;
                let key = this.searchKey.toLowerCase();
                if(key) {
                    this.isLoadingBlade = true;
                    let data = { 'key' : key };
                    this.records = [];
                    this.searchExitRegister(data).then((res) => {// get records
                        this.isLoadingBlade = false;
                        this.records = res;
                        if(this.records.length == 0) {
                            this.showErrorStatus = true;
                            this.errorType = 'warning';
                            this.errorMsg = 'No Matched Record!!!';
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                    this.emptyRecordsMsg();
                }
            },
            removeExitRegister(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRemoveApiCall(id);// make api call
                    }
                });
            },
            makeRemoveApiCall(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
                this.preloader(); // show loading
                this.removeUserExitRegister(data).then((res) => {// delete country
                    if(res.status) {
                        this.loadExitRegister();// refresh page
                        swal.fire("User Removed", res.msg, "success");
                    } else {
                        this.loadExitRegister();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadExitRegister();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadExitRegister();
            this.getLocations();
        }
    };
</script>
<style scoped>
</style>
