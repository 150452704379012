
<template>
        <div class="table-responsive">

            <table class="table table-bordered">

                <tbody>
                    <td width="30%">
                        <h4>Registration Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-1 paddingLeft0">
                                    <h4 class="text-uppercase font-weight-light paddingTop13">
                                        {{ application.user_data.reg_prefix }} -
                                    </h4>
                                </div>
                                <div class="col-sm-5 paddingRight0">
                                    <base-input
                                        placeholder="Enter Registration Number"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="application.user_data.registration_no"
                                        @keyup="displaySaveButton()"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="application.user_data.registration_no == '' ||
                                application.user_data.registration_no == null"
                        >
                            Kindly enter a valid Registration Number,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody>

                <!-- <tbody>
                    <td width="30%">
                        <h4>CTC Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-1 paddingLeft0">
                                    <h4 class="text-uppercase font-weight-light paddingTop13">
                                        CTC -
                                    </h4>
                                </div>
                                <div class="col-sm-5 paddingRight0">
                                    <base-input
                                        placeholder="Enter AQ Number"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="application.has_good_standing.ctc_no"
                                        @keyup="displaySaveButton()"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                        <span
                            class="text-danger"
                            v-if="application.has_good_standing.ctc_no == '' ||
                                application.has_good_standing.ctc_no == null"
                        >
                            Kindly enter a valid CTC Number,
                            so approval button can be available for you.
                        </span>
                    </td>
                </tbody> -->

                <tbody v-if="! isProvisionalLogs">
                    <td width="30%">
                        <h4>Full Registration Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required
                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: true}"
                                            class="form-control datepicker"
                                            v-model="application.has_good_standing.full_reg_date"
                                            @input="displaySaveButton()"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="! isProvisionalLogs && ! isFullWithoutLogs">
                    <td width="30%">
                        <h4>Provisional Registration Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        placeholder="Enter Provisional Registration Number"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="application.has_good_standing.prov_reg_number"
                                        @keyup="displaySaveButton()"
                                        >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="! isFullWithoutLogs">
                    <td width="30%">
                        <h4>Provisional Registration Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required
                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: true}"
                                            class="form-control datepicker"
                                            v-model="application.has_good_standing.prov_reg_date"
                                            @input="displaySaveButton()"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="showApproveButton">
                    <td width="30%">
                        <h4>Certificates Generated:</h4>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-5">
                                <form :action="printCertificateUrl" method="POST" target="_blank">
                                    <input type="hidden" name="id" :value="application.id" />
                                    <input
                                        type="hidden" name="cert_id"
                                        :value="application.certificate.id"
                                    />
                                    <input
                                        type="hidden" name="admin_id"
                                        :value="user.id"
                                    />

                                    <input
                                        type="hidden" name="valid_date"
                                        :value="null"
                                    />

                                    <base-button
                                        size="sm"
                                        type="info"
                                        nativeType="submit"
                                        :disabled="privileges && privileges.view == 'no'"
                                    >
                                        <i class="fa fa-print"></i>
                                        {{ getCertificateName(application.certificate) }}
                                        <badge size="sm"
                                            type="primary"
                                            class="text-dark"
                                        >
                                            {{ getCountry(application) }}
                                        </badge>
                                    </base-button>
                                </form><br>

                                <form :action="printCertificateUrl" method="POST" target="_blank">
                                    <input type="hidden" name="id" :value="application.id" />
                                    <input
                                        type="hidden" name="cert_id"
                                        :value="application.certificate.id"
                                    />
                                    <input
                                        type="hidden" name="admin_id"
                                        :value="user.id"
                                    />

                                    <input
                                        type="hidden" name="logs_admin"
                                        value="yes"
                                    />

                                    <base-button
                                        size="sm"
                                        type="info"
                                        nativeType="submit"
                                        :disabled="privileges && privileges.view == 'no'"
                                    >
                                        <i class="fa fa-print"></i>
                                        {{ getCertificateName(application.certificate) }}
                                        <badge size="sm"
                                            type="primary"
                                            class="text-dark"
                                        >
                                            {{ getCountry(application) }} (ADMIN)
                                        </badge>
                                    </base-button>
                                </form>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody v-if="showSaveButton || showApproveButton || showBackButton">
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="primary"
                            :disabled="privileges && privileges.update == 'no'"
                            @click="updateProfile()"
                            v-if="showSaveButton && ! showBackButton"
                        >
                            <i class="fa fa-save"></i>
                            Save Changes
                        </base-button>

                        <base-button
                            size="lg"
                            type="success"
                            :disabled="privileges && privileges.update == 'no'"
                            @click="approveUserApplication(application.id)"
                            v-if="! showSaveButton && ! showBackButton && showApproveButton"
                        >
                            <i class="fa fa-check"></i>
                            Approve Application
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton && ! showSaveButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Approval
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'GoodStandingApproval',
    props: ['application', 'privileges', 'loadApplication'],
    components: {
        flatPicker
    },
    data() {
      return {
        code: this.$route.params.code,
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false,
        showSaveButton: true,
      }
    },
    computed: {
        ...mapGetters(['user', 'printCertificateUrl']),
        showApproveButton() {
            return this.showSaveButton ? false : true;
        },
        userRegType() {
            let user = this.application && this.application.user_data ? this.application.user_data : false;
            let sub_reg = user && user.has_sub_registration_type ? user.has_sub_registration_type : false;
            let reg_type = sub_reg && sub_reg.registration_type ? sub_reg.registration_type : false;
            return (user && sub_reg && reg_type) ? reg_type : false;
        },
        isProvisionalLogs() {// check if is logs for provisional 
            return this.userRegType && (this.userRegType.code == 'provisional-reg') ? true : false;
            // return this.application && this.application.has_good_standing
            //     && this.application.has_good_standing.prov_reg_number == null
            //     && this.application.has_good_standing.prov_reg_date != null;
        },
        isFullWithoutLogs() {// check if is logs for full without provisional
            return this.userRegType && (this.userRegType.code == 'full-without-provisional-reg') ? true : false;
            // return this.application && this.application.has_good_standing
            //     && this.application.has_good_standing.prov_reg_number == null
            //     && this.application.has_good_standing.prov_reg_date == null;
        }
    },
    methods: {
      ...mapActions(['approveApplication', 'updateUserData']),
        getFullName(profile) {
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.last_name;
            return name;
        },
        getCertificateName(type) {
            return type.title ? type.title : 'N/A';
        },
        getCountry(app) {// get destination country
            return app && app.has_good_standing ? app.has_good_standing.country : 'N/A';
        },
        updateProfile() {// update user data
            let data = {
                'id' : this.application.user_data_id,
                'log_id' : this.application.has_good_standing.id,
                'registration_no' : this.application.user_data.registration_no,
                // 'ctc_no' : this.application.has_good_standing.ctc_no,
                'full_reg_date' : this.application.has_good_standing.full_reg_date,
                'prov_reg_number' : this.application.has_good_standing.prov_reg_number,
                'prov_reg_date' : this.application.has_good_standing.prov_reg_date
            };
            if(this.isProvisionalLogs) {// if logs for provisional
                delete data.full_reg_date;// delete full reg date
                delete data.prov_reg_number;// delete provisional reg no
            } else if(this.isFullWithoutLogs) {// if logs for full without provisional
                delete data.prov_reg_date;// delete provisional reg date
                delete data.prov_reg_number;// delete provisional reg no
            }
            let check = this.validatedInputs(data); // validate
            if(check) {
                this.preloader();// show loading
                this.updateUserData(data).then((res) => {// get records
                    if(res.status) {
                        this.showSaveButton = false
                        swal.fire('Profile Updated!', res.msg, 'success');
                        this.loadApplication(); // reload page
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                }).
                catch(() => {
                    let msg = 'Server Failure!, Kindly reload the page...';
                    swal.fire('Error Occurred!', msg, 'error');
                });
            }
        },
        validatedInputs(data) {
            let record = { registration_no: null, full_reg_date: null };
            record.full_reg_date = (data.full_reg_date == null || data.full_reg_date == '0000-00-00') &&
                ! this.isProvisionalLogs ? this.showInuptError('Full Reg. Date') : data.full_reg_date;
            // record.ctc_no = (data.ctc_no == null || data.ctc_no == '') ?
            //     this.showInuptError('AQ Number') : data.ctc_no;
            record.registration_no = (data.registration_no == null || data.registration_no == '') ?
                this.showInuptError('Registration Number') : data.registration_no;
            if(this.isProvisionalLogs) {// if logs for provisional
                delete record.full_reg_date;// delete full reg date
            }
            return this.isEmpty(record) ? true : false;
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        approveUserApplication(id) {// approve application
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeApproveApiCall(id);// make api call
                }
            });
        },
        makeApproveApiCall(id) {// approve application
            let data = { 'id' : id, 'valid_date' : null, 'status' : 'approved',
                        'admin_id' : this.user.id, 'license_year' : null };
            this.preloader();// show loading
            this.approveApplication(data).then((res) => {// approve application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        displaySaveButton() {
            this.showSaveButton = true;
        },
        isEmpty(obj) {// check for empty field
            return ! Object.keys(obj).some(k => !obj[k]);
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
    .right {
    float: right;
    }
</style>
