
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="row align-items-center" v-if="! isLoadingBlade">

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Subject"
                    placeholder="Enter Title"
                    input-classes="form-control-alternative"
                    v-model="record.subject"
                    required
                />
            </div>

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Parameter's Content"
                    row="5"
                >
                    <ckeditor
                        :editor="editor"
                        v-model="record.message"
                        :config="editorConfig"
                    ></ckeditor>
                </base-input>
            </div>

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="saveRecord()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : button}}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('addNotification', false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import CKEditor from '@ckeditor/ckeditor5-vue';

    export default {
        name: 'AddNotification',
        props: ['privileges', 'loadNotifications', 'data', 'addNotification', 'saveNotification'],
        components: {
            ckeditor: CKEditor.component
        },
        data() {
            return {
                record: {
                    subject: null,
                    message: null,
                    type: 'general',
                    id: null
                },
                states: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList' ],
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            button() {
                return (this.record.id ? 'Update' : 'Send') + ' Notification';
            }
        },
        methods: {
            setData() {// set data for editing
                this.emptyRecords();
                let res = this.data;
                if(res) {
                    this.record.subject = res.subject;
                    this.record.message = res.message;
                    this.record.id = res.id;
                }
                this.isLoadingBlade = false;
            },
            emptyRecords() {
                this.record.subject = null;
                this.record.message = null;
                this.record.type = 'general';
                this.record.id = null;
            },
            saveRecord() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save notification
                    return this.saveNotification(this.record);
                }
            },
            validatedInputs() {
                let data = this.record;
                let record = { subject: null, message: null };
                record.message = data.message == null ? this.showInuptError('Message') : data.message;
                record.subject = data.subject == null ? this.showInuptError('Subject') : data.subject;
                return this.isEmpty(record) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            }
        },
        created() {
            this.setData();// load data
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>
<style>
    .ck-content {
        height: 300px;
    }
</style>