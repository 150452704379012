
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold" v-if="! noPagination">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>

            <small class="font-weight-bold" v-if="noPagination">
                Showing 1 - {{ records.length }} of {{ records.length }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">School's Name</th>
                        <th width="10%">City</th>
                        <th width="10%">Country</th>
                        <th width="10%">Quota</th>
                        <th width="15%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            <span v-html="breakWord(row.school_name)"></span>
                        </td>
                        <td class="text-capitalize">
                            {{ row.city ? row.city : 'N/A' }} <br>
                            <badge type="primary" v-if="getExcatState(row)">
                                {{ getExcatState(row) }}
                            </badge>
                        </td>
                        <td class="text-capitalize">{{ row.country ? row.country : 'N/A' }}</td>
                        <td class="text-capitalize">{{ row.quota ? row.quota : 'N/A' }}</td>
                        <td>
                            <button
                                class="btn btn-sm btn-primary"
                                @click="$emit('editSchool', row)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </button>

                            <button
                                class="btn btn-sm btn-danger"
                                @click="$emit('deleteSchool', row.id)"
                                :disabled="! isHodOrSuper"
                            >
                                <i class="fa fa-ban"></i>
                                Delete
                            </button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination && ! noPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'ForeignSchoolsData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'loadSchools', 'noPagination'],
        computed: {
            ...mapGetters(['user', 'locations']),
            isHodOrSuper() {// check if user is HOD or Super Admin
                let isHod = this.user && this.user.is_hod == 'yes';// if user is HOD
                let isSuper = this.user && this.user.unit && this.user.unit.department
                            && this.user.unit.department.code == 'super-admin';
                return isHod || isSuper;
            }
        },
        methods: {
            ...mapActions(['getLocations']),
            getExcatState(record) {
                if(record.state_id) {
                    let state = this.locations.states.filter(e => e.id == record.state_id);
                    return state && state.length > 0 ? state[0].name : false;
                }
                return false;
            },
            getColor(status) {// get color
                return (status && status == 1) ? 'success' : 'danger';
            },
            breakWord(word, length = 30) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            }
        },
        created() {
            this.getLocations();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
