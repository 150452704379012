
<template>
    <div>
        <div class="row align-items-center">

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Department Name"
                    placeholder="Enter Department Name"
                    input-classes="form-control-alternative"
                    v-model="record.name"
                    @keyup="updateDeptCode()"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Department Code"
                    placeholder="Department Code"
                    input-classes="form-control-alternative"
                    v-model="record.code"
                    readonly
                />
            </div>

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="saveDepartmentRecords()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : isEdit + ' Record'}}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('showNewDepartment', false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddDepartment',
        props: ['privileges', 'loadDepartments', 'showNewDepartment', 'department'],
        data() {
            return {
                record: {
                    name: null,
                    code: null,
                    id: null,
                    admin_id: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        computed: {
            isEdit() {
                return this.record && this.record.id ? 'Update' : 'Add';
            }
        },
        methods: {
            ...mapActions(['saveDepartment']),
            setData() {// set data for editing
                let dept = this.department;
                if(dept) {
                    this.record.name = dept.name;
                    this.record.code = dept.code;
                    this.record.id = dept.id;
                }
            },
            emptyEduRecords() {
                this.record.name = null;
                this.record.code = null;
            },
            updateDeptCode() {// format code from name
                let text = this.record.name ? this.record.name.toLowerCase() : this.record.name;
                let formatted = this.replaceAll(text, ' ', '-');
                let code = this.replaceAll(formatted, '&', '');
                return this.record.code = code;
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
            },
            escapeRegExp(str) {// eslint-disable-next-line
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
            },
            saveDepartmentRecords() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save department
                    let data = this.record;
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.saveDepartment(data).then((res) => {// update department
                        if(res.status) {
                            this.showNewDepartment(false);// hide edit page
                            this.loadDepartments();// refresh page
                            swal.fire("Record "+res.type, res.msg, "success");
                        } else {
                            this.loadDepartments();// refresh page
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadDepartments();// refresh page
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let dept = { name: null };
                dept.name = data.name == null ? this.showInuptError('Department\'s Name') : data.name;
                return this.isEmpty(dept) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {// set records
            this.setData();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>