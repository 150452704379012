
<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-10">
                                    <h3 class="mb-0 text-uppercase" v-if="application && application.training_school">
                                        {{ application.training_school.type }} School
                                        <em class="text-danger">{{ application.type }}</em>
                                        Application from
                                        <em class="text-danger">
                                            {{ application.training_school.name }}
                                        </em>
                                    </h3>
                                </div>
                                <div class="col-2 text-right">
                                    <a href="javascript:void(0)" @click="$router.go(-1)" class="btn btn-sm btn-danger">
                                      <i class="fa fa-reply"></i> Back
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && ! approvalPage && application && ! showVisitationDatePage">

                            <!-- Import basic details template here -->
                            <BasicDetails
                                :application="application"
                                :loadApplication="loadApplication"
                                :privileges="privileges"
                                :isAccreditation="isAccreditation"
                                v-on:displayVisitationDatePage="displayVisitationDatePage"
                            />

                            <div v-if="isAccreditation">
                                <!-- Import Profile details template here -->
                                <ProfileDetails
                                    :application="application"
                                />

                                <!-- Import Doctor details template here -->
                                <DoctorDetails
                                    :records="application.training_school.doctors"
                                    :application="application"
                                    :privileges="privileges"
                                    v-if="application.training_school && application.training_school.doctors"
                                />

                                <!-- Import staff details template here -->
                                <StaffDetails
                                    :records="application.training_school.staff"
                                    v-if="application.training_school && application.training_school.staff"
                                />

                                <!-- Import facility details template here -->
                                <FacilityDetails
                                    :records="application.training_school.facilities"
                                    v-if="application.training_school && application.training_school.facilities"
                                />
                            </div>

                            <!-- Import Profile details template here -->
                            <AdvisoryProfileDetails
                                :application="application"
                                v-if="! isAccreditation"
                            />


                          <!-- Import transaction details template here -->
                          <Transaction
                            :transaction="application.transaction"
                            v-if="application.transaction"
                          />

                          <div v-if="application.status == 'submitted'">
                            <base-button
                                type="danger" size="lg"
                                @click="showApprovalPage('reject')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-ban"></i> 
                                Reject {{ (application.visit_status == 'yes') ? 'Visit' : '' }}
                            </base-button>

                            <base-button
                                type="success" size="lg"
                                class="right"
                                @click="showApprovalPage('approve')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-check"></i> Approve
                            </base-button>
                          </div>

                        </div>

                        <NotificationDate 
                            :application="application"
                            :privileges="privileges"
                            :loadApplication="loadApplication"
                            v-on:displayVisitationDatePage="displayVisitationDatePage"
                            v-if="showVisitationDatePage"
                        />

                        <div v-if="approvalPage && ! showVisitationDatePage">

                          <!-- Import Approve Application template here -->
                          <ApproveApplication
                            :application="application"
                            :privileges="privileges"
                            :loadApplication="loadApplication"
                            :hideApprovePage="hideApprovePage"
                            v-on:hideApprovePage="hideApprovePage"
                            :isAccreditation="isAccreditation"
                            v-if="pageType == 'approve'"
                          />

                          <!-- Import Reject Application template here -->
                          <RejectApplication
                            :application="application"
                            :privileges="privileges"
                            :loadApplication="loadApplication"
                            v-on:hideApprovePage="hideApprovePage"
                            v-if="pageType == 'reject'"
                          />

                        </div>

                        <div class="col-sm-12 text-center" v-if="showErrorStatus && ! showVisitationDatePage">
                          <base-alert :type="errorType">
                              <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                              <span class="alert-inner--text">
                                  {{errorMsg}}
                              </span>
                          </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import BasicDetails from './BasicDetails';
    import ProfileDetails from './ProfileDetails';
    import DoctorDetails from './DoctorDetails';
    import StaffDetails from './StaffDetails';
    import FacilityDetails from './FacilityDetails';
    import Transaction from './Transaction';
    import RejectApplication from './RejectApplication';
    import ApproveApplication from './ApproveApplication';
    import AdvisoryProfileDetails from './AdvisoryProfileDetails.vue';
    import NotificationDate from './NotificationDate.vue';
    import moment from 'moment';

    export default {
        name: 'ApplicationDetail',
        components: {
            BasicDetails,
            ProfileDetails,
            DoctorDetails,
            StaffDetails,
            FacilityDetails,
            Transaction,
            RejectApplication,
            ApproveApplication,
            AdvisoryProfileDetails,
            NotificationDate
        },
        data() {
            return {
                id: this.$route.params.id,
                application: {},
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                approvalPage: false,
                pageType: null,
                showVisitationDatePage: false
            }
        },
        filters: {
            getDateFormat(value) {
                if(value) {
                return moment(value).format('Do MMM, YYYY');
                } else {
                return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'menus']),
            privileges() {
                let privileges = null;
                if(this.menus && this.menus.length > 0) {
                for(let menu of this.menus) {
                    if(menu.has_child && menu.has_child.length > 0) {
                        for(let child of menu.has_child) {
                            if(('/'+child.link == '/training-schools/medical-school-accreditation-applications') ||
                                ('/'+child.link == '/training-schools/dental-school-accreditation-applications') ||
                                ('/'+child.link == '/training-schools/medical-schools-advisory-visit-applications') ||
                                ('/'+child.link == '/training-schools/dental-schools-advisory-visit-applications')) {
                                privileges = child.privileges;
                            }
                        }
                    }
                }
                }
                return privileges;
            },
            isAccreditation() {
                return this.application && this.application.type == 'accreditation';
            }
        },
        methods: {
            ...mapActions(['getSchoolApplication']),
            displayVisitationDatePage(status) {
                return this.showVisitationDatePage = status;
            },
            loadApplication() {// load Application
                this.getSchoolApplication({id : this.id}).then((res) => {// get records
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.application = res.application;
                    } else {
                        this.showErrorStatus = true;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            showApprovalPage(type) {// show approval/reject page
                this.approvalPage = true;
                this.pageType = type;
            },
            hideApprovePage() {// hide approve page
                this.approvalPage = false;
                this.pageType = null;
            }
        },
        mounted() {
            this.loadApplication();// load Application
        }
    };
</script>
<style scoped>
.right {
  float: right;
}
</style>
