
<template>
    <div>

        <div class="row">
            <div class="col-lg-12">
                <base-button
                    class="float-right"
                    type="success"
                    size="sm"
                    @click="displayNewRecord()"
                >
                    <i class="fa fa-plus-circle"></i>
                    Add New Record
                </base-button>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="col-lg-12" v-if="! showErrorStatus && ! showEditPage">
                <div class="card shadow">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="records"
                        >
                            <template slot="columns">
                                <th width="2%">S/N</th>
                                <th width="10%">Beneficiary Name</th>
                                <th width="20%">Trans No</th>
                                <th width="10%">Status</th>
                                <th width="10%">Policy No</th>
                                <th width="10%">Expiry Date</th>
                                <th width="5%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ index+1}}
                            </td>
                            <td>
                                {{row.beneficiary_name }}
                            </td>
                            <td>
                                {{(row.trans_no)}}
                            </td>
                            <td>
                                <badge :type="showStatus(row.trans_status)">
                                    {{ row.trans_status }}
                                </badge>
                            </td>
                            <td>
                                {{row.policy_no ? row.policy_no : 'N/A'}}
                            </td>
                            <td>
                                {{ row.expiry_date | getDateFormat }}
                            </td>
                            <td>
                                <base-button
                                    type="info" size="sm"
                                    @click="editRecord(row)"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-pen"></i> Edit
                                </base-button>
                            </td>

                            </template>

                        </base-table>
                    </div>

                </div>
            </div>


        </div>


        <UpdateInsurance
            v-bind:newRecord="newRecord"
            v-bind:privileges="privileges"
            v-bind:userId="record.id"
            v-bind:loadInsuranceRecord="loadInsuranceRecord"
            v-bind:closeEditPage="closeEditPage"
            v-on:closeEditPage="closeEditPage"
            v-if="showEditPage"
        />

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus && ! showEditPage'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <hr class="my-4" />

        <div class="col-lg-12">
            <base-button
                type="danger" size="lg"
                class="right"
                @click="$emit('hideInsuranceData')"
                :disabled="privileges && privileges.view == 'no'"
            >
                <i class="fa fa-times"></i>
                Go Back
            </base-button>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import UpdateInsurance from './UpdateInsurance';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'MedicalSchool',
        props: ['record','privileges'],
        components: {
            UpdateInsurance
        },
        data() {
            return {
                records: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showEditPage: false,
                newRecord: {},
                isLoadingBlade: true
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            getDateTimeFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY [at] h:mm:ss a') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getUserInsuranceRecords']),
            loadInsuranceRecord() {// load details
                this.showErrorStatus = false;
                this.getUserInsuranceRecords({'id' : this.record.id}).then((res) => {// get Insurance records
                    this.isLoadingBlade = false;
                    this.records = res;
                    this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Insurance Record Yet!!!';
            },
            editRecord(record) {// show edit page
                this.newRecord = record;
                this.showEditPage = true;
            },
            displayNewRecord() {// show new record
                this.newRecord = null;
                this.showEditPage = true;
            },
            closeEditPage() {// hide edit page
                this.newRecord = null;
                this.showEditPage = false;
            },
            showStatus(value) {
                if(value == 'PAID') {
                    return 'success';
                } else if(value == 'PENDING') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadInsuranceRecord();// load add qual records
        },
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>