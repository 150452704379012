<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <h3 class="text-uppercase">Edit Additional Data</h3>
            <hr>
            <div class="row">
                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Professional Experiences"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Professional Experiences including details"
                            v-model="record.professional_experiences"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Practice Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.practice_type"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="m">Medical Practitioner</option>
                            <option value="d">Dental Practitioner</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Employment Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.employ_status"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="showEmploymentSector">
                    <base-input alternative=""
                        label="Employment Sector"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.employ_sector"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="government">Government</option>
                            <option value="private">Private</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="showEmploymentSector">
                    <base-input alternative=""
                        label="Employment Country"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.country_id"
                        >
                            <option :value="null" disabled>---Select One---</option>
                            <option
                                v-for="country in locations.countries"
                                :key="country.id"
                                :value="country.id"
                            >
                                {{country.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="record.country_id && showForeignState">
                    <base-input alternative=""
                        label="Employment State"
                        placeholder="Enter Employment State"
                        input-classes="form-control-alternative"
                        v-model="record.foreign_state"
                        required
                    />
                </div>

                <div class="col-lg-3" v-if="! showForeignState && locations && locations.states">
                    <base-input alternative=""
                        label="Employment State"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.state_id"
                        >
                            <option :value="null" disabled>---Select One---</option>
                            <option
                                v-for="state in locations.states"
                                :value="state.id"
                                :key="state.id"
                            >
                                {{state.name}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Have you taken this exam before"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.exam_taken"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3" v-if="showExamTimes">
                    <base-input alternative=""
                        label="How many times"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.exam_times"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option
                                v-for="number in numbers"
                                :value="number"
                                :key="number"
                            >
                                {{number}}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Have you attended any remedial course"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.remedial_course"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Epic Verification Reference ID"
                        placeholder="Enter Epic Verification Reference ID"
                        input-classes="form-control-alternative"
                        v-model="record.epic_id"
                    />
                </div>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <base-button
                type="danger"
                @click="editAdditionalData()"
            >
                <i class="fa fa-times"></i>
                Cancel Edit
            </base-button>

            <base-button
                type="success"
                class="right"
                @click="saveAdditionalData()"
            >
                <i class="fa fa-save"></i>
                {{ isloading ? 'Please Wait...' : 'Update Changes'}}
            </base-button>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'EditAdditionalDetails',
        props: ['locations', 'applicant', 'editPage', 'loadAssessmentApplicant', 'editAdditionalData'],
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                rrr: null,
                record: {
                    professional_experiences: null,
                    employ_status: null,
                    employ_sector: null,
                    country_id: null,
                    foreign_state: null,
                    state_id: null,
                    exam_taken: null,
                    exam_times: null,
                    remedial_course: null,
                    epic_id: null,
                    practice_type: null
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            showForeignState() {
                return this.record.country_id == 164 ? false : true;
            },
            showEmploymentSector() {
                return this.record.employ_status && this.record.employ_status == 'yes' ? true : false;
            },
            showExamTimes() {
                return this.record.exam_taken && this.record.exam_taken == 'yes' ? true : false;
            },
            numbers() {
                let startNum = 1;// start from 1
                let endNum = 50
                let numbers = [];
                for (let i = startNum; i <= endNum; i++ ) {
                    numbers.push(i);
                }
                return numbers;
            }
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData']),
            setData() {// set applicant data
                this.showInputErrorStatus = false;
                this.emptyFields();
                this.isLoadingBlade = false;
                if(this.applicant) {
                    this.record = this.applicant.additional_data ? this.applicant.additional_data : this.emptyFields();

                }
            },
            saveAdditionalData() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// update
                    let data = { 'additional_data' : this.record , 'id' : this.applicant.id };
                    this.saveAssessmentApplicantData(data).then((res) => {
                        this.loadAssessmentApplicant();
                        this.isloading = false;
                        this.editAdditionalData();
                        if(res.status) {
                            swal.fire("Information Updated!", res.msg, "success");
                        } else {
                            let msg = "Payment details could not be found, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.editAdditionalData();
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    professional_experiences: null,
                    employ_status: null,
                    employ_sector: null,
                    country_id: null,
                    foreign_state: null,
                    state_id: null,
                    exam_taken: null,
                    exam_times: null,
                    remedial_course: null,
                    epic_id: null,
                    practice_type: null
                };
            },
            validatedData() {
                let employSector = true;
                let state = true;
                let foreignState = true;
                let country = true;
                let examTaken = true;
                let data = this.record;
                data.remedial_course != null ? data.remedial_course : this.showInuptError('Remedial Course');
                if(data.exam_taken && data.exam_taken == 'yes' && data.exam_times) {
                    examTaken = true;
                } else if(data.exam_taken && data.exam_taken == 'no') {
                    examTaken = true;
                } else {
                    examTaken = false;
                    this.showInuptError('Examination Times');
                }
                data.exam_taken != null ? data.exam_taken : this.showInuptError('Examination Taken');
                if(data.employ_status && data.employ_status == 'yes') {
                    employSector = data.employ_sector ? true : false;
                    country = data.country_id ? true : false;
                    if((data.country_id && data.country_id == 164) && data.state_id) {
                        state = true;
                    } else if((data.country_id && data.country_id == 164) && ! data.state_id) {
                        state = false;
                    }
                    if((data.country_id && data.country_id != 164) && data.foreign_state) {
                        foreignState = true;
                    } else if((data.country_id && data.country_id != 164) && ! data.foreign_state) {
                        foreignState = false;
                    }
                } else if(data.employ_status && data.employ_status == 'no') {
                    employSector = true;
                    country = true;
                    state = true;
                    foreignState = true;
                }
                ! foreignState && (data.country_id != 164) ? this.showInuptError('Employment Foreign State') : false;
                ! state && (data.country_id == 164) ? this.showInuptError('Employment State') : false;
                ! country ? this.showInuptError('Employment Country') : false;
                ! employSector ? this.showInuptError('Employment Sector') : false;
                data.employ_status != null ? data.employ_status : this.showInuptError('Employment Status');
                data.practice_type != null ? data.practice_type : this.showInuptError('Practice Type');
                data.professional_experiences != null ? data.professional_experiences : this.showInuptError('Professional Experiences');
                let status = data.professional_experiences && data.employ_status && employSector && state && foreignState
                                && data.exam_taken && data.remedial_course && country && examTaken && data.practice_type;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>