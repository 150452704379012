
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="row align-items-center" v-if="! isLoadingBlade">

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Name"
                    placeholder="Enter Name"
                    input-classes="form-control-alternative"
                    v-model="record.name"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="State"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.state_id"
                    >
                        <option value="null" disabled selected>--- Select One ---</option>
                        <option
                            v-for="state in locations.states"
                            :key="state.id"
                            :value="state.id"
                        >
                            {{state.name}}
                        </option>
                    </select>
                </base-input>
            </div>

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Address"
                >
                    <textarea
                        rows="2"
                        class="form-control form-control-alternative"
                        placeholder="Enter Address"
                        v-model="record.address"
                    ></textarea>
                </base-input>
            </div>

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="saveHospital()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : (data ? 'Update' : 'Add') +' Record'}}
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddHospitals',
        props: ['privileges', 'loadHospitals', 'data', 'displayAddPage', 'locations'],
        data() {
            return {
                record: {
                    name: null,
                    address: null,
                    state_id: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                displayOtherState: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['createHospital']),
            showOtherSchool() {// show other school
                return this.displayOtherState = this.record.school_state_id == 0 ? true : false;
            },
            setData() {// set data for editing
                let res = this.data;
                this.isLoadingBlade = false;
                if(res) {
                    this.record.name = res.name;
                    this.record.address = res.address;
                    this.record.state_id = res.state_id;
                    this.record.id = res.id;
                }
            },
            emptyEduRecords() {
                return this.record = {
                        name: null,
                        address: null,
                        state_id: null
                    };
            },
            saveHospital() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save hospital
                    let data = this.record;
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.createHospital(data).then((res) => {// create school
                        if(res.status) {
                            this.displayAddPage(false);// hide edit page
                            this.loadHospitals();// refresh page
                            swal.fire("Hospital Added", res.msg, "success");
                        } else {
                            this.loadHospitals();// refresh page
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadHospitals();// refresh page
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let res = { name: null, state_id: null };
                res.state_id = data.state_id == null ? this.showInuptError('State') : data.state_id;
                res.name = data.name == null ? this.showInuptError('Name') : data.name;
                return this.isEmpty(res) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>