
<template>
    <div>

        <div class="table-responsive">

            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>School's Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.training_school ? application.training_school.name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Email Address:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ application.training_school ? application.training_school.email : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Phone Number:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ application.training_school ? application.training_school.phone : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="success"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            @click="approveApplication(application.id)"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-check"></i>
                            Approve List
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Approval
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'ApproveApplication',
    props: ['application', 'privileges', 'loadApplication'],
    data() {
      return {
        id: this.$route.params.id,
        showBackButton: false
      }
    },
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
      ...mapActions(['approveSchoolList']),
        approveApplication(id) {// reject application
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeApproveApiCall(id);// make api call
                }
            });
        },
        makeApproveApiCall(id) {// reject application api
            let data = { id : id, reject_reason : null, status : 'approved', admin_id : this.user.id };
            this.preloader();// show loading
            this.approveSchoolList(data).then((res) => {// reject application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('List Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
