
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="row align-items-center" v-if="! isLoadingBlade">

            <div class="col-lg-12">
                <base-input alternative=""
                    label="School's Name"
                    placeholder="Enter School's Name"
                    input-classes="form-control-alternative"
                    v-model="record.school_name"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School's Country"
                    required
                >
                    <autocomplete
                        ref="autocomplete"
                        v-if="locations.countries && locations.countries.length > 0"
                        input-class="form-control form-control-alternative"
                        placeholder="Type to Search School's Country"
                        showNoResults
                        :source="locations.countries"
                        :initialValue="record.country"
                        :initialDisplay="record.country"
                        @selected="changeCountry"
                        @clear="clearData"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School's City"
                    required
                >
                    <autocomplete
                        ref="autocomplete"
                        :disableInput="cities && cities.length == 0"
                        input-class="form-control form-control-alternative"
                        placeholder="Type to Search School's City"
                        showNoResults
                        :source="cities"
                        :initialValue="record.city"
                        :initialDisplay="record.city"
                        resultsValue="name"
                        @selected="changeCity"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-6" v-if="displayOtherState">
                <base-input alternative=""
                    label="Other School's City"
                    placeholder="Enter School's City"
                    input-classes="form-control-alternative"
                    v-model="record.other_city"
                />
            </div>

            <div class="col-lg-6" v-if="isNigeria">
                <base-input alternative=""
                    label="School's State"
                    required
                >
                    <autocomplete
                        ref="autocomplete"
                        v-if="locations.states && locations.states.length > 0"
                        input-class="form-control form-control-alternative"
                        placeholder="Type to Search School's State"
                        showNoResults
                        :source="locations.states"
                        :initialValue="record.state_id"
                        :initialDisplay="(state && state.name ? state.name : null)"
                        @selected="changeState"
                    >
                    </autocomplete>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School's Quota"
                    placeholder="Enter School's Quota"
                    input-classes="form-control-alternative"
                    v-model="record.quota"
                    type="number"
                />
            </div>

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="saveSchool()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : saveButtonText }}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('displayAddPage', false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';
    import states from '../../../../states.json';

    export default {
        name: 'AddForeignSchool',
        props: ['privileges', 'loadSchools', 'school', 'displayAddPage'],
        components: {
            Autocomplete
        },
        data() {
            return {
                record: {
                    school_name: null,
                    country_id: null,
                    country: null,
                    city: null,
                    other_city: null,
                    state_id: null,
                    quota: null,
                    id: null,
                    admin_id: null,
                },
                states: states,
                cities: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                displayOtherState: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'locations']),
            saveButtonText() {
                let text = this.record && this.record.id ? 'Update' : 'Add';
                return text + ' Record';
            },
            isNigeria() {
                return this.record && this.record.country_id == 164;
            },
            state() {
                if(this.record.state_id) {
                    let state = this.locations.states.filter(e => e.id == this.record.state_id);
                    return state && state.length > 0 ? state[0] : null;
                }
                return null;
            }
        },
        methods: {
            ...mapActions(['createForeignSchool', 'getLocations']),
            clearData() {
                this.setData();
                this.$refs.autocomplete.clear();
            },
            changeCountry(e) {// update country
                let data = e.selectedObject;
                this.displayOtherState = false;
                this.record.country_id = null;
                this.record.country = null;
                this.cities = [];
                this.record.country_id = data.id;
                this.record.country = data.name;
                let other = { id : 0, name: 'Others (Specify)'};// get cities
                this.cities = this.states.filter(item => item.country_code == data.code);
                this.cities.push(other);
            },
            changeState(e) {// update state
                let data = e.selectedObject;
                this.record.state_id = null;
                this.record.state_id = data.id;
            },
            changeCity(e) {// update city
                let data = e.selectedObject;
                this.record.city = null;
                this.other_city = null;
                if(data.id == 0) {
                    this.record.city = null;
                    this.displayOtherState = true;
                } else {
                    this.displayOtherState = false;
                    this.record.city = data.name;
                }
            },
            setData() {// set data for editing
                this.emptyEduRecords();// empty record
                let school = this.school;
                if(school) {
                    this.record.school_name = school.school_name;
                    this.record.country_id = school.country_id;
                    this.record.country = school.country;
                    this.record.city = school.city;
                    this.record.state_id = school.state_id;
                    this.record.quota = school.quota;
                    this.record.id = school.id;
                }
                this.isLoadingBlade = false;
            },
            emptyEduRecords() {
                this.cities = [];
                return this.record = {
                    school_name: null,
                    country_id: null,
                    country: null,
                    city: null,
                    other_city: null,
                    quota: null,
                    id: null,
                    admin_id: null,
                };
            },
            saveSchool() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save user
                    let data = this.record;
                    data.city = data.city ? data.city : data.other_city;
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.createForeignSchool(data).then((res) => {// create school
                        if(res.status) {
                            this.displayAddPage(false);// hide edit page
                            this.loadSchools();// refresh page
                            swal.fire("School's Account "+res.type, res.msg, "success");
                        } else {
                            this.loadSchools();// refresh page
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadSchools();// refresh page
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                this.record.city = this.record.city ? this.record.city : this.record.other_city;
                let data = this.record;
                let school = { school_name: null, country_id: null, city: null };
                school.city = data.city == null ? this.showInuptError('School\'s City') : data.city;
                school.country_id = data.country_id == null ? this.showInuptError('School\'s Country') : data.country_id;
                school.school_name = data.school_name == null ? this.showInuptError('School\'s Name') : data.school_name;
                return this.isEmpty(school) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations();
            this.setData();// load Departments
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>