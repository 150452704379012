<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/induction-ticketing/ticketing-batches"
                                        class="btn btn-lg btn-danger"
                                    >
                                        <i class="fa fa-reply"></i>
                                        Go Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">

                            <div class="container">
                                <div class="row" v-if="! showAddPage">
                                    <div class="col-lg-2">
                                        <base-input alternative="">
                                            <select
                                                class="form-control form-control-alternative"
                                                v-model="page"
                                                @change="getPageItems()"
                                            >
                                                <option
                                                    v-for="number in pageNumbers"
                                                    :key="number"
                                                    :value="number"
                                                >
                                                    {{ number }}
                                                </option>
                                            </select>
                                        </base-input>
                                    </div>
                                    <div class="col-lg-2"></div>
                                    <div class="col-lg-4 text-right">
                                        <base-input 
                                            placeholder="Search by Folio Number"
                                            class="input-group-alternative"
                                            alternative=""
                                            addon-right-icon="fas fa-search"
                                            @change="searchRecords"
                                            v-model="searchKey"
                                            >
                                        </base-input>
                                    </div>

                                    <div class="col-lg-4">
                                        <form :action="getRootUrl+'/print-inductees-list'" method="POST" class="right">
                                            <input type="hidden" name="batch_id" :value="id" />
                                            <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                                            <input
                                                type="hidden" name="admin_id"
                                                :value="user.id"
                                            />

                                            <base-button
                                                size="lg"
                                                type="info"
                                                nativeType="submit"
                                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                                            >
                                                <i class="fa fa-print"></i>
                                                Print List
                                            </base-button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <!-- import Attachment Batch Data template here -->
                            <TicketInducteesData
                                :records="records"
                                :hasPagination="hasPagination"
                                :pagination="pagination"
                                :paginate="paginate"
                                :privileges="privileges"
                                :searchKey="searchKey"
                                :loadBatchInductees="loadBatchInductees"
                                v-on:editBatch="editBatch"
                                v-if="! showAddPage"
                            />

                            <div
                                class="col-sm-12 text-center"
                                v-if="showErrorStatus && ! showAddPage"
                            >
                                <base-alert :type="errorType">
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{errorMsg}}
                                    </span>
                                </base-alert>
                            </div>

                            <!-- import Add Attachment Batch template here -->
                            <EditTicketBatchInductee
                                :privileges="privileges"
                                :data="record"
                                :goBack="goBack"
                                v-if="showAddPage"
                            />
                            
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import TicketInducteesData from './TicketInducteesData';
  import EditTicketBatchInductee from './EditTicketBatchInductee';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'TicketInductees',
    components: {
        TicketInducteesData,
        EditTicketBatchInductee
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        page: 1,
        searchKey: null,
        showAddPage: false,
        showbackButton: false,
        record: null,
        id: this.$route.params.id,
        title: null,
        batch: null
      }
    },
    computed: {
        ...mapGetters(['menus', 'user', 'loadingBladeUrl', 'getRootUrl', 'getAuthorizationToken']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == '/induction-ticketing/ticketing-batches') {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        },
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        },
        years() {
            let startYear = 2018;// start from 2019
            let currentYear = parseInt(moment().format('YYYY'));
            let years = [];
            for (let i = currentYear; i > startYear; i-- ) {
                years.push(i);
            }
            return years;
        },
    },
    methods: {
        ...mapActions(['getTicketBatchInductees', 'getPaginatedPaymentItems']),
        loadTitle() {// load title
            let batch = this.batch;
            this.title = '<em class="text-danger">';
            this.title += batch.name + ' ' + batch.code + ' - ' + batch.year;
            this.title += ' </em>Ticket Batch Inductees';
        },
        goBack() {
            this.loadBatchInductees();
            return this.showAddPage = false;
        },
        editBatch(data) {
            this.loadTitle();
            this.record = data;
            this.title = 'Edit <em class="text-danger">' + data.surname.toLowerCase();
            this.title += ' '+data.first_name.toLowerCase() +' </em>\'s Inductee Details';
            this.showbackButton = true;
            return this.showAddPage = true;
        },
        loadBatchInductees() {// load details
            let data = { id : this.id, limit : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getTicketBatchInductees(data).then((res) => {// get batches
                this.isLoadingBlade = false;
                this.batch = res.batch;
                this.setPages(res.batches);//set data
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
            this.loadTitle();
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.batches.current_page;
                    this.setPages(res.batches);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Ticket Batch Inductees yet!!!';
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                this.pagination.number = res.batches.current_page;
                this.setPages(res.batches);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        searchRecords() {
            this.showErrorStatus = false;
            let key = this.searchKey;
            if(key) {
                this.isLoadingBlade = true;
                let data = { id : this.id, key : key };
                this.records = [];
                this.getTicketBatchInductees(data).then((res) => {// get records
                    this.isLoadingBlade = false;
                    this.records = res.batches;
                    if(this.records.length == 0) {
                        this.showErrorStatus = true;
                        this.errorType = 'warning';
                        this.errorMsg = 'No Matched Record!!!';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
                this.emptyRecordsMsg();
            }
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadBatchInductees();
    }
  };
</script>
<style scoped>
    .right {
        float: right;
    }
</style>
<style>
    .table td {
        font-size: 15px;
    }
</style>
