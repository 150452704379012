
<template>
    <div>

        <div class="table-responsive">

            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>CPD Provider's Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.cpd ? application.cpd.name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Reject Reason:</h4>
                    </td>
                    <td>
                        <base-input alternative="" required>
                            <textarea
                                rows="2"
                                class="form-control form-control-alternative"
                                placeholder="Enter Reject Comment"
                                v-model="reject_reason"
                                :readonly="showBackButton"
                            ></textarea>
                        </base-input>
                    </td>
                </tbody>

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="info"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            @click="rejectApplication(application.id)"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-ban"></i>
                            Reject Application
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Reject
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'RejectApplication',
    props: ['application', 'privileges', 'loadApplication'],
    data() {
      return {
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false,
        reject_reason: null
      }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
      ...mapActions(['approveCpdApplication']),
        rejectApplication(id) {// reject application
            if(this.reject_reason) {// reject application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRejectApiCall(id);// make api call
                    }
                });
            } else {
                return this.showInuptError('Reject Reason');
            }
        },
        makeRejectApiCall(id) {// reject application api
            let data = { id : id, reject_reason : this.reject_reason,
                        'status' : 'rejected', admin_id : this.user.id };
            this.preloader();// show loading
            this.approveCpdApplication(data).then((res) => {// reject application
                if(res.status) {
                    this.showBackButton = true;
                    this.reject_reason = null;
                    swal.fire('Application Rejected!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
