
<template>
    <div>
        <div class="row align-items-center">

            <div class="col-lg-12 text-uppercase">
                <h4 class="mb-0">Update Medical School Record Below</h4>
                <hr class="my-4" />
            </div>
            <div class="col-lg-4">
                <base-input alternative=""
                    label="School Type"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.school_type"
                    >
                        <option
                            :value="null"
                            disabled
                        >
                            --- Select One ---
                        </option>
                        <option value="medical-school">Medical School</option>
                    </select>
                </base-input>
            </div>


            <div class="col-lg-4">
                <base-input alternative=""
                    label="Country"
                    required
                >
                    <autocomplete
                        v-if="locations && locations.countries && locations.countries.length > 0 && hasCountry"
                        input-class="form-control form-control-alternative"
                        placeholder="Type Country"
                        showNoResults
                        :source="locations.countries"
                        v-model="record.country_id"
                        :initialDisplay="hasCountry.name"
                        @selected="changeCountry"
                    >
                    </autocomplete>
                </base-input>
            </div>


            <div class="col-lg-4">
                <base-input alternative=""
                    label="Name of Institution"
                    required
                >
                    <autocomplete
                        v-if="schools && schools.length > 0"
                        ref="schoolAutocomplete"
                        input-class="form-control form-control-alternative"
                        placeholder="Type to search Institution"
                        showNoResults
                        :disableInput="schools && schools.length == 0"
                        :source="schools"
                        :initialDisplay="record.school_name"
                        @selected="changeInstitution"
                    >
                    </autocomplete>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="showOtherInstitution">
                <base-input alternative=""
                    label="Other Institution"
                    placeholder="Enter Other Institution Name"
                    input-classes="form-control-alternative"
                    v-model="record.other_school_name"
                    required
                />
            </div>
            <div class="col-lg-4">
                <base-input alternative=""
                    label="Qualifications Obtained"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.qualifications_obtained"
                        @change="changeOtherQualifications()"
                    >
                        <option
                            :value="null"
                            disabled
                        >
                            --- Select One ---
                        </option>
                        <option value="MBBS">MBBS</option>
                        <option value="BDS">BDS</option>
                        <option value="others">Others (Please, specify)</option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="showOtherQualifications">
                <base-input alternative=""
                    label="Other Qualifications"
                    placeholder="Enter Other Qualification Name"
                    input-classes="form-control-alternative"
                    v-model="record.other_qualifications_obtained"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input
                    label="Degree Start Date (Date you started Degree)"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: true}"
                        class="form-control datepicker"
                        v-model="record.start_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-4">
                <base-input
                    label="Degree End Date(Date of Completing Degree)"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: true}"
                        class="form-control datepicker"
                        v-model="record.end_date"
                    >
                    </flat-picker>
                </base-input>
            </div>

        </div>

        <div
            class="col-sm-12 text-center"
            v-if='showInputErrorStatus'
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <hr class="my-4" />

        <div class="col-lg-12">
            <base-button
                type="success" size="lg"
                @click="saveEduRecords()"
                :disabled="(privileges && privileges.update == 'no') || ! privileges"
            >
                <i class="fa fa-save"></i>
                {{isloading ? 'Please Wait...' : 'Update Record'}}
            </base-button>

            <base-button
                type="danger" size="lg"
                class="right"
                @click="$emit('closeEditPage')"

            >
                <i class="fa fa-times"></i>
                Cancel
            </base-button>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        name: 'UpdateMedicalSchool',
        props: ['newRecord','privileges', 'loadMedicalSchoolRecord', 'closeEditPage', 'userId'],
        components: {
            flatPicker,
            Autocomplete
        },
        data() {
            return {
                record: {
                    has_country: {},
                    school_type: 'medical-school',
                    school_name: null,
                    school_id: null,
                    other_school_name: null,
                    qualifications_obtained: null,
                    country_id: null,
                    start_date: null,
                    end_date: null,
                    other_qualifications_obtained: null
                },
                schools: [],
                showAddRecord: false,
                qualifications: [],
                degrees: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showOtherQualifications: false,
                showInputErrorStatus: false,
                showOtherInstitution: false,
                isloading: false
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['locations']),
            hasCountry() {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.record.country_id) : null;
                return country ? country[0] : {};
            }
        },
        methods: {
            ...mapActions(['updateMedicalSchoolRecord', 'getLocations', 'getExactInstitutions']),
            changeCountry(e) {// get exact institutions
                this.record.school_name = null,
                this.record.other_school_name = null,
                this.record.school_id = null;
                this.schools = [];
                if(! this.isOtherSchool) {
                    this.preloader();
                    this.getExactInstitutions(e.selectedObject.id).then((res) => {
                        swal.close();
                        this.schools = res.schools
                        let other = { id : 0, name: 'Others (Specify)'};
                        this.schools.push(other);
                    });
                }
            },
            changeInstitution(e) {
                let data = e.selectedObject;
                this.record.other_school_name = null;
                this.showOtherInstitution = false;
                this.record.school_id = data.id;
                if(data.id == 0) {
                    this.showOtherInstitution = true;
                } else {
                    this.record.school_name = data.name;
                }
            },
            emptyEduRecords() {
                this.record.school_name = null;
                this.record.other_school_name = null;
                this.record.qualifications_obtained = null;
                this.record.country_id = null;
                this.record.start_date = null;
                this.record.end_date = null;
                this.record.other_qualifications_obtained = null;
            },
            saveEduRecords() {
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    data.id = this.newRecord ? this.newRecord.id : null;
                    data.user_data_id = this.userId;// add user data id
                    delete data.other_school_name;// remove other school name
                    delete data.other_qualifications_obtained;// remove other qualifications obtained
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.updateMedicalSchoolRecord(data).then((res) => {// update school
                        if(res.status) {
                            this.closeEditPage();// hide edit page
                            this.loadMedicalSchoolRecord();// load add qual records
                            swal.fire("Record Updated!", res.msg, "success");
                        } else {
                            this.loadMedicalSchoolRecord();// load add qual records
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadMedicalSchoolRecord();// load add qual records
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let eduRecord = { school_type: null, start_date: null, country_id: null,
                    end_date: null, school_name: null, qualifications_obtained: null };
                eduRecord.school_type = data.school_type == null ?
                    this.showInuptError('School\'s Type') : data.school_type;
                eduRecord.end_date  = data.end_date == null ?
                    this.showInuptError('School\'s End Date') : data.end_date;
                eduRecord.start_date  = data.start_date == null ?
                    this.showInuptError('School\'s Start Date') : data.start_date;
                eduRecord.country_id  = data.country_id == null ?
                    this.showInuptError('Country') : data.country_id;
                // validate qualification obtained
                if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained != null) {
                    eduRecord.qualifications_obtained = data.other_qualifications_obtained;
                } else if(data.qualifications_obtained != null && data.qualifications_obtained != 'others') {
                    eduRecord.qualifications_obtained = data.qualifications_obtained;
                } else if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained == null) {
                    this.showInuptError('Qualification Obtained');
                }
                // validate institution obtained
                if((data.school_name == null || data.school_name == 'others')
                    && data.other_school_name != null) {
                    eduRecord.school_name = data.other_school_name;
                } else if(data.school_name != null && data.school_name != 'others') {
                    eduRecord.school_name = data.school_name;
                } else if((data.school_name == null || data.school_name == 'others')
                    && data.other_school_name == null) {
                    this.showInuptError('School\'s Name');
                }
                return this.isEmpty(eduRecord) ? eduRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            changeOtherQualifications() {// change other qualifications
                let value = this.record.qualifications_obtained;
                return this.showOtherQualifications = (value == 'others') ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            setData() {
                if(this.newRecord) {
                    let data = this.newRecord;
                    this.updateData(data);// update data
                    this.preloader();
                    this.getExactInstitutions(data.country_id).then((res) => {
                        swal.close();
                        this.schools = res.schools ? res.schools : [];
                        let other = { id : 0, name: 'Others (Specify)'};
                        this.schools.push(other);
                    });
                }
            },
            updateData(data) {
                this.record.school_type = data.school_type;
                this.record.school_name = data.school_name;
                this.record.qualifications_obtained = data.qualifications_obtained;
                this.record.country_id = data.country_id;
                this.record.school_id = data.school_id;
                this.record.start_date = data.start_date;
                this.record.end_date = data.end_date;
                this.record.other_qualifications_obtained = data.other_qualifications_obtained;
                this.record.has_country = data.has_country;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData(); // set Edu Data
            this.getLocations(); // load locations
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>