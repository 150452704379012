
<template>
    <div>

        <div class="col-lg-12">
            <base-button
                class="float-right"
                type="success"
                size="sm"
                @click="displayNewRecord()"
                :disabled="(privileges && privileges.add == 'no') || ! privileges"
            >
                <i class="fa fa-plus-circle"></i>
                Add New Record
            </base-button>
            <br>
        </div>

        <hr class="my-4" />

        <div class="col-lg-12" v-if="! showErrorStatus && ! showEditPage">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="30%">Description</th>
                            <th width="10%">Degree</th>
                            <th width="10%">Qualify Date</th>
                            <th width="10%">Licensing <br> Body</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ index+1}}
                        </td>
                        <td>
                            {{ row.qualification ? row.qualification.course_title : 'N/A' }}<br>
                            <b>from</b> {{ row.school_name }}<br>
                            <strong
                                v-if="row.country_id && hasCountry(row.country_id)"
                                class="text-uppercase"
                            >
                                 {{ hasCountry(row.country_id).name }}<br>
                            </strong>
                            <badge
                                size="sm" type="primary"
                                v-if="getCategory(row.qualification)"
                            >
                                {{ getCategory(row.qualification) }}
                            </badge>
                            <badge size="sm" :type="getColor(row.status)">
                                {{ row.status }}
                            </badge>
                            <badge size="sm" type="primary">
                                {{ row.type }}
                            </badge>
                        </td>
                        <td>
                            {{ row.degree ? row.degree : 'N/A' }}
                        </td>
                        <td>
                            {{ row.qualify_date | getDateFormat }}
                        </td>
                        <td>
                            {{row.licencing_body}}
                        </td>
                        <td>
                            <base-button
                                type="info" size="sm"
                                @click="editRecord(row)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i> Edit
                            </base-button>
                            <base-button
                                type="danger" size="sm"
                                @click="deleteAq(row.id)"
                                v-if="((row.status && row.status != 'approved')
                                        && (row.type && ((row.type == 'old')) ||
                                        (row.application && ! row.application.transaction)))"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                            >
                                <i class="fa fa-trash"></i> Delete
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>
            </div>
        </div>

        <AddNewQualification
            :newRecord="newRecord"
            :user="record"
            :privileges="privileges"
            :loadAddQualifications="loadAddQualifications"
            :closeEditPage="closeEditPage"
            :qualifications="qualifications"
            v-on:closeEditPage="closeEditPage"
            v-if="showEditPage"
        />

        <div class="col-sm-12 text-center" v-if='showErrorStatus && ! showEditPage'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import AddNewQualification from './AddNewQualification';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddQualifications',
        props: ['record','privileges'],
        components: {
            AddNewQualification
        },
        data() {
            return {
                records: [],
                qualifications: [],
                paginatedItems: [],
                categories: [],
                schools: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showEditPage: false,
                newRecord: {}
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['userPhotoUrl', 'blankImageUrl', 'locations']),
            years() {
                let startYear = moment().format('YYYY') - 119;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            }
        },
        methods: {
            ...mapActions(['getUserAddQual', 'getAdditionalQulifications', 'getInstitutions', 'deleteUserAq',
                'getLocations']),
            hasCountry(id) {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == id) : null;
                return country ? country[0] : {};
            },
            loadAddQualifications() {// load details
                this.loadAqAndInstitions();// load Aq And Institions
                this.getUserAddQual(this.record.user_id).then((res) => {// get Aq records
                    this.isLoadingBlade = false;
                    this.showErrorStatus = false;
                    this.records = res.aqs;
                    this.qualifications = res.qualifications;
                    this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Additional Qualifications Yet!!!';
            },
            loadAqAndInstitions() {// load Aq And Institions
                this.getAdditionalQulifications().then((res) => {
                    this.categories = res;
                });
                this.getInstitutions().then((res) => {
                    this.schools = res;
                });
            },
            updateProfile() {// update profile
                this.preloader(); // show loading
                this.updateUserProfile(this.record).then((res) => {// get records
                    if(res.status) {
                        swal.fire('Profile updated!', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            getStatus(value) {
                return value == 'approved' ? 'approved' : 'pending';
            },
            getColor(value) {
                return value == 'approved' ? 'success' : 'danger';
            },
            editRecord(record) {// show edit page
                let country = this.hasCountry(record.country_id);
                record.country = country;
                this.newRecord = record;
                this.showEditPage = true;
            },
            displayNewRecord() {// show edit page
                this.newRecord = null;
                this.showEditPage = true;
            },
            closeEditPage() {// hide edit page
                this.newRecord = null;
                this.showEditPage = false;
            },
            getCategory(qual) {
                return (qual && qual.category) ? qual.category.name : false;
            },
            deleteAq(id) {// delete Additional Qualification
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeDeleteAqApiCall(id);// make api call
                    }
                });
            },
            makeDeleteAqApiCall(id) {// make api call
                this.preloader(); // show loading
                this.deleteUserAq(id).then((res) => {
                    if(res.status) {
                        this.loadAddQualifications();// load add qual records
                        swal.fire('AQ Deleted', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.getLocations();
            this.loadAddQualifications();// load add qual records
        },
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>