
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th>Student's Name /<br> Matriculation No</th>
                        <th>Email Address <br>/ Gender</th>
                        <th>School Type</th>
                        <th>Serial Number / <br> Indexing Year</th>
                        <th>Status</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            <span v-html="getName(row)"></span><br>
                            <badge type="info">
                                {{ row.matric_no ? row.matric_no : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.email ? row.email : 'N/A' }}<br>
                            <badge type="primary">
                                {{ row.gender ? row.gender : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ getSchoolType(row) }}
                        </td>
                        <td class="text-capitalize">
                            {{ getSerialNumber(row) }}<br>
                            <badge type="dark">
                                {{ row.year ? row.year : 'N/A' }}
                            </badge>
                        </td>                        
                        <td class="text-capitalize">
                            {{ getIndexingStatus(row) }}
                        </td> 
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import Badge from '../../../components/Badge.vue';

    export default {
    components: { Badge },
        name: 'ReportingData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges'],
        methods: {
            getName(record) {// get full name
                let name = record.first_name ? record.first_name : '';
                name += record.other_names ? ' ' + record.other_names : '';
                name += record.surname ? ' ' + record.surname : '';
                return name ? this.breakWord(name) : 'N/A';
            },
            getIndexingStatus(record) {// get indexing status
                return record.indexing && record.indexing.status ? record.indexing.status : 'N/A';
            },
            getSchoolType(record) {// get student school type
                return record.indexing && record.indexing.type ? record.indexing.type : 'N/A';
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            },
            breakWord(word, length = 40) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            },
            getSerialNumber(record) {// format serial number function
                if(record && record.indexing && record.indexing.prefix && record.serial_no) {
                    let number = this.addLeadingZeros(record.serial_no, 3);
                    return record.indexing.prefix + number;
                } else {
                    return 'N/A';
                }
            },
            addLeadingZeros(num, size) {
                var s = "000000000" + num;
                return s.substr(s.length-size);
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
