<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h3 class="mb-0 text-uppercase">Add a Secondary Email Address</h3>
                                    <br>
                                    <span class="text-danger">
                                        Note that this Email Address will only be used to receive email alerts when necessary (mostly for password reset).
                                        Preferably Gamil or Yahoo Email Address.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <!-- Import Bio Data template here -->
                        <!-- <UpdateBioData
                            v-if="profileInfo && profileInfo.hasCompleteBioData
                                && ! profileInfo.hasCompleteBioData.status"
                            v-bind:bioData="profileInfo.hasCompleteBioData.records"
                            v-bind:hasEmptyData="hasEmptyData"
                        /> -->

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        components: {
            
        },
        name: 'UpdateProfile',
        data() {
            return {
                profileInfo: [],
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            
        },
        created() {
            
        },
    }
</script>