
<template>
    <div>
        <div class="row align-items-center" v-if="! isLoadingBlade">

            <div class="col-lg-6">
                <base-input alternative=""
                    label="User's Name"
                    placeholder="Enter User's Name"
                    input-classes="form-control-alternative"
                    v-model="record.name"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="User's Email Address"
                    placeholder="Enter User's Email Address"
                    input-classes="form-control-alternative"
                    v-model="record.email"
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="User's Phone Number"
                    placeholder="Enter User's Phone Number"
                    input-classes="form-control-alternative"
                    v-model="record.phone"
                    type="number"
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="User's Gender"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.gender"
                    >
                        <option
                            :value="null"
                            disabled
                        >
                            --- Select One ---
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </base-input>
            </div>

            <div :class="'col-lg-' + (department_id ? 4 : 6)">
                <base-input alternative=""
                    label="Department"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="department_id"
                        @change="getUnits"
                    >
                        <option value="null" disabled selected>--- Select One ---</option>
                        <option
                            v-show="departments && departments.length > 0"
                            v-for="department in departments"
                            :key="department.id"
                            :value="department.id"
                        >
                            {{department.name}}
                        </option>
                    </select>
                </base-input>
            </div>

            <div :class="'col-lg-' + (department_id ? 4 : 6)" v-if="showUnits">
                <base-input alternative=""
                    label="Units"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.admin_unit_id"
                    >
                        <option :value="null" disabled>--- Select One ---</option>
                        <option
                            v-show="units && units.length > 0"
                            v-for="unit in units"
                            :key="unit.id"
                            :value="unit.id"
                        >
                            {{unit.name}}
                        </option>
                    </select>
                </base-input>
            </div>

            <div :class="'col-lg-' + (department_id ? 4 : 6)">
                <base-input alternative=""
                    label="Role"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.role"
                    >
                        <option :value="null" disabled>--- Select One ---</option>
                        <option value="admin">Administrator</option>
                        <option value="agent">Admin Agent</option>
                        <option value="other">Other Agent</option>
                    </select>
                </base-input>
            </div>

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="saveUser()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Add Record'}}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('showAddUser', false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddUser',
        props: ['privileges', 'loadUsers', 'user', 'showAddUser'],
        data() {
            return {
                record: {
                    name: null,
                    email: null,
                    gender: null,
                    phone: null,
                    admin_unit_id: null,
                    id: null,
                    admin_id: null,
                    status: 'enable',
                    role: null
                },
                department_id: null,
                departments: [],
                units: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showUnits: false,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getAdminDepartments', 'createAdminUser']),
            loadDepartments() {// set data for editing
                this.emptyEduRecords();// empty record
                this.getAdminDepartments().then((res) => {// get users
                    this.departments = res;//set departments
                    this.setData(); // set data for editing
                }).
                catch(() => {
                    // this.isLoadingBlade = false;
                });
            },
            setData() {// set data for editing
                let user = this.user;
                if(user) {
                    this.record.name = user.name;
                    this.record.email = user.email;
                    this.record.phone = user.phone;
                    this.record.gender = user.gender;
                    this.record.admin_unit_id = user.admin_unit_id;
                    this.record.id = user.id;
                    this.record.status = user.status;
                    this.record.role = user.role && user.role.role ? user.role.role : null;
                    this.getDepartmentForEditing(user.unit);
                }
                var self = this;
                setTimeout(function() { 
                    self.isLoadingBlade = false; 
                }, 100);
            },
            getDepartmentForEditing(unit) {// get department for editing
                if(unit && unit.admin_department_id) {
                    let id = unit.admin_department_id;
                    for(let department of this.departments) {
                        if(department.id == id) {
                            this.showUnits = true;
                            this.department_id = id;
                            return this.units = department.units;
                        }
                    }
                }
            },
            getUnits(event) {
                let id = event.target.value;
                this.units = [];
                this.showUnits = false;
                this.record.admin_unit_id = null;
                for(let department of this.departments) {
                    if(department.id == id) {
                        if(department.units.length == 0) {// check for empty units
                            let msg = 'Kindly create at least a unit under '+department.name+' Department';
                            swal.fire("No Units!", msg, "warning");
                        } else {// add units
                            this.showUnits = true;
                            return this.units = department.units;
                        }
                    }
                }
            },
            emptyEduRecords() {
                this.record = {
                    name: null,
                    email: null,
                    gender: null,
                    phone: null,
                    admin_unit_id: null,
                    id: null,
                    admin_id: null,
                    status: 'enable',
                    role: null
                };
            },
            saveUser() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save user
                    let data = this.record;
                    if(data.id) { data.update = true; }// for update only
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.createAdminUser(data).then((res) => {// create user 
                        if(res.status) {
                            this.showAddUser(false);// hide edit page
                            this.loadUsers();// refresh page
                            swal.fire("Account "+res.type, res.msg, "success");
                        } else {
                            this.loadUsers();// refresh page
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadUsers();// refresh page
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() { // nysc@mdcn.gov.ng
                let data = this.record;
                let user = { name: null, email: null, gender: null, admin_unit_id: null, role: null, department_id: null };
                user.role = data.role == null ? this.showInuptError('User\'s Role') : data.role;
                user.admin_unit_id = data.admin_unit_id == null ?
                        this.showInuptError('Unit') : data.admin_unit_id;
                user.department_id = this.department_id == null ? this.showInuptError('Department') 
                                            : this.department_id;
                user.gender = data.gender == null ? this.showInuptError('User\'s Gender') : data.gender;
                user.email = data.email == null ? this.showInuptError('User\'s Email Address') : data.email;
                user.name = data.name == null ? this.showInuptError('User\'s Name') : data.name;
                return this.isEmpty(user) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadDepartments();// load Departments
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>