
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-10 text-right">
                        <div v-if="! hasExportPages">
                            <form :action="getRootUrl+'/export-audit-trail-report'" method="POST">

                                <input
                                    v-for="(value, item) in filters"
                                    :key="item"
                                    type="hidden"
                                    :name="item"
                                    :value="value"
                                />

                                <input
                                    type="hidden"
                                    name="admin_id"
                                    :value="user.id"
                                />

                                <button
                                    :class="'btn btn-lg btn-'+ (showFilters ? 'danger' : 'primary')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    @click="displayFilters()"
                                >
                                    <i :class="'fa fa-'+(showFilters ? 'times' : 'sort')"></i>
                                    {{ showFilters ? 'Remove Filters' : 'Filters Report'}}
                                </button>

                                <button
                                    class="btn btn-lg btn-success"
                                    nativeType="submit"
                                    v-if="records && records.length > 0"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-file-export"></i>
                                    Export Report
                                </button>
                            </form>
                        </div>

                        <div v-if="hasExportPages">
                            <button
                                :class="'btn btn-lg btn-'+ (showFilters ? 'danger' : 'primary')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @click="displayFilters()"
                            >
                                <i :class="'fa fa-'+(showFilters ? 'times' : 'sort')"></i>
                                {{ showFilters ? 'Remove Filters' : 'Filters Report'}}
                            </button>
                            <button
                                :class="'btn btn-lg btn-'+ (showExportPages ? 'danger' : 'success')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @click="displayExportPages()"
                            >
                                <i :class="'fa fa-'+(showExportPages ? 'times' : 'sort')"></i>
                                {{ showExportPages ? 'Remove Export Pages' : 'Show Export Pages'}}
                            </button>
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="hasExportPages && showExportPages">
                        <hr>
                        <form :action="getRootUrl+'/export-audit-trail-report'" method="POST">
                            <input
                                v-if="item != 'limit'"
                                v-for="(value, item) in filters"
                                :key="item"
                                type="hidden"
                                :name="item"
                                :value="value"
                            />

                            <input
                                type="hidden"
                                name="admin_id"
                                :value="user.id"
                            />

                            <input
                                type="hidden"
                                name="limit"
                                :value="exportPerSheet"
                            />

                            <div class="col-lg-12 text-center">
                                <h3 class="text-danger">
                                    Note: Maximum of {{ exportPerSheet }} record(s) per export.
                                </h3>
                                <base-input label="Select Range of Records" required>
                                    <select
                                        class="form-control form-control-alternative"
                                        name="export_page"
                                        @change="enableExportPageButton()"
                                        required
                                    >
                                        <option  value="null" disabled selected>
                                            ---Select One---
                                        </option>
                                        <option
                                            v-for="page in exportPages"
                                            :key="page"
                                            :value="page"
                                        >
                                            Page {{ page }}
                                        </option>
                                    </select>
                                </base-input>
                            </div>

                            <button
                                class="btn btn-lg btn-success btn-block"
                                style="margin-top: 10px;"
                                nativeType="submit"
                                v-if="records && records.length > 0"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges || disableExportPageButton"
                            >
                                <i class="fa fa-file-export"></i>
                                Export Page
                            </button>
                        </form>
                        <hr>
                    </div>
                </div>
            </div>

            <div class="container" v-if="showFilters && ! isLoadingBlade">
                <hr>
                <h3 class="mb-0 text-uppercase text-center" v-html="filteredTitle"></h3>
                <hr v-if="! isFiltered">
                <div class="row" v-if="! isFiltered">

                    <div class="col-lg-4">
                        <label class="form-control-label">Doctor's Folio Number</label>
                        <div class="row">
                            <div class="col-lg-5">
                                <base-dropdown>
                                    <template v-slot:title>
                                        <base-button type="primary" class="dropdown-toggle">
                                            {{ folio.prefix }}
                                        </base-button>
                                    </template>
                                    <a class="dropdown-item" href="javascript:void(0)" @click="changeFolioPrefix">
                                        {{ (folio.prefix == 'MDCN/R/') ? 'MDCN/R/ALT/' : 'MDCN/R/' }}
                                    </a>
                                </base-dropdown>
                            </div>
                            <div class="col-lg-7">
                                <base-input alternative=""
                                    input-classes="form-control-alternative"
                                    placeholder="Enter Folio Number"
                                    v-model="folio.number"
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Doctor's Email Address"
                            input-classes="form-control-alternative"
                            placeholder="Enter Email Address"
                            v-model="filters.email"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Departments"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.department_id"
                                @change="getUnits"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="department in departments"
                                    :key="department.id"
                                    :value="department.id"
                                >
                                    {{ department.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showUnits">
                        <base-input alternative=""
                            label="Units"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.unit_id"
                                @change="getMembers"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="unit in units"
                                    :value="unit.id"
                                    :key="unit.id"
                                >
                                    {{ unit.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showMembers">
                        <base-input alternative=""
                            label="Unit Members"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.member_id"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="member in members"
                                    :value="member.id"
                                    :key="member.id"
                                >
                                    {{ member.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <!-- <div class="col-lg-4">
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            label="Specific Audit Date"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                v-model="filters.date"
                                :config="{allowInput: true}"
                                class="form-control datepicker"
                            >
                            </flat-picker>
                        </base-input>
                    </div> -->

                    <div class="col-lg-4">
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            label="Select Audit Date Range"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                v-model="filters.date_range"
                                :config="{allowInput: true, mode: 'range'}"
                                class="form-control datepicker"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-12">
                        <button
                            class="btn btn-lg btn-info btn-block"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            @click="applyFilters()"
                        >
                            <i class="fa fa-check"></i>
                            Apply Filters
                        </button>
                        <button
                            class="btn btn-lg btn-danger btn-block"
                            @click="displayFilters()"
                        >
                            <i class="fa fa-times"></i>
                            Remove Filters
                        </button>
                    </div>
                </div>
                <hr>
            </div>

            <!-- import Audit Trails Data template here -->
            <AuditTrailsData
                v-bind:records="records"
                v-bind:hasPagination="hasPagination"
                v-bind:pagination="pagination"
                v-bind:paginate="paginate"
                v-bind:privileges="privileges"
                v-if="! isLoadingBlade"
            />

        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import AuditTrailsData from './AuditTrailsData';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'AuditTrails',
    components: {
        AuditTrailsData,
        flatPicker
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        filters: {
            folio: null,
            department_id: null,
            unit_id: null,
            date: null,
            date_range: null,
            member_id: null,
            email: null,
            limit: this.$store.state.url.perPage // get per page count
        },
        folio: {
            prefix: 'MDCN/R/',
            number: null
        },
        page: 1,
        departments: [],
        units:[],
        members: [],
        showFilters: false,
        isFiltered: false,
        showExportPages: false,
        disableExportPageButton: true,
        filteredTitle: 'Filtered Audit Trail Actions',
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl', 'getRootUrl', 'user', 'exportPerSheet']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == this.$route.path) {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        },
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        },
        showUnits() {
            return this.units && this.units.length > 0;
        },
        showMembers() {
            return this.members && this.members.length > 0;
        },
        showState() {// display state column
            return this.filters.unit_id != null;
        },
        hasExportPages() {
            let divider = this.$store.state.url.exportPerSheet;
            return this.pagination.total > divider;
        },
        exportPages() {
            let divider = this.$store.state.url.exportPerSheet;
            let pages = Math.ceil(this.pagination.total / divider);
            return pages;
        }
    },
    methods: {
        ...mapActions(['getAuditTrailRecords', 'getPaginatedPaymentItems', 'getFilteredAuditTrailRecords',
            'getExportPayments']),
        applyFilters() {// apply filters function
            let status = this.validFilterInputs();
            this.showErrorStatus = false;
            if(status) {
                this.preloader();// show loading
                if(this.folio.number) {// add folio number
                    this.filters.folio = this.folio.prefix + this.folio.number;
                }
                this.getFilteredAuditTrailRecords(this.filters).then((res) => {// get
                    this.isLoadingBlade = false;
                    this.setPages(res.records);//set data
                    this.isFiltered = true;
                    this.getFilteredTitle();// set filtered title
                    swal.close();
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                let msg = 'Kindly select at least a unit member or date range, and try again';
                swal.fire('Invalid Selection', msg, 'warning');
            }
        },
        getFilteredTitle() {// set filtered payment title
            let data = this.filters;
            this.filteredTitle = '';
            this.filteredTitle += 'Filtered Audit Trail Actions Performed';
            if(data.member_id) {
                let member = this.members.filter(item => item.id == data.member_id);
                this.filteredTitle += ' By <em class="text-danger">' +member[0].name+'</em>';
            }
            this.filteredTitle += data.folio ? (' on <em class="text-danger">'+data.folio+'</em>') : '';
            this.filteredTitle += data.email ? (' on <em class="text-danger">'+data.email+'</em>') : '';
            this.filteredTitle += ' Only';
            this.filteredTitle += data.date ? '<br><em><small>( on '+this.formatRangeDate(data.date)+')<\small><\em>' : '';
            if(data.date_range) {// Add date range to title
                let date = data.date_range.split('to');
                let from = this.formatRangeDate(date[0]);
                let to = this.formatRangeDate(date[1]);// eslint-disable-next-line
                this.filteredTitle += '<br><em><small>('+ from + (to ? ' to ' +to : '')+')<\small><\em>';
            }
        },
        formatRangeDate(value) {// format range date
            return value ? moment(value).format('Do MMM, YYYY') : false;
        },
        validFilterInputs() {
            let data = this.filters;
            return data.member_id || data.date_range || this.folio.number || data.email || data.date;
        },
        displayFilters() {// diplay filters template
            this.emptyFilters();// empty filters
            this.loadApplications();// reload payments
            this.showExportPages = false;
            return this.showFilters = ! this.showFilters;
        },
        changeFolioPrefix() {// change folio prefix
            return this.folio.prefix = (this.folio.prefix == 'MDCN/R/') ? 'MDCN/R/ALT/' : 'MDCN/R/';
        },
        emptyFilters() {// empty filters
            this.filters.folio = null;
            this.filters.department_id = null;
            this.filters.unit_id = null;
            this.filters.date = null;
            this.filters.date_range = null;
            this.filters.member_id = null;
            this.filters.email = null;
            this.isFiltered = false;
            this.filteredTitle = 'Filtered Audit Trail Actions';
            this.units = [];
            this.members = [];
            this.folio = {
                prefix: 'MDCN/R/',
                number: null
            };
        },
        loadApplications() {// load details
            let data = { 'limit' : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getAuditTrailRecords(data).then((res) => {// get records
                this.departments = res.departments;
                this.isLoadingBlade = false;
                this.setPages(res.records);//set data
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            // console.log(res)
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.records.current_page;
                    this.page = res.records.current_page;
                    this.setPages(res.records);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Audit Trail Recorded Yet!!!';
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = this.createNewUrl(exploded);// get new url
            // let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&'+exploded[3]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                this.pagination.number = res.records.current_page;
                this.page = res.records.current_page;
                this.setPages(res.records);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        displayExportPages() {
            this.showFilters = false;
            return this.showExportPages = ! this.showExportPages;
        },
        createNewUrl(exploded) {// create new url function
            let index = exploded.indexOf('page');
            exploded.splice(index);// remove page value
            let newUrl = '';
            let count = 1;
            for(let link of exploded) {
                newUrl += link+((count == exploded.length) ? '' : '&');
                count++;
            }
            newUrl += '&page='+this.page;
            return newUrl;
        },
        enableExportPageButton() {
            return this.disableExportPageButton = false;
        },
        getUnits() {// get units function
            this.units = [];
            this.members = [];
            this.filters.unit_id = null;
            this.filters.member_id = null;
            let id = this.filters.department_id;
            for(let department of this.departments) {
                if(department.id == id) {
                    this.units = department.units;
                }
            }
        },
        getMembers() {// get members function
            this.members = [];
            this.filters.member_id = null;
            let id = this.filters.unit_id;
            for(let unit of this.units) {
                if(unit.id == id) {
                    this.members = unit.members;
                }
            }
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadApplications();
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
</style>
