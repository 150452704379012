<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="30%">CPD Provider</th>
                            <th width="20%">Type / Email Address</th>
                            <th width="10%">Status</th>
                            <th width="10%">{{ title }}</th>
                            <th width="8%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.cpd ? row.cpd.name : 'N/A' }}
                            <br>
                            <badge size="lg"
                                type="dark"
                                class="text-capitalize"
                                v-if="row.cpd && row.cpd.username"
                            >
                                {{ row.cpd.username  }}
                            </badge>
                        </td>
                        <td>
                            {{ row.application_type && row.application_type.name ? row.application_type.name : 'N/A' }}
                            <br>
                            <badge size="lg"
                                type="primary"
                                class="text-lowercase"
                            >
                                {{ row.cpd && row.cpd.email ? row.cpd.email : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            <badge size="lg" :type="getColor(row.status)" class="text-uppercase">
                                {{ row.status ? row.status : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.application_date | getDateFormat }}
                        </td>
                        <td>
                            <router-link 
                                :to="'/cpd/application-details/'+ row.id"
                                class="btn btn-sm btn-success"
                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                            >
                                <i class="fa fa-eye"></i> Details
                            </router-link>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'PendingApplicationData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "title",
            "paginatedItems", "code"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getColor(status) {
                if(status == 'submitted') {
                    return 'warning';
                } else if(status == 'approved') {
                    return 'success';
                } else {
                    return 'danger';
                }
            },
            redirectUrl(id) {// redirect to view page
                return window.location.href = '/cpd/application-details/'+id;
            }
        },
    }
</script>