
<template>
    <div>
        <template>
            <tabs fill class="flex-column flex-md-row">
                <card shadow>
                    <tab-pane title="Medical Applications">
                        <span slot="title">
                            <i class="ni ni-books"></i>
                            Medical Applications
                        </span>
                        <!-- import Pending Applications template here -->
                        <MedicalRejectedApplications 
                            :type="'medical'"
                            :status="status"
                        />
                    </tab-pane>

                    <tab-pane title="Dental Applications">
                        <span slot="title">
                            <i class="ni ni-badge"></i>
                            Dental Applications
                        </span>
                        <!-- import Rejected Applications template here -->
                        <!-- <DentalRejectedApplications /> -->
                        <MedicalRejectedApplications 
                            :type="'dental'"
                            :status="status"
                        />
                    </tab-pane>
                </card>
            </tabs>
        </template>
    </div>
</template>
<script>
  import MedicalRejectedApplications from './RejectedApplications/MedicalRejectedApplications';
//   import DentalRejectedApplications from './RejectedApplications/DentalRejectedApplications';

  export default {
    name: 'RejectedApplications',
    props: ['status'],
    components: {
        MedicalRejectedApplications,
        // DentalRejectedApplications
    }
  };
</script>
<style></style>
