
<template>
    <div>
        <h3 class="text-uppercase">{{ isEdit }} ACCREDITED HOSPITAL</h3>
        <hr>
        <div class="row align-items-center">

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Hospital's Name"
                    placeholder="Enter Hospital's Name"
                    input-classes="form-control-alternative"
                    v-model="record.name"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input label="Training Type">
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.type"
                        :disabled="disableType"
                    >
                        <option  :value="null" disabled>---Select One---</option>
                        <option value="medical">Medical Training</option>
                        <option value="dental">Dental Training</option>
                    </select>
                </base-input>
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Hospital's Quota"
                    placeholder="Hospital's Quota"
                    input-classes="form-control-alternative"
                    v-model="record.quota"
                    required
                />
            </div>

            <div class="col-lg-4">
                <base-input
                    label="Expiry Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required
                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: true}"
                        class="form-control datepicker"
                        v-model="record.expiry_date"
                    >
                    </flat-picker>
                </base-input>
            </div>

            <div class="col-lg-4">
                <base-input label="Status">
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.status"
                    >
                        <option  :value="null" disabled>---Select One---</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </base-input>
            </div>

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="updateHospital()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : isEdit + ' Record'}}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('editRecord', null, false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'EditHospital',
        props: ['privileges', 'data', 'loadHospitals'],
        components: {
            flatPicker
        },
        data() {
            return {
                record: {
                    name: null,
                    quota: null,
                    type: null,
                    expiry_date: null,
                    status: 'active',
                    id: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        computed: {
            isEdit() {
                return this.record && this.record.id ? 'Update' : 'Add';
            },
            disableType() {
                return this.record && this.record.id ? true : false;
            }
        },
        methods: {
            ...mapActions(['updateAccreditedHospital']),
            setData() {// set data for editing
                let data = this.data;
                if(data) {
                    this.record =   {
                        name: data.name,
                        quota: data.quota,
                        type: data.type,
                        expiry_date: data.expiry_date,
                        status: data.status,
                        id: data.id
                    }
                }
            },
            emptyEduRecords() {
                return this.record = {
                    name: null,
                    quota: null,
                    type: null,
                    expiry_date: null,
                    status: 'active',
                    id: null
                };
            },
            updateHospital() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save department
                    let data = this.record;
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.updateAccreditedHospital(data).then((res) => {// update unit
                        this.loadHospitals();// refresh page
                        if(res.status) {
                            let type = (this.isEdit == 'Update') ? 'Updated' : 'Added';
                            swal.fire("Record "+type, res.msg, "success");
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadHospitals();// refresh page
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let hospital = { name: null, quota: null, type: null, expiry_date: null };
                hospital.expiry_date = data.expiry_date ? data.expiry_date : this.showInuptError('Expiry Date');
                hospital.quota = data.quota ? data.quota : this.showInuptError('Quota');
                hospital.type = data.type ? data.type : this.showInuptError('Training Type');
                hospital.name = data.name ? data.name : this.showInuptError('Hospital\'s Name');
                return this.isEmpty(hospital) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {// set records
            this.setData();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>