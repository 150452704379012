<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Hospital's Name</th>
                            <th>Email Address <br>/ Phone Number</th>
                            <th>Selected Hospital</th>
                            <th>Status</th>
                            <th>Year of <br> Establishment</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            <span v-html="breakWord(row.name)"></span>
                        </td>
                        <td>
                            {{ row.email ? row.email : 'N/A' }}<br>
                            <badge size="lg"
                                type="primary"
                                class="text-uppercase"
                            >
                                {{ row.phone ? row.phone : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            <span v-if="row.teaching_hospital && row.teaching_hospital.name" v-html="breakWord(row.teaching_hospital.name)"></span>
                        </td>
                        <td>
                            <badge size="lg"
                                :type="((row.status == 'enable') ? 'success' : 'danger')"
                                class="text-uppercase"
                            >
                                {{ row.status }}
                            </badge>
                        </td>
                        <td>{{ row.establishment ? row.establishment : 'N/A' }}</td>
                        <td>
                            <base-button
                                :type="((row.status == 'enable') ? 'danger' : 'success')"
                                size="sm"
                                @click="changeStatus(row.id, (row.status != 'new'))"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges 
                                            || (row.status == 'new')"
                            >
                                <i :class="'fa fa-'+((row.status == 'enable') ? 'ban' : 'check')"></i>
                                {{ (row.status == 'enable') ? 'Disable' : 'Enable' }}
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions } from 'vuex';

    export default {
        name: 'HospitalsData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "paginatedItems", "loadHospitals"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            ...mapActions(['changeTrainingHospitalStatus']),
            changeStatus(id, check) {
                if(check) {// if account is not new, proceed
                    let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
                    this.preloader(); // show loading
                    this.changeTrainingHospitalStatus(data).then((res) => {// delete country
                        if(res.status) {
                            this.loadHospitals();// refresh page
                            swal.fire("Status Changed", res.msg, "success");
                        } else {
                            this.loadHospitals();// refresh page
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadHospitals();// refresh page
                        swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                    });
                }
            },
            breakWord(word, length = 20) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>