
<template>
    <div>
        <h3 class="text-uppercase text-center">
            {{ isEdit }} ACCREDITED CPD Provider
        </h3>
        <hr>
        <div class="row align-items-center">

            <div class="col-lg-12">
                <base-input alternative=""
                    label="CPD Provider's Name"
                    placeholder="Enter CPD Provider's Name"
                    input-classes="form-control-alternative"
                    v-model="record.name"
                    required
                />
            </div>

            <!-- <div class="col-lg-6">
                <base-input alternative=""
                    label="CPD Provider's Username"
                    input-classes="form-control-alternative"
                    v-model="record.username"
                    required
                    readonly
                />
            </div> -->

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="updateCpd()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : isEdit + ' Record'}}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('addProvider')"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddAccreditedCpd',
        props: ['privileges', 'data', 'loadAccreditedCpds'],
        data() {
            return {
                record: {
                    name: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false
            }
        },
        computed: {
            isEdit() {
                return this.record && this.record.id ? 'Update' : 'Add';
            },
            disableType() {
                return this.record && this.record.id ? true : false;
            }
        },
        methods: {
            ...mapActions(['modifyDeleteCpd']),
            setData() {// set data for editing
                let data = this.data;
                if(data) {
                    this.record =   {
                        name: data.name,
                        id: data.id
                    }
                }
            },
            emptyEduRecords() {
                return this.record = {
                    name: null,
                    username: null
                };
            },
            updateCpd() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save department
                    let data = this.record;
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    data.option = 'update';
                    this.preloader(); // show loading
                    this.modifyDeleteCpd(data).then((res) => {// update unit
                        this.loadAccreditedCpds();// refresh page
                        if(res.status) {
                            let type = (this.isEdit == 'Update') ? 'Updated' : 'Added';
                            swal.fire("Record "+type, res.msg, "success");
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadAccreditedCpds();// refresh page
                        swal.fire(err.message+"!", "An error occurred while processing, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let cpd = { name: null };
                cpd.name = data.name ? data.name : this.showInuptError('Provider\'s Name');
                return this.isEmpty(cpd) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {// set records
            this.setData();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>