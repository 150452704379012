
<template>
    <div>
        <!-- import Users template here -->
        <AdminUser
            :privileges="privileges"
            :changeTitle="changeTitle"
        />

    </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import AdminUser from './AdminUser'; 

  export default {
    name: 'Users',
    props: ['privileges'],
    components: {
        AdminUser
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        title: 'User Accounts',
      }
    },
    computed: {
        ...mapGetters(['menus'])
    },
    methods: {
        changeTitle(value) {// change title
            this.title = value;
        },
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
