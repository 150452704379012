
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="container" v-if="! isLoadingBlade">
            <div class="row" v-if="showBackButton">
                <div class="col-lg-12 text-right">
                    <button
                        class="btn btn-lg btn-danger"
                        @click="loadPosting(null)"
                    >
                        <i class="fa fa-reply"></i>
                        Back To Batches
                    </button>
                    <br><br>
                </div>
            </div>
            <div v-if="! showPosting">
                 <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-6"></div>
                    <div class="col-lg-4 text-right">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="searchKey"
                                @change="searchRecords()"
                            >
                                <option :value="null" disabled selected>
                                    --- Search By Year ---
                                </option>
                                <option
                                    v-for="year in years"
                                    :key="year"
                                    :value="year"
                                >
                                    {{ year }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                </div>

                <AttachmentApplicationData
                    :records="records"
                    :hasPagination="hasPagination"
                    :pagination="pagination"
                    :paginate="paginate"
                    :privileges="privileges"
                    :searchKey="searchKey"
                    :type="'posting'"
                    v-on:loadAplications="loadPosting"
                    v-if="! showPosting && ! isLoadingBlade"
                />
            </div>

            <template v-if="showPosting">
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Awaiting Posting">
                            <span slot="title">
                                <i class="ni ni-books"></i>
                                Awaiting Posting
                            </span>
                            <!-- import Awaiting Posting template here -->
                            <AwaitingPosting
                                :batch="batch"
                                :changeTitle="changeTitle"
                                :privileges="privileges"
                                :loadTitle="loadTitle"
                                :status="'awaiting-posting'"
                                :displayBackButton="displayBackButton"
                                v-if="batch && batch.id"
                            />
                        </tab-pane>

                        <tab-pane title="Posted Applicants">
                            <span slot="title">
                                <i class="ni ni-badge"></i>
                                Posted Applicants
                            </span>
                            <!-- import Posted Posting template here -->
                            <AwaitingPosting
                                :batch="batch"
                                :changeTitle="changeTitle"
                                :privileges="privileges"
                                :loadTitle="loadTitle"
                                :status="'posted'"
                                :displayBackButton="displayBackButton"
                                v-if="batch && batch.id"
                            />
                        </tab-pane>
                    </card>
                </tabs>
            </template>
        </div>

        <div class="col-sm-12 text-center" v-if='showErrorStatus'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import AwaitingPosting from './AwaitingPosting/AwaitingPosting.vue';
  import AttachmentApplicationData from '../AttachmentApplications/AttachmentApplicationData.vue';
  import moment from 'moment';

  export default {
    name: 'AttachmentPosting',
    props: ['batches', 'changeTitle', 'privileges', 'loadTitle'],
    components: {
        AwaitingPosting,
        AttachmentApplicationData
    },
    data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: true,
            batch: null,
            showPosting: false,
            records: [],
            paginatedItems: [],
            pagination: {
                total: null,
                prev_page_url: null,
                next_page_url: null,
                current_page: null,
                last_page: null,
                number: 1,
                from: 0,
                to: null,
                perPage: this.$store.state.url.perPage // get per page count
            },
            page: 1,
            searchKey: null,
            showBackButton: false
        }
    },
    computed: {
        ...mapGetters(['loadingBladeUrl', 'menus']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        },
        years() {
            let startYear = 2018;// start from 2019
            let currentYear = parseInt(moment().format('YYYY'));
            let years = [];
            for (let i = currentYear; i > startYear; i-- ) {
                years.push(i);
            }
            return years;
        }
    },
    methods: {
        ...mapActions(['getAttachmentBatches', 'getPaginatedPaymentItems']),
        displayBackButton(status) {// show back button
            this.showBackButton = status;
        },
        loadPosting(data) {
            this.loadTitle();
            this.batch = data;
            this.displayBackButton(true);
            return this.showPosting = ! this.showPosting;
        },
        loadAttachmentBatches() {// load details
            this.loadTitle();
            let data = { 'limit' : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getAttachmentBatches(data).then((res) => {// get users
                this.isLoadingBlade = false;
                this.setPages(res.batches);//set data
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.batches.current_page;
                    this.setPages(res.batches);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        searchRecords() {
            this.showErrorStatus = false;
            let key = this.searchKey;
            if(key) {
                this.isLoadingBlade = true;
                let data = { 'key' : key };
                this.records = [];
                this.getAttachmentBatches(data).then((res) => {// get records
                    this.isLoadingBlade = false;
                    this.records = res.batches;
                    if(this.records.length == 0) {
                        this.showErrorStatus = true;
                        this.errorType = 'warning';
                        this.errorMsg = 'No Matched Record!!!';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
                this.emptyRecordsMsg();
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Attachment Program Batches yet!!!';
        }
    },
    mounted() {
      this.loadAttachmentBatches();
    }
  };
</script>
<style></style>
