
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th>CPD Provider's Name <br> / Provider's No</th>
                        <th>Email Address / <br> Phone Number</th>
                        <th>Category</th>
                        <th>Accreditation Status <br>/ State Location</th>
                        <th>Date Created</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            <span v-html="breakWord(row.name)"></span><br>
                            <badge type="primary">
                                {{ row.username ? row.username : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.email ? row.email : 'N/A' }}<br>
                            <badge type="primary">
                                {{ row.phone ? row.phone : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.category ? row.category : 'N/A' }}<br>
                            <badge type="info">
                                {{ row.cac ? replaceAll(row.cac, '-', ' ') : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.accredit_status ? replaceAll(row.accredit_status, '-', ' ') : 'N/A' }}<br>
                            <badge type="primary">
                                {{ row.state && row.state.name ? row.state.name : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.created_at | formatDate }}
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>

            <div class="col-lg-12">
                <hr>
                <div class="container">
                    <h3 class="text-uppercase">
                        Total Number of CPD Providers Accredited:
                        <span class="right">
                            {{ accreditedCount }}
                        </span>
                    </h3>

                    <h3 class="text-uppercase">
                        Total Number of CPD Providers Awaiting Accreditation:
                        <span class="right">
                            {{ waitingCount }}
                        </span>
                    </h3>
                </div>
                <hr>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import Badge from '../../../components/Badge.vue';

    export default {
    components: { Badge },
        name: 'ProvidersReportingData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'accreditedCount','waitingCount',
                'replaceAll'],
        filters: {
            formatDate(date) {
                return date ? moment(date).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            breakWord(word, length = 40) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
