
<template>
    <div>

        <div>

            <div
                class="col-lg-12 text-right"
            >
                <button
                    class="btn btn-lg btn-primary"
                    @click="editBreakdown()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges"
                >
                    <i class="fa fa-plus"></i> Add New Breakdown
                </button>

                <button
                    class="btn btn-lg btn-danger"
                    @click="$emit('showBreakdowns', false)"
                >
                    <i class="fa fa-reply"></i> Back
                </button>
                <br><br>
            </div>

            <div
                class="card shadow"
                v-if="breakdowns && breakdowns.length > 0 && ! showErrorStatus"
            >
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="breakdowns"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="20%">Payment Breakdown</th>
                            <th width="10%">Amount</th>
                            <th width="15%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td class="budget">
                                {{ index+1 }}
                            </td>
                            <td>
                                {{ row.name }}
                            </td>
                            <td v-html="(row.amount > 0 ? getExactCurrency(item) +formatPrice(row.amount) : 'N/A')"></td>
                            <td class="text-capitalize">
                                <div class="right">
                                    <base-button
                                        type="info" size="sm"
                                        @click="editBreakdown(row)"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-pen"></i>
                                        Edit
                                    </base-button>

                                    <base-button
                                        type="danger" size="sm"
                                        @click="deleteBreakdown(row.id)"
                                        :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-trash"></i>
                                        Delete
                                    </base-button>
                                </div>
                            </td>
                        </template>
                    </base-table>
                </div>
            </div>

            <div
                class="container"
                v-if="item && item.application_type && ! showErrorStatus"
            >
                <hr>
                <div class="row">
                    <div class="col-lg-6">
                        <h3>{{ item.application_type.name }}'s Total Amount:</h3>
                    </div>
                    <div class="col-lg-6">
                        <h2 class="right" v-html="(getExactCurrency(item) + formatPrice(item.amount))"></h2>
                    </div>
                </div>
                <hr>
            </div>

            <div
                class="col-sm-12 text-center"
                v-if="showErrorStatus"
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

        </div>
    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'PaymentBreakdowns',
    props: ['payment', 'privileges', 'loadPaymentItems'],
    components: {
        // PaymentBreakdowns
    },
    data() {
      return {
        breakdowns: [],
        item: null,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
      }
    },
    computed: {
        ...mapGetters(['loadingBladeUrl']),
    },
    methods: {
        ...mapActions(['getPaymentItems', 'getPaginatedPaymentItems', 'updatePaymentBreakdown',
                'deletePaymentBreakdown']),
        getExactCurrency(record) {// get exact payment currency
            const currency = record.currency;
            return (currency == 'NGN') ? '&#x20a6; ' : '&dollar; ';
        },
        formatPrice(value) {
            if(value) {
                let val = (value/1).toFixed(2).replace(',', '.');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 'N/A';
            }
        },
        setData(data) {
            this.breakdowns = [];
            this.item = null;
            this.breakdowns = data && data.breakdowns.length > 0 ? data.breakdowns
                            : this.payment.has_breakdowns;
            this.item = data && data.payment ? data.payment : this.payment;
            this.emptyRecordsMsg();// check for empty records
        },
        emptyRecordsMsg() {
            return this.payment && this.payment.has_breakdowns.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Payment Breakdowns added yet!!!';
        },
        editBreakdown(breakdown) {
            let name = breakdown && breakdown.name ? breakdown.name : '';
            let amount = breakdown && breakdown.amount ? breakdown.amount : '';
            let title = this.payment && this.payment.application_type ? this.payment.application_type.name : 'N/A';
            swal.fire({
                title: (breakdown ? 'Update '+name : 'Add New Breakdown to '+title),
                html:
                    '<br>'+
                    '<label><b>Payment Title</b></label>' +
                    '<input id="swal-input1" class="swal2-input" value="'+ name+'">' +
                    '<label><b>Amount</b></label><br>' +
                    '<input id="swal-input2" class="swal2-input" value="'+ amount+'" type="number">',
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: (breakdown ? 'Update it!' : 'Add Now!'),
                cancelButtonText: 'No, cancel!',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let data =  {
                        'id' : breakdown ? breakdown.id : null,
                        'name' : document.getElementById('swal-input1').value,
                        'amount' : document.getElementById('swal-input2').value,
                        'application_payment_id' : this.payment.id,// payment type id
                        'admin_id' : this.$store.state.user.userId // add admin user id
                    };
                    if(data && data.name && data.amount) {//update
                        return this.updatePaymentBreakdown(data).then((response) => {
                            if (!response.status) {
                            throw new Error(response.msg)
                            }
                            return response
                        }).
                        catch((error) => {
                            swal.showValidationMessage(
                            `Request failed: ${error}`
                            )
                        });
                    } else {
                       swal.showValidationMessage(
                            `Request failed: Fill all necessary fields`
                        )
                    }
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then((res) => {
                if (res.value) {
                    let type = res.value.status ? 'success' : 'error';
                    let title = res.value.status ? 'Breakdown '+res.value.type+'!' : 'Error Occurred!';
                    swal.fire(title, res.value.msg, type);
                    this.setData(res.value);// update records
                    this.loadPaymentItems();// refresh items
                }
            });
        },
        deleteBreakdown(id) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall(id);// make api call
                }
            });
        },
        makeDeleteApiCall(id) {
            let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
            this.preloader(); // show loading
            this.deletePaymentBreakdown(data).then((res) => {// delete breakdown
                this.loadPaymentItems();// refresh items
                if(res.status) {
                    this.setData(res);// update records
                    swal.fire("Record Deleted", res.msg, "success");
                } else {
                    swal.fire("Error Occurred!", res.msg, "warning");
                }
            }).
            catch(err => {
                this.loadPaymentItems();// refresh items
                swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    created() {
        this.setData();
    }
  };
</script>
<style scoped>
    .right {
        float: right;
    }
</style>
