
<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="! isLoadingBlade">
                            <!-- import Assessment Batches template here -->
                            <AssessmentBatch
                                v-bind:privileges="privileges"
                                v-bind:changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'assessment-batches'"
                            />

                            <!-- import Assessment Type template here -->
                            <AssessmentType
                                v-bind:privileges="privileges"
                                v-bind:changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'assessment-types'"
                            />

                            <!-- import Assessment Applications template here -->
                            <AssessmentApplication
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                :batches="batches"
                                v-if="code && code == 'assessment-applications'"
                            />

                            <!-- import Assessment Previous Batch template here -->
                            <AssessmentPreviousBatch
                                v-bind:privileges="privileges"
                                v-bind:changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'assessment-previous-entries'"
                            />

                            <!-- import Assessment Exam Reporting template here -->
                            <Reporting
                                v-bind:privileges="privileges"
                                v-bind:changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'assessment-exam-reporting'"
                            />

                            <!-- import Previous Assessment Exam Batch Reporting template here -->
                            <PreviousBatchReporting
                                v-bind:privileges="privileges"
                                v-bind:changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'previous-assessment-exam-reporting'"
                            />

                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-sm-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                <span class="alert-inner--text">
                                    {{errorMsg}}
                                </span>
                            </base-alert>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import AssessmentBatch from './AssessmentBatch/AssessmentBatch';
  import AssessmentType from './AssessmentType/AssessmentType';
  import AssessmentApplication from './AssessmentApplication/AssessmentApplication';
  import AssessmentPreviousBatch from './AssessmentPreviousBatch/AssessmentPreviousBatch';
  import Reporting from './Reporting/Reporting.vue';
  import PreviousBatchReporting from './Reporting/PreviousBatchReporting.vue';

  export default {
    name: 'Assessment',
    components: {
        AssessmentBatch,
        AssessmentType,
        AssessmentApplication,
        AssessmentPreviousBatch,
        Reporting,
        PreviousBatchReporting
    },
    data() {
      return {
        code: this.$route.params.code,
        title: null,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        batches: []
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == this.$route.path) {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        }
    },
    methods: {
        ...mapActions(['getBatches']),
        loadAssessmentBatches() {// load payments
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getBatches().then((res) => {
                this.isLoadingBlade = false;
                this.batches = res.batches;
                this.loadTitle(); // load title
            }).
            catch(() => {
                this.batches = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        loadTitle() {// load title
            return this.title = this.replaceAll(this.code, '-', ' ');
        },
        replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, 'g'), replace);
        },
        changeTitle(value, status) {// change title
            return status ? this.title = value : this.loadTitle();
        }
    },
    created() {
        this.loadAssessmentBatches(); // load batches
    }
  };
</script>
<style scoped>
</style>
