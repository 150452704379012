
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th>Institution's Name <br> / Hospital Type</th>
                        <th>Training Type</th>
                        <th>CURRENT QUOTA</th>
                        <th>EXPIRY DATE</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            <span v-html="breakWord(row.name)"></span><br>
                            <badge type="primary">
                                {{ row.hospital_type ? row.hospital_type : 'N/A' }}
                            </badge>
                        </td>
                        <td class="text-capitalize">
                            {{ row.type ? (row.type + ' Training') : 'N/A' }}
                        </td>
                        <td>
                            {{ row.quota ? row.quota : 'NILL' }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.expiry_date | formatDate }}
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>

            <div class="col-lg-12">
                <hr>
                <div class="container">
                    <h3 class="text-uppercase">
                        Total Number of Institutions Accredited:
                        <span class="right">
                            {{ accreditedCount }}
                        </span>
                    </h3>

                    <h3 class="text-uppercase">
                        Total Number of Institutions Awaiting Accreditation:
                        <span class="right">
                            {{ waitingCount }}
                        </span>
                    </h3>
                </div>
                <hr>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import Badge from '../../../components/Badge.vue';

    export default {
    components: { Badge },
        name: 'HospitalReportingData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'accreditedCount',
            'waitingCount'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            breakWord(word, length = 40) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
