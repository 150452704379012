
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-10 text-right">
                        <div v-if="! hasExportPages">
                            <form :action="getRootUrl+'/export-application-report'" method="POST">
                                <input
                                    v-for="(value, item) in filters"
                                    :key="item"
                                    type="hidden"
                                    :name="item"
                                    :value="value"
                                />

                                <input
                                    type="hidden"
                                    name="admin_id"
                                    :value="user.id"
                                />

                                <button
                                    :class="'btn btn-lg btn-'+ (showFilters ? 'danger' : 'primary')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    @click="displayFilters()"
                                >
                                    <i :class="'fa fa-'+(showFilters ? 'times' : 'sort')"></i>
                                    {{ showFilters ? 'Remove Filters' : 'Filters Report'}}
                                </button>

                                <button
                                    class="btn btn-lg btn-success"
                                    nativeType="submit"
                                    v-if="records && records.length > 0"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-file-export"></i>
                                    Export Report
                                </button>
                            </form>
                        </div>

                        <div v-if="hasExportPages">
                            <button
                                :class="'btn btn-lg btn-'+ (showFilters ? 'danger' : 'primary')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @click="displayFilters()"
                            >
                                <i :class="'fa fa-'+(showFilters ? 'times' : 'sort')"></i>
                                {{ showFilters ? 'Remove Filters' : 'Filters Report'}}
                            </button>
                            <button
                                :class="'btn btn-lg btn-'+ (showExportPages ? 'danger' : 'success')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @click="displayExportPages()"
                            >
                                <i :class="'fa fa-'+(showExportPages ? 'times' : 'sort')"></i>
                                {{ showExportPages ? 'Remove Export Pages' : 'Show Export Pages'}}
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-12" v-if="hasExportPages && showExportPages">
                        <hr>
                        <form :action="getRootUrl+'/export-application-report'" method="POST">
                            <input
                                v-if="item != 'limit'"
                                v-for="(value, item) in filters"
                                :key="item"
                                type="hidden"
                                :name="item"
                                :value="value"
                            />

                            <input
                                type="hidden"
                                name="admin_id"
                                :value="user.id"
                            />

                            <input
                                type="hidden"
                                name="limit"
                                :value="exportPerSheet"
                            />

                            <div class="col-lg-12 text-center">
                                <h3 class="text-danger">
                                    Note: Maximum of {{ exportPerSheet }} record(s) per export.
                                </h3>
                                <base-input label="Select Range of Records" required>
                                    <select
                                        class="form-control form-control-alternative"
                                        name="export_page"
                                        @change="enableExportPageButton()"
                                        required
                                    >
                                        <option  value="null" disabled selected>
                                            ---Select One---
                                        </option>
                                        <option
                                            v-for="page in exportPages"
                                            :key="page"
                                            :value="page"
                                        >
                                            Page {{ page }}
                                        </option>
                                    </select>
                                </base-input>
                            </div>

                            <button
                                class="btn btn-lg btn-success btn-block"
                                style="margin-top: 10px;"
                                nativeType="submit"
                                v-if="records && records.length > 0"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges || disableExportPageButton"
                            >
                                <i class="fa fa-file-export"></i>
                                Export Page
                            </button>
                        </form>
                        <hr>
                    </div>
                </div>
            </div>

            <div class="container" v-if="showFilters && ! isLoadingBlade">
                <hr>
                <h3 class="mb-0 text-uppercase text-center" v-html="filteredTitle"></h3>
                <hr v-if="! isFiltered">
                <div class="row" v-if="! isFiltered">
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Application Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.application_type_id"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="type in applicationTypes"
                                    :key="type.id"
                                    :value="type.id"
                                >
                                    {{ type.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showLicenseYear">
                        <base-input alternative=""
                            label="License Year"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.license_year"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="year in years"
                                    :value="year"
                                    :key="year"
                                >
                                    {{year}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Age Ranges"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.age"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option value="1-30">30 below</option>
                                <option value="31-40">31 - 40</option>
                                <option value="41-50">41 - 50</option>
                                <option value="51-60">51 - 60</option>
                                <option value="61-70">61 - 70</option>
                                <option value="70-200">70 above</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Marital Status"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.marital_status"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option value="single">Single</option>
                                <option value="married">Married</option>
                                <option value="divorced">Divorced</option>
                                <option value="widow">Widow/Widower</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Gender"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.gender"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Employment Status"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.employ_status"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option value="unemployed">Unemployed</option>
                                <option value="employed">Employed</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showEmploymentSector">
                        <base-input alternative=""
                            label="Employment Sector"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.employ_sector"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option value="private">Private</option>
                                <option value="government">Government</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Practice Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.doctor_type"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option value="1">Medical Registration</option>
                                <option value="2">Dental Registration</option>
                                <option value="3">Alternative Registration</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Registration Type"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.reg_type"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="regTypes && regTypes.length > 0"
                                    v-for="regType in regTypes"
                                    :key="regType.id"
                                    :value="regType.id"
                                >
                                    {{ regType.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Nationality"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.nationality"
                                @change="emptyState"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="locations && locations.countries && locations.countries.length > 0"
                                    v-for="country in locations.countries"
                                    :key="country.id"
                                    :value="country.id"
                                >
                                    {{ country.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showStateOfOrigin">
                        <base-input alternative=""
                            label="State of Origin"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.state_origin"
                                @change="getOriginLgas('origin')"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="locations && locations.states && locations.states.length > 0"
                                    v-for="state in locations.states"
                                    :key="state.id"
                                    :value="state.id"
                                >
                                    {{ state.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showStateOfOrigin && showLgaOfOrigin">
                        <base-input alternative=""
                            label="Lga of Origin"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.lga_origin"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="originLgas && originLgas.length > 0"
                                    v-for="lga in originLgas"
                                    :key="lga.id"
                                    :value="lga.id"
                                >
                                    {{ lga.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Year of Graduation"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.graduation"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="year in years"
                                    :value="year"
                                    :key="year"
                                >
                                    {{year}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Specializations"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.specialization"
                                @change="getSubSpecialization"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="specializations && specializations.length > 0"
                                    v-for="specialization in specializations"
                                    :value="specialization.id"
                                    :key="specialization.id"
                                >
                                    {{ specialization.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showSubSpecializations">
                        <base-input alternative=""
                            label="Sub Specializations"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.sub_specialization"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="subSpecializations && subSpecializations.length > 0"
                                    v-for="subSpec in subSpecializations"
                                    :value="subSpec.id"
                                    :key="subSpec.id"
                                >
                                    {{ subSpec.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="State of Practice"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.state_practice"
                                @change="getOriginLgas('practice')"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="locations && locations.states && locations.states.length > 0"
                                    v-for="state in locations.states"
                                    :key="state.id"
                                    :value="state.id"
                                >
                                    {{ state.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showLgaOfPractice">
                        <base-input alternative=""
                            label="Lga of Practice"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.lga_practice"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="practiceLgas && practiceLgas.length > 0"
                                    v-for="lga in practiceLgas"
                                    :key="lga.id"
                                    :value="lga.id"
                                >
                                    {{ lga.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Schools"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.school"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="school in schools"
                                    :value="school.id"
                                    :key="school.id"
                                >
                                    {{school.school_name}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showAqCategories">
                        <base-input alternative=""
                            label="AQ Categories"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.category"
                                @change="getQualifications"
                            >
                                <option :value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="category in categories"
                                    :key="category.id"
                                    :value="category.id"
                                >
                                    {{ category.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div
                        class="col-lg-4"
                        v-if="showAqCategories && filters.category && qualifications && qualifications.length > 0"
                    >
                        <base-input alternative=""
                            label="AQ Qualifications"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.qualification"
                            >
                                <option :value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="qual in qualifications"
                                    :key="qual.id"
                                    :value="qual.id"
                                >
                                    {{ qual.course_title }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showLogsDestination">
                        <base-input alternative=""
                            label="Destination Country"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.country_travel"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-show="locations && locations.countries && locations.countries.length > 0"
                                    v-for="country in locations.countries"
                                    :key="country.id"
                                    :value="country.name"
                                >
                                    {{ country.name }}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showLogsDestination">
                        <base-input alternative=""
                            label="Purpose of Travel"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.purpose_travel"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option value="For further education">
                                    For further education
                                </option>
                                <option value="Professional practice">
                                    Professional practice
                                </option>
                                <option value="To join spouse">
                                    To join spouse
                                </option>
                                <option value="Dissatisfaction with facilities for medical/dental practice in Nigeria">
                                    Dissatisfaction with facilities for medical/dental practice in Nigeria
                                </option>
                                <option value="Dissatisfaction with income from medical/dental practice in Nigeria">
                                    Dissatisfaction with income from medical/dental practice in Nigeria
                                </option>
                                <option value="Dissatisfaction with the regulation of medical/dental practice in Nigeria">
                                    Dissatisfaction with the regulation of medical/dental practice in Nigeria
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Application Year"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.application_year"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="year in years"
                                    :value="year"
                                    :key="year"
                                >
                                    {{year}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="isProvisionalReport">
                        <base-input alternative=""
                            label="Approval Year"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.year"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="year in years"
                                    :value="year"
                                    :key="year"
                                >
                                    {{year}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <!-- <div class="col-lg-4">
                        <base-input alternative=""
                            label="Folio Number (In Full)"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Folio Number in full"
                            v-model="filters.folio"
                        />
                    </div> -->

                    <div class="col-lg-4">
                        <label class="form-control-label">Folio Number</label>
                        <div class="row">
                            <div class="col-lg-5">
                                <base-dropdown>
                                    <template v-slot:title>
                                        <base-button type="primary" class="dropdown-toggle">
                                            {{ folio.prefix }}
                                        </base-button>
                                    </template>
                                    <a class="dropdown-item" href="javascript:void(0)" @click="changeFolioPrefix">
                                        {{ (folio.prefix == 'MDCN/R/') ? 'MDCN/R/ALT/' : 'MDCN/R/' }}
                                    </a>
                                </base-dropdown>
                            </div>
                            <div class="col-lg-7">
                                <base-input alternative=""
                                    input-classes="form-control-alternative text-capitalize"
                                    placeholder="Enter Folio Number"
                                    v-model="folio.number"
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Application Status"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.status"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            label="Date of Issue"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                v-model="filters.date_issue"
                                :config="{allowInput: true}"
                                class="form-control datepicker"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <!-- <div class="col-lg-4">
                        <base-input alternative=""
                            label="Application Year"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="filters.application_date"
                            >
                                <option value="null" selected disabled>--- Select One ---</option>
                                <option
                                    v-for="year in years"
                                    :value="year"
                                    :key="year"
                                >
                                    {{year}}
                                </option>
                            </select>
                        </base-input>
                    </div> -->

                    <div class="col-lg-4" v-if="! showLicenseYear">
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            label="Report Date Range"
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                v-model="filters.date_range"
                                :config="{allowInput: true, mode: 'range'}"
                                class="form-control datepicker"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-12">
                        <button
                            class="btn btn-lg btn-info btn-block"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            @click="applyFilters()"
                        >
                            <i class="fa fa-check"></i>
                            Apply Filters
                        </button>
                        <button
                            class="btn btn-lg btn-danger btn-block"
                            @click="displayFilters()"
                        >
                            <i class="fa fa-times"></i>
                            Remove Filters
                        </button>
                    </div>
                </div>
                <hr>
            </div>

            <!-- import Application Report Data template here -->
            <ApplicationReportData
                v-bind:records="records"
                v-bind:hasPagination="hasPagination"
                v-bind:pagination="pagination"
                v-bind:paginate="paginate"
                v-bind:privileges="privileges"
                v-if="! isLoadingBlade"
            />

        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import ApplicationReportData from './ApplicationReportData';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'ApplicationReport',
    props: ['privileges'],
    components: {
        ApplicationReportData,
        flatPicker
    },
    data() {
      return {
        payment: [],
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        filters: {
            application_type_id: null,
            license_year: null,
            date_range: null,
            state_practice: null,
            lga_practice: null,
            status: null,
            age: null,
            marital_status: null,
            gender: null,
            employ_status: null,
            employ_sector: null,
            doctor_type: null,
            graduation: null,
            year: null,
            reg_type: null,
            nationality: null,
            state_origin: null,
            lga_origin: null,
            specialization: null,
            sub_specialization: null,
            school: null,
            category: null,
            qualification: null,
            country_travel: null,
            purpose_travel: null,
            folio: null,
            date_issue: null,
            application_year: null,
            limit: this.$store.state.url.perPage // get per page count
        },
        folio: {
            prefix: 'MDCN/R/',
            number: null
        },
        page: 1,
        applicationTypes: [],
        states: [],
        regTypes: [],
        specializations: [],
        subSpecializations: [],
        schools: [],
        categories: [],
        qualifications: [],
        showFilters: false,
        isFiltered: false,
        showExportPages: false,
        disableExportPageButton: true,
        filteredTitle: 'Filtered Application Report',
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        ...mapGetters(['loadingBladeUrl', 'getRootUrl', 'locations', 'exportPerSheet', 'user']),
        hasExportPages() {
            let divider = this.$store.state.url.exportPerSheet;
            return this.pagination.total > divider;
        },
        exportPages() {
            let divider = this.$store.state.url.exportPerSheet;
            let pages = Math.ceil(this.pagination.total / divider);
            return pages;
        },
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        },
        years() {
            let startYear = moment().format('YYYY') - 119;// start from 1900
            let currentYear = parseInt(moment().format('YYYY'));
            let years = [];
            for (let i = currentYear; i > startYear; i-- ) {
                years.push(i);
            }
            return years;
        },
        showLicenseYear() {// display license year column
            return (this.filters.application_type_id == 7) || (this.filters.application_type_id == 15)
                    || (this.filters.application_type_id == 18);
        },
        showEmploymentSector() {
            return this.filters.employ_status && (this.filters.employ_status == 'employed')
        },
        showAqCategories() {
            return this.filters.application_type_id == 5;
        },
        showLogsDestination() {
            return this.filters.application_type_id == 8;
        },
        showLgaOfPractice() {
            return this.filters.state_practice && this.practiceLgas && this.practiceLgas.length > 0;
        },
        showStateOfOrigin() {
            return this.filters.nationality && (this.filters.nationality == 164);
        },
        showLgaOfOrigin() {
            return this.filters.nationality && (this.filters.nationality == 164) &&
                this.filters.state_origin && this.originLgas && this.originLgas.length > 0;
        },
        showSubSpecializations() {
            return this.filters.specialization && this.subSpecializations && this.subSpecializations.length > 0;
        },
        isProvisionalReport() {
            return this.filters.application_type_id && (this.filters.application_type_id == 1
                    || this.filters.application_type_id == 16 || this.filters.application_type_id == 2
                    || this.filters.application_type_id == 9 || this.filters.application_type_id == 25
                    || this.filters.application_type_id == 26 || this.filters.application_type_id == 27
                    || this.filters.application_type_id == 6 || this.filters.application_type_id == 5) &&
                    (this.filters.status == null || this.filters.status == 'approved');
        }
    },
    methods: {
        ...mapActions(['getApplications', 'getPaginatedPaymentItems', 'getFilteredApplication', 'getExportPayments', 'getLocations']),
        applyFilters() {// apply filters function
            let status = this.validFilterInputs();
            this.showErrorStatus = false;
            if(status) {
                this.preloader();// show loading
                if(this.folio.number) {// add folio number
                    this.filters.folio = this.folio.prefix + this.folio.number;
                }
                this.getFilteredApplication(this.filters).then((res) => {// get applications
                    this.isLoadingBlade = false;
                    this.applicationTypes = res.application_types;
                    this.states = res.states;// get states
                    this.setPages(res.applications);//set data
                    this.isFiltered = true;
                    this.getFilteredTitle();// set filtered title
                    swal.close();
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                let msg = 'Kindly select one of the filter actions, and try again';
                swal.fire('Invalid Selection', msg, 'warning');
            }
        },
        getFilteredTitle() {// set filtered payment title
            let data = this.filters;
            this.filteredTitle = 'Filtered Application Report';
            this.filteredTitle += this.filters.status ? ', ' + this.filters.status+' ' : '';
            if(data.application_type_id) {
                let type = this.applicationTypes.filter(item => item.id == data.application_type_id);
                if(type && type.length > 0) {
                    this.filteredTitle += ', ' + type[0].name;
                    this.filteredTitle += (data.license_year ? ' ('+data.license_year+')' : '');
                }
            }
            if(data.state_practice) { this.getExactLocation(data.state_practice, this.locations.states, 'State of Practice'); }// add state of practice title
            if(data.lga_practice) { this.getExactLocation(data.lga_practice, this.locations.lgas, 'Lga of Practice'); }// add lga of Practice title
            if(data.date_range) {// Add date range to title
                let date = data.date_range.split('to');
                let from = this.formatRangeDate(date[0]);
                let to = this.formatRangeDate(date[1]);// eslint-disable-next-line
                this.filteredTitle += '<br><em><small>('+from+' to '+to+')<\small><\em>';
            }
            if(data.age) {
                let range = data.age.split('-');
                let ageRange = '';
                if(range[1] && range[1] <= 30) {
                    ageRange = '30 - below';
                } else if(range[0] && range[0] >= 70) {
                    ageRange = '70 - above';
                } else {
                    ageRange = range[0]+' - '+range[1];
                }
                this.filteredTitle += ', Age range -> <em class="text-danger">'+ageRange+'</em>';
            }
            this.filteredTitle += data.marital_status ? ', Martial Status -> <em class="text-danger">'+data.marital_status+'</em>' : '';
            this.filteredTitle += data.gender ? ', Gender -> <em class="text-danger">'+data.gender+'</em>' : '';
            this.filteredTitle += data.employ_status ? ', Employment Status -> <em class="text-danger">'+data.employ_status+'</em>' : '';
            this.filteredTitle += data.employ_sector ? ', Employment Sector -> <em class="text-danger">'+data.employ_sector+'</em>' : '';
            if(data.doctor_type) {// add doctor type title
                let type = null;
                if(data.doctor_type == 1) { type = 'Medical Registration' }
                else if(data.doctor_type == 2) { type = 'Dental Registration' }
                else { type = 'Alternative Registration' }
                this.filteredTitle += ', Practice Type -> <em class="text-danger">'+type+'</em>';
            }
            this.filteredTitle += data.graduation ? ', Graduation Year -> <em class="text-danger">'+data.graduation+'</em>' : '';
            if(data.reg_type) {// add reg type title
                for(let type of this.regTypes) {
                    if(type.id == data.reg_type) {
                        this.filteredTitle += ', Registration Type -> <em class="text-danger">'+type.name+'</em>';
                        break;
                    }
                }
            }
            if(data.nationality) { this.getExactLocation(data.nationality, this.locations.countries, 'Nationality'); }// add nationality title
            if(data.state_origin) { this.getExactLocation(data.state_origin, this.locations.states, 'State of Origin'); }// add state of origin title
            if(data.lga_origin) { this.getExactLocation(data.lga_origin, this.locations.lgas, 'Lga of Origin'); }// add lga of origin title
            if(data.specialization) {// add Specialization title
                for(let spec of this.specializations) {
                    if(spec.id == data.specialization) {
                        this.filteredTitle += ', Specialization -> <em class="text-danger">'+spec.name+'</em>';
                        break;
                    }
                }
            }
            if(data.sub_specialization && this.subSpecializations.length > 0) {// add Specialization title
                for(let spec of this.subSpecializations) {
                    if(spec.id == data.sub_specialization) {
                        this.filteredTitle += ', Sub Specialization -> <em class="text-danger">'+spec.name+'</em>';
                        break;
                    }
                }
            }
            if(data.school) {// add school
                for(let sch of this.schools) {
                    if(sch.id == data.school) {
                        this.filteredTitle += ', School -> <em class="text-danger">'+sch.school_name+'</em>';
                        break;
                    }
                }
            }
            if(data.category) {// add AQ Category
                let cat = this.categories.filter(item => item.id == data.category);
                if(cat && cat.length > 0) {
                    this.filteredTitle += ', AQ Category -> <em class="text-danger">'+cat[0].name+'</em>';
                }
            }
            if(data.qualification) {// add AQ qualification
                let qual = this.qualifications.filter(item => item.id == data.qualification);
                if(qual && qual.length > 0) {
                    this.filteredTitle += ', AQ Qualification -> <em class="text-danger">'+qual[0].course_title+'</em>';
                }
            }
            this.filteredTitle += data.country_travel ? ', Destination Country -> <em class="text-danger">'+data.country_travel+'</em>' : '';
            this.filteredTitle += data.purpose_travel ? ', Purpose of Travel -> <em class="text-danger">'+data.purpose_travel+'</em>' : '';
            this.filteredTitle += data.folio ? ', Folio Number -> <em class="text-danger">'+data.folio+'</em>' : '';
            this.filteredTitle += data.date_issue ? ', Date of Issue -> <em class="text-danger">'+this.formatRangeDate(data.date_issue)+'</em>' : '';
            this.filteredTitle += data.application_year ? ', Application Year -> <em class="text-danger">'+data.application_year+'</em>' : '';
            this.filteredTitle += data.year ? ', Approval Year -> <em class="text-danger">'+data.year+'</em>' : '';
            this.filteredTitle += ' Applications Only';
        },
        enableExportPageButton() {
            return this.disableExportPageButton = false;
        },
        displayExportPages() {
            this.showFilters = false;
            return this.showExportPages = ! this.showExportPages;
        },
        changeFolioPrefix() {// change folio prefix
            return this.folio.prefix = (this.folio.prefix == 'MDCN/R/') ? 'MDCN/R/ALT/' : 'MDCN/R/';
        },
        getExactLocation(id, records, title) {
            if(this.locations && records && records.length > 0) {
                for(let item of records) {
                    if(item.id == id) {
                        this.filteredTitle += ', '+title+' -> <em class="text-danger">'+item.name+'</em>';
                        break;
                    }
                }
            }
        },
        formatRangeDate(value) {// format range date
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
        validFilterInputs() {
            let data = this.filters;
            return data.application_type_id || data.date_range || data.status || data.folio || data.date_issue ||
                this.folio.number;
        },
        displayFilters() {// diplay filters template
            this.emptyFilters();// empty filters
            this.loadApplications();// reload payments
            this.showExportPages = false;
            return this.showFilters = ! this.showFilters;
        },
        getSubSpecialization() {// get Sub Specialization
            let specId = this.filters.specialization;
            this.filters.sub_specialization = null;
            this.subSpecializations = [];
            for(let spec of this.specializations) {
                if(spec.id == specId) {
                    this.subSpecializations = spec.sub_specialization;
                }
            }
        },
        emptyFilters() {// empty filters
            this.filters = {
                application_type_id: null,
                license_year: null,
                date_range: null,
                state_practice: null,
                lga_practice: null,
                status: null,
                age: null,
                marital_status: null,
                gender: null,
                employ_status: null,
                employ_sector: null,
                doctor_type: null,
                graduation: null,
                year: null,
                reg_type: null,
                nationality: null,
                state_origin: null,
                lga_origin: null,
                specialization: null,
                sub_specialization: null,
                school: null,
                category: null,
                qualification: null,
                country_travel: null,
                purpose_travel: null,
                folio: null,
                date_issue: null,
                application_year: null,
                limit: this.$store.state.url.perPage // get per page count
            };
            this.folio = {
                prefix: 'MDCN/R/',
                number: null
            },
            this.isFiltered = false;
            this.filteredTitle = 'Filtered Application Report';
        },
        loadApplications() {// load details
            let data = { 'limit' : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getApplications(data).then((res) => {// get applications
                this.isLoadingBlade = false;
                this.applicationTypes = res.application_types;
                this.states = res.states;// get states
                this.setPages(res.applications);//set data
                this.regTypes = res.reg_types;// set registration types
                this.specializations = res.specializations;// set specializations
                this.schools = res.schools;// set schools
                this.categories = res.categories;// set categories
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.applications.current_page;
                    this.setPages(res.applications);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        getOriginLgas(type) {// get exact lgas
            let id = null;
            if(type == 'origin') {
                id = this.filters.state_origin;
                this.filters.lga_origin = null;
                this.originLgas = [];
            } else {
                id = this.filters.state_practice;
                this.filters.lga_practice = null;
                this.practiceLgas = [];
            }
            if(this.locations && this.locations.lgas && this.locations.lgas.length > 0) {
                for(let lga of this.locations.lgas) {
                    if(lga.state_id == id) {
                        if(type == 'origin') {
                            this.originLgas.push(lga);
                        } else {
                            this.practiceLgas.push(lga);
                        }
                    }
                }
            }
        },
        emptyState() {
            if(this.filters.nationality && this.filters.nationality != 164) {
                this.filters.state_origin = null;
                this.filters.lga_origin = null;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Applications recorded yet!!!';
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = this.createNewUrl(exploded);// get new url
            this.isLoadingBlade = true
            this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                this.pagination.number = res.applications.current_page;
                this.setPages(res.applications);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        createNewUrl(exploded) {// create new url function
            let index = exploded.indexOf('page');
            exploded.splice(index);// remove page value
            let newUrl = '';
            let count = 1;
            for(let link of exploded) {
                newUrl += link+((count == exploded.length) ? '' : '&');
                count++;
            }
            newUrl += '&page='+this.page;
            return newUrl;
        },
        getQualifications() {// get AQ categories qualification
            this.qualifications = [];
            let id = this.filters.category;
            let cat = this.categories.filter(item => item.id == id);
            this.qualifications = cat && cat.length > 0 ? cat[0].has_qualifications : [];
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadApplications();
        this.getLocations();
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
