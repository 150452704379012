
<template>
    <div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <div
                class="card shadow"
                v-if="(records && records.length > 0)"
            >
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="50%">Batch Name / Code</th>
                            <th width="40%">Centre Quota</th>
                            <th width="10%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td class="budget">
                                {{ index+1 }}
                            </td>
                            <td class="text-capitalize">
                                {{ row.name }}<br>
                                <badge
                                    size="sm" type="success"
                                    class="font-weight-bolder"
                                >
                                    {{ row.code }}
                                </badge>
                            </td>
                            <td>
                                <base-input alternative=""
                                    input-classes="form-control-alternative"
                                    placeholder="Enter Centre Quota"
                                    v-model="row.quota"
                                    type="number"
                                />
                            </td>
                            <td>
                                <button
                                    class="btn btn-sm btn-success"
                                    @click="updateCentreQuota(row, index)"
                                    :disabled="(privileges && privileges.delete == 'no') || ! privileges || isloading"
                                >
                                    <i class="fa fa-save"></i>
                                    {{ isloading && (row.id == selected) ? 'Please Wait...' : 'Update Quota' }}
                                </button>
                            </td>
                        </template>
                    </base-table>
                </div>
            </div>

            <div class="row">
            
            <div class="col-lg-12">
                <hr>
                <base-button
                    type="danger"
                    @click="$emit('goBack')"
                    :disabled="isloading"
                >
                    <i class="fa fa-arrow-left"></i>
                    Previous                        
                </base-button>

                <base-button
                    type="success"
                    class="right"
                    @click="completeProcess()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading || ! hasCompleteQuota"
                >
                    <i class="fa fa-save"></i>
                    Complete Process
                </base-button>

                
            </div>
        </div>
        </div>

        <div v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType" dismissible>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'AttachmentBatchCentre',
        props: ['privileges', 'batch', 'displayAddPage'],
        data() {
            return {
                records: [],
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selected: null
            }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }                
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user']),
            hasCompleteQuota() {// check if all quota has been updated
                let status = [];
                for(let record of this.records) {
                    status.push(record.quota);
                }
                let checker = arr => arr.every(v => v !== null);
                return checker(status);
            },
        },
        methods: {
            ...mapActions(['getAttachmentBatchCentres', 'updateAttachmentCentreQuota']),
            completeProcess() {
                swal.fire("Batch Completed", "Attachment Batch has been created successfully", "success");
                return this.displayAddPage(false);
            },
            loadBatchCentres() {
                if(this.batch) {
                    this.showErrorStatus = false;
                    this.records = [];
                    this.getAttachmentBatchCentres(this.batch.id).then((res) => {// get batches
                        this.isLoadingBlade = false;
                        this.records = res.records;//set data
                    }).
                    catch(() => {
                        this.records = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "An Error occurred while processing, reload the page";
                    this.errorType = 'danger';
                }
            },
            updateCentreQuota(centre, index) {// update centre quota
                this.selected = null;
                this.isloading = false;
                if(centre.quota) {
                    this.selected = centre.id;
                    let lastIndex = ((this.records.length - 1) == index);//  check for last quota
                    let data = { // get inputs
                        id: centre.id,
                        attachment_batch_id: centre.attachment_batch_id, 
                        attachment_centre_id: centre.attachment_centre_id, 
                        quota: parseInt(centre.quota),
                        status: lastIndex,
                        admin_id: this.user.id
                    };
                    this.preloader(); // show loading
                    this.isloading = true;
                    this.updateAttachmentCentreQuota(data).then((res) => {// update function
                        this.isloading = false;
                        this.loadBatchCentres();// refresh page
                        if(res.status) {
                            swal.fire("Quota Updated", res.msg, "success");
                        } else {
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadBatchCentres();// refresh page
                        swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                    });
                } else {
                    swal.fire("Invalid Quota!", "Kindly enter a valid Quota", "warning");
                }                
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadBatchCentres();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
<style scoped>
    .right {
        float: right;
    }
</style>
