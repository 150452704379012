
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Subject</th>
                        <th width="10%">Date Notified</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.subject }}
                        </td>
                        <td>
                            {{ row.created_at | formatDate }}
                        </td>
                        <td>
                            <base-button
                                type="success" size="sm"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @click="$emit('addNotification',row)"
                            >
                                <i class="fa fa-pen"></i> Edit
                            </base-button>
                            <base-button
                                type="danger" size="sm"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                @click="$emit('removeNotification',row.id)"
                            >
                                <i class="fa fa-trash"></i> Delete
                            </base-button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"
                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        name: 'NotificationsData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'loadNotifications'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
