<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <h3 class="text-uppercase"  v-if="! isLoadingBlade">Edit Referee Data</h3>

        <hr class="my-4" />

        <div class="row align-items-center" v-if="! isLoadingBlade">

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Referee's Name"
                    placeholder="Enter Referee's Name"
                    input-classes="form-control-alternative"
                    v-model="record.name"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Phone Number"
                    placeholder="Phone Number"
                    input-classes="form-control-alternative"
                    required
                >
                <!-- v-model="profile.phone"  -->
                    <VuePhoneNumberInput
                        v-model="tel.phone"
                        @update="updatePhoneNumber"
                        :default-country-code="tel.code"
                        :no-example="true"
                        :clearable="true"
                    />
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Referee's Occupation"
                    placeholder="Enter Referee's Occupation"
                    input-classes="form-control-alternative"
                    v-model="record.occupation"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="Referee's Position / Status"
                    placeholder="Enter Referee's position in respective occupation"
                    input-classes="form-control-alternative"
                    v-model="record.position"
                    required
                />
            </div>

            <!-- <div class="col-lg-4">
                <base-input alternative=""
                    label="Referee's Folio NUmber (In Full)"
                    placeholder="Enter Referee's Folio NUmber"
                    input-classes="form-control-alternative"
                    v-model="record.folio_number"
                />
            </div> -->

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Referee's Address"
                    required
                >
                    <textarea
                        rows="2"
                        class="form-control form-control-alternative"
                        placeholder="Enter Referee's Address"
                        v-model="record.address"
                    ></textarea>
                </base-input>
            </div>

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Referee's Business Address"
                    required
                >
                    <textarea
                        rows="2"
                        class="form-control form-control-alternative"
                        placeholder="Enter Referee's Address"
                        v-model="record.business_address"
                    ></textarea>
                </base-input>
            </div>

            <div class="col-lg-12">
                <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>
            </div>
            <div class="col-lg-12"></div>
            <div class="col-lg-12">
                <base-button
                    type="success"
                    class="right"
                    @click="saveRefereeRecords()"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Update Record'}}
                </base-button>

                <div>
                    <base-button
                        type="danger"
                        @click="editRefereeRecord(false)"
                    >
                        <i class="fa fa-times"></i>
                        Cancel Edit
                    </base-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'EditRefereeDetails',
        props: ['editRefereeRecord', 'applicant', 'loadAssessmentApplicant', 'res'],
        components: {
            VuePhoneNumberInput
        },
        data() {
            return {
                record: {
                    name: null,
                    occupation: null,
                    address: null,
                    position: null,
                    business_address: null,
                    phone: null,
                    phone_code: null,
                    folio_number: null
                },
                tel: {
                    phone: null,
                    code: 'NG',
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                showAddRecord: false,
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['saveAssessmentApplicantData']),
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            setData() {// set paginated function
                if(this.res) {
                    let data = this.res;
                    this.record = {
                        name: data.name,
                        occupation: data.occupation,
                        address: data.address,
                        position: data.position,
                        business_address: data.business_address,
                        phone: data.phone,
                        phone_code: data.phone_code,
                        folio_number: data.folio_number,
                        id: data.id
                    }
                    this.isLoadingBlade = false;
                    this.loadPhoneNumber();
                }
            },
            saveRefereeRecords() {
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save
                    data.id = this.res.id
                    data.folio_number = this.record.folio_number;
                    data.phone_code = this.record.phone_code;
                    this.preloader();// show loading
                    let record = { 'referee_data' : data , 'id' : this.applicant.id };
                    this.saveAssessmentApplicantData(record).then((res) => {
                        this.loadAssessmentApplicant();
                        this.isloading = false;
                        this.emptyRecords();
                        this.editRefereeRecord(false);
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.showAddRecord = false;
                            swal.fire("Record Added!", res.msg , "success");
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.editRefereeRecord(false);
                        this.isloading = false;
                        this.emptyRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                    // this.emptyRecords();
                }
            },
            showErrorMsg() {
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'An error occurred, kindly reload page and try again';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            validatedInputs() {
                let data = this.record;
                let appRecord = { name: null, phone: null, occupation: null, address: null, position: null,
                                business_address: null };
                appRecord.business_address = data.business_address == null
                                        ? this.showInuptError('Referee\'s Business Address') : data.business_address;
                appRecord.address = data.address == null ? this.showInuptError('Referee\'s Address') : data.address;
                appRecord.position = data.position == null ? this.showInuptError('Referee\'s Position') : data.position;
                appRecord.occupation  = data.occupation == null ? this.showInuptError('Referee\'s Occupation') : data.occupation;
                appRecord.phone  = data.phone == null ? this.showInuptError('Referee\'s Phone Number') : data.phone;
                appRecord.name  = data.name == null ? this.showInuptError('Referee\'s Name') : data.name;
                return this.isEmpty(appRecord) ? appRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyRecords() {
                return this.record = {
                    name: null,
                    occupation: null,
                    address: null,
                    position: null,
                    business_address: null,
                    phone: null,
                    phone_code: null,
                    folio_number: null
                };
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setData();// load edu records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>