<template>
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary shadow border-0">
                <div class="card-body px-lg-5 py-lg-5">
                    <form role="form">
                        <base-alert type="warning" dismissible v-if="showErrorBlade">
                            <span class="alert-inner--icon"><i class="ni ni-sound-wave"></i></span>
                            &nbsp;
                            <span class="alert-inner--text" v-text="errorMessage"></span>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </base-alert>
                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Email Address"
                                    addon-left-icon="ni ni-email-83"
                                    :valid="validEmailStatus"
                                    @change="changeStatus('email')"
                                    v-model="model.email">
                        </base-input>

                        <base-input class="input-group-alternative"
                                    placeholder="Password"
                                    type="password"
                                    addon-left-icon="ni ni-lock-circle-open"
                                    :valid="validPasswordStatus"
                                    @keyup.enter="login()"
                                    @change="changeStatus('password')"
                                    v-model="model.password">
                        </base-input>

                        <div class="text-center">
                            <base-button type="success" class="my-4" @click="login"
                                v-if="! isLoading">
                                Login
                            </base-button>
                            <button class="btn btn-success" type="button" disabled v-if="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Please Wait...
                            </button>
                        </div>
                        
                    </form>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <router-link to="/password-reset" class="text-white">
                        <span class="font-weight-bolder">
                            Forgot password?
                        </span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script> 
    import { mapGetters, mapActions } from 'vuex';
    import axios from 'axios';
    import swal from 'sweetalert2';// import swee

    export default {
        name: 'login',
        data() {
            return {
                model: {
                email: '',
                password: ''
                },
                showErrorBlade: false,
                errorMessage: '',
                validEmailStatus: null,
                validPasswordStatus: null,
                isLoading: false
            }
        },
        computed: {
        ...mapGetters(['getLoginUserUrl','getAuthorizationToken'])
        },
        methods: {
            ...mapActions(['destroyAccessToken', 'storeAndLoginUser']),
            login() {                
                this.showErrorBlade = false;
                this.isLoading = true;
                const header = {Authorization: `Bearer ${this.getAuthorizationToken}`}; 
                let data = this.validateForm();//get validated data
                if(data.status) {//login user  
                    this.preloader();// show loader                  
                    axios.post(this.getLoginUserUrl, this.model, { headers: header })
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {// store token and login user
                            this.storeAndLoginUser(res.data); 
                            // let url = res.data.url ? '/update/profile' : '/';
                            return window.location.href = '/'; 
                        } else {//show error 
                            this.destroyAccessToken();// destroy local token 
                            swal.fire("Login Failed!", res.data.msg, "warning");
                        }                     
                    })
                    .catch(() => {//check for failure
                        this.isLoading = false;
                        this.destroyAccessToken();// destroy local token 
                        swal.fire("Server Failure!", 'Server Failure! Kindly try again', "warning"); 
                    })
                } else {// show red alert
                    this.destroyAccessToken();// destroy local token  
                    this.isLoading = false;
                    return this.showError(data); // MDCN/R/93334
                }
                
            },
            validateForm() {
                let email = this.model.email;
                let password = this.model.password;
                let data = [];
                if(email && this.validEmail(email) && password) {//check for valid login crendentials                
                    data.status = true;
                } else {
                    data.status = false;
                    if (! email) {// check for empty email field
                        data.msg = 'Email/Folio Number field is empty';
                        data.type = 'email';
                    } else if(email && ! this.validEmail(email)) {//check for invalid email
                        data.msg = 'Invalid email/folio number has been inputed';
                        data.type = 'email';
                    } else if(! password) {// check for empty password field
                        data.msg = 'Password field is empty';
                        data.type = 'password';
                    }                
                }
                return data;
            },
            validEmail(email) {// eslint-disable-next-line
                var regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regEmail.test(email);
            },
            showError(data) {
                this.showErrorBlade = false;
                this.errorMessage = '';
                this.validEmailStatus = false;
                this.validPasswordStatus = false;
                this.showErrorBlade = true;
                this.errorMessage = data.msg;
            },
            changeStatus(type) {
                this.showErrorBlade = false;
                let email = this.model.email;
                let password = this.model.password;
                if(type == 'email') {
                    this.validEmailStatus = null;
                    this.validEmailStatus = this.validEmail(email) ? true :false;
                } else if(password && type == 'password') {
                    this.validPasswordStatus = null;
                    this.validPasswordStatus = true;
                } else {
                    this.validEmailStatus = false;
                    this.validPasswordStatus = false;
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Loging in...',
                    html: 'Trying to fetch data from <b>server</b> ...',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .red-alert {
        border: 2px solid red;
    }
</style>

<style>
    .bg-default {
        background-color: #2e405f !important;
    }
    .bg-gradient-success {
        background: linear-gradient(87deg, #96e7c4 0, #96e7c4 70%) !important;
    }
    .fill-default {
        fill: #2e405f;
    }
    .navbar-horizontal .navbar-brand img {
        height: 90px;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: rgb(50, 50, 93);
    }
</style>
