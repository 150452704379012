<template>
    <div>
        <template>
            <tabs fill class="flex-column flex-md-row">
                <card shadow>                                    

                    <tab-pane title="Pending Applications">
                        <span slot="title">
                            <i class="ni ni-books"></i>
                            Pending Applications
                        </span>
                        <!-- import Pending Applications template here -->
                        <PendingApplications
                            :privileges="privileges"
                            :changeTitle="changeTitle"
                            :loadTitle="loadTitle"
                            :id="id"
                            :type="type"
                            :status="'submitted'"
                        />
                    </tab-pane>

                    <tab-pane title="Rejected Applications">
                        <span slot="title">
                            <i class="ni ni-badge"></i>
                            Rejected Applications
                        </span>
                        <!-- import Rejected Applications template here -->
                        <PendingApplications
                            :privileges="privileges"
                            :changeTitle="changeTitle"
                            :loadTitle="loadTitle"
                            :id="id"
                            :type="type"
                            :status="'rejected'"
                        />
                    </tab-pane>

                    <tab-pane title="Approved Applications">
                        <span slot="title">
                            <i class="ni ni-archive-2"></i>
                            Approved Applications
                        </span>
                        <PendingApplications
                            :privileges="privileges"
                            :changeTitle="changeTitle"
                            :loadTitle="loadTitle"
                            :id="id"
                            :type="type"
                            :status="'approved'"
                        />
                    </tab-pane>

                </card>
            </tabs>
        </template>
    </div>
</template>
<script>
    import PendingApplications from './PendingApplications/PendingApplications';

    export default {
        name: 'MedicalApplications',
        props: ['privileges', 'changeTitle', 'loadTitle', 'id', 'type'],
        components: {
            PendingApplications
        },
    };
</script>
<style></style>
