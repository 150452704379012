<template>
    <div>
        <div class="row">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>RRR</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.transaction ? application.transaction.payment_ref : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.name ? profile.name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Email Address</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.email ? profile.email : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Phone No</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.phone ? profile.phone : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Year of <br>establishment</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.establishment ? profile.establishment : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Request Type</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.request_type ? replaceAll(application.request_type, '-', ' ') : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Location</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.location ? profile.location : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Type of School</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.school_type ? profile.school_type : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Additional <br>Accreditation Notes</h4>
                        </td>
                        <td colspan="5">
                            <h4 class="text-capitalize font-weight-light">
                                {{ application.additional_notes ? application.additional_notes : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody
                        v-for="(document, index) in documents"
                        :key="index"
                    >
                        <td colspan="3">
                            <h4>{{ document.title }}</h4>
                        </td>
                        <td colspan="3">
                            <base-button
                                tag="a" size="sm"
                                type="success"
                                :href="document.url"
                                target="_blank"
                                role="button"
                                aria-pressed="true"
                                v-if="document.has_file"
                            >
                                <i class="fa fa-eye"></i> View File
                            </base-button>
                            <badge type="danger" class="text-uppercase" v-if="! document.has_file">
                                No File Uploaded
                            </badge>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdvisoryProfileDetails',
        props: [ 'application' ],
        computed: {
            profile() {
                return this.application ? this.application.training_school : {};
            },
            documents() {
                return this.application ? this.application.files : [];
            }
        },
        methods: {
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>