
<template>
    <div>

        <div class="table-responsive">

            <h2 class="text-center text-uppercase">Notification for Visitation Date</h2>

            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>Hospital's Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application && application.training_hospital ?
                                    application.training_hospital.name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Notification Date *:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: false, minDate: 'today'}"
                                            class="form-control datepicker"
                                            placeholder="Select Date..."
                                            v-model="notify_date"
                                            :disabled="showBackButton"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Additional Comments:</h4>
                    </td>
                    <td>
                        <base-input alternative="" required>
                            <textarea
                                rows="2"
                                class="form-control form-control-alternative"
                                placeholder="Enter Additional Comments"
                                v-model="notify_comment"
                                :readonly="showBackButton"
                            ></textarea>
                        </base-input>
                    </td>
                </tbody>

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="info"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            @click="notifyVisitation(application.id)"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-bullhorn"></i>
                            Notify Now
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('displayVisitationDatePage', false)"
                        >
                            <i class="fa fa-arrow-left"></i>
                            Go Back
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import swal from 'sweetalert2';// import sweet alert
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  export default {
    name: 'NotificationDate',
    props: ['application', 'privileges', 'loadHospitalInfo'],
    components: {
        flatPicker
    },
    data() {
      return {
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false,
        notify_date: this.application.notify_date,
        notify_comment: this.application.notify_comment
      }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
      ...mapActions(['notifyHospitalVisitation']),
        notifyVisitation(id) {// notify Cpd Visitation
            if(this.notify_date) {// notify Cpd Visitation
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeRejectApiCall(id);// make api call
                    }
                });
            } else {
                return this.showInuptError('Select Notification Date');
            }
        },
        makeRejectApiCall(id) {// notify Cpd Visitation api
            let data = { id : id, notify_date : this.notify_date, notify_comment : this.notify_comment, 
                            admin_id : this.user.id };
            this.preloader();// show loading
            this.notifyHospitalVisitation(data).then((res) => {// notify Cpd Visitation
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Notification Successful!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadHospitalInfo(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
