
<template>
    <div>

        <div class="row">
            <div class="col-lg-12">
                <base-button
                    class="float-right"
                    type="success"
                    size="sm"
                    @click="displayNewRecord()"
                    v-if="showAddNewRecord"
                >
                    <i class="fa fa-plus-circle"></i>
                    Add New Record
                </base-button>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12"></div>

            <div class="col-lg-12" v-if="! showAddNewRecord">
                <div class="card shadow" v-if="! showErrorStatus && ! showEditPage">
                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="records"
                        >
                            <template slot="columns">
                                <th width="2%">S/N</th>
                                <th width="30%">School Name</th>
                                <th width="10%">Qualification</th>
                                <th width="10%">Start Date</th>
                                <th width="10%">End Date</th>
                                <th width="5%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ index+1}}
                            </td>
                            <td>
                                <span v-html="breakWord(row.school_name)"></span><br>
                                <badge size="sm" type="primary">
                                    {{ row.has_country ? row.has_country.name : 'N/A' }}
                                </badge>
                            </td>
                            <td>
                                {{ row.qualifications_obtained }}
                            </td>
                            <td>
                                {{ row.start_date | getDateFormat }}
                            </td>
                            <td>
                                {{ row.end_date | getDateFormat }}
                            </td>
                            <td>
                                <base-button
                                    type="info" size="sm"
                                    @click="editRecord(row)"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-pen"></i> Edit
                                </base-button>
                            </td>

                            </template>

                        </base-table>
                    </div>
                </div>
            </div>


        </div>


        <UpdateMedicalSchool
            v-bind:newRecord="newRecord"
            v-bind:privileges="privileges"
            v-bind:schools="schools"
            v-bind:userId="record.id"
            v-bind:loadMedicalSchoolRecord="loadMedicalSchoolRecord"
            v-bind:closeEditPage="closeEditPage"
            v-on:closeEditPage="closeEditPage"
            v-if="showEditPage"
        />

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus && ! showEditPage'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import UpdateMedicalSchool from './UpdateMedicalSchool';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'MedicalSchool',
        props: ['record','privileges'],
        components: {
            UpdateMedicalSchool
        },
        data() {
            return {
                records: [],
                schools: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                showEditPage: false,
                newRecord: {}
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            showAddNewRecord() {
                return this.records && this.records.length == 0;
            }
        },
        methods: {
            ...mapActions(['getUserMedicalSchool', 'getInstitutions']),
            loadMedicalSchoolRecord() {// load details
                this.showErrorStatus = false;
                this.loadInstitutions();// load Institions
                this.getUserMedicalSchool(this.record.id).then((res) => {// get Aq records
                    this.isLoadingBlade = false;
                    this.records = res;
                    this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Medical School Record Yet!!!';
            },
            loadInstitutions() {// load Institions
                this.getInstitutions().then((res) => {
                    this.schools = res;
                });
            },
            hasCountry(id) {
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == id) : null;
                return country ? country[0] : {};
            },
            editRecord(record) {// show edit page
                this.newRecord = record;
                this.showEditPage = true;
            },
            displayNewRecord() {// show new record
                this.newRecord = null;
                this.showEditPage = true;
            },
            closeEditPage() {// hide edit page
                this.newRecord = null;
                this.showEditPage = false;
            },
            breakWord(word, length = 40) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadMedicalSchoolRecord();// load add qual records
        },
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>