
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div v-if="! showErrorStatus && ! isLoadingBlade">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-10 text-right">
                        <button
                            class="btn btn-lg btn-info"
                            @click="editDepartment()"
                            :disabled="(privileges && privileges.add == 'no') || ! privileges"
                        >
                            <i class="fa fa-plus"></i> Add Posting Department
                        </button>
                        <br><br>
                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>

            <div
                class="card shadow"
                v-if="records && records.length > 0"
            >
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="30%">Department Name</th>
                            <th width="20%">Category</th>
                            <th width="20%">Request Type</th>
                            <th width="10%">Date Modified</th>
                            <th width="20%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td class="text-capitalize font-weight-bold">
                                {{ row.name }}
                            </td>
                            
                            <td>
                                <span
                                    v-show="convertToArray(row.category).length > 0"
                                    v-for="(item, index) in convertToArray(row.category)"
                                    :key="index"
                                >
                                    <badge type="primary">{{ item }}</badge><br>
                                </span> 
                                <span v-show="convertToArray(row.category).length == 0">N/A</span>                               
                            </td>
                            <td>
                                <span
                                    v-show="convertToArray(row.request_type).length > 0"
                                    v-for="(item, index) in convertToArray(row.request_type)"
                                    :key="index"
                                >
                                    <badge type="info">{{ replaceAll(item, '-', ' ') }}</badge><br>
                                </span>   
                                <span v-show="convertToArray(row.request_type).length == 0">N/A</span>                              
                            </td>
                            <td>{{ row.updated_at | formatDate }}</td>
                            <td>
                                <div class="text-right">
                                    <base-button
                                        type="primary" size="sm"
                                        @click="editDepartment(row)"
                                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    >
                                        <i class="fa fa-pen"></i>
                                        Edit
                                    </base-button>

                                    <base-button
                                        type="danger" size="sm"
                                        @click="deleteDepartment(row.id)"
                                        :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                        v-if="row.delete_status"
                                    >
                                        <i class="fa fa-trash"></i>
                                        Delete
                                    </base-button>
                                </div>
                            </td>
                        </template>
                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                        @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>
        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import Badge from '../../../../components/Badge.vue';

    export default {
    components: { Badge },
        name: 'Posting',
        props: ['privileges'],
        data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            title: 'Appiontment Posting Dept. Settings',
            isLoadingBlade: true,
            records: [],
            paginatedItems: [],
            pagination: {
                total: null,
                prev_page_url: null,
                next_page_url: null,
                current_page: null,
                last_page: null,
                number: 1,
                from: 0,
                to: null,
                perPage: this.$store.state.url.perPage // get per page count
            },
            page: 1
        }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
            pageNumbers() {
                let start = 1;
                let end = this.pagination.last_page;
                let pages = [];
                for (let i = start; i <= end; i++ ) {
                    pages.push(i);
                }
                return pages;
            }
        },
        methods: {
            ...mapActions(['getAppointmentPostings', 'getPaginatedSpecializations', 'updatePostingDepartment',
                    'deleteAppointmentPosting']),
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find, 'g'), replace);
            },
            convertToArray(record) {
                return record ? Object.values(JSON.parse(record)) : [];
            },
            loadAppointmentPostings() {// load details
                this.page = 1;// return page to default
                let data = { 'limit' : this.pagination.perPage };
                this.getAppointmentPostings(data).then((res) => {// get users
                    this.isLoadingBlade = false;
                    this.setPages(res);//set data
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedSpecializations(url).then((res) => {// get users
                        this.pagination.number = res.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Additional Qualifications added yet!!!';
            },
            getPageItems() {
                let url = this.pagination.next_page_url ?
                            this.pagination.next_page_url : this.pagination.prev_page_url;
                let exploded = url.split('&');
                let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
                this.isLoadingBlade = true
                this.getPaginatedSpecializations(newUrl).then((res) => {// get records
                    this.pagination.number = res.current_page;
                    this.setPages(res);
                }).
                catch(() => {
                    this.records = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            editDepartment(dept) {
                let name = dept && dept.name ? dept.name : '';
                let type = dept && dept.type ? dept.type : '';
                let cat = dept && dept.category ? dept.category : '';
                let request = dept && dept.request_type ? dept.request_type : '';
                // format checkbox inputs
                let catArray = cat ? Object.values(JSON.parse(cat)) : [];
                let requestArray = request ? Object.values(JSON.parse(request)) : [];
                swal.fire({
                    title: (dept ? 'Update ('+dept.name+') Department' : 'Add New Department'),
                    html:
                        '<br>'+
                        '<label><b>Department Name</b></label>' +
                        '<input id="swal-input1" class="swal2-input" value="'+ name+'" ' +
                        'placeholder="Enter Department Name"><br>'+
                        '<label><b>Pratice Type</b></label><br>' + 
                        '<select id="swal-input2" class="swal2-input text-center" value="'+ type +'">' +
                        '<option value="" selected disabled>--- Select One ---</option>' + 
                        '<option value="medical" ' + ((type == 'medical') ? 'selected' : '') + '>Medical Practice</option>' +
                        '<option value="dental" ' + ((type == 'dental') ? 'selected' : '') + '>Dental Practice</option> </select>'+
                        '<label><b>Category</b></label><br>'+ 
                        '<div><div class="row"><div class="col-lg-3"></div><div class="col-lg-3">'+
                        '<input class="form-check-input" type="checkbox" value="hospital" id="swal-input3"'+ (catArray.includes('hospital') ? 'checked' : '') +'>'+
                        '<label class="form-check-label">Hospital</label>'+
                        '</div><div class="col-lg-3">'+
                        '<input class="form-check-input" type="checkbox" value="school" id="swal-input4"'+ (catArray.includes('school') ? 'checked' : '') +'>'+
                        '<label class="form-check-label">School</label></div></div></div><br><br>'+
                        '<label><b>Request (Accreditation) Type</b></label><br>' + 
                        '<input class="form-check-input" type="checkbox" value="pre-clinical-accreditation"'+
                        'id="swal-input5"'+ (requestArray.includes('pre-clinical-accreditation') ? 'checked' : '') +'>'+
                        '<label class="form-check-label">Pre Clinical Accreditation</label><br>'+
                        '<input class="form-check-input" type="checkbox" value="first-clinical-accreditation"'+
                        'id="swal-input6"'+ (requestArray.includes('first-clinical-accreditation') ? 'checked' : '') +'>'+
                        '<label class="form-check-label">First Clinical Accreditation</label><br>'+
                        '<input class="form-check-input" type="checkbox" value="final-clinical-accreditation"'+
                        'id="swal-input7"'+ (requestArray.includes('final-clinical-accreditation') ? 'checked' : '') +'>'+
                        '<label class="form-check-label">Final Clinical Accreditation</label>',
                    focusConfirm: false,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: (dept ? 'Update it!' : 'Add Now!'),
                    cancelButtonText: 'No, cancel!',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        // for the category
                        let cat1 = document.getElementById('swal-input3');
                        let cat2 = document.getElementById('swal-input4');
                        let category = this.formatCheckboxData([cat1, cat2]);
                        // for the request type
                        let req1 = document.getElementById('swal-input5');
                        let req2 = document.getElementById('swal-input6');
                        let req3 = document.getElementById('swal-input7');
                        let request = this.formatCheckboxData([req1, req2, req3]);
                        let data =  {// get all data
                            id : dept ? dept.id : null,
                            name : document.getElementById('swal-input1').value,
                            type : document.getElementById('swal-input2').value,
                            category : JSON.stringify(category),
                            request_type : JSON.stringify(request),
                            admin_id : this.$store.state.user.userId // add admin user id
                        };
                        if(data && (data.name != '') && (data.type != '')) {//update
                            return this.updatePostingDepartment(data).then((response) => {
                                if (!response.status) {
                                throw new Error(response.msg)
                                }
                                return response
                            }).
                            catch((error) => {
                                swal.showValidationMessage(
                                `Request failed: ${error}`
                                )
                            });
                        } else {
                        swal.showValidationMessage(
                                `Request failed: Fill all necessary fields`
                            )
                        }
                    },
                    allowOutsideClick: () => !swal.isLoading()
                }).then((res) => {
                    if (res.value) {
                        let type = res.value.status ? 'success' : 'error';
                        let title = res.value.status ? 'Department '+res.value.type+'!' : 'Error Occurred!';
                        swal.fire(title, res.value.msg, type);
                        this.loadAppointmentPostings();// refresh items
                    }
                });
            },
            formatCheckboxData(data) {// format checkbox data
                let records = [];
                for(let item of data) {
                    if(item.checked) { 
                        records.push(item.value); 
                    }
                }
                return records;
            },
            deleteDepartment(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeDeleteApiCall(id);// make api call
                    }
                });
            },
            makeDeleteApiCall(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
                this.preloader(); // show loading
                this.deleteAppointmentPosting(data).then((res) => {// delete Qualification
                    if(res.status) {
                        this.loadAppointmentPostings();// refresh page
                        swal.fire("Record Deleted", res.msg, "success");
                    } else {
                        this.loadAppointmentPostings();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadAppointmentPostings();// refresh page
                    swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadAppointmentPostings();
        }
    };
</script>
<style scoped>
    .right {
        float: 'right';
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
