
<template>
    <div>
        <div class="container">
            <div class="row">

                <div class="col-lg-2">
                    <h3 class="mb-0 text-uppercase">Basic Details</h3>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>

                <div class="col-lg-8" v-if="application">

                    <h3>
                        Application Date - &nbsp;&nbsp;&nbsp;
                        {{ application.application_date | formatDate }}
                    </h3>

                    <h3>
                        Application Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm"
                            :type="getStatusColor(application.status)"
                        >
                            {{ application.status }}
                        </badge>
                    </h3>

                    <h3 v-if="application.training_school">
                        Account Status - &nbsp;&nbsp;&nbsp;
                        <badge size="sm"
                            :type="getAccountStatusColor(application.training_school.status)"
                        >
                            {{ application.training_school.status }}
                        </badge>
                    </h3>

                    <h3 v-if="application.training_school">
                        Type of Training - &nbsp;&nbsp;&nbsp;
                        <span class="text-capitalize">
                            {{ application.training_school.type }} Training
                        </span>
                    </h3>

                    <h3 v-if="application.training_school">
                        Indexing Year - &nbsp;&nbsp;&nbsp;
                        <span class="text-capitalize">
                            {{ application.year ? application.year : 'N/A' }}
                        </span>
                    </h3>

                    <br>

                </div>

                <div class="col-lg-4">
                    <div class="card-profile-image marginTop40">
                        <img
                            :src="getSchoolPix(application.training_school)"
                            class="rounded-circle image-size"
                            @error="replaceByDefault"
                        >
                    </div>
                    <br><br><br><br><br>
                </div>

                <div class="col-lg-12">
                    <hr class="my-4" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'BasicDetails',
        props: ['application', 'privileges', 'loadApplication'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['schoolPhotoUrl', 'blankImageUrl'])
        },
        methods: {
            getStatusColor(value) {
                if(value == 'approved') {
                    return 'success';
                } else if(value == 'pending') {
                    return 'warning';
                } else {
                    return 'danger'
                }
            },
            getPaymentStatusColor(value) {
                return (value == 'paid') ? 'success' : 'danger';
            },
            getAccountStatusColor(value) {
                return (value == 'enable') ? 'success' : 'danger';
            },
            getSchoolPix(school) {// get user photo url
                return school ? this.schoolPhotoUrl+school.id+'/photo/'+school.photo : null;
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
    .right {
        float: right;
    }
    .marginLeft5 {
        margin-left: 5px;
    }
    .image-size {
        height: 200px;
    }
</style>
