
<template>
    <div>
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="row align-items-center" v-if="! isLoadingBlade">

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School's Name"
                    placeholder="Enter School's Name"
                    input-classes="form-control-alternative"
                    v-model="record.school_name"
                    required
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School's Email Address"
                    placeholder="Enter School's Email Address"
                    input-classes="form-control-alternative"
                    v-model="record.school_email"
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School's Code"
                    placeholder="Enter School's Code"
                    input-classes="form-control-alternative"
                    v-model="record.school_code"
                />
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School's City"
                    placeholder="Enter School's City"
                    input-classes="form-control-alternative"
                    v-model="record.school_city"
                />
            </div>

            <div class="col-lg-12">
                <base-input alternative=""
                    label="School Address">
                    <textarea
                        rows="3"
                        class="form-control form-control-alternative"
                        v-model="record.school_address"></textarea>
                </base-input>
            </div>

            <div class="col-lg-6">
                <base-input alternative=""
                    label="School's State"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.school_state_id"
                        @change="showOtherSchool"
                    >
                        <option value="null" disabled selected>--- Select One ---</option>
                        <option
                            v-show="states && states.length > 0"
                            v-for="state in states"
                            :key="state.id"
                            :value="state.id"
                        >
                            {{state.name}}
                        </option>
                        <option value="0">Others (Specify)</option>
                    </select>
                </base-input>
            </div>

            <div class="col-lg-6" v-if="displayOtherState">
                <base-input alternative=""
                    label="Other School's State"
                    placeholder="Enter Other School's State"
                    input-classes="form-control-alternative"
                    v-model="record.other_school"
                />
            </div>

            <div
                class="col-sm-12 text-center"
                v-if='showErrorStatus'
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>

            <hr class="my-4" />

            <div class="col-lg-12">
                <base-button
                    type="success" size="lg"
                    @click="saveSchool()"
                    :disabled="privileges && privileges.add == 'no' || ! privileges"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Add Record'}}
                </base-button>

                <base-button
                    type="danger" size="lg"
                    class="right"
                    @click="$emit('displayAddPage', false)"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddNigerianSchool',
        props: ['privileges', 'loadSchools', 'school', 'displayAddPage'],
        data() {
            return {
                record: {
                    school_name: null,
                    school_address: null,
                    school_city: null,
                    school_code: null,
                    school_email: null,
                    school_state_id: null,
                    id: null,
                    admin_id: null,
                    school_status: 1,
                    other_school: null
                },
                states: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                isLoadingBlade: true,
                displayOtherState: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getStates', 'createSchool']),
            showOtherSchool() {// show other school
                return this.displayOtherState = this.record.school_state_id == 0 ? true : false;
            },
            loadStates() {// set data for editing
                let data = { 'limit' : 50 }; // to exceed states limit
                this.emptyEduRecords();// empty record
                this.getStates(data).then((res) => {// get users
                    this.isLoadingBlade = false;
                    this.states = res && res.data ? res.data : [];//set states
                    this.setData(); // set data for editing
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            setData() {// set data for editing
                let school = this.school;
                if(school) {
                    this.record.school_name = school.school_name;
                    this.record.school_address = school.school_address;
                    this.record.school_city = school.school_city;
                    this.record.school_code = school.school_code;
                    this.record.school_email = school.school_email;
                    this.record.school_state_id = school.school_state_id;
                    this.record.id = school.id;
                    this.record.school_status = school.school_status;
                    this.record.other_school = school.other_school;
                    this.getStatesForEditing(school.school_state_id);
                }
            },
            getStatesForEditing(id) {// get other state for editing
                return this.displayOtherState = (id == 0) ? true : false;
            },
            emptyEduRecords() {
                this.record.school_name = null;
                this.record.school_address = null;
                this.record.school_city = null;
                this.record.school_code = null;
                this.record.school_email = null;
                this.record.school_state_id = null;
                this.record.school_status = 1;
                this.record.other_school = null;
            },
            saveSchool() {
                let status = this.validatedInputs();// validate inputs
                if(status) {// save user
                    let data = this.record;
                    data.admin_id = this.$store.state.user.userId;// add admin user id
                    this.preloader(); // show loading
                    this.createSchool(data).then((res) => {// create school
                        if(res.status) {
                            this.displayAddPage(false);// hide edit page
                            this.loadSchools();// refresh page
                            swal.fire("School's Account "+res.type, res.msg, "success");
                        } else {
                            this.loadSchools();// refresh page
                            swal.fire("Error Occurred!", res.msg, "warning");
                        }
                    }).
                    catch(err => {
                        this.loadSchools();// refresh page
                        swal.fire(err.message+"!", "An error occurred while saving, try again", "warning");
                    });
                }
            },
            validatedInputs() {
                let data = this.record;
                let school = { school_name: null, school_address: null, school_city: null, school_code: null,
                    school_email: null, state: null
                };
                // validate school's state
                if((data.school_state_id == null || data.school_state_id == 0)
                    && data.other_school != null) {
                    school.state = data.other_school;
                } else if(data.school_state_id != null && data.school_state_id > 0) {
                    school.state = data.school_state_id;
                } else if((data.school_state_id == null || data.school_state_id == 0)
                    && data.other_school == null) {
                    this.showInuptError('School\'s State');
                }
                school.school_address = data.school_address == null ? this.showInuptError('School\'s Address') : data.school_address;
                school.school_city = data.school_city == null ? this.showInuptError('School\'s City') : data.school_city;
                school.school_code = data.school_code == null ? this.showInuptError('School\'s Code') : data.school_code;
                school.school_email = data.school_email == null ? this.showInuptError('School\'s Email Address') : data.school_email;
                school.school_name = data.school_name == null ? this.showInuptError('School\'s Name') : data.school_name;
                return this.isEmpty(school) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadStates();// load Departments
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>