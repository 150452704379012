
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Doctor's Name</th>
                        <th width="10%">Practice Type</th>
                        <th width="10%">Gender</th>
                        <th width="10%">State / LGA <br>of Practice</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ getFullName(row) }}<br>
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row && row.folio_number ? row.folio_number : 'M/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ getPracticeTypeName(row) }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.gender }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.has_practice_state ? row.has_practice_state.name : 'N/A' }} <br>
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.has_practice_lga ? row.has_practice_lga.name : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            <button
                                class="btn btn-sm btn-danger"
                                @click="viewDetails(row)"
                                v-if="! row.has_sanction"
                                :disabled="(privileges && privileges.add == 'no') || ! privileges"
                            >
                                <i class="fa fa-plus"></i>
                                Add To List
                            </button>

                            <badge
                                size="sm" type="danger"
                                class="font-weight-bolder"
                                v-if="row.has_sanction"
                            >
                                On Sanction
                            </badge>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>


        <!-- View details modal -->
        <div class="col-md-4" v-if="record">
            <modal :show="showModal">
                <h3 slot="header" class="modal-title text-capitalize" id="modal-title-default">
                    Additional Sanction Data for
                    <em class="text-danger">{{ getFullName(record) }}</em>
                </h3>
                <div class="row">
                    <div class="col-lg-12">
                        <base-input
                            label="Sanctioned Date"
                            addon-left-icon="ni ni-calendar-grid-58"
                            required
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="{allowInput: true}"
                                class="form-control datepicker"
                                v-model="sanction.sanctioned_date"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-12">
                        <base-input alternative=""
                            label="Nature of Sanction"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Nature of Sanction"
                            v-model="sanction.sanction_nature"
                            required
                        />
                    </div>

                    <div class="col-lg-12">
                        <base-input alternative=""
                            label="Sanction Duration (Months)"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Sanction Duration"
                            v-model="sanction.sanction_duration"
                            type="number"
                        />
                    </div>

                    <div class="col-lg-12">
                        <base-input alternative=""
                            label="Regulatory Body"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Regulatory Body"
                            v-model="sanction.regulatory_body"
                            required
                        />
                    </div>

                    <div class="col-lg-12">
                        <base-input alternative=""
                            label="Publish to Website"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="sanction.publish"
                            >
                                <option :value="null" disabled selected>--- Select One ---</option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </base-input>
                    </div>

                    <div
                        class="col-sm-12 text-center"
                        v-if='showErrorStatus'
                    >
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </div>

                </div>

                <template slot="footer">
                    <base-button
                        type="success"
                        @click="addToList(record)"
                    >
                        <i class="fa fa-plus"></i>
                        Add Now
                    </base-button>
                    <base-button type="link" class="ml-auto" @click="showModal = false">Close
                    </base-button>
                </template>
            </modal>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddSanctionData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'loadUsers'],
        components: {
            flatPicker
        },
        data() {
            return {
                showModal: false,
                record: null,
                sanction: {
                    sanctioned_date: null,
                    sanction_nature: null,
                    sanction_duration: null,
                    regulatory_body: null,
                    publish: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null
            }
        },
        methods: {
            ...mapActions(['addUserSanction']),
            viewDetails(data) {
                this.emptyFields();
                this.showModal = true;
                this.record = data;
            },
            getFullName(user) {// get full name
                if(user) {
                    let name = user.first_name+' ';
                    name += user.other_name ? user.other_name+' ' : '';
                    name += user.last_name+' ';
                    return name.toLowerCase();
                } else {
                    return 'N/A';
                }
            },
            getPracticeTypeName(data) {// get practice type name
                let type = data && data.get_doctor_type ? data.get_doctor_type : false;
                return (type && type.name) ? type.name : 'N/A';
            },
            emptyFields() {
                return this.sanction = {
                    sanctioned_date: null,
                    sanction_nature: null,
                    sanction_duration: null,
                    regulatory_body: null,
                    publish: null
                };
            },
            addToList(record) {// change complaint's status
                let status = this.validatedInputs();// validate inputs
                if(status) {
                    this.preloader();
                    let data = this.sanction;
                    data.user_data_id = record.id;
                    data.last_name = record.last_name;
                    data.first_name = record.first_name;
                    data.other_name = record.other_name;
                    data.folio_number = record.folio_number;
                    data.doctor_type_id = record.doctor_type_id;
                    data.gender = record.gender;
                    data.state_id = record.state_of_practice;
                    data.lga_id = record.lga_of_practice;
                    data.status = 'yes';
                    data.admin_id = this.$store.state.user.userId;
                    this.addUserSanction(data).then((res) => {
                        this.showModal = false;
                        this.loadUsers();// refresh page
                        if(res.status) {
                            swal.fire('Sanction Added!', res.msg, 'warning');
                        } else {
                            swal.fire('Error Occurred', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        swal.fire('Error Occurred', 'An error occurred while updating, try again', 'warning');
                    });
                }
            },
            validatedInputs() {
                let data = this.sanction;
                let res = { sanctioned_date: null, publish: null, regulatory_body: null, sanction_nature: null };
                res.publish = data.publish == null ? this.showInuptError('Publish to Website') : data.publish;
                res.regulatory_body = data.regulatory_body == null ? this.showInuptError('Regulatory Body') : data.regulatory_body;
                res.sanction_nature = data.sanction_nature == null ? this.showInuptError('Nature of Sanction') : data.sanction_nature;
                res.sanctioned_date = data.sanctioned_date == null ? this.showInuptError('Sanctioned Date') : data.sanctioned_date;
                return this.isEmpty(res) ? true : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
