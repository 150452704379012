<template>
    <div>

        <div class="col-lg-12 text-right">
            <button
                class="btn btn-lg btn-info"
                @click="editSubSpecialization()"
                :disabled="(privileges && privileges.add == 'no') || ! privileges"
            >
                <i class="fa fa-plus"></i> Add Sub Specialization
            </button>
            <button
                class="btn btn-lg btn-danger"
                @click="showEditPage(false, null)"
            >
                <i class="fa fa-arrow-left"></i> Back
            </button>
            <br><br>
        </div>

        <div class="col-lg-8" v-if="! showErrorStatus">
            <small class="font-weight-bold">
                Showing 1 - {{ subSpecializations.length }} of {{ subSpecializations.length }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="subSpecializations && subSpecializations.length > 0 && ! showErrorStatus"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="subSpecializations"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="18%">Sub Specialization</th>
                        <th width="10%">Date Modified</th>
                        <th width="18%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                    <td class="budget">
                        {{ index+1}}
                    </td>
                    <td v-html="breakWord(row.name, 40)">
                    </td>
                    <td>
                        {{ row.updated_at | formatDate }}
                    </td>
                    <td class="text-capitalize">
                        <div class="right">
                            <base-button
                                type="primary" size="sm"
                                @click="editSubSpecialization(row)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </base-button>

                            <base-button
                                type="danger" size="sm"
                                @click="deleteSubSpecialization(row.id, 'sub-specialization')"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                v-if="row.delete_status"
                            >
                                <i class="fa fa-trash"></i>
                                Delete
                            </base-button>
                        </div>
                    </td>

                    </template>

                </base-table>
            </div>
        </div>

        <div class="col-sm-12 text-center" v-if='showErrorStatus'>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';

    export default {
        name: 'SubSpecialization',
        props: ['specialization','privileges', 'loadSpecializations', 'deleteSpecialization', 'showEditPage'],
        data() {
            return {
                subSpecializations: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                member: null,
                users: [],
            }
        },
        filters: {
        formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        methods: {
            ...mapActions(['updateSubSpecialization']),
            loadData() {
                this.subSpecializations = [];
                this.subSpecializations = this.specialization.sub_specialization;
                this.emptyRecordsMsg();
            },
            emptyRecordsMsg() {
                return this.subSpecializations && this.subSpecializations.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Sub Specializations yet!!!';
            },
            editSubSpecialization(spec) {
                let name = spec && spec.name ? spec.name : '';
                swal.fire({
                    title: (spec ? 'Update ('+name+') Sub Specialization' :
                        'Add New Sub Specialization to '+this.specialization.name),
                    html:
                        '<br>'+
                        '<label><b>Sub Specialization Name</b></label>' +
                        '<input id="swal-input1" class="swal2-input" value="'+ name+'">',
                    focusConfirm: false,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: (spec ? 'Update it!' : 'Add Now!'),
                    cancelButtonText: 'No, cancel!',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        let data =  {
                            'id' : spec ? spec.id : null,
                            'name' : document.getElementById('swal-input1').value,
                            'specialization_id' : this.specialization.id,
                            'admin_id' : this.$store.state.user.userId // add admin user id
                        };
                        if(data && data.name) {//update
                            return this.updateSubSpecialization(data).then((response) => {
                                if (!response.status) {
                                throw new Error(response.msg)
                                }
                                return response
                            }).
                            catch((error) => {
                                swal.showValidationMessage(
                                `Request failed: ${error}`
                                )
                            });
                        } else {
                        swal.showValidationMessage(
                                `Request failed: Fill all necessary fields`
                            )
                        }
                    },
                    allowOutsideClick: () => !swal.isLoading()
                }).then((res) => {
                    if (res.value) {
                        let type = res.value.status ? 'success' : 'error';
                        let title = res.value.status ? 'Sub Specialization '+res.value.type+'!' : 'Error Occurred!';
                        swal.fire(title, res.value.msg, type);
                        this.subSpecializations = res.value.subs;// update subs
                        this.showErrorStatus = false;
                        this.loadSpecializations();// refresh items
                    }
                });
            },
            deleteSubSpecialization(id, type) {// delete sub specialization
                this.showErrorStatus = false;
                this.deleteSpecialization(id, type);
            },
            breakWord(word, length) {// break word into two parts
                if(word) {
                    let textLength = word.length;
                    let exploded = word.split(' ');
                    if(word && textLength < length) {
                        return word;
                    } else if(word) {
                        let formedWord = '';
                        let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                        for(let phrase of exploded) {
                            formedWord += phrase+' ';
                            if(exploded[half] == phrase) {
                                formedWord += ' <br> ';
                            }
                        }
                        return formedWord;
                    }
                }
                return 'N/A';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadData();
        }
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>