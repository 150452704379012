<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Student Profile Information</h3>
                <hr class="my-4" />
            </div>

            <div class="col-lg-8">
                <h3 class="text-uppercase">
                    Application Status:

                    <badge
                        :type="(application.status == 'approved') ? 'success' : 'warning'"
                        v-if="application"
                    >
                        {{ application.status }}
                    </badge>
                </h3>
                <h3 class="text-uppercase">
                    Application Date:
                    <em class="font-weight-light">
                        {{ application.application_date | getDateFormat }}
                    </em>
                </h3>
                <h3 class="text-uppercase">
                    Payment Status:

                    <badge
                        :type="(application.payment_status == 'paid') ? 'success' : 'warning'"
                        v-if="application"
                    >
                        {{ application.payment_status }}
                    </badge>
                </h3>
                <h3 
                    class="text-uppercase" 
                    v-if="application && (application.status == 'approved') || (application.status == 'rejected')"
                >
                    {{ application.status }} Date:
                    <em class="font-weight-light">
                        {{ application.approved_date | getDateFormat }}
                    </em>
                </h3>
                <h3 class="text-uppercase">
                    Practice Type:
                    <em class="font-weight-light text-capitalize">
                        {{ application.personal_data && application.personal_data.type ? (application.personal_data.type + ' Practitioner') : 'N/A' }}
                    </em>
                </h3>

                <hr v-if="application && ((application.status == 'approved') || (application.status == 'rejected'))">

                <h3 class="text-uppercase text-danger" v-if="(application.status == 'rejected')">
                    Rejection Reason:
                    <em class="font-weight-light text-capitalize">
                        {{ application.reject_reason }}
                    </em>
                    <hr>
                </h3>


            </div>

            <div class="col-lg-4 text-right">
                <img :src="photo" class="photo-class"/>
                <br><br>
            </div>

            <div class="table-responsive">

                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>RRR</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ application.transaction ? application.transaction.payment_ref : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>First Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.first_name ? profile.first_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Last Name</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.last_name ? profile.last_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Other Names</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.other_names ? profile.other_names : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Previous Name</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.previous_name ? profile.previous_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Gender</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.gender ? profile.gender : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Email Address</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.email ? profile.email : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Phone No</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.phone ? profile.phone : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Marital Status</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.marital_status ? profile.marital_status : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Date of Birth</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.date_of_birth | getDateFormat }}
                            </h4>
                        </td>
                        <td>
                            <h4>Place of Birth</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.place_of_birth ? profile.place_of_birth : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Nationality</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.country && profile.country.name ? profile.country.name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>State / Province</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.state_origin ? profile.state_origin : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>HomeTown</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.home_town ? profile.home_town : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Contact Address</h4>
                        </td>
                        <td colspan="7">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.contact_address ? profile.contact_address : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Student's School</h4>
                        </td>
                        <td colspan="7">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.school && profile.school.school_name ? profile.school.school_name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'ProfileDetails',
        props: [ 'application' ],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            profile() {
                return this.application ? this.application.personal_data : {};
            },
            photo() {// get MD passport
                return this.application && this.application.personal_data && this.application.personal_data.photo_url 
                            ? this.application.personal_data.photo_url : this.blankImageUrl;
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>