
<template>
    <div>
        <template>
            <tabs fill class="flex-column flex-md-row">
                <card shadow>
                    <tab-pane title="Medical Applications">
                        <span slot="title">
                            <i class="ni ni-books"></i>
                            Medical Applications
                        </span>
                        <!-- import Pending Applications template here --> 
                        <MedicalPendingApplications 
                            :type="'medical'"
                            :visitStatus="visitStatus"
                        />
                    </tab-pane>

                    <tab-pane title="Dental Applications">
                        <span slot="title">
                            <i class="ni ni-badge"></i>
                            Dental Applications
                        </span>
                        <!-- import Rejected Applications template here -->
                        <!-- <DentalPendingApplications /> --> 
                        <MedicalPendingApplications 
                            :type="'dental'"
                            :visitStatus="visitStatus"
                        />
                    </tab-pane>
                </card>
            </tabs>
        </template>
    </div>
</template>
<script>
  import MedicalPendingApplications from './PendingApplications/MedicalPendingApplications';
//   import DentalPendingApplications from './PendingApplications/DentalPendingApplications';

  export default {
    name: 'PendingApplications',
    props: ['visitStatus'],
    components: {
        MedicalPendingApplications,
        // DentalPendingApplications
    }
  };
</script>
<style></style>
