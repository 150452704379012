
<template>
    <div>

        <div
            class="col-lg-12 text-right"
            v-if="! showMembers && ! showAddUnit"
        >
            <button
                class="btn btn-lg btn-info"
                @click="showNewUnit(true, 'Add')"
                :disabled="(privileges && privileges.add == 'no') || ! privileges"
            >
                <i class="fa fa-plus"></i> Add New Unit
            </button>
            <button
                class="btn btn-lg btn-danger"
                @click="$emit('closeUnitPage')"
            >
                <i class="fa fa-arrow-left"></i> Back
            </button>
            <br><br>
        </div>

        <div
            class="container ct-example-row"
            v-if="units && units.length > 0 && ! showMembers && ! showAddUnit"
        >
            <div class="row">
                <div
                    class="col-sm-4 text-center borderLine"
                    v-for="unit in units"
                    :key="unit.id"
                >
                    <br>
                    <h4 class="text-capitalize height-50">
                        {{ unit.name }}
                    </h4>
                    <h6 class="text-capitalize">
                        <em>{{ unit.description }}</em>
                    </h6><br>
                    <base-button
                        size="sm" type="primary"
                        :disabled="(privileges && privileges.view == 'no') || ! privileges"
                        @click="displayUnitMembers(unit)"
                    >
                        <i class="fa fa-eye"></i>
                        View Members
                    </base-button>
                    <base-button
                        size="sm" type="info"
                        @click="showNewUnit(true, 'Update', unit)"
                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                    >
                        <i class="fa fa-pen"></i>
                        Edit
                    </base-button>
                    <base-button
                        size="sm" type="danger"
                        @click="deleteUnit(unit.id)"
                        :disabled="(privileges && privileges.delete == 'no') || ! privileges || 
                            (unit.members && unit.members.length > 0)"
                    >
                        <i class="fa fa-trash"></i>
                        Delete
                    </base-button>
                    <br><br>
                </div>
            </div>
        </div>

        <div
            class="col-sm-12 text-center"
            v-if="units && units.length == 0 && ! showMembers && ! showAddUnit"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <!-- import Members template here -->
        <UnitMembers
            v-bind:unit="unit"
            v-bind:privileges="privileges"
            v-bind:changeTitle="changeTitle"
            v-on:closeMembersPage="closeMembersPage"
            v-if="showMembers && ! showAddUnit"
        />

        <!-- import Add Department template here -->
        <AddUnit
            v-bind:unit="unit"
            v-bind:privileges="privileges"
            v-bind:showNewUnit="showNewUnit"
            v-bind:department="department"
            v-bind:loadDepartments="loadDepartments"
            v-bind:updateUnits="updateUnits"
            v-bind:justAddedUnits="justAddedUnits"
            v-on:showNewUnit="showNewUnit"
            v-if="showAddUnit"
        />

    </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import UnitMembers from '../Unit/UnitMembers';
  import AddUnit from '../Unit/AddUnit';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'Units',
    props: ['units', 'privileges', 'changeTitle', 'displayUnits', 'department', 'loadDepartments', 'updateUnits'],
    components: {
        UnitMembers,
        AddUnit
    },
    data() {
      return {
        isLoadingBlade: false,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        unit: null,
        showMembers: false,
        showAddUnit: false,
        justAdded: false
      }
    },
    methods: {
        ...mapActions(['deleteAdminUnit']),
        showNewUnit(value, type, data) {// display new dept form
            let title = value ? type+' ' : this.department.name+' Department\'s Units';
            let formatTitle = data ? data.name+'\'s Unit' : 'New Unit to '+this.department.name+' Department';
            title +=  (formatTitle && type) ? formatTitle : '';
            this.changeTitle(title);
            this.unit = data;
            return this.showAddUnit = value;
        },
        showEmptyMsg() {// show empty msg
            if(this.units && this.units.length == 0 && ! this.showMembers && ! this.justAdded) {
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Units added yet!!!';
            }
        },
        displayUnitMembers(data) {// show members
            this.unit = data;
            this.showMembers = true;
            let title = data.name+'\'s Members'
            this.changeTitle(title);
        },
        closeMembersPage() {// hide members
            this.showMembers = false;
            this.displayUnits(this.department.id);
        },
        justAddedUnits() {
            return this.justAdded = true;
        },
        deleteUnit(id) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall(id);// make api call
                }
            });
        },
        makeDeleteApiCall(id) {
            let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
            this.preloader(); // show loading
            this.deleteAdminUnit(data).then((res) => {// delete department
                if(res.status) {
                    this.updateUnits(res.units);// refresh page
                    swal.fire("Record Deleted", res.msg, "success");
                } else {
                    this.loadDepartments();// refresh page
                    swal.fire("Error Occurred!", res.msg, "warning");
                }
            }).
            catch(err => {
                this.loadDepartments();// refresh page
                swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.showEmptyMsg();
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
