
<template>
    <div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="row" v-if="! showProfile && isLoadingBlade">
            <div class="col-lg-4"></div>
            <div class="col-lg-4 text-center">
                <base-input alternative=""
                    label="Enter Folio Number/ Email Address"
                    placeholder="Enter Folio Number/ Email Address"
                    input-classes="form-control-alternative"
                    v-model="key"
                    @keyup.enter="findDoctor()"
                    required
                />
            </div>
        </div>

        <div class="col-sm-12 text-center" v-if="! showProfile">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <div class="row" v-if="! showProfile && isLoadingBlade">
            <div class="col-lg-4"></div>
            <div class="col-lg-4 text-center">
                <base-button
                    type="info" size="lg"
                    @click="findDoctor()"
                    :disabled="(privileges && privileges.view == 'no') || ! privileges"
                >
                    <i class="fa fa-search"></i>
                    {{isLoading ? 'Please Wait...' : 'Find Doctor'}}
                </base-button>
            </div>
        </div>

        <!-- import Profile template here -->
        <Profile
            :record="record"
            :privileges="privileges"
            v-on:hideProfile="hideProfile"
            v-if="showProfile && isLoadingBlade"
        />

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import Profile from './Profile/Profile';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'VerifyDoctor',
        props: ['privileges'],
        components: {
            Profile
        },
        data() {
        return {
            key: null,
            isLoading: false,
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            showProfile: false,
            record: {}
        }
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl']),
            isLoadingBlade() {
                return this.menus && this.menus.length > 0
            }
        },
        methods: {
            ...mapActions(['getAUser']),
            findDoctor() {
                if(this.key) {
                    this.preloader();// show loading
                    this.getAUser(this.key).then((res) => {// get records  masterpath1@gmail.com
                        if(res.status) {
                            this.showProfile = true
                            this.key = null;
                            this.record = res.user;
                            swal.close();
                        } else {
                            this.key = null;
                            swal.fire('Error Occurred!', res.msg, 'error');
                        }
                    }).
                    catch(() => {
                        let msg = "Server Failure!, Kindly reload the page...";
                        swal.fire('Error Occurred!', msg, 'error');
                    });
                } else {
                    this.showInuptError('Folio Number/ Email Address')
                }
            },
            showInuptError(name) {
                this.showErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            hideProfile() {// close profile page
                return this.showProfile = false;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>
<style></style>
