<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">School's Name</th>
                            <th width="10%">Email Address</th>
                            <th width="10%">Status</th>
                            <th width="10%">{{ title }}</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.training_school ? row.training_school.name : 'N/A' }} <br>
                            <badge type="primary">{{ row.request_type }}</badge>
                        </td>
                        <td>
                            {{ row.training_school && row.training_school.email ? row.training_school.email : 'N/A' }}
                        </td>
                        <td>
                            <badge size="lg" :type="getColor(row.status)" class="text-uppercase">
                                {{ row.status ? row.status : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            {{ row.application_date | getDateFormat }}
                        </td>
                        <td>
                            <router-link
                                :to="'/training-schools/application-details/'+row.id"
                                :class="'btn btn-sm btn-success '+
                                    (((privileges && privileges.view == 'no') || ! privileges) ? 'disabled' : '')"
                            >
                                <i class="fa fa-eye"></i> Details
                            </router-link>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
import Badge from '../../../../components/Badge.vue';

    export default {
  components: { Badge },
        name: 'PendingApplicationData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "title", "paginatedItems", "code"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getColor(status) {
                if(status == 'submitted') {
                    return 'warning';
                } else if(status == 'approved') {
                    return 'success';
                } else {
                    return 'danger';
                }
            }
        },
    }
</script>