
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0 && ! searchKey">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">Batch Name <br>/ Code</th>
                        <th width="10%">Quota</th>
                        <th width="10%">Commencement <br> Date</th>
                        <th width="10%">Expiry <br> Date</th>
                        <th width="10%">Status</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.title }}<br>
                            <badge
                                size="sm" type="success"
                                class="font-weight-bolder"
                            >
                                {{ row.description }}
                            </badge>&nbsp;
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.year }}
                            </badge>
                        </td>
                        <td>
                            {{ row.quota }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.commencement_date | formatDate }}
                        </td>
                        <td class="text-capitalize">
                            {{ row.expiry_date | formatDate }}
                        </td>
                        <td class="text-capitalize">
                            <badge
                                size="sm" :type="((getExactStatus(row) == 'active') ? 'success' : 'danger')"
                                class="font-weight-bolder"
                            >
                                {{ getExactStatus(row) }}
                            </badge>
                        </td>
                        <td>
                            <button
                                class="btn btn-sm btn-success"
                                @click="$emit('loadAplications', row)"
                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                            >
                                <i class="fa fa-eye"></i>
                                Applications
                            </button>

                            <button
                                class="btn btn-sm btn-dark"
                                @click="$emit('getSeatStatus', row)"
                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                            >
                                <i class="fa fa-eye"></i>
                                Seat Status
                            </button>
                            <br>

                            <button
                                class="btn btn-sm btn-primary marginTop5"
                                @click="$emit('getPassStatus', row)"
                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                            >
                                <i class="fa fa-eye"></i>
                                Pass Status
                            </button>

                            <router-link
                                :to="'/assessment-exam-cards/' + row.id"
                                class="btn btn-sm btn-info marginTop5"
                            >
                                <i class="fa fa-print"></i>
                                Print Cards
                            </router-link> 
                            
                            <form 
                                :action="getRootUrl+'/download-assessment-files'" 
                                method="POST"
                                v-if="isSuperAdmin && row && row.applicant_count > 0"
                            >
                                <input type="hidden" name="batch_id" :value="row.id"/>
                                <input type="hidden" name="admin_id" :value="user.id"/>

                                <button
                                    class="btn btn-sm btn-warning marginTop5"
                                    nativeType="submit"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-download"></i>
                                    Download Files
                                </button>
                            </form>

                            <form 
                                :action="getRootUrl+'/export/assessment/sheet'" 
                                method="POST"
                                v-if="row && row.applicant_count > 0"
                            >
                                <input type="hidden" name="batch_id" :value="row.id"/>
                                <input type="hidden" name="admin_id" :value="user.id"/>
                                <input type="hidden" name="authorization_token" :value="getAuthorizationToken"/>

                                <button
                                    class="btn btn-sm btn-success marginTop5"
                                    nativeType="submit"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-file-export"></i>
                                    Exam Sheet
                                </button>
                            </form>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'AssessmentApplicationData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'searchKey'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['user', 'getRootUrl', 'getAuthorizationToken']),
            isSuperAdmin() {// check if user Super Admin
                return this.user && this.user.unit && this.user.unit.department
                        && this.user.unit.department.code == 'super-admin';
            }
        },
        methods: {
            getExactStatus(data) {// get exact status of batch 
                let startDate   = moment(data.commencement_date);// get commencement date minus one day
                let endDate     = moment(data.expiry_date).add(1, 'days');// get expiry date plus one day
                return (moment().isBetween(startDate, endDate)) ? 'active' : 'closed'; 
            }
        }
    };
</script>
<style scoped>
    .marginTop5 {
        margin-top: 5px;
    }
</style>
<style>
    .table td {
        font-size: 15px;
    }
</style>
