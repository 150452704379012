<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Name</th>
                            <th>Email Address /<br> Phone Number</th>
                            <th>Type</th>
                            <th>Documents</th>                            
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row && row.basic_profile ? getFullName(row.basic_profile) : 'N/A'}} <br>
                            <badge size="lg"
                                type="primary"
                                class="text-capitalize"
                                v-if="row.basic_profile"
                            >
                                {{ getSignupStatus(row.basic_profile)  }}
                            </badge>
                        </td>
                        <td>
                            {{ row.email }} <br>
                            <badge size="lg"
                                type="primary"
                                class="text-capitalize"
                                v-if="row.basic_profile"
                            >
                                {{ row && row.basic_profile  ? row.basic_profile.phone : 'N/A' }}
                            </badge>


                            
                        </td>
                        <td>
                            {{ getType(row.basic_profile) }}
                        </td>
                        <td>
                            <badge size="lg"
                                type="danger"
                                class="text-uppercase"
                                v-if="row.files && row.files.certificate_file && ! row.files.certificate_file.has_file"
                            >
                                No File Uploaded
                            </badge>
                            
                            <base-button
                                tag="a" size="sm"
                                type="success"
                                :href="row.files.certificate_file.url"
                                target="_blank"
                                role="button"
                                aria-pressed="true"
                                v-if="row.files && row.files.certificate_file && row.files.certificate_file.has_file"
                            >
                                <i class="fa fa-eye"></i> Basic Medical File
                            </base-button> <br>
                            <base-button
                                tag="a" size="sm"
                                type="primary"
                                :href="row.files.additional_file.url"
                                target="_blank"
                                role="button"
                                aria-pressed="true"
                                class="marginTop5"
                                v-if="row.files && row.files.additional_file && row.files.additional_file.has_file"
                            >
                                <i class="fa fa-eye"></i> Additional Qual File
                            </base-button>
                        </td>
                        <td>
                            <base-button
                                type="success" size="sm"
                                @click="updateSignup(row.id, 'approved')"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-check"></i> Approve
                            </base-button>
                            <base-button
                                type="danger" size="sm"
                                @click="updateSignup(row.id, 'rejected')"
                                :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                            >
                                <i class="fa fa-ban"></i> Reject
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NewTempSignupData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "getFullName",
            "loadPendingSignups", "paginatedItems", "updateSignup"],
        methods: {
            getType(data) {
                if(data) {
                    let type = data.has_sub_registration_type ? data.has_sub_registration_type : false;
                    return type ? type.name : 'N/A';
                } else {
                    return 'N/A';
                }
            },
            getSignupStatus(profile) {
                let check = profile && profile.is_temp_renewal ? profile.is_temp_renewal : false;
                if(check) {
                    return (check == 'no') ? 'New Registration' : 'Renewal';
                }
                return 'N/A';
            }
        }
    }
</script>

<style scoped>
    .marginTop5 {
        margin-top: 5px;
    }
</style>