
<template>
    <div>
        <template>
            <tabs fill class="flex-column flex-md-row">
                <card shadow>
                    <tab-pane title="Medical Applications">
                        <span slot="title">
                            <i class="ni ni-books"></i>
                            Medical Applications
                        </span>
                        <!-- import Pending Applications template here -->
                        <MedicalCompletedApplications 
                            :type="'medical'"
                        />
                    </tab-pane>

                    <tab-pane title="Dental Applications">
                        <span slot="title">
                            <i class="ni ni-badge"></i>
                            Dental Applications
                        </span>
                        <!-- import Rejected Applications template here -->
                        <!-- <DentalCompletedApplications /> -->
                        <MedicalCompletedApplications 
                            :type="'dental'"
                        />
                    </tab-pane>
                </card>
            </tabs>
        </template>
    </div>
</template>
<script>
  import MedicalCompletedApplications from './CompletedApplications/MedicalCompletedApplications';
//   import DentalCompletedApplications from './CompletedApplications/DentalCompletedApplications';

  export default {
    name: 'CompletedApplications',
    components: {
        MedicalCompletedApplications,
        // DentalCompletedApplications
    }
  };
</script>
<style></style>
