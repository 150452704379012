
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div class="card shadow" v-if="records && records.length > 0">
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="10%">Complainant's Name</th>
                        <th width="10%">Complainant's Email</th>
                        <th width="10%">Complaint's Status</th>
                        <th width="10%">Date Created</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                    <td class="budget">
                        {{ pagination.from+index}}
                    </td>
                    <td class="text-capitalize">
                        {{ row.name }}
                    </td>
                    <td>
                        {{ row.email }}
                    </td>
                    <td>
                        <badge size="lg"
                            :type="((row.status == 'pending') ? 'warning' : 'success')"
                            class="text-uppercase"
                        >
                            {{ row.status  }}
                        </badge>
                    </td>
                    <td>
                        {{ row.created_at | getDateFormat }}
                    </td>
                    </template>

                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
  import moment from 'moment';

  export default {
    name: 'ComplaintsReportData',
    props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges'],
    filters: {
        getDateFormat(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        }
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
