
<template>
    <div>

        <div class="table-responsive">

            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>School's Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application.training_school ? application.training_school.name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Email Address:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ application.training_school ? application.training_school.email : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Phone Number:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            {{ application.training_school ? application.training_school.phone : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody v-if="isAccreditation">
                    <td width="30%">
                        <h4>Accreditation Status:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input alternative="">
                                <select
                                    class="form-control form-control-alternative"
                                    v-model="accredit_status"
                                    :disabled="showBackButton"
                                >
                                    <option :value="null" disabled selected>--- Select One ---</option>
                                    <option value="pre-clinical-accreditation">Pre Clinical Accreditation</option>
                                    <option value="first-clinical-accreditation">First Clinical Accreditation</option>
                                    <option value="final-clinical-accreditation">Final Clinical Accreditation</option>
                                    <option value="re-accreditation">Re-Accreditation</option>
                                </select>
                            </base-input>
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Approved Quota:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input alternative=""
                                input-classes="form-control-alternative"
                                placeholder="Enter Approved Quota"
                                v-model="quota"
                                type="number"
                                min="1"
                                :readonly="showBackButton"
                            />
                        </h4>
                    </td>
                </tbody>

                <tbody v-if="isAccreditation">
                    <td width="30%">
                        <h4>Expiry Year:</h4>
                    </td>
                    <td>
                        <base-input
                            addon-left-icon="ni ni-calendar-grid-58"
                            required

                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="{allowInput: false, minDate: 'today'}"
                                class="form-control datepicker"
                                v-model="expiry_year"
                                :disabled="showBackButton"
                            >
                            </flat-picker>
                        </base-input>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Ref Number:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input alternative=""
                                input-classes="form-control-alternative"
                                placeholder="Enter Ref Number"
                                v-model="ref_no"
                                type="text"
                                :readonly="showBackButton"
                            />
                        </h4>
                    </td>
                </tbody>

                <tbody v-if="isAccreditation">
                    <td width="30%">
                        <h4>Plenary Meeting:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input alternative=""
                                input-classes="form-control-alternative"
                                placeholder="Enter Plenary Meeting Number"
                                v-model="plenary"
                                type="number"
                                :readonly="showBackButton"
                            />
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>
                            {{ isAccreditation ? 'Plenary Meeting Date' : 'Number Five (v) Text' }}:
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input alternative=""
                                input-classes="form-control-alternative"
                                :placeholder="'Enter ' + (isAccreditation 
                                                ? 'Plenary Meeting Date' : 'Number Five (v) Text')"
                                v-model="plenary_date"
                                type="text"
                                :readonly="showBackButton"
                            />
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>
                            {{ isAccreditation ? 'Plenary Meeting Venue' : 'Number Eleven (xi) Text' }}:
                        </h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input alternative=""
                                input-classes="form-control-alternative"
                                :placeholder="'Enter ' + (isAccreditation 
                                                ? 'Plenary Meeting Venue' : 'Number Eleven (xi) Text')"
                                v-model="plenary_venue"
                                type="text"
                                :readonly="showBackButton"
                            />
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Last Visitation Date:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input
                                addon-left-icon="ni ni-calendar-grid-58"
                                required

                            >
                                <flat-picker
                                    slot-scope="{focus, blur}"
                                    @on-open="focus"
                                    @on-close="blur"
                                    :config="{allowInput: false}"
                                    class="form-control datepicker"
                                    v-model="visit_date"
                                    :disabled="showBackButton"
                                >
                                </flat-picker>
                            </base-input>
                        </h4>
                    </td>
                </tbody>

                <tbody v-if="isAccreditation">
                    <td width="30%"> 
                        <h4>Additional Comments:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input alternative="">
                                <textarea
                                    rows="2"
                                    class="form-control form-control-alternative"
                                    placeholder="Enter Additional Comments"
                                    v-model="comment"
                                    :readonly="showBackButton"
                                    ></textarea>
                            </base-input>
                        </h4>
                    </td>
                </tbody>

                <tbody v-if="isCompleted && ! showBackButton">
                    <td width="30%">
                        <h4>Preview Letter:</h4>
                    </td>
                    <td>
                        <form
                            :action="getRootUrl+'/print-school-letter'"
                            method="POST"
                            target="_blank"
                        >
                            <input type="hidden" name="id" :value="application.id" />
                            <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                            <input type="hidden" name="user_id" :value="user.id" />
                            <input type="hidden" name="option" value="admin" />
                            <input type="hidden" name="date" :value="dateNow" />
                            <input type="hidden" name="ref_no" :value="ref_no" />
                            <input type="hidden" name="visit_date" :value="visit_date" />
                            <input type="hidden" name="quota" :value="quota" />
                            <input 
                                v-if="isAccreditation" 
                                type="hidden" 
                                name="accredit_status" 
                                :value="accredit_status" 
                            />
                            <input 
                                v-if="! isAccreditation" 
                                type="hidden" 
                                name="accredit_status" 
                                :value="application.request_type" 
                            />
                            <input type="hidden" name="plenary" :value="plenary" />
                            <input type="hidden" name="plenary_date" :value="plenary_date" />
                            <input type="hidden" name="plenary_venue" :value="plenary_venue" />

                            <base-button
                                type="primary"
                                class="btn btn-lg"
                                nativeType="submit"
                            >
                                <i class="fa fa-print"></i>
                                Print Letter (Preview)
                            </base-button>
                        </form>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="success"
                            :disabled="privileges.update == 'no' || ! isCompleted"
                            @click="approveApplication(application.id)"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-check"></i>
                            {{ approveText }}
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Approval
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  export default {
    name: 'ApproveApplication',
    props: ['application', 'privileges', 'loadApplication', 'isAccreditation'],
    components: {
        flatPicker
    },
    data() {
      return {
        id: this.$route.params.id,
        accredit_status: null,
        quota: null,
        expiry_year: null,
        plenary: null,
        plenary_date: null,
        plenary_venue: null,
        visit_date: null,
        comment: null,
        ref_no: null,
        showBackButton: false
      }
    },
    computed: {
        ...mapGetters(['user', 'getRootUrl', 'getAuthorizationToken']),
        approveText() {
            return this.application && (this.application.type == 'accreditation') ?
                    'Accredit School' : 'Approve Application';
        },
        isCompleted() {// check if has completed values
            return (this.quota && this.ref_no && this.visit_date && this.plenary_date && this.plenary_venue) && (
                (this.accredit_status && this.plenary && this.expiry_year) || (! this.isAccreditation));
        },
        dateNow() {
            return moment().format("YYYY-MM-DD");
        }
    },
    methods: {
        ...mapActions(['approveSchoolApplication']),
        updateAccreditStatus() {
            let date = moment().add(5, 'year');
            let school = this.application && this.application.training_school 
                                ? this.application.training_school : false;
            this.accredit_status = school && school.accredit_status ? school.accredit_status : null;            
            return this.expiry_year = date.format('YYYY-MM-DD');
        },
        approveApplication(id) {// reject application
            if(! this.isAccreditation || this.isCompleted) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeApproveApiCall(id);// make api call
                    }
                });
            } else {
                let msg = 'Fill necessary field(s)';
                // msg += this.isAccreditation ? ' / Accreditation Status' : '';
                swal.fire('Error Occurred!', msg, 'warning');
            }
        },
        makeApproveApiCall(id) {// reject application api
            let data = { 
                id : id, reject_reason : null, status : 'approved', admin_id : this.user.id,
                accredit_status: this.accredit_status, quota : this.quota, expiry_year : this.expiry_year,
                plenary: this.plenary, plenary_date : this.plenary_date, plenary_venue : this.plenary_venue,
                visit_date: this.visit_date, visit_status: 'no', visit_comment: null, comment : this.comment,
                ref_no: this.ref_no, approved_status : 'yes', notify_date: null, notify_comment: null
            };
            this.preloader();// show loading
            this.approveSchoolApplication(data).then((res) => {// reject application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadApplication(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    created() {
        this.updateAccreditStatus();
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
