
<template>
    <div>
        <div class="row">
            <div class="col-lg-12 text-right">
                <button
                    class="btn btn-lg btn-danger"
                    @click="$emit('getPassStatus', null)"
                >
                    <i class="fa fa-reply"></i>
                    Back To Batches
                </button>
                <br><br>
            </div>
        </div>

        <template>
            <tabs fill class="flex-column flex-md-row">
                <card shadow>
                    <tab-pane title="Failed Applicants">
                        <span slot="title">
                            <i class="ni ni-books"></i>
                            Applicants that Failed
                        </span>
                        <!-- import Failed Applicants template here -->
                        <FailedApplicants
                            :batch="batch"
                            :changeTitle="changeTitle"
                            :privileges="privileges"
                            :loadTitle="loadTitle"
                            :type="'fail'"
                            :checked="false"
                            v-if="batch && batch.id"
                        />
                    </tab-pane>

                    <tab-pane title="Passed Applicants">
                        <span slot="title">
                            <i class="ni ni-badge"></i>
                            Applicants that Passed
                        </span>
                        <!-- import Passed Applications template here -->
                        <FailedApplicants
                            :batch="batch"
                            :changeTitle="changeTitle"
                            :privileges="privileges"
                            :loadTitle="loadTitle"
                            :type="'pass'"
                            :checked="true"
                            v-if="batch && batch.id"
                        />
                    </tab-pane>

                </card>
            </tabs>
        </template>
    </div>
</template>
<script>
    import FailedApplicants from './FailedApplicants/FailedApplicants.vue';

    export default {
        name: 'AssessmentPassStatus',
        props: ['batch', 'changeTitle', 'privileges', 'loadTitle'],
        components: {
            FailedApplicants
        }
    };
</script>
<style></style>
