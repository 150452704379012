
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="20%">Payee's Name</th>
                        <th width="20%">Application Type</th>
                        <th width="10%">Amount (&#x20a6;)</th>
                        <th width="10%">RRR Number</th>
                        <th width="10%">Date Paid</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ getFullName(row) }}
                        </td>
                        <td v-html="breakWord(getApplicationTypeName(row))"></td>
                        <td>
                            <span v-if="selectedAmt">
                                {{ selectedAmt > 0 ? '&#x20a6; '+formatPrice(selectedAmt) : 'N/A' }}
                            </span>
                            <span v-if="! selectedAmt" v-html="getExactAmount(row)"></span>
                        </td>
                        <td>
                            {{ row.payment_ref }}
                        </td>
                        <td>
                            {{ getExactDate(row) | formatDate }}
                        </td>
                    </template>
                </base-table>
            </div>

            <div class="col-lg-12" v-if="totalAmount">
                <hr>
                <div class="container">
                    <h3 class="text-uppercase">
                        total amount paid:
                        <span class="right">
                            &#x20a6; {{ formatPrice(totalAmount) }}
                        </span>
                    </h3>
                </div>
                <hr>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
  import moment from 'moment';

  export default {
    name: 'PaymentReportData',
    props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'selectedSubHead', 'totalAmount', 'surcharge'],
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        selectedAmt() {
            return this.selectedSubHead ? this.selectedSubHead.amount : false;
        }
    },
    methods: {
        getFullName(trans) {// get full name
            let app = trans && trans.application ? trans.application : false;
            if(app && app.user_data) {
                let name = app.user_data.first_name+' ';
                name += app.user_data.other_name ? app.user_data.other_name+' ' : '';
                name += app.user_data.last_name+' ';
                return name.toLowerCase();
            } else {
                return 'N/A';
            }
        },
        getExactDate(record) {
            return record.date_paid ? record.date_paid : record.date_generated;
        },
        getApplicationTypeName(trans) {// get application name
            let app = trans && trans.application ? trans.application : false;
            return (app && app.application_type) ? app.application_type.name : 'N/A';
        },
        formatPrice(value) {
            if(value) {
                let val = (value/1).toFixed(2).replace(',', '.');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 'N/A';
            }
        },
        getExactAmount(row) {
            let type = row && row.application && row.application.application_type ? row.application.application_type : false;
            let isLicense = type && (type.id == 7 || type.id == 15) ? true : false;
            let data = type && type.has_payment ? type.has_payment : false;
            if(isLicense) {
                if(data) {
                    let total = parseInt(data.surcharge) + parseInt(data.amount);
                    return (this.surcharge == 'yes') ?  '&#x20a6; '+ this.formatPrice(total) : '&#x20a6; '+ this.formatPrice(data.amount);
                } else {
                    return 'N/A';
                }
            } else {
                return row ? '&#x20a6; '+ this.formatPrice(row.amount) : 'N/A';
            }
        },
        breakWord(word) {// break word into two parts
            let exploded = word.split(' ');
            let formedWord = '';
            let half = Math.ceil((exploded.length - 1)/2);
            for(let phrase of exploded) {
                formedWord += phrase+' ';
                if(exploded[half] == phrase) {
                    formedWord += ' <br> ';
                }
            }
            return formedWord;
        }
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .right {
        float: right !important;
    }
</style>
