
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Surname"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Doctor's Surname"
                            v-model="record.last_name"
                            required
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="First Name"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Doctor's First Name"
                            v-model="record.first_name"
                            required
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Other Names"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Doctor's Other Names"
                            v-model="record.other_name"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Folio Number (In Full)"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Doctor's Folio Number"
                            v-model="record.folio_number"
                            required
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input label="Practice Type" required>
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.doctor_type_id"
                                required
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option value="1">Medical Registration</option>
                                <option value="2">Dental Registration</option>
                                <option value="3">Alternative Registration</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input label="Gender">
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.gender"
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="State of Practice"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.state_id"
                                @change="getStateLgas()"
                            >
                                <option
                                    :value="null"
                                    disabled
                                >
                                    --- Select One ---
                                </option>
                                <option
                                    v-show="locations.states"
                                    v-for="state in locations.states"
                                    :key="state.id"
                                    :value="state.id"
                                >
                                    {{state.name}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showLga && record.state_id">
                        <base-input alternative=""
                            label="LGA of Practice"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.lga_id"
                            >
                                <option value="null" disabled>--- Select One ---</option>
                                <option
                                    v-show="lgas && lgas.length > 0"
                                    v-for="lga in lgas"
                                    :value="lga.id"
                                    :key="lga.id"
                                >
                                    {{lga.name}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                </div>
            </div>


            <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>

            <div class="pl-lg-4">
                <base-button
                    type="success"
                    @click="addToRegister()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                >
                    <i class="fa fa-save" @if='! isloading'></i>
                    {{isloading ? 'Please Wait' : (record.id ? 'Update' : 'Add')+' Record'}}
                </base-button>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'AddOfflineExitRegister',
    props: ['privileges', 'data', 'locations'],
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: false,
        record: {
            last_name: null,
            first_name: null,
            other_name: null,
            folio_number: null,
            doctor_type_id: null,
            gender: null,
            state_id: null,
            lga_id: null,
            id: null
        },
        lgas: [],
        isloading: false
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
        showLga() {
            return this.record && this.record.state_id;
        }
    },
    methods: {
        ...mapActions(['addOfflineExitRegister']),
        setData() {
            this.emptyFields();
            let data = this.data;
            if(data && data.id) {
                this.record = {
                    last_name: data.last_name,
                    first_name: data.first_name,
                    other_name: data.other_name,
                    folio_number: data.folio_number,
                    doctor_type_id: data.doctor_type_id,
                    gender: data.gender,
                    state_id: data.state_id,
                    lga_id: data.lga_id,
                    id: data.id
                };
                this.getStateLgas(data.state_id);
            }
        },
        addToRegister() {
            this.isloading = true;
            this.preloader();// show loading
            let status = this.validatedData();// valid input data
            if(status) {// add to sanction list
                this.record.admin_id = this.user.id;// add admin id
                this.addOfflineExitRegister(this.record).then((res) => {
                    this.isloading = false;
                    swal.close();
                    if(res.status) {
                        this.emptyFields();
                        swal.fire("Register Updated!", res.msg, "success");
                    } else {
                        swal.fire("Error Ocurred!", res.msg, "warning");
                    }
                });
            } else {
                this.isloading = false;
                swal.close();
            }
        },
        emptyFields() {
            return this.record = {
                last_name: null,
                first_name: null,
                other_name: null,
                folio_number: null,
                doctor_type_id: null,
                gender: null,
                state_id: null,
                lga_id: null,
                id: null
            };
        },
        validatedData() {
            let data = this.record;
            data.doctor_type_id != null ? data.doctor_type_id : this.showInuptError('Practice Type');
            data.folio_number != null ? data.folio_number : this.showInuptError('Folio Number');
            data.first_name != null ? data.first_name : this.showInuptError('First Name');
            data.last_name != null ? data.last_name : this.showInuptError('Surname');
            let status = data.doctor_type_id && data.folio_number && data.first_name && data.last_name;
            return status ? true : false;
        },
        showInuptError(name) {
            this.isloading = false;
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        getStateLgas(id) {// get state's lgas
            let lgas = [];
            let stateId = id ? id : this.record.state_id;
            for(let lga of this.locations.lgas) {
                if (lga.state_id == stateId) {
                    lgas.push(lga);
                }
            }
            return this.lgas = lgas;
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.setData();
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
