<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/"
                                        class="btn btn-sm btn-primary"
                                    >
                                        <i class="ni ni-tv-2"></i>
                                        Dashboard
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade">
                            <!-- import Applications template here -->
                            <AttachmentBatches
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'attachment-batches'"
                            />

                            <!-- import Attachment Centres template here -->
                            <AttachmentCentres
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'attachment-centres'"
                            />

                            <!-- import Attachment Applications template here -->
                            <AttachmentApplications
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'attachment-applications'"
                            />

                            <!-- import Attachment posting template here -->
                            <AttachmentPosting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'attachment-posting'"
                            />

                            <!-- import Attachment Dashboard template here -->
                            <AttachmentDashboard
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'attachment-dashboard'"
                            />

                            <!-- import Attachment Reporting template here -->
                            <Reporting
                                :privileges="privileges"
                                :changeTitle="changeTitle"
                                :loadTitle="loadTitle"
                                v-if="code && code == 'attachment-reporting'"
                            />

                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                    </card>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import AttachmentBatches from './AttachmentBatches/AttachmentBatches';
  import AttachmentCentres from './AttachmentCentres/AttachmentCentres.vue';
  import AttachmentApplications from './AttachmentApplications/AttachmentApplications.vue';
  import AttachmentPosting from './AttachmentPosting/AttachmentPosting.vue';
  import AttachmentDashboard from './AttachmentDashboard/AttachmentDashboard.vue';
  import Reporting from './Reporting/Reporting';

  export default {
    name: 'Attachment',
    components: {
        AttachmentBatches,
        AttachmentCentres,
        AttachmentApplications,
        AttachmentPosting,
        AttachmentDashboard,
        Reporting
    },
    data() {
      return {
        code: this.$route.params.code,
        title: null,
        isLoadingBlade: false
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        privileges() {
          let privileges = null;
          if(this.menus && this.menus.length > 0) {
            for(let menu of this.menus) {
                if(menu.has_child && menu.has_child.length > 0) {
                    for(let child of menu.has_child) {
                        if('/'+child.link == this.$route.path) {
                            privileges = child.privileges;
                        }
                    }
                }
            }
          }
          return privileges;
        }
    },
    methods: {
        loadTitle() {// load title
            return this.title = this.replaceString(this.code);
        },
        replaceString(value) {
            if(value) {
                let text = value.replace('-', ' ');
                return text.replace('-', ' ');
            }
            return 'Attachment Program';
        },
        changeTitle(value, status) {// change title
            return status ? this.title = value : this.loadTitle();
        }
    },
    created() {
        this.loadTitle(); // load title
    }
  };
</script>
<style scoped>
</style>
