<template>
    <div>
        <div class="row" v-if="! showEditPage">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Educational Details</h3>
                <hr class="my-4" />
            </div>

            <div class="table-responsive" v-if="records && records.length > 0">
                <table class="table table-bordered">
                    <thead class="text-uppercase">
                        <td>
                            <h4>S/N</h4>
                        </td>
                        <td>
                            <h4>School Type</h4>
                        </td>
                        <td>
                            <h4>School Name</h4>
                        </td>
                        <td>
                            <h4>Country</h4>
                        </td>
                        <td>
                            <h4>Qualification</h4>
                        </td>
                        <td>
                            <h4>Start Date</h4>
                        </td>
                        <td>
                            <h4>End Date</h4>
                        </td>
                        <td v-if="applicant.status == 'submitted'">
                            &nbsp;
                        </td>
                    </thead>
                    <tbody
                        v-for="(record , index) in records"
                        :key="record.id"
                    >
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ index+1 }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ replaceString(record.school_type) }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                <span v-html="breakWord(record.school_name)"></span>
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.has_country ? record.has_country.name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-uppercase">
                                {{ record.qualifications_obtained ? record.qualifications_obtained : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.start_date | getDateFormat }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.end_date | getDateFormat }}
                            </h4>
                        </td>
                        <td v-if="applicant.status == 'submitted'">
                            <base-button
                                type="primary" size="sm"
                                class="right"
                                @click="editEduRecord(true, record)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </base-button>
                        </td>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Educational Records !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>

        <!-- edit personal data template here -->
        <EditEduDetails
            :res="record"
            :applicant="applicant"
            :locations="locations"
            :editEduRecord="editEduRecord"
            :loadAssessmentApplicant="loadAssessmentApplicant"
            v-if="showEditPage"
        />

    </div>
</template>

<script>
    import moment from 'moment';
    import EditEduDetails from './EditAssessment/EditEduDetails';
import { mapGetters } from 'vuex';

    export default {
        name: 'EduDetails',
        props: [ 'records', 'applicant' , 'loadAssessmentApplicant', 'privileges', 'editPage', 'breakWord' ],
        components: {
            EditEduDetails
        },
        data() {
            return {
                showEditPage: false,
                record: null
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['locations'])
        },
        methods: {
            replaceString(value) {
                return value.replace("-", " ");
            },
            editEduRecord(status, data = null) {
                this.record = null;
                let text = status ? 'edu' : null;
                this.editPage(status, text);
                this.record = data;
                return this.showEditPage = ! this.showEditPage
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>