
<template>
    <div>
        <div class="col-lg-12">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Pending Applications">
                            <span slot="title">
                                <i class="ni ni-books"></i>
                                Active Accredited CPDs
                            </span>
                            <!-- import Pending Applications template here -->
                            <ActiveAccreditedCpds />
                        </tab-pane>

                        <tab-pane title="Rejected Applications">
                            <span slot="title">
                                <i class="ni ni-badge"></i>
                                Inactive Accredited CPDs
                            </span>
                            <!-- import Rejected Applications template here -->
                            <InActiveAccreditedCpds />
                        </tab-pane>

                    </card>
                </tabs>
            </template>
        </div>
    </div>
</template>
<script>
    import ActiveAccreditedCpds from './ActiveAccreditedCpds';
    import InActiveAccreditedCpds from './InActiveAccreditedCpds';

    export default {
        name: 'AccreditedCpds',
        components: {
            ActiveAccreditedCpds,
            InActiveAccreditedCpds
        }
    };
</script>
<style></style>
