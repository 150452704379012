<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Preferred Attachment Centres Information</h3>
                <hr class="my-4" />
            </div>
        </div>

        <div class="row">
            <div 
                class="col-sm-4 text-center borderLine"
                v-for="(record, index) in centres"
                :key="index"
            >
                <br>
                <h4 class="text-capitalize height-50">
                    <span>
                        {{ record.title }}
                    </span><br><br>
                    <em class="title-font text-danger">
                        {{ record.description }}
                    </em>
                </h4><br><br>

                <div v-if="record.name">
                    <em class="title-font text-success">
                        {{ record.name ? record.name.name : 'N/A' }} <br>
                        <strong class="text-danger">(Selected)</strong>
                    </em>
                    <br><br>
                </div>
            </div>

        </div>

        <div class="col-lg-12 text-center" v-if="records && records.length == 0">
            <base-alert type="danger">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">No Preferred Centre Records !!!</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12">
            <hr class="my-4" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CentreDetails',
        props: [ 'records', 'isDental' ],
        computed: {
            centres() {
                if(this.isDental) {// dental applicants
                    return this.records.dental;
                } else {// medical applicants
                    return this.records.medical;
                }
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>