<template>
    <div>
        <div class="row" v-if="! showEditPage">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Referees Information</h3>
                <hr class="my-4" />
            </div>

            <div class="table-responsive" v-if="records && records.length > 0">
                <table class="table table-bordered">
                    <thead class="text-uppercase">
                        <td>
                            <h4>S/N</h4>
                        </td>
                        <td>
                            <h4>Name</h4>
                        </td>
                        <td>
                            <h4>Occupation / Position</h4>
                        </td>
                        <td>
                            <h4>address / Business Address</h4>
                        </td>
                        <td>
                            <h4>Tel</h4>
                        </td>
                        <!-- <td>
                            <h4>Folio No</h4>
                        </td> -->
                        <td v-if="applicant.status == 'submitted'">
                            &nbsp;
                        </td>
                    </thead>
                    <tbody
                        v-for="(record , index) in records"
                        :key="record.id"
                    >
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ index+1 }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ (record.name) }}
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.occupation }}<br>
                                <badge type="success">
                                    {{ record.position }}
                                </badge>
                            </h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                <span v-html="breakWord(record.address, 60)"></span><br>
                                <badge type="success">
                                    <span v-html="breakWord(record.business_address, 60)"></span>
                                </badge>
                            </h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ record.phone }}
                            </h4>
                        </td>
                        <!-- <td>
                            <h4 class="font-weight-light">
                                {{ record.folio_number ? record.folio_number : 'N/A' }}
                            </h4>
                        </td> -->
                        <td v-if="applicant.status == 'submitted'">
                            <base-button
                                type="primary" size="sm"
                                class="right"
                                @click="editRefereeRecord(true, record)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </base-button>
                        </td>
                    </tbody>
                </table>
            </div>

            <div class="col-lg-12 text-center" v-if="records && records.length == 0">
                <base-alert type="danger">
                    <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                    <span class="alert-inner--text">
                        <h2 class="text-white">No Referee Records !!!</h2>
                    </span>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>

        <!-- edit Referee data template here -->
        <EditRefereeDetails
            :res="record"
            :applicant="applicant"
            :editRefereeRecord="editRefereeRecord"
            :loadAssessmentApplicant="loadAssessmentApplicant"
            v-if="showEditPage"
        />


    </div>
</template>

<script>
    import EditRefereeDetails from './EditAssessment/EditRefereeDetails';
    export default {
        name: 'RefereeDetails',
        props: [ 'records', 'applicant' , 'loadAssessmentApplicant', 'privileges', 'editPage', 'breakWord' ],
        components: {
            EditRefereeDetails
        },
        data() {
            return {
                showEditPage: false,
                record: null
            }
        },
        methods: {
            editRefereeRecord(status, data = null) {
                this.record = null;
                let text = status ? 'referee' : null;
                this.editPage(status, text);
                this.record = data;
                return this.showEditPage = ! this.showEditPage
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>