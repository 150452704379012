<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12" v-if="! showPostPage && ! showSinglePost">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="col-lg-12" v-if="showChangeButton">
                <hr>
                <base-button                    
                    class="btn btn-lg btn-success btn-block"
                    type="success" size="sm" 
                    @click="getPostPage(true)"
                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                >
                    <i class="fas fa-sticky-note"></i> Post Group
                </base-button><br>
            </div>
            <div class="card shadow">

                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%" v-if="! isPosted">
                                <input
                                    class="mb-3"
                                    id="all-checked"
                                    type="checkbox"
                                    @change="updateRecords(null, true)"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                />
                                <!-- @change="changeStatus(null, true)" -->
                            </th>
                            <th width="2%">S/N</th>
                            <th width="10%">Applicant's Name</th>
                            <th width="10%" v-if="isPosted">Centre's Name</th>
                            <th width="10%">RRR</th>
                            <th width="10%">Training Type</th>
                            <th width="10%" v-if="! isPosted">Application Date</th>
                            <th width="10%" v-if="isPosted">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td v-if="! isPosted">
                            <input
                                class="mb-3"
                                type="checkbox"
                                :id="'other-check'+row.id"
                                :checked="row.checked"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                @change="updateRecords(row)"
                            />
                        </td>
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.fullname }}
                        </td>
                        <td class="text-capitalize" v-if="isPosted">
                            {{ row.post_centre && row.post_centre.name ? row.post_centre.name : 'N/A' }}
                        </td>
                        <td>{{ row.rrr }}</td>
                        <td class="text-capitalize">
                            {{ row.training_type ? (row.training_type + ' Training') : 'N/A' }}
                        </td>
                        <td v-if="! isPosted">
                            {{ row.reg_date | getDateFormat }}
                        </td>
                        <td v-if="isPosted">
                            <button
                                class="btn btn-sm btn-success"
                                @click="getSinglePostPage(row, true)"
                                :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            >
                                <i class="fas fa-sticky-note"></i>
                                Post Detail
                            </button>
                        </td>
                        </template>

                    </base-table>
                </div>

                <div class="col-lg-12" v-if="showChangeButton">
                    <br>
                    <base-button                    
                        class="btn btn-lg btn-success btn-block"
                        type="success" size="sm" 
                        @click="getPostPage(true)"
                        :disabled="(privileges && privileges.update == 'no') || ! privileges"
                    >
                        <i class="fas fa-sticky-note"></i> Post Group
                    </base-button><br>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>

        <div class="col-lg-12" v-if="showPostPage && ! showSinglePost">
            <PostingPage 
                :applicants="applicants"
                :centres="centres"
                :privileges="privileges"
                :loadAplications="loadAplications"
                :preloader="preloader"    
                :getPostPage="getPostPage"          
            />
        </div>

        <div class="col-lg-12" v-if="showPostPage && showSinglePost">
            <SinglePostPage 
                :applicant="applicant"
                :centres="centres"
                :privileges="privileges"
                :loadAplications="loadAplications"
                :preloader="preloader"    
                :getSinglePostPage="getSinglePostPage"          
            />
        </div>

    </div>
</template>

<script>
    import moment from 'moment';
    import PostingPage from './PostingPage/PostingPage.vue';
    import SinglePostPage from './SinglePostPage/SinglePostPage.vue';

    export default {
        name: 'AwaitingPostingData',
        props: ["records", "pagination", "hasPagination", "paginate", "paginatedItems", "privileges", "displayPostPage",
                "showPostPage", "centres", "displayBackButton", "loadAplications", "preloader", "isPosted"],
        components: {
            PostingPage,
            SinglePostPage
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        data() {
            return {
                applicants: [],
                showChangeButton: false,
                showSinglePost: false,
                applicant: null
            }
        },
        methods: {
            getSinglePostPage(record, status) {// show single post page
                this.applicant = record;
                this.showSinglePost = status;
                this.getPostPage(status);
            },
            updateRecords(applicant, status = false) {
                this.showChangeButton = false;
                if(status) {
                    this.removeAllChecks();// remove general checks
                } else {// remove each checks on their checkboxes
                    this.removeIndividualCheck(applicant);
                    this.showChangeButton = true;
                }
            },
            removeAllChecks() {// remove general checks
                this.applicants = [];// eslint-disable-next-line
                if($('#all-checked').is(":checked")) {
                    for(let record of this.records) {
                        record.checked = true;
                        this.applicants.push(record);
                    }
                    this.showChangeButton = true;
                } else {
                    for(let record of this.records) {
                        record.checked = false;
                    }
                } 
            },
            removeIndividualCheck(applicant) {// remove each checks on their checkboxes
                // eslint-disable-next-line
                if($('#other-check'+applicant.id).is(":checked")) {
                    applicant.checked = true;
                    this.applicants.push(applicant);
                } else {
                    applicant.checked = false;
                    if(this.applicants.length > 0) {
                        this.applicants = this.applicants.filter(item => item.id !== applicant.id);
                    }
                }
            },
            getPostPage(status) {// display Posting page function
                if(! status) {  this.removeAllChecks(); }// remove general checks
                this.showChangeButton = status;
                this.displayPostPage(status);
                this.displayBackButton(! status);
            }
        }
    }
</script>