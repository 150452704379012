<template>
    <div>

        <div class="col-lg-12" v-if="isLoading">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-8" v-if="! isLoading">
            <small class="font-weight-bold">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of 
                {{ pagination.total }} entries
            </small>
        </div>
        
        <div class="card shadow" v-if="! showErrorStatus && ! isLoading">
            
            <div class="table-responsive">
                <base-table 
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="10%">Application Type</th>
                        <th width="10%">Status</th>
                        <th width="10%">Payment <br>Status</th>
                        <th width="10%">Apply <br>Date</th>
                        <th width="10%" v-if="isHodOrSuper">&nbsp;</th>
                    </template>

                    <template 
                        slot-scope="{row, index}"
                    >
                    <td class="budget">
                        {{ pagination.from+index}}
                    </td>
                    <td class="text-capitalize">
                        {{ row.application_type ? row.application_type.name : 'N/A' }}
                        <br>
                        <badge size="lg" 
                            type="dark"
                            class="text-capitalize"
                            v-show="row.license_year"
                        >
                            {{ row.license_year }}
                        </badge>
                    </td>
                    <td class="text-capitalize">
                        <badge size="lg" 
                            :type="getColor(row.status)"
                        >
                            {{ row.status }}
                        </badge>
                    </td>
                    <td>
                        <badge size="lg" 
                            :type="getPaymentColor(row.payment_status)"
                        >
                            {{ row.payment_status | replaceString }}
                        </badge> <br>
                        <badge size="lg" 
                            :type="getColor(row.status)"
                            v-if="! row.transaction"
                        >
                            No Transaction
                        </badge>
                    </td>
                    <td>
                        {{ row.application_date | getDateFormat }}
                    </td>
                    <td v-if="isHodOrSuper">
                        <base-button
                            type="danger" size="sm"
                            @click="deleteApplication(row.id)"
                            :disabled="row.transaction"
                        >
                            <i class="fa fa-trash"></i>
                            Delete
                        </base-button>
                    </td>
                    </template>

                </base-table>
            </div>

            <div 
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination"
                    @click="paginate"
            >
                <base-pagination 
                    :total="pagination.total" 
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"
                    
                >
                </base-pagination>
            </div>

        </div>

        <div class="col-sm-12 text-center">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>
        
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'Applications',
        props: ['record','privileges', 'isHodOrSuper'],
        data() {
            return {
                records: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.url.perPage // get per page count
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoading: true,
            }
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
            replaceString(value) {
                return value ? value.replace("-", " ") : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            hasPagination() {
                return this.pagination.next_page_url || this.pagination.prev_page_url;
            },
        },
        methods: {
            ...mapActions(['getUserApplications', 'getPaginatedUserApplications', 'deleteAnApplication']),
            deleteApplication(id) {// delte an application without transaction
                if(this.isHodOrSuper) {
                    swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Delete It!'
                    }).then((result) => {
                        if (result.value) {
                            this.makeDeleteApplicationCall(id);// make api call
                        }
                    });
                } else {
                    swal.fire('Permission Denied', 'You don\'t the necessary permission', 'warning');
                }  
            },
            makeDeleteApplicationCall(id) {// delete api call
                this.preloader(); // show loading
                this.deleteAnApplication(id).then((res) => {
                    if(res.status) {
                        this.loadAplications(); // load applications
                        swal.fire('Application Deleted!', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            loadAplications() {// load applications
                let data = { 'id' : this.record.user_id, 'limit' : this.pagination.perPage };
                this.getUserApplications(data).then((res) => {
                    this.isLoading = false;
                    this.setPages(res);
                }).
                catch(() => {
                    this.isLoading = false;
                    this.showErrorStatus = true; 
                    this.errorType = 'warning';
                    this.errorMsg = 'An Error occurred,kindly try again!!!';
                });
            },
            setPages(res) {// set paginated function
                this.records = [];
                this.paginatedItems = res.data;
                this.records = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                
                this.emptyRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                let url = (this.pagination.number > this.pagination.current_page) ? 
                    this.pagination.next_page_url : this.pagination.prev_page_url;            
                if(url) {// make api call
                    this.isLoading = true;
                    this.getPaginatedUserApplications(url).then((res) => {// get records
                        this.isLoading = false;
                        this.pagination.number = res.current_page;
                        this.setPages(res);
                    }).
                    catch(() => {
                        this.records = [];
                        this.showErrorStatus = true;
                        this.isLoading = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.records = this.paginatedItems;
                }           
            },
            emptyRecordsMsg() {
                return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true; 
                this.errorType = 'warning';
                this.errorMsg = 'No Applications Yet!!!';
            },
            getPaymentColor(value) {
                return value == 'paid' ? 'success' : 'danger';
            },
            getColor(value) {
                return value == 'approved' ? 'success' : 'danger';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadAplications();// load applications
        },
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>