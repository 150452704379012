<template>
    <div class="row">
        <div class="col-lg-12">
            <h2 class="text-uppercase text-center">
                Applicant's Information
            </h2>
            <hr>
            <table class="table table-bordered">
                <tbody>
                    <td>
                        <h4>Applicant Name</h4>
                    </td>
                    <td colspan="3">
                        <h4 class="text-capitalize font-weight-light">
                            {{ applicant.fullname ? applicant.fullname : 'N/A' }}
                        </h4>
                    </td>                    
                </tbody>
                <tbody>
                    <td>
                        <h4>Centre Name</h4>
                    </td>
                    <td colspan="3">
                        <h4 class="text-capitalize font-weight-light">
                            {{ applicant.post_centre ? applicant.post_centre.name : 'N/A' }}
                        </h4>
                    </td>                    
                </tbody>
                <tbody>
                    <td>
                        <h4>Posted Date</h4>
                    </td>
                    <td colspan="3">
                        <h4 class="font-weight-light">
                            <span v-if="applicant.post_date">
                                {{ applicant.post_date | getDateFormat }}
                            </span>
                            <span v-if="! applicant.post_date">
                                N/A
                            </span>
                        </h4>
                    </td>                    
                </tbody>
                <tbody>
                    <td>
                        <h4>Posting Letter</h4>
                    </td>
                    <td colspan="3">
                        <form
                            :action="getRootUrl+'/print-posting-letter'"
                            method="POST"
                            target="_blank"
                        >
                            <input type="hidden" name="id" :value="applicant.id" />
                            <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                            <input type="hidden" name="user_id" :value="user.id" />

                            <base-button
                                type="primary"
                                class="btn btn-lg"
                                nativeType="submit"
                            >
                                <i class="fa fa-print"></i>
                                Posting Letter
                            </base-button>
                        </form>
                    </td>                    
                </tbody>
            </table>
            <hr>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'SinglePostPageInfo',
        props: ["applicant", "user"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY [at] h:m') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['getRootUrl', 'getAuthorizationToken'])
        }
    }
</script>
<style scoped>
    .right {
        float: right !important;
    }
</style>