
<template>
    <div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>Applicant Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ getFullName(applicant) }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Applicant Date:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ applicant.reg_date | getDateFormat }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Preview Exam Card:</h4>
                    </td>
                    <td>
                        <form
                            :action="getRootUrl+'/print-assessment-card'"
                            method="POST"
                            target="_blank"
                        >
                            <input type="hidden" name="id" :value="applicant.id" />
                            <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                            <input type="hidden" name="type" :value="user.id" />

                            <base-button
                                type="primary"
                                class="btn btn-sm"
                                nativeType="submit"
                            >
                                <i class="fa fa-print"></i>
                                Exam Card
                            </base-button>
                        </form>
                    </td>
                </tbody>

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="success"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges"
                            @click="approveUserApplication(applicant.id)"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-check"></i>
                            Approve Application
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('showApprovalPage')"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Approval
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('showApprovalPage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'ApproveApplication',
    props: ['applicant', 'privileges', 'loadAssessmentApplicant'],
    data() {
      return {
        id: this.$route.params.id,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false
      }
    },
    filters: {
        getDateFormat(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        }
    },
    computed: {
        ...mapGetters(['user', 'getRootUrl', 'getAuthorizationToken']),
    },
    methods: {
      ...mapActions(['approveAssessmentApplication']),
        getFullName(record) {
            let profile = record.personal_data;
            let name = profile.first_name+' ';
            name += profile.other_name ? profile.other_name+' ' : '';
            name += profile.surname;
            return name;
        },
        approveUserApplication(id) {// approve application
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeRejectApiCall(id);// make api call
                }
            });
        },
        makeRejectApiCall(id) {// approve application api
            let data = { 'id' : id, 'reject_reason' : null,
                 'status' : 'approved', 'admin_id' : this.user.id };
            this.preloader();// show loading
            this.approveAssessmentApplication(data).then((res) => {// approve application
                if(res.status) {
                    this.showBackButton = true;
                    swal.fire('Application Approved!', res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadAssessmentApplicant(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
