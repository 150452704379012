
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Surname"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Doctor's Surname"
                            v-model="record.last_name"
                            required
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="First Name"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Doctor's First Name"
                            v-model="record.first_name"
                            required
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Other Names"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Doctor's Other Names"
                            v-model="record.other_name"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Folio Number (In Full)"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Doctor's Folio Number"
                            v-model="record.folio_number"
                            required
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input label="Practice Type" required>
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.doctor_type_id"
                                required
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option value="1">Medical Registration</option>
                                <option value="2">Dental Registration</option>
                                <option value="3">Alternative Registration</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input label="Gender">
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.gender"
                            >
                                <option  value="null" disabled>
                                    ---Select One---
                                </option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="State of Practice"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.state_id"
                                @change="getStateLgas()"
                            >
                                <option
                                    :value="null"
                                    disabled
                                >
                                    --- Select One ---
                                </option>
                                <option
                                    v-show="locations.states"
                                    v-for="state in locations.states"
                                    :key="state.id"
                                    :value="state.id"
                                >
                                    {{state.name}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4" v-if="showLga && record.state_id">
                        <base-input alternative=""
                            label="LGA of Practice"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.lga_id"
                            >
                                <option value="null" disabled>--- Select One ---</option>
                                <option
                                    v-show="lgas && lgas.length > 0"
                                    v-for="lga in lgas"
                                    :value="lga.id"
                                    :key="lga.id"
                                >
                                    {{lga.name}}
                                </option>
                            </select>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input
                            label="Sanctioned Date"
                            addon-left-icon="ni ni-calendar-grid-58"
                            required
                        >
                            <flat-picker
                                slot-scope="{focus, blur}"
                                @on-open="focus"
                                @on-close="blur"
                                :config="{allowInput: true}"
                                class="form-control datepicker"
                                v-model="record.sanctioned_date"
                            >
                            </flat-picker>
                        </base-input>
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Nature of Sanction"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Nature of Sanction"
                            v-model="record.sanction_nature"
                            required
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Sanction Duration (Months)"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Sanction Duration"
                            v-model="record.sanction_duration"
                            type="number"
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Regulatory Body"
                            input-classes="form-control-alternative text-capitalize"
                            placeholder="Enter Regulatory Body"
                            v-model="record.regulatory_body"
                            required
                        />
                    </div>

                    <div class="col-lg-4">
                        <base-input alternative=""
                            label="Publish to Website"
                        >
                            <select
                                class="form-control form-control-alternative"
                                v-model="record.publish"
                            >
                                <option :value="null" disabled selected>--- Select One ---</option>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </base-input>
                    </div>

                </div>
            </div>

            <base-alert :type="errorType" dismissible v-if='showErrorStatus && ! isLoadingBlade'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </base-alert>

            <div class="pl-lg-4">
                <base-button
                    type="success"
                    @click="addSaction()"
                    :disabled="(privileges && privileges.add == 'no') || ! privileges || isloading"
                >
                    <i class="fa fa-save" @if='! isloading'></i>
                    {{isloading ? 'Please Wait' : (data ? 'Update' : 'Add')+' Dcotor'}}
                </base-button>
            </div>

        </div>

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'AddOfflineSanction',
        props: ['privileges', 'data', 'locations', 'displayAddOfflinePage'],
        components: {
            flatPicker
        },
        data() {
        return {
            showErrorStatus: false,
            errorType: null,
            errorMsg: null,
            isLoadingBlade: false,
            record: {
                last_name: null,
                first_name: null,
                other_name: null,
                folio_number: null,
                sanctioned_date: null,
                doctor_type_id: null,
                gender: null,
                state_id: null,
                lga_id: null,
                sanction_nature: null,
                sanction_duration: null,
                regulatory_body: null,
                publish: null
            },
            lgas: [],
            isloading: false
        }
        },
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            },
        },
        computed: {
            ...mapGetters(['menus', 'loadingBladeUrl', 'user']),
            showLga() {
                return this.record && this.record.state_id;
            }
        },
        methods: {
            ...mapActions(['addOfflineSanctionList']),
            setData() {
                this.emptyFields();
                let sanction = this.data;
                if(sanction && sanction.id) {
                    this.record = {
                        id: sanction.id,
                        last_name: sanction.last_name,
                        first_name: sanction.first_name,
                        other_name: sanction.other_name,
                        folio_number: sanction.folio_number,
                        sanctioned_date: sanction.sanctioned_date,
                        doctor_type_id: sanction.doctor_type_id,
                        gender: sanction.gender,
                        state_id: sanction.state_id,
                        lga_id: sanction.lga_id,
                        sanction_nature: sanction.sanction_nature,
                        sanction_duration: sanction.sanction_duration,
                        regulatory_body: sanction.regulatory_body,
                        publish: sanction.publish
                    };
                    this.getStateLgas(sanction.state_id);
                }
            },
            addSaction() {
                this.isloading = true;
                this.preloader();// show loading
                let status = this.validatedData();// valid input data
                if(status) {// add to sanction list
                    this.record.admin_id = this.user.id;// add admin id
                    this.addOfflineSanctionList(this.record).then((res) => {
                        this.isloading = false;
                        swal.close();
                        if(res.status) {
                            this.record.id ? this.displayAddOfflinePage(false) : this.emptyFields();
                            swal.fire("List Updated!", res.msg, "success");
                        } else {
                            swal.fire("Error Ocurred!", res.msg, "warning");
                        }
                    });
                }   else {
                    this.isloading = false;
                    swal.close();
                }
            },
            emptyFields() {
                return this.record = {
                    last_name: null,
                    first_name: null,
                    other_name: null,
                    folio_number: null,
                    sanctioned_date: null,
                    doctor_type_id: null,
                    gender: null,
                    state_id: null,
                    lga_id: null,
                    sanction_nature: null,
                    sanction_duration: null,
                    regulatory_body: null,
                    publish: null
                };
            },
            validatedData() {
                let data = this.record;
                data.publish != null ? data.publish : this.showInuptError('Publish to Website');
                data.regulatory_body != null ? data.regulatory_body : this.showInuptError('Regulatory Body');
                data.sanction_nature != null ? data.sanction_nature : this.showInuptError('Nature of Sanction');
                data.sanctioned_date != null ? data.sanctioned_date : this.showInuptError('Sanctioned Date');
                data.doctor_type_id != null ? data.doctor_type_id : this.showInuptError('Practice Type');
                data.folio_number != null ? data.folio_number : this.showInuptError('Folio Number');
                data.first_name != null ? data.first_name : this.showInuptError('First Name');
                data.last_name != null ? data.last_name : this.showInuptError('Surname');
                let status = data.doctor_type_id && data.folio_number && data.first_name && data.last_name &&
                            data.sanctioned_date && data.sanction_nature && data.regulatory_body
                            && data.publish;
                return status ? true : false;
            },
            showInuptError(name) {
                this.isloading = false;
                this.showErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showErrorStatus = false; }, 5000);
            },
            getStateLgas() {// get state's lgas
                let lgas = [];
                let stateId = this.record.state_id;
                for(let lga of this.locations.lgas) {
                    if (lga.state_id == stateId) {
                        lgas.push(lga);
                    }
                }
                return this.lgas = lgas;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.setData();
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
    .form-control-label {
        width: 100%;
    }
</style>
