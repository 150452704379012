<template>
    <div>
        <div class="row" v-if="records && records.length > 0">
            <div class="col-lg-12">
                <div class="col-lg-8">
                    <small class="font-weight-bold">
                        Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                        {{ pagination.total }} entries
                    </small>
                </div>
                <div class="card shadow">

                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="records"
                        >
                            <template slot="columns">
                                <th width="5%">S/N</th>
                                <th width="30%">CPD Provider's Name</th>
                                <th width="20%">CPD Provider's Username</th>
                                <th width="25%">Status</th>
                                <th width="20%">Date Created</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td class="text-capitalize">
                                {{ row.name ? row.name : 'N/A' }}
                            </td>
                            <td>
                                {{ row.username ? row.username : 'N/A' }}
                            </td>
                            <td>
                                <badge size="lg"
                                    :type="getColor(row.status)"
                                    class="text-uppercase"
                                >
                                    {{ row.status }}
                                </badge>
                            </td>
                            <td>
                                {{ row.created_at | getDateFormat }}
                            </td>
                            </template>
                        </base-table>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"

                        >
                        </base-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'AccreditedReportingData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "paginatedItems"],
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        methods: {
            getColor(value, status = false) {
                if(status) {
                    return (value == "enable") ? 'warning' : 'success';
                } else {
                    return (value == "enable") ? 'success' : 'warning';
                }
            },
            getIcon(value) {
                return (value == "enable") ? 'ban' : 'check';
            }
        }
    }
</script>