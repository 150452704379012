<template>
    <div class="pl-lg-4">

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <h3 class="text-uppercase"  v-if="! isLoadingBlade">Edit Educational Data</h3>

        <hr class="my-4" />

        <div
            class="row align-items-center"
            v-if="! isLoadingBlade"
        >

            <div class="col-lg-4">
                <base-input alternative=""
                    label="School Type"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="records.school_type"
                    >
                        <option value="primary">Primary</option>
                        <option value="secondary">Secondary</option>
                        <option value="tertiary">Tertiary</option>
                        <option value="medical-school">Medical School</option>
                        <option value="pre-clinical-period">Pre-Clinical Period</option>
                        <option value="clinical-period">Clinical Period</option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-4">
                <base-input alternative=""
                    label="Country"
                    required
                >
                    <autocomplete
                        input-class="form-control form-control-alternative"
                        placeholder="Type Country"
                        showNoResults
                        :source="locations.countries"
                        v-model="records.country"
                        :initialDisplay="hasCountry.name"
                        @selected="changeCountry"
                    >
                    </autocomplete>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="! isOtherSchool">
                <base-input alternative=""
                    label="Name of Institution"
                    required
                >
                    <autocomplete
                        ref="schoolAutocomplete"
                        input-class="form-control form-control-alternative"
                        placeholder="Type to search Institution"
                        showNoResults
                        :disableInput="institutions && institutions.length == 0"
                        :source="institutions"
                        resultsValue="name"
                        :initialDisplay="records.school_name"
                        @selected="updateSchool"
                    >
                    </autocomplete>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="isOtherSchool">
                <base-input alternative=""
                    label="Name of Institution"
                    placeholder="Enter Institution Name"
                    input-classes="form-control-alternative"
                    v-model="records.other_school_name"
                    required
                />
            </div>
            <div class="col-lg-4">
                <base-input alternative=""
                    label="Qualifications Obtained"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="records.qualifications_obtained"
                        @change="changeOtherQualifications()"
                    >
                        <option
                            :value="null"
                            disabled
                        >
                            --- Select One ---
                        </option>
                        <option value="mbbs">MBBS</option>
                        <option value="md">MD</option>
                        <option value="mbbch">MBBCH</option>
                        <option value="bds">BDS</option>
                        <option value="dds">DDS</option>
                        <option value="waec">WAEC</option>
                        <option value="neco">NECO</option>
                        <option value="flsc">FLSC</option>
                        <option value="others">Others (Please, specify)</option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-4" v-if="showOtherQualifications">
                <base-input alternative=""
                    label="Other Qualifications"
                    placeholder="Enter Other Qualification Name"
                    input-classes="form-control-alternative"
                    v-model="records.other_qualifications_obtained"
                    required
                />
            </div>
            <div class="col-lg-4">
                <base-input
                    label="Degree Start Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="records.start_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-4">
                <base-input
                    label="Degree End Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="config"
                        class="form-control datepicker"
                        v-model="records.end_date"
                    >
                    </flat-picker>
                </base-input>
            </div>
            <div class="col-lg-12">
                <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>
            </div>
            <div class="col-lg-12"></div>
            <div class="col-lg-12">
                <base-button
                    type="success"
                    class="right"
                    @click="saveEduRecords()"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Update Record'}}
                </base-button>

                <div>
                    <base-button
                        type="danger"
                        @click="editEduRecord(false)"
                    >
                        <i class="fa fa-times"></i>
                        Cancel Edit
                    </base-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import swal from 'sweetalert2';// import sweet alert
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        components: {
            flatPicker,
            Autocomplete
        },
        name: 'EditEduDetails',
        props: ['editEduRecord', 'applicant', 'loadAssessmentApplicant', 'res', 'locations'],
        data() {
            return {
                eduRecords: [],
                paginatedItems: [],
                records: {
                    school_type: 'medical-school',
                    school_name: null,
                    school_id: null,
                    other_school_name: null,
                    qualifications_obtained: null,
                    country: null,
                    start_date: null,
                    end_date: null,
                    other_qualifications_obtained: null
                },
                institutions: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showOtherQualifications: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                config: {
                    allowInput: true,
                    altInput: true,// eslint-disable-next-line
                    maxDate: new Date().fp_incr(1),
                    plugins: [new monthSelectPlugin({shorthand: true, dateFormat: "Y-m-d", altFormat: "M Y"})]
                }
            };
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            showOtherSchoolName() {
                return this.records.country && (this.records.country == 164) && this.records.school_type
                        && (this.records.school_type == 'medical-school') ? false : true;
            },
            hasCountry() {// 
                let country = this.locations && this.locations.countries ?
                            this.locations.countries.filter(item => item.id == this.records.country) : null;
                return country ? country[0] : {};
            },
            isOtherSchool() {
                let type = this.records.school_type;
                return type && (type != 'medical-school') && (type != 'pre-clinical-period') &&
                            (type != 'clinical-period') && (type != 'tertiary');
            }
        },
        methods: {
            ...mapActions(['getInstitutions', 'saveAssessmentApplicantData', 'deleteAssessmentEduRecord', 
                'getExactInstitutions']),
            loadInstitutions() {// get Institutions
                this.setData();
                this.getExactInstitutions(this.res.country_id).then((res) => {
                    this.isLoadingBlade = false;
                    this.institutions = res.schools;
                });
            },
            changeCountry(e) {// get exact institutions
                this.records.school_name = null;
                this.records.other_school_name = null;
                this.records.school_id = null;
                this.institutions = [];
                this.preloader();
                this.getExactInstitutions(e.selectedObject.id).then((res) => {
                    swal.close();
                    this.institutions = res.schools;
                    this.$refs.schoolAutocomplete.clear();
                });
            },
            updateSchool(e) {// update school
                this.records.school_id = null;
                this.records.school_name = null;
                let data = e.selectedObject;
                this.records.school_id = data.id;
                this.records.school_name = data.school_name;
            },
            setData() {// set paginated function
                if(this.res) {
                    let data = this.res;
                    this.records = {
                        school_type: data.school_type,
                        school_name: data.school_name,
                        other_school_name: data.school_name,
                        school_id: data.school_id,
                        qualifications_obtained: data.qualifications_obtained,
                        country: data.country_id,
                        start_date: data.start_date,
                        end_date: data.end_date,
                        id: data.id
                    }
                }
            },
            changeOtherQualifications() {// change other qualifications
                let value = this.records.qualifications_obtained;
                return this.showOtherQualifications = (value == 'others') ? true : false;
            },
            saveEduRecords() {// save new edu records
                this.isloading = true;
                let data = this.validatedInputs();// validate inputs
                if(data) {// save edu records
                    data.id = this.res.id;
                    data.school_id = this.records.school_id;
                    this.preloader();// show loader
                    let record = { 'educational_data' : data , 'id' : this.applicant.id, type : true };
                    this.saveAssessmentApplicantData(record).then((res) => {
                        this.loadAssessmentApplicant();
                        this.isloading = false;
                        this.emptyEduRecords();
                        if(res.status) {
                            this.showErrorStatus = false;
                            this.editEduRecord(false); //
                            swal.fire("Record Updated!", "School record was added successfully", "success");
                        } else {
                            swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.emptyEduRecords();
                        swal.fire("Error Occurred!", "An error occurred, kindly reload page and try again", "warning");
                    });
                } else {
                    this.isloading = false;
                }
            },
            validatedInputs() {
                let data = this.records;
                let eduRecord = { school_type: null, start_date: null, country_id: null,
                    end_date: null, school_name: null, qualifications_obtained: null };
                eduRecord.end_date  = data.end_date == null ?
                    this.showInuptError('School\'s End Date') : data.end_date;
                eduRecord.start_date  = data.start_date == null ?
                    this.showInuptError('School\'s Start Date') : data.start_date;
                eduRecord.country_id  = data.country == null ?
                    this.showInuptError('Country') : data.country;
                // validate qualification obtained
                if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained != null) {
                    eduRecord.qualifications_obtained = data.other_qualifications_obtained;
                } else if(data.qualifications_obtained != null && data.qualifications_obtained != 'others') {
                    eduRecord.qualifications_obtained = data.qualifications_obtained;
                } else if((data.qualifications_obtained == null || data.qualifications_obtained == 'others')
                    && data.other_qualifications_obtained == null) {
                    this.showInuptError('Qualification Obtained');
                }
                // validate institution obtained
                if((data.school_name == null || data.school_name == 'others')
                    && data.other_school_name != null) {
                    eduRecord.school_name = data.other_school_name;
                } else if(data.school_name != null && data.school_name != 'others') {
                    eduRecord.school_name = data.school_name;
                } else if((data.school_name == null || data.school_name == 'others')
                    && data.other_school_name == null) {
                    this.showInuptError('School\'s Name');
                }
                eduRecord.school_type = data.school_type == null ?
                    this.showInuptError('School\'s Type') : data.school_type;
                return this.isEmpty(eduRecord) ? eduRecord : false;
            },
            isEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + name + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            emptyEduRecords() {
                this.records.school_name = null;
                this.records.other_school_name = null;
                this.records.qualifications_obtained = null;
                this.records.country = null;
                this.records.start_date = null;
                this.records.end_date = null;
                this.records.other_qualifications_obtained = null;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadInstitutions();// load edu records
        },
    }
</script>

<style scoped>
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
</style>