
<template>
    <div>
        <div>
            <div class="col-lg-12" v-if="isLoadingBlade && ! showAddPage">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-12 text-right">
                        <button
                            class="btn btn-lg btn-primary"
                            @click="displayAddPage(true)"
                            v-if="! showAddPage"
                            :disabled="privileges && privileges.add == 'no' || ! privileges"
                        >
                            <i class="fa fa-plus"></i>
                            Add New School
                        </button>

                        <button
                            class="btn btn-lg btn-danger"
                            @click="displayAddPage(false)"
                            v-if="showbackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Go back
                        </button>
                        <br><br>
                    </div>
                </div>

                <div class="row" v-if="! showAddPage">
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-4"></div>
                    <div class="col-lg-6 text-right">
                        <base-input
                            placeholder="Search by Name and hit enter"
                            class="input-group-alternative"
                            alternative=""
                            addon-right-icon="fas fa-search"
                            @change="searchRecords"
                            v-model="searchKey"
                            >
                        </base-input>
                    </div>
                </div>
            </div>

            <!-- import View School Data template here -->
            <NigerianSchoolsData
                v-bind:records="records"
                v-bind:hasPagination="hasPagination"
                v-bind:noPagination="noPagination"
                v-bind:pagination="pagination"
                v-bind:paginate="paginate"
                v-bind:privileges="privileges"
                v-bind:loadSchools="loadSchools"
                v-on:deleteSchool="deleteSchool"
                v-on:editSchool="editSchool"
                v-if="! showAddPage && ! isLoadingBlade"
            />

            <div
                class="col-sm-12 text-center"
                v-if="showErrorStatus && ! showAddPage"
            >
                <base-alert :type="errorType">
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                </base-alert>
            </div>
        </div>

        <!-- import Add School template here -->
        <AddNigerianSchool
            v-bind:privileges="privileges"
            v-bind:loadSchools="loadSchools"
            v-bind:school="school"
            v-bind:displayAddPage="displayAddPage"
            v-on:displayAddPage="displayAddPage"
            v-if="showAddPage"
        />

    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import NigerianSchoolsData from './NigerianSchoolsData';
  import AddNigerianSchool from './AddNigerianSchool';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'ViewNigerianSchools',
    props: ['privileges', 'changeTitle'],
    components: {
        NigerianSchoolsData,
        AddNigerianSchool
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        school: null,
        page: 1,
        searchKey: '',
        showAddPage: false,
        showbackButton: false,
        noPagination: false
      }
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        }
    },
    methods: {
        ...mapActions(['getSchools', 'getPaginatedPaymentItems', 'getSearchedSchools', 'removeSchool']),
        editSchool(school) {
            this.school = null;
            let title = 'Edit '+ school.school_name;
            this.changeTitle(title, true);
            this.showbackButton = true;
            this.school = school;
            this.showAddPage = true;
        },
        displayAddPage(value) {
            let title = 'Add New Nigerian School';
            this.changeTitle(title, value);
            this.showbackButton = value;
            this.loadSchools();
            return this.showAddPage = value;
        },
        loadSchools() {// load details
            this.page = 1;
            let data = { 'limit' : this.pagination.perPage };
            this.isLoadingBlade = true;
            this.showErrorStatus = false;
            this.getSchools(data).then((res) => {// get schools
                this.isLoadingBlade = false;
                this.setPages(res.schools);//set data
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedPaymentItems(url).then((res) => {// get users
                    this.pagination.number = res.schools.current_page;
                    this.setPages(res.schools);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Schools added yet!!!';
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedPaymentItems(newUrl).then((res) => {// get records
                this.pagination.number = res.schools.current_page;
                this.setPages(res.schools);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        searchRecords() {
            this.showErrorStatus = false;
            let key = this.searchKey.toLowerCase();
            if(key != '' ) {
                this.isLoadingBlade = true;
                this.records = [];
                this.getSearchedSchools(key).then((res) => {// get records
                    this.isLoadingBlade = false;
                    this.records = res;
                    this.noPagination = true;
                    if(this.records.length == 0) {
                        this.showErrorStatus = true;
                        this.errorType = 'warning';
                        this.errorMsg = 'No Matched Record!!!';
                        this.noPagination = false;
                    }
                }).
                catch(() => {
                    this.noPagination = false;
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.noPagination = false;
                this.records = this.paginatedItems;
                this.emptyRecordsMsg();
            }
        },
        deleteSchool(id) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall(id);// make api call
                }
            });
        },
        makeDeleteApiCall(id) {
            let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
            this.preloader(); // show loading
            this.removeSchool(data).then((res) => {// delete school
                if(res.status) {
                    this.loadSchools();// refresh page
                    swal.fire("School Deleted", res.msg, "success");
                } else {
                    this.loadSchools();// refresh page
                    swal.fire("Error Occurred!", res.msg, "warning");
                }
            }).
            catch(err => {
                this.loadSchools();// refresh page
                swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
            });
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadSchools();
    }
  };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
