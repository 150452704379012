
<template>
    <div>

        <div class="col-lg-12">
            <h3 class="mb-0 text-uppercase">
                Good Standing Details
            </h3>
            <hr class="my-4" />
        </div>

        <div class="row" v-if="record">
            <div class="table-responsive">                    
                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>Destination Address</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.address }}
                            </h4>
                        </td>
                        <td>
                            <h4>Destination Country</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.country }}
                            </h4>
                        </td>
                    </tbody>

                    <tbody>
                        <td>
                            <h4>Duration of Stay</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.duration_of_stay }}
                            </h4>
                        </td>
                        <td>
                            <h4>Purpose of Travel</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.purpose_of_travel }}
                            </h4>
                        </td>
                    </tbody> 

                    <tbody v-if="! isProvisionalLogs"> 
                        <td>
                            <h4>Full Reg. Number</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.full_reg_number }}
                            </h4>
                        </td>                           
                        <td>
                            <h4>Full Reg. Date</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.full_reg_date | formatDate }}
                            </h4>
                        </td>                            
                    </tbody> 

                    <tbody v-if="! isFullWithoutLogs">
                        <td>
                            <h4>Provisional Reg. Number</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ isProvisionalLogs ? getRegNo(application) : record.prov_reg_number }}
                            </h4>
                        </td>                            
                        <td>
                            <h4>Provisional Reg. Date</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ record.prov_reg_date | formatDate }}
                            </h4>
                        </td>                            
                    </tbody> 

                    <tbody v-if="application.status == 'approved'">
                        <td>
                            <h4>LOGs Certificate</h4> 
                        </td>
                        <td colspan="3">
                            <div class="row">
                        <div class="col-sm-5">
                            <form :action="printCertificateUrl" method="POST" target="_blank">
                                <input type="hidden" name="id" :value="application.id" />
                                <input 
                                    type="hidden" name="cert_id" 
                                    :value="application.certificate.id" 
                                />
                                <input 
                                    type="hidden" name="admin_id" 
                                    :value="user.id" 
                                />

                                <input 
                                    type="hidden" name="logs_admin" 
                                    value="yes" 
                                />

                                <base-button 
                                    size="sm"
                                    type="info"
                                    nativeType="submit" 
                                    :disabled="privileges && privileges.view == 'no'"
                                >
                                    <i class="fa fa-print"></i> 
                                    {{ getCertificateName(application.certificate) }}
                                    <badge size="sm" 
                                        type="primary"
                                        class="text-dark"
                                    >
                                        {{ record.country }}
                                    </badge>
                                </base-button> 
                            </form>
                        </div>
                    </div>
                        </td>                            
                    </tbody>
                </table>

            </div>
        </div>  

        <div class="col-lg-12 text-center" v-if="! record">
            <base-alert type="danger">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    No Good Standing Details, Kindly reject application for Doctor to re-apply
                </span>
            </base-alert>  
        </div>
        <hr class="my-4" />

    </div>
</template>
<script>
    import { mapGetters } from 'vuex'; 
    import moment from 'moment';

    export default {
        name: 'GoodStanding',
        props: ['record', 'privileges', 'application'],
        filters: {
            formatDate(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['printCertificateUrl', 'user']),
            isProvisionalLogs() {// check if is logs for provisional 
                return this.record && this.record.prov_reg_number == null
                        && this.record.prov_reg_date != null;
            },
            isFullWithoutLogs() {// check if is logs for provisional 
                return this.record && this.record.prov_reg_number == null 
                        && this.record.prov_reg_date == null;
            }
        },
        methods: {
            getCertificateName(type) {
                return type.title ? type.title : 'N/A';
            },
            getRegNo(app) {// get user registration number
                return app && app.user_data ? app.user_data.registration_no : 'N/A';
            }
        }
    };
</script>
<style scoped>
    .marginTop40 {
        margin-top: 40px;
    }
</style>
