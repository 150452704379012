
<template>
    <div>
        <div class="col-lg-8" v-if="records && records.length > 0">
            <small class="font-weight-bold" v-if="! noPagination">
                Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                {{ pagination.total }} entries
            </small>

            <small class="font-weight-bold" v-if="noPagination">
                Showing 1 - {{ records.length }} of {{ records.length }} entries
            </small>
        </div>

        <div
            class="card shadow"
            v-if="records && records.length > 0"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="records"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="15%">School's Name</th>
                        <!-- <th width="10%">Code</th> -->
                        <th width="10%">Email Address</th>
                        <th width="10%">Status</th>
                        <th width="15%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.school_name ? row.school_name.toLowerCase() : 'N/A' }}<br>
                            <badge
                                size="sm" type="primary"
                                class="font-weight-bolder"
                            >
                                {{ row.state ? row.state.name : 'M/A' }}
                            </badge>
                        </td>
                        <!-- <td>
                            {{ row.school_code ? row.school_code : 'N/A' }}
                        </td> -->
                        <td>
                            {{ row.school_email ? row.school_email : 'N/A' }}
                        </td>
                        <td class="text-capitalize">
                            <badge
                                size="sm"
                                :type="getColor(row.school_status)"
                                class="font-weight-bolder"
                            >
                                {{ row.school_status && (row.school_status == 1) ? 'Active': 'Disabled' }}
                            </badge>
                        </td>
                        <td>
                            <button
                                class="btn btn-sm btn-primary"
                                @click="$emit('editSchool', row)"
                                :disabled="privileges && privileges.add == 'no' || ! privileges"
                            >
                                <i class="fa fa-pen"></i>
                                Edit
                            </button>

                            <button
                                :class="'btn btn-sm btn-'+(row.school_status == 1 ? 'warning' : 'success')"
                                @click="updateStatus(row.id)"
                                :disabled="privileges && privileges.add == 'no' || ! privileges"
                            >
                                <i :class="'fa fa-'+(row.school_status == 1 ? 'ban' : 'check')"></i>
                                {{ row.school_status == 1 ? 'Disable' : 'Enable' }}
                            </button>

                            <button
                                class="btn btn-sm btn-danger"
                                @click="$emit('deleteSchool', row.id)"
                                :disabled="! isHodOrSuper"
                            >
                                <i class="fa fa-ban"></i>
                                Delete
                            </button>
                        </td>
                    </template>
                </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end bg-transparent"
                v-if="hasPagination && ! noPagination"
                    @click="paginate"
            >
                <base-pagination
                    :total="pagination.total"
                    align="center"
                    v-model="pagination.number"
                    :perPage="pagination.perPage"

                >
                </base-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'NigerianSchoolsData',
        props: ['records', 'hasPagination', 'pagination', 'paginate', 'privileges', 'loadSchools', 'noPagination'],
        computed: {
            ...mapGetters(['user']),
            isHodOrSuper() {// check if user is HOD or Super Admin
                let isHod = this.user && this.user.is_hod == 'yes';// if user is HOD
                let isSuper = this.user && this.user.unit && this.user.unit.department
                            && this.user.unit.department.code == 'super-admin';
                return isHod || isSuper;
            }
        },
        methods: {
            ...mapActions(['updateSchoolStatus']),
            getColor(status) {// get color
                return (status && status == 1) ? 'success' : 'danger';
            },
            updateStatus(id) {// activate/ deactivate school
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeActivateApiCall(id);// make api call
                    }
                });
            },
            makeActivateApiCall(id) {
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId };
                this.preloader(); // show loading
                this.updateSchoolStatus(data).then((res) => {// delete school
                    if(res.status) {
                        this.loadSchools();// refresh page
                        swal.fire("School's Status "+res.type, res.msg, "success");
                    } else {
                        this.loadSchools();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadSchools();// refresh page
                    swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    };
</script>
<style>
    .table td {
        font-size: 15px;
    }
</style>
