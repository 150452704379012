<template>
    <div>

        <div class="col-lg-12" v-if="isLoadingBlade && ! showMemberPrivileges">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12" v-if="! showMemberPrivileges && ! isLoadingBlade">
            <div class="row">
                <div class="col-lg-12">
                    <base-button
                        type="danger" size="sm"
                        @click="$emit('displayMembers',false)"
                        class="right"
                    >
                        <i class="fa fa-reply"></i>
                        Go Back
                    </base-button>
                </div>
            </div>
        </div>

        <hr class="my-4" v-if="! showMemberPrivileges" />

        <div
            class="card shadow"
            v-if="members && members.length > 0 && ! showErrorStatus && ! showMemberPrivileges"
        >
            <div class="table-responsive">
                <base-table
                    class="table align-items-center table-flush"
                    thead-classes="thead-light"
                    tbody-classes="list"
                    :data="members"
                >
                    <template slot="columns">
                        <th width="2%">S/N</th>
                        <th width="25%">Name</th>
                        <th width="10%">Email Address</th>
                        <th width="10%">Status</th>
                        <th width="10%">&nbsp;</th>
                    </template>

                    <template
                        slot-scope="{row, index}"
                    >
                    <td class="budget">
                        {{ index+1}}
                    </td>
                    <td>
                        {{ row.name }}
                    </td>
                    <td>
                        {{ row.email }}
                    </td>
                    <td class="text-capitalize">
                        <badge size="sm" :type="getColor(row.status)">
                            {{ row.status }}
                        </badge>
                    </td>
                    <td class="text-capitalize">
                        <base-button
                            :type="getHodColor(row.is_hod)" size="sm"
                            @click="makeHod(row)"
                            :disabled="checkIfIsHod(row)"
                        >
                            <i :class="'fa fa-'+getHodIcon(row.is_hod)"></i>
                            {{ (row.is_hod == 'yes') ? 'Remove HOD' : 'Make HOD' }}
                        </base-button>

                        <base-button
                            type="info" size="sm"
                            @click="viewMemberPrivileges(row)"
                            :disabled="checkIfIsHod(row)"
                        >
                            <i class="fa fa-pen"></i>
                            Privileges
                        </base-button>

                        <base-button
                            :type="getButtonColor(row.status)" size="sm"
                            :disabled="checkIfIsHod(row)"
                            @click="enableUser(row.id, row.status)"
                        >
                            <i :class="'fa fa-'+getIcon(row.status)"></i>
                            {{ row.status == 'enable' ? 'Disable' : 'Enable' }}
                        </base-button>
                    </td>

                    </template>

                </base-table>
            </div>
        </div>

        <div class="col-sm-12 text-center" v-if="! showMemberPrivileges">
            <base-alert :type="errorType" v-if='showErrorStatus'>
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <MemberPrivileges
            v-bind:member="member"
            v-bind:privileges="privileges"
            v-on:closeMemberPrivileges="closeMemberPrivileges"
            v-if="showMemberPrivileges"
        />

    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import MemberPrivileges from '../Unit/MemberPrivileges';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'DepartmentMembers',
        props: ['department','privileges', 'displayMembers', 'changeTitle'],
        components: {
            MemberPrivileges
        },
        data() {
            return {
                members: [],
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                member: null,
                showMemberPrivileges: false,
                users: [],
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'user']),
        },
        methods: {
            ...mapActions(['getDepartmentMembers', 'updateUserUnit', 'enableAdminUser', 'makeUserHod']),
            checkIfIsHod(user) {
                if(this.user.id == user.id && user.is_hod == 'yes') {
                    return true;
                } else {
                    return (this.privileges && this.privileges.update == 'no' || ! this.privileges)
                }
            },
            loadDepartmentMembers() {// load details
                this.getDepartmentMembers(this.department.id).then((res) => {// get members
                    this.isLoadingBlade = false;
                    this.members = res.members;
                    this.emptyRecordsMsg();//show empty msg
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            emptyRecordsMsg() {
                return this.members && this.members.length == 0 ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Members in this Department yet!!!';
            },
            getHodColor(value) {
                return value == 'yes' ? 'success' : 'primary';
            },
            getHodIcon(value) {
                return value == 'yes' ? 'ban' : 'check';
            },
            getColor(value) {
                return value == 'enable' ? 'success' : 'danger';
            },
            getButtonColor(value) {
                return value == 'enable' ? 'danger' : 'success';
            },
            getIcon(value) {
                return value == 'enable' ? 'ban' : 'check';
            },
            viewMemberPrivileges(data) {
                this.member = data;
                this.showMemberPrivileges = true;
                let title = data.name+'\'s Privileges';
                this.changeTitle(title);// change title
            },
            closeMemberPrivileges() {
                this.member = null;
                this.showMemberPrivileges = false;
                this.changeTitle(this.department.name+' Department\'s Members');// change title
            },
            getUsers() {// format users for selection
                let users = {};
                users[0] = '--- Select One ---';
                for(let user of this.users) {
                    let unit = user && user.unit ? user.unit.name : null;
                    users[user.id] = user.name+' ('+unit+')';
                }
                return users;
            },
            addAUser() {// add a user to unit
                let users = this.getUsers();
                swal.fire({
                    title: 'Select an admin user',
                    input: 'select',
                    inputOptions: users,
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    showLoaderOnConfirm: true,
                    preConfirm: (id) => {
                        let data = { 'id' : id, 'admin_unit_id' : this.unit.id,
                            'admin_id' : this.$store.state.user.userId // add admin user id
                        };
                        this.showErrorStatus = false;
                        return this.updateUserUnit(data).then((response) => {//update
                            if (!response.status) {
                            throw new Error(response.msg)
                            }
                            return response
                        }).
                        catch((error) => {
                            swal.showValidationMessage(
                            `Request failed: ${error}`
                            )
                        });
                    },
                    allowOutsideClick: () => !swal.isLoading()
                    }).then((res) => {
                    if (res.value) {
                        let type = res.value.status ? 'success' : 'error';
                        let title = res.value.status ? 'Record Updated!' : 'Error Occurred!';
                        swal.fire(title, res.value.msg, type);
                        this.loadUnitMembers();// refresh page
                    }
                });
            },
            enableUser(id, type) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeEnableApiCall(id, type);// make api call
                    }
                });
            },
            makeEnableApiCall(id, type) {
                let status = type == 'enable' ? 'disable' : 'enable';
                let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId, 'type' : status };
                this.preloader(); // show loading
                this.enableAdminUser(data).then((res) => {// delete department
                    if(res.status) {
                        this.loadUnitMembers();// refresh page
                        swal.fire(res.type, res.msg, "success");
                    } else {
                        this.loadUnitMembers();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadUnitMembers();// refresh page
                    swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                });
            },
            makeHod(member) {// enable/disable HOD status
                let status = (member.is_hod == 'yes') ? 'no' : 'yes';
                let data = { 'id' : member.id, 'admin_id' : this.$store.state.user.userId,
                    'is_hod' : status, 'update' : true
                };
                this.preloader(); // show loading
                this.makeUserHod(data).then((res) => {// make api call
                    if(res.status) {
                        this.loadDepartmentMembers();// refresh page
                        swal.fire("Status Updated", res.msg, "success");
                    } else {
                        this.loadDepartmentMembers();// refresh page
                        swal.fire("Error Occurred!", res.msg, "warning");
                    }
                }).
                catch(err => {
                    this.loadDepartmentMembers();// refresh page
                    swal.fire(err.message+"!", "An error occurred while updating, try again", "warning");
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        mounted() {
            this.loadDepartmentMembers();// load dept members
        },
    };
</script>
<style scoped>
    .right {
        float: right;
    }
    .marginTop40 {
        margin-top: 40px;
    }
</style>