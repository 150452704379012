<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12" v-if="! showComplanit">
            <div class="col-lg-8">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">Complainant's Name</th>
                            <th width="10%">Complainant's Email</th>
                            <th width="10%">Complaint's Status</th>
                            <th width="10%">Date Created</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.name }}
                        </td>
                        <td>
                            {{ row.email }}
                        </td>
                        <td>
                            <badge size="lg"
                                :type="((row.status == 'pending') ? 'warning' : 'success')"
                                class="text-uppercase"
                            >
                                {{ row.status  }}
                            </badge>
                        </td>
                        <td>
                            {{ row.created_at | getDateFormat }}
                        </td>
                        <td>
                            <base-button
                                type="success" size="sm"
                                @click="viewDetails(row, true)"
                                :disabled="(privileges && privileges.view == 'no') || ! privileges"
                            >
                                <i class="fa fa-eye"></i> Details
                            </base-button>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                    @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>
        </div>

        <ViewComplaint
            :complanit="complanit"
            :privileges="privileges"
            :loadComplaints="loadComplaints"
            :viewDetails="viewDetails"
            v-if="showComplanit"
        />
    </div>
</template>

<script>
    import moment from 'moment';
    import ViewComplaint from './ViewComplaint';

    export default {
        name: 'PendingComplaintsData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "loadComplaints", "displayComplaint",
            "showComplanit"],
        components: {
            ViewComplaint
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        data() {
            return {
                showModal: false,
                complanit: null
            }
        },
        methods: {
            viewDetails(record, status) {// show details modal
                this.complanit = status ? record : null;
                this.displayComplaint(status);
            }
        }
    }
</script>