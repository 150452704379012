
<template>
    <div>

        <div class="table-responsive">

            <table class="table table-bordered">
                <tbody>
                    <td width="30%">
                        <h4>Hospital's Name:</h4>
                    </td>
                    <td>
                        <h4 class="text-capitalize font-weight-light">
                            {{ application && application.training_hospital ?
                                    application.training_hospital.name : 'N/A' }}
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Application Expiry Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: false}"
                                            class="form-control datepicker"
                                            placeholder="Select Date..."
                                            v-model="expiry_date"
                                            :disabled="showBackButton"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Approved Quota:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        placeholder="Enter Approved Quota"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="quota"
                                        :readonly="showBackButton"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Ref Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        input-classes="form-control-alternative"
                                        placeholder="Enter Ref Number"
                                        v-model="ref_no"
                                        type="text"
                                        :readonly="showBackButton"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Plenary Meeting Number:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        input-classes="form-control-alternative"
                                        placeholder="Enter Plenary Meeting Number"
                                        v-model="plenary"
                                        type="number"
                                        :readonly="showBackButton"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Plenary Meeting Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        input-classes="form-control-alternative"
                                        placeholder="Enter Plenary Meeting Date"
                                        v-model="plenary_date"
                                        type="text"
                                        :readonly="showBackButton"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Plenary Meeting Venue:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        input-classes="form-control-alternative"
                                        placeholder="Enter Plenary Meeting Venue"
                                        v-model="plenary_venue"
                                        type="text"
                                        :readonly="showBackButton"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Last Visitation Date:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        addon-left-icon="ni ni-calendar-grid-58"
                                        required

                                    >
                                        <flat-picker
                                            slot-scope="{focus, blur}"
                                            @on-open="focus"
                                            @on-close="blur"
                                            :config="{allowInput: false}"
                                            class="form-control datepicker"
                                            placeholder="Select Date..."
                                            v-model="visit_date"
                                            :disabled="showBackButton"
                                        >
                                        </flat-picker>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </td>
                </tbody>

                <tbody v-if="isReaccredit">
                    <td width="30%">
                        <h4>Last Approved Quota:</h4>
                    </td>
                    <td>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 paddingRight0">
                                    <base-input
                                        placeholder="Enter Last Approved Quota"
                                        class="input-group-alternative"
                                        alternative=""
                                        type="number"
                                        v-model="last_quota"
                                        :readonly="showBackButton"
                                    >
                                    </base-input>
                                </div>
                            </div>
                        </div> 
                    </td>
                </tbody>

                <tbody>
                    <td width="30%"> 
                        <h4>Additional Comments:</h4>
                    </td>
                    <td>
                        <h4 class="font-weight-light">
                            <base-input alternative="">
                                <textarea
                                    rows="2"
                                    class="form-control form-control-alternative"
                                    placeholder="Enter Additional Comments"
                                    v-model="comment"
                                    :readonly="showBackButton"
                                    ></textarea>
                            </base-input>
                        </h4>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        <h4>Preview Letter:</h4>
                    </td>
                    <td>
                        <form
                            :action="getRootUrl+'/print-hospital-letter'"
                            method="POST"
                            target="_blank"
                        >
                            <input type="hidden" name="id" :value="application.id" />
                            <input type="hidden" name="authorization_token" :value="getAuthorizationToken" />
                            <input type="hidden" name="user_id" :value="user.id" />
                            <input type="hidden" name="option" value="admin" />
                            <input type="hidden" name="date" :value="dateNow" />
                            <input type="hidden" name="ref_no" :value="ref_no" />
                            <input type="hidden" name="plenary" :value="plenary" />
                            <input type="hidden" name="plenary_date" :value="plenary_date" />
                            <input type="hidden" name="plenary_venue" :value="plenary_venue" />
                            <input type="hidden" name="visit_date" :value="visit_date" />
                            <input type="hidden" name="quota" :value="quota" />
                            <input type="hidden" name="last_quota" :value="last_quota" />

                            <base-button
                                type="primary"
                                class="btn btn-lg"
                                nativeType="submit"
                                :disabled="(! isCompleted || showBackButton)"
                            >
                                <i class="fa fa-print"></i>
                                Print Letter (Preview)
                            </base-button>
                        </form>
                    </td>
                </tbody>

                <tbody v-if='showErrorStatus'>
                    <td colspan="2">
                        <base-alert :type="errorType">
                            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                            <span class="alert-inner--text">
                                {{errorMsg}}
                            </span>
                        </base-alert>
                    </td>
                </tbody>

                <tbody>
                    <td width="30%">
                        &nbsp;
                    </td>
                    <td>
                        <base-button
                            size="lg"
                            type="success"
                            :disabled="(privileges && privileges.update == 'no') || ! privileges || ! isCompleted 
                                        || showBackButton"
                            @click="approveApplication(application.id)"
                        >
                            <i class="fa fa-check"></i>
                            Approve Application
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            class="right"
                            @click="$emit('hideApprovePage')"
                            v-if="! showBackButton"
                        >
                            <i class="fa fa-times"></i>
                            Cancel Approval
                        </base-button>

                        <base-button
                            size="lg"
                            type="danger"
                            @click="$emit('hideApprovePage')"
                            v-if="showBackButton"
                        >
                            <i class="fa fa-reply"></i>
                            Back To Application
                        </base-button>
                    </td>
                </tbody>

            </table>

        </div>

    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import swal from 'sweetalert2';// import sweet alert
  import moment from 'moment';

  export default {
    name: 'ApproveApplication',
    props: ['application', 'privileges', 'loadHospitalInfo'],
    components: {
        flatPicker
    },
    data() {
      return {
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        showBackButton: false,
        expiry_date: null,
        quota: null,
        ref_no: null,
        plenary: null,
        plenary_date: null,
        plenary_venue: null,
        visit_date: null,
        last_quota: null,
        comment: null
      }
    },
    computed: { 
        ...mapGetters(['user', 'getRootUrl', 'getAuthorizationToken']),
        isCompleted() {// check if has completed values
            return this.expiry_date && this.quota && this.ref_no && this.plenary && this.plenary_date 
                    && this.plenary_venue && this.visit_date && ((this.last_quota && this.isReaccredit)
                    || ! this.isReaccredit) && this.comment;
        },
        isReaccredit() {
            return this.application && (this.application.request_type == 're-accreditation');
        },
        dateNow() {
            return moment().format("YYYY-MM-DD");
        }
    },
    methods: {
        ...mapActions(['approveHospitalApplication']),
        updateExpiryDate() {// add four years automatically
            let date = moment().add(4, 'year');
            return this.expiry_date = date.format('YYYY-MM-DD');
        },
        approveApplication(id) {// reject application
            if(this.isCompleted) {// reject application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeAproveApiCall(id);// make api call
                    }
                });
            } else {
                return this.showInuptError('Fill all fields and try again');
            }
        },
        makeAproveApiCall(id) {// reject application api
            let data = {
                id : id,
                reject_reason : null,
                status : 'approved',
                quota : this.quota,
                expiry_date: this.expiry_date,
                admin_id : this.user.id,
                ref_no: this.ref_no,
                plenary: this.plenary,
                plenary_date: this.plenary_date,
                plenary_venue: this.plenary_venue,
                visit_date: this.visit_date,
                last_quota: this.last_quota,
                comment: this.comment
            };
            this.preloader();// show loading
            this.approveHospitalApplication(data).then((res) => {// reject application
                if(res.status) {
                    this.showBackButton = true;
                    this.expiry_date = null;
                    this.quota = null;
                    swal.fire(('Application ' + data.status), res.msg, 'success');
                } else {
                    swal.fire('Error Occurred!', res.msg, 'error');
                }
                this.loadHospitalInfo(); // reload data
            }).
            catch(() => {
                let msg = "Server Failure, kindly reload the page...";
                swal.fire('Server Failure!', msg, 'error');
            });
        },
        showInuptError(name) {
            this.showErrorStatus = true
            this.errorType = 'warning'
            this.errorMsg = 'Invalid ' + name + ' has been entered';
            var self = this;
            setTimeout(function() { self.showErrorStatus = false; }, 5000);
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    created() {
        this.updateExpiryDate();
    }
  };
</script>
<style scoped>
.right {
  float: right;
}
.paddingLeft0 {
    padding-left: 0px;
}
.paddingRight0 {
    padding-right: 0px;
}
.paddingTop13 {
    padding-top: 13px;
}
</style>
