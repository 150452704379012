
<template>
    <div>
        <!-- <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-success opacity-8"></span>
        </base-header> -->

        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase" v-html="title"></h3>
                                </div>
                                <div class="col-4 text-right">
                                    <a href="#!" @click="$router.go(-1)" class="btn btn-sm btn-danger">
                                      <i class="fa fa-reply"></i> Back
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && ! approvalPage && ! showVisitationDatePage">

                            <!-- This is basic details template here -->
                            <BasicDetails
                                :application="application"
                                v-on:displayVisitationDatePage="displayVisitationDatePage"
                                v-if="application"
                            />

                            <!-- This is profile details template here -->
                            <ProfileDetails
                                :application="application"
                                v-if="application"
                            />

                            <!-- This is consultant details template here -->
                            <ConsultantDetails
                                :records="application.training_hospital.doctors"
                                :application="application"
                                :privileges="privileges"
                                v-if="application && application.training_hospital && application.training_hospital.doctors"
                            />

                            <!-- This is nurse details template here -->
                            <NurseDetails
                                :records="application.training_hospital.nurses"
                                v-if="application && application.training_hospital && application.training_hospital.nurses"
                            />

                            <!-- This is staff details template here -->
                            <StaffDetails
                                :records="application.training_hospital.staff"
                                v-if="application && application.training_hospital && application.training_hospital.staff"
                            />

                            <!-- This is facility details template here -->
                            <FacilityDetails
                                :records="application.training_hospital.facilities"
                                v-if="application && application.training_hospital && application.training_hospital.facilities"
                            />

                            <div v-if="application && application.status == 'submitted'">
                                <base-button
                                    type="danger" size="lg"
                                    @click="showApprovalPage('reject')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-ban"></i> Reject
                                </base-button>

                                <base-button
                                    type="success" size="lg"
                                    class="right"
                                    @click="showApprovalPage('approve')"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-check"></i> Approve
                                </base-button>
                            </div>

                        </div>

                        <NotificationDate 
                            :application="application"
                            :privileges="privileges"
                            :loadHospitalInfo="loadHospitalInfo"
                            v-on:displayVisitationDatePage="displayVisitationDatePage"
                            v-if="showVisitationDatePage"
                        />

                        <div v-if="approvalPage && ! showVisitationDatePage">

                          <!-- Import Approve Application template here -->
                          <ApproveApplication
                            :application="application"
                            :privileges="privileges"
                            :loadHospitalInfo="loadHospitalInfo"
                            v-on:hideApprovePage="hideApprovePage"
                            v-if="pageType == 'approve'"
                          />

                          <!-- Import Reject Application template here -->
                          <RejectApplication
                            :application="application"
                            :privileges="privileges"
                            :loadHospitalInfo="loadHospitalInfo"
                            v-on:hideApprovePage="hideApprovePage"
                            v-if="pageType == 'reject'"
                          />

                        </div>

                        <div class="col-sm-12 text-center">
                          <base-alert :type="errorType" v-if='showErrorStatus'>
                              <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                              <span class="alert-inner--text">
                                  {{errorMsg}}
                              </span>
                          </base-alert>
                        </div>

                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import BasicDetails from './BasicDetails';
    import ProfileDetails from './ProfileDetails';
    import ConsultantDetails from './ConsultantDetails';
    import NurseDetails from './NurseDetails';
    import StaffDetails from './StaffDetails';
    import FacilityDetails from './FacilityDetails';
    import RejectApplication from './RejectApplication';
    import ApproveApplication from './ApproveApplication';
    import NotificationDate from './NotificationDate.vue'
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'ApplicationDetail',
        components: {
            BasicDetails,
            ProfileDetails,
            ConsultantDetails,
            NurseDetails,
            StaffDetails,
            FacilityDetails,
            RejectApplication,
            ApproveApplication,
            NotificationDate
        },
        data() {
            return {
                id: this.$route.params.id,
                isloading: false,
                application: {},
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                approvalPage: false,
                pageType: null,
                title: null,
                showVisitationDatePage: false
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'menus']),
            privileges() {
                let privileges = null;
                if(this.menus && this.menus.length > 0) {
                for(let menu of this.menus) {
                    if(menu.has_child && menu.has_child.length > 0) {
                        for(let child of menu.has_child) {
                            if('/'+child.link+'/'+this.id == this.$route.path) {
                                privileges = child.privileges;
                            }
                        }
                    }
                }
                }
                return privileges;
            },
        },
        methods: {
            ...mapActions(['getHospitalApplication']),
            displayVisitationDatePage(status) {
                return this.showVisitationDatePage = status;
            },
            loadTitle(app) {// load title
                this.title = app.training_hospital ? '<em class="text-danger">'
                                + app.training_hospital.name + '</em> ' : '';
                this.title += ' Application for ';
                this.title += app && app ? '<em class="text-danger">'+app.request_type + '</em> ' : '';
                this.title += app && app ? '<em class="text-danger">('+app.training_type + ' Training)</em>' : '';
            },
            replaceString(value) {
                return value ? value.replace('-', ' ') : 'Accreditation Application';
            },
            loadHospitalInfo() {
                this.isLoadingBlade = true;
                this.application = null;
                this.getHospitalApplication(this.id).then((res) => {
                    this.isLoadingBlade = false;
                    if(res.status) {
                        this.application = res.application;
                        this.loadTitle(res.application);
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            submitNow() {// submit application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeSubmitApiCall();// make api call
                    }
                });
            },
            makeSubmitApiCall() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                if(this.application.id) {// submit
                    this.submitVerificationApplication(this.application.id).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Application Submitted!", res.msg, "success");
                            setTimeout(function() { window.location.href = '/'; }, 3000);
                        } else {
                            let msg = "An error occurred while submitting form, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    let msg = "Server Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                }
            },
            showApprovalPage(type) {// show approval/reject page
                this.approvalPage = true;
                this.pageType = type;
            },
            hideApprovePage() {// hide approve page
                this.approvalPage = false;
                this.pageType = null;
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadHospitalInfo();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>