
<template>
    <div>
        <div class="col-lg-12">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Active Accredited Schools">
                            <span slot="title" class="text-capitalize">
                                <i class="ni ni-books"></i>
                                Active Accredited {{ type }} Schools
                            </span>
                            <!-- import Pending Applications template here -->
                            <ActiveAccreditedSchools
                                :type="type"
                            />
                        </tab-pane>

                        <tab-pane title="Inactive Accredited Schools">
                            <span slot="title" class="text-capitalize">
                                <i class="ni ni-badge"></i>
                                Inactive Accredited {{ type }} Schools
                            </span>
                            <!-- import Rejected Applications template here -->
                            <InActiveAccreditedSchools
                                :type="type"
                            />
                        </tab-pane>

                    </card>
                </tabs>
            </template>
        </div>
    </div>
</template>
<script>
    import ActiveAccreditedSchools from './ActiveAccreditedSchools';
    import InActiveAccreditedSchools from './InActiveAccreditedSchools';

    export default {
        name: 'AccreditedSchools',
        props: ['type'],
        components: {
            ActiveAccreditedSchools,
            InActiveAccreditedSchools
        }
    };
</script>
<style></style>
