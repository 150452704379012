
<template>
    <div>
        <div v-if="! displayEditPage">
            <div class="col-lg-12" v-if="isLoadingBlade">
                <div class="d-flex justify-content-center">
                    <img :src="loadingBladeUrl" />
                </div>
            </div>

            <div class="container" v-if="! isLoadingBlade">
                <div class="row">
                    <div class="col-lg-12 text-right">
                        <button
                            class="btn btn-lg btn-info"
                            @click="editSpecialization()"
                            :disabled="(privileges && privileges.add == 'no') || ! privileges"
                        >
                            <i class="fa fa-plus"></i> Add Specialization
                        </button>
                        <br><br>
                    </div>
                    <div class="col-lg-2">
                        <base-input alternative="">
                            <select
                                class="form-control form-control-alternative"
                                v-model="page"
                                @change="getPageItems()"
                            >
                                <option
                                    v-for="number in pageNumbers"
                                    :key="number"
                                    :value="number"
                                >
                                    {{ number }}
                                </option>
                            </select>
                        </base-input>
                    </div>
                    <div class="col-lg-6"></div>
                    <div class="col-lg-4 text-right">
                        <base-input
                            placeholder="Search by Name and hit enter"
                            class="input-group-alternative"
                            alternative=""
                            addon-right-icon="fas fa-search"
                            @change="getSearchRecords"
                            v-model="searchKey"
                            >
                        </base-input>
                    </div>
                </div>
            </div>

            <div class="col-lg-8" v-if="! isLoadingBlade && ! isSearch">
                <small class="font-weight-bold">
                    Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }} entries
                </small>
            </div>

            <div class="col-lg-8" v-if="isSearch">
                <small class="font-weight-bold">
                    Showing 1 - {{ records.length }} of {{ records.length }} entries
                </small>
            </div>

            <div
                class="card shadow"
                v-if="records && records.length > 0"
            >
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="18%">Specialization</th>
                            <th width="10%">Practice Type</th>
                            <th width="10%">Date Modified</th>
                            <th width="20%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td class="text-capitalize font-weight-bold">
                                <span v-html="breakWord(row.name, 40)" /> <br>
                                <em class="text-light font-weight-bolder">Subs -
                                <badge size="sm" type="primary">
                                    {{ row.sub_specialization.length }}
                                </badge>
                                </em>
                            </td>
                            <td class="text-capitalize">
                                {{ row.doctor_type ? row.doctor_type.name : 'N/A' }}
                            </td>
                            <td>
                                {{ row.updated_at | formatDate }}
                            </td>
                            <td>
                                <base-button
                                    type="info" size="sm"
                                    @click="showEditPage(true, row)"
                                    :disabled="(privileges && privileges.view == 'no') || ! privileges"
                                >
                                    <i class="fa fa-eye"></i>
                                    Sub Specs
                                </base-button>

                                <base-button
                                    type="primary" size="sm"
                                    @click="editSpecialization(row)"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                >
                                    <i class="fa fa-pen"></i>
                                    Edit
                                </base-button>

                                <base-button
                                    type="danger" size="sm"
                                    @click="deleteSpecialization(row.id, 'specialization')"
                                    :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                    v-if="row.delete_status"
                                >
                                    <i class="fa fa-trash"></i>
                                    Delete
                                </base-button>
                            </td>
                        </template>
                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination && ! isSearch"
                        @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>
            </div>
        </div>

        <div
            class="col-sm-12 text-center"
            v-if="showErrorStatus && ! displayEditPage && ! isLoadingBlade"
        >
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        <!-- import Sub Specialization template here -->
        <SubSpecialization
            v-bind:specialization="specialization"
            v-bind:privileges="privileges"
            v-bind:loadSpecializations="loadSpecializations"
            v-bind:deleteSpecialization="deleteSpecialization"
            :showEditPage="showEditPage"
            v-if="displayEditPage && ! isLoadingBlade"
        />
    </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import SubSpecialization from './SubSpecialization';
  import moment from 'moment';
  import swal from 'sweetalert2';// import sweet alert

  export default {
    name: 'SpecializationSettings',
    props: ['privileges'],
    components: {
        SubSpecialization
    },
    data() {
      return {
        specialization: null,
        showErrorStatus: false,
        errorType: null,
        errorMsg: null,
        title: 'Specialization Settings',
        isLoadingBlade: true,
        records: [],
        paginatedItems: [],
        pagination: {
            total: null,
            prev_page_url: null,
            next_page_url: null,
            current_page: null,
            last_page: null,
            number: 1,
            from: 0,
            to: null,
            perPage: this.$store.state.url.perPage // get per page count
        },
        doctorTypes: [],
        page: 1,
        displayEditPage: false,
        searchKey: null,
        isSearch: false
      }
    },
    filters: {
        formatDate(value) {
            return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
        },
    },
    computed: {
        ...mapGetters(['menus', 'loadingBladeUrl']),
        hasPagination() {
            return this.pagination.next_page_url || this.pagination.prev_page_url;
        },
        pageNumbers() {
            let start = 1;
            let end = this.pagination.last_page;
            let pages = [];
            for (let i = start; i <= end; i++ ) {
                pages.push(i);
            }
            return pages;
        }
    },
    methods: {
        ...mapActions(['getSpecializations', 'getPaginatedSpecializations', 'updateSpecialization',
            'deleteUserSpecialization', 'searchSpecializations']),
        getSearchRecords() {
            let key = this.searchKey.toLowerCase();
            this.showErrorStatus = false;
            this.isSearch = false;
            if(key != '' ) {
                this.isLoadingBlade = true;
                this.records = [];
                this.searchSpecializations(key).then((res) => {// get records
                    // console.log(res);
                    this.isLoadingBlade = false;
                    this.isSearch = true;
                    this.records = res.specializations;
                    if(this.records.length == 0) {
                        this.showErrorStatus = true;
                        this.errorType = 'warning';
                        this.errorMsg = 'No Matched Record!!!';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
                this.emptyRecordsMsg();
            }
        },
        loadSpecializations() {// load details
            this.page = 1;// return page to default
            let data = { 'limit' : this.pagination.perPage };
            this.getSpecializations(data).then((res) => {// get users
                this.isLoadingBlade = false;
                this.doctorTypes = [];
                this.doctorTypes = res.doctor_types;// set doctor type
                this.setPages(res.specializations);//set data
            }).
            catch(() => {
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        setPages(res) {// set paginated function
            this.records = [];
            this.paginatedItems = res.data;
            this.records = this.paginatedItems;
            this.pagination.from = res.from;
            this.pagination.to = res.to;
            this.pagination.total = res.total;
            this.pagination.current_page = res.current_page;
            this.pagination.last_page = res.last_page;
            this.pagination.prev_page_url = res.prev_page_url;
            this.pagination.next_page_url = res.next_page_url;
            this.isLoadingBlade = false;
            this.emptyRecordsMsg();//show empty msg
        },
        paginate() {// paginate function
            this.showErrorStatus = false;
            let url = (this.pagination.number > this.pagination.current_page) ?
                this.pagination.next_page_url : this.pagination.prev_page_url;
            if(url) {// make api call
                this.isLoadingBlade = true
                this.getPaginatedSpecializations(url).then((res) => {// get users
                    this.pagination.number = res.specializations.current_page;
                    this.setPages(res.specializations);
                }).
                catch(() => {
                    this.users = [];
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, Kindly reload the page...";
                    this.errorType = 'danger';
                });
            } else {
                this.records = this.paginatedItems;
            }
        },
        emptyRecordsMsg() {
            return this.records && this.records.length == 0 ? this.showEmptyMsg() : false;
        },
        showEmptyMsg() {// show empty msg
            this.showErrorStatus = true;
            this.errorType = 'warning';
            this.errorMsg = 'No Specializations added yet!!!';
        },
        changeTitle(value) {// change title
            this.title = value;
        },
        getPageItems() {
            let url = this.pagination.next_page_url ?
                        this.pagination.next_page_url : this.pagination.prev_page_url;
            let exploded = url.split('&');
            let newUrl = exploded[0]+'&'+exploded[1]+'&'+exploded[2]+'&page='+this.page;
            this.isLoadingBlade = true
            this.getPaginatedSpecializations(newUrl).then((res) => {// get records
                this.pagination.number = res.specializations.current_page;
                this.setPages(res.specializations);
            }).
            catch(() => {
                this.records = [];
                this.showErrorStatus = true;
                this.isLoadingBlade = false;
                this.errorMsg = "Server Failure!, Kindly reload the page...";
                this.errorType = 'danger';
            });
        },
        showEditPage(status, data) {
            this.specialization = null;
            this.title = status && data ? data.name +'\'s Sub Specializations' : 'Specialization Settings';
            this.displayEditPage = status;
            this.specialization = data;
        },
        editSpecialization(spec) {
            let name = spec && spec.name ? spec.name : '';
            let doctor_type_id = spec && spec.doctor_type_id ? spec.doctor_type_id : '';
            let options = '<option value="" selected disabled>--- Select One ---</option>';
            for(let type of this.doctorTypes) {
                options += '<option value="'+type.id+'">'+type.name+'</option>';
            }
            swal.fire({
                title: (spec ? 'Update ('+name+') Specialization' : 'Add New Specialization'),
                html:
                    '<br>'+
                    '<label><b>Specialization Name</b></label>' +
                    '<input id="swal-input1" class="swal2-input" value="'+ name+'">' +
                    '<label><b>Doctor Type</b></label><br>' +
                    '<select id="swal-input2" class="swal2-input" value="'+ doctor_type_id+'">'+
                    options+'</select>',
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: (spec ? 'Update it!' : 'Add Now!'),
                cancelButtonText: 'No, cancel!',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    let data =  {
                        'id' : spec ? spec.id : null,
                        'name' : document.getElementById('swal-input1').value,
                        'doctor_type_id' : document.getElementById('swal-input2').value,
                        'admin_id' : this.$store.state.user.userId // add admin user id
                    };
                    if(data && data.name && data.doctor_type_id) {//update
                        return this.updateSpecialization(data).then((response) => {
                            if (!response.status) {
                            throw new Error(response.msg)
                            }
                            return response
                        }).
                        catch((error) => {
                            swal.showValidationMessage(
                            `Request failed: ${error}`
                            )
                        });
                    } else {
                       swal.showValidationMessage(
                            `Request failed: Fill all necessary fields`
                        )
                    }
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then((res) => {
                if (res.value) {
                    let type = res.value.status ? 'success' : 'error';
                    let title = res.value.status ? 'Specialization '+res.value.type+'!' : 'Error Occurred!';
                    swal.fire(title, res.value.msg, type);
                    this.loadSpecializations();// refresh items
                }
            });
        },
        deleteSpecialization(id, type) {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Proceed!'
            }).then((result) => {
                if (result.value) {
                    this.makeDeleteApiCall(id, type);// make api call
                }
            });
        },
        makeDeleteApiCall(id, type) {
            let data = { 'id' : id, 'admin_id' : this.$store.state.user.userId, 'type' : type };
            this.preloader(); // show loading
            this.deleteUserSpecialization(data).then((res) => {// delete Specialization
                if(res.status) {
                    this.showEditPage(false);
                    this.loadSpecializations();// refresh page
                    swal.fire("Record Deleted", res.msg, "success");
                } else {
                    this.loadSpecializations();// refresh page
                    swal.fire("Error Occurred!", res.msg, "warning");
                }
            }).
            catch(err => {
                this.loadSpecializations();// refresh page
                swal.fire(err.message+"!", "An error occurred while deleting, try again", "warning");
            });
        },
        breakWord(word, length) {// break word into two parts
            if(word) {
                let textLength = word.length;
                let exploded = word.split(' ');
                if(word && textLength < length) {
                    return word;
                } else if(word) {
                    let formedWord = '';
                    let half = (exploded.length > 2) ? Math.ceil((exploded.length - 1)/2) : 0;
                    for(let phrase of exploded) {
                        formedWord += phrase+' ';
                        if(exploded[half] == phrase) {
                            formedWord += ' <br> ';
                        }
                    }
                    return formedWord;
                }
            }
            return 'N/A';
        },
        preloader() {
            return  swal.fire({
                title: 'Please Wait...',
                html: 'Trying to fetch data from <b>server</b>',
                // timer: 2000,
                timerProgressBar: true,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
        }
    },
    mounted() {
        this.loadSpecializations();
    }
  };
</script>
<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>
