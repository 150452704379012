
<template>
    <div>
        <div class="col-lg-12">
            <template>
                <tabs fill class="flex-column flex-md-row">
                    <card shadow>
                        <tab-pane title="Pending Applications">
                            <span slot="title">
                                <i class="ni ni-books"></i>
                                Pending Applications
                            </span>
                            <!-- import Pending Applications template here --> 
                            <PendingApplications
                                :schoolType="schoolType"
                                :type="type"
                                :visitStatus="'no'"
                            />
                        </tab-pane>

                        <tab-pane 
                            title="Verification Visit"
                            v-if="! isAdvisory"
                        >
                            <span slot="title">
                                <i class="ni ni-archive-2"></i>
                                Verification Visit
                            </span>
                            <!-- import Verification Visit template here -->
                            <PendingApplications
                                :schoolType="schoolType"
                                :type="type"
                                :visitStatus="'yes'"
                            />
                        </tab-pane>

                        <tab-pane title="Rejected Applications">
                            <span slot="title">
                                <i class="ni ni-badge"></i>
                                Rejected Applications
                            </span>
                            <!-- import Rejected Applications template here -->
                            <RejectedApplications
                                :schoolType="schoolType"
                                :type="type"
                                :status="'rejected'"
                            />
                        </tab-pane>

                        <tab-pane title="Approved Applications">
                            <span slot="title">
                                <i class="ni ni-archive-2"></i>
                                Approved Applications
                            </span>
                            <!-- import Approved Applications template here -->
                            <ApprovedApplications
                                :schoolType="schoolType"
                                :type="type"
                            />
                        </tab-pane>

                        <tab-pane title="Incomplete Applications">
                            <span slot="title">
                                <i class="ni ni-badge"></i>
                                Incomplete Applications
                            </span>
                            <!-- import Incomplete Applications template here -->
                            <RejectedApplications
                                :schoolType="schoolType"
                                :type="type"
                                :status="'pending'"
                            />
                        </tab-pane>

                    </card>
                </tabs>
            </template>
        </div>
    </div>
</template>
<script>
    import PendingApplications from './PendingApplications/PendingApplications';
    import RejectedApplications from './RejectedApplications/RejectedApplications';
    import ApprovedApplications from './ApprovedApplications/ApprovedApplications';

    export default {
        name: 'Applications',
        props: ['schoolType', 'type'],
        components: {
            PendingApplications,
            RejectedApplications,
            ApprovedApplications
        },
        data() {
            return {
                code: this.$route.params.code
            }
        },
        computed: {
            isAdvisory() {// check if it is an advisory application
                return (this.code == 'medical-schools-advisory-visit-applications') ||
                    (this.code == 'dental-schools-advisory-visit-applications');
            }
        }
    };
</script>
<style></style>
