<template>
    <div>
        <div class="row" v-if="records && records.length > 0">
            <div class="col-lg-12" v-if="! showAddPage">
                <div class="col-lg-8">
                    <small class="font-weight-bold">
                        Showing {{ pagination.from == 0 ? 1 : pagination.from }} - {{ pagination.to }} of
                        {{ pagination.total }} entries
                    </small>
                </div>
                <div class="card shadow">

                    <div class="table-responsive">
                        <base-table
                            class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="records"
                        >
                            <template slot="columns">
                                <th width="5%">S/N</th>
                                <th width="40%">CPD Provider</th>
                                <!-- <th width="20%">CPD Username</th> -->
                                <th width="25%">Status</th>
                                <th width="20%">Date Created</th>
                                <th width="10%">&nbsp;</th>
                            </template>

                            <template
                                slot-scope="{row, index}"
                            >
                            <td class="budget">
                                {{ pagination.from+index}}
                            </td>
                            <td class="text-capitalize">
                                {{ row.name ? row.name : 'N/A' }}
                                <br>
                                <badge size="lg"
                                    type="dark"
                                    class="text-capitalize"
                                    v-if="row.user_data && row.user_data.folio_number"
                                >
                                    {{ row.user_data.folio_number  }}
                                </badge>
                            </td>
                            <!-- <td>
                                {{ row.username ? row.username : 'N/A' }}
                            </td> -->
                            <td>
                                <badge size="lg"
                                    :type="getColor(row.status)"
                                    class="text-capitalize"
                                >
                                    {{ row.status }}
                                </badge>
                            </td>
                            <td>
                                {{ row.created_at | getDateFormat }}
                            </td>
                            <td>
                                <base-button
                                    :type="getColor(row.status, true)" size="sm"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    @click="modifyCpd(row.id, 'status')"
                                >
                                    <i :class="'fa fa-' +getIcon(row.status)"></i>
                                    {{ (row.status == 'enable') ? 'Deactivate' : 'Activate' }}
                                </base-button>

                                <base-button
                                    type="info" size="sm"
                                    :disabled="(privileges && privileges.update == 'no') || ! privileges"
                                    @click="$emit('editProvider', row)"
                                >
                                    <i class="fa fa-pen"></i> Edit
                                </base-button>

                                <base-button
                                    type="danger" size="sm"
                                    :disabled="(privileges && privileges.delete == 'no') || ! privileges"
                                    @click="modifyCpd(row.id, 'delete')"
                                >
                                    <i class="fa fa-trash"></i> Delete
                                </base-button>
                            </td>

                            </template>

                        </base-table>
                    </div>

                    <div
                        class="card-footer d-flex justify-content-end bg-transparent"
                        v-if="hasPagination"
                        @click="paginate"
                    >
                        <base-pagination
                            :total="pagination.total"
                            align="center"
                            v-model="pagination.number"
                            :perPage="pagination.perPage"

                        >
                        </base-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- import add accredited cpd template here -->
        <AddAccreditedCpd
            :data="record"
            :privileges="privileges"
            :loadAccreditedCpds="loadAccreditedCpds"
            v-on:addProvider="addProvider"
            v-if="showAddPage"
        />

    </div>
</template>

<script>
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import { mapActions, mapGetters } from 'vuex';
    import AddAccreditedCpd from './AddAccreditedCpd';

    export default {
        name: 'AccreditedCpdsData',
        props: ["records", "pagination", "hasPagination", "paginate", "privileges", "paginatedItems", "loadAccreditedCpds",
            "showAddPage", "addProvider", "record"],
        components: {
            AddAccreditedCpd
        },
        filters: {
            getDateFormat(value) {
                return value ? moment(value).format('Do MMM, YYYY') : 'N/A';
            }
        },
        computed: {
            ...mapGetters(['user'])
        },
        methods: {
            ...mapActions(['modifyDeleteCpd']),
            getColor(value, status = false) {
                if(status) {
                    return (value == "enable") ? 'warning' : 'success';
                } else {
                    return (value == "enable") ? 'success' : 'warning';
                }
            },
            getIcon(value) {
                return (value == "enable") ? 'ban' : 'check';
            },
            modifyCpd(id, status) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeModifyCpdApiCall(id, status);// make api call
                    }
                });
            },
            makeModifyCpdApiCall(id, option) {// modify / delete cpd record
                let data = { id : id, option : option, admin_id : this.user.id };
                this.preloader();// show loading
                this.modifyDeleteCpd(data).then((res) => {
                    if(res.status) {
                        let type = (option == 'status') ? 'Modified' : 'Deleted';
                        swal.fire('CPD Provider '+ type +'!', res.msg, 'success');
                    } else {
                        swal.fire('Error Occurred!', res.msg, 'error');
                    }
                    this.loadAccreditedCpds();// refresh page
                }).
                catch(() => {
                    let msg = "Server Failure, kindly reload the page...";
                    swal.fire('Server Failure!', msg, 'error');
                });
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>